import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LoadingOverlay from 'src/components/loader/loader';
import { usePosting } from 'src/hooks/postings';
import PostingForm from './form';
import styles from './postings.module.scss';

const ClonePosting = () => {
  const { t } = useTranslation('page_postings');
  const { postingId } = useParams();
  const posting = usePosting(postingId);

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <h1>{t('addApplicationForLongTermCommitment', 'Aanvraag langdurige inzet toevoegen')}</h1>
        {posting.isFetched ? <PostingForm posting={_.omit(posting.data, ['id', 'status'])} /> : <LoadingOverlay />}
      </div>
    </div>
  );
};

export default ClonePosting;
