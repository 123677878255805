import _ from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Award, Briefcase, Calendar, CreditCard, Users } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Button, { PlusButton } from 'src/components/button/button';
import DatePicker from 'src/components/datepicker/datepicker';
import { FormRow } from 'src/components/form/form';
import Checkbox from 'src/components/input_checkbox';
import { InputTime } from 'src/components/input_datetime';
import InputCurrency from 'src/components/input_number/input_currency';
import InputNumber from 'src/components/input_number/input_number';
import { InputText } from 'src/components/input_text/input_text';
import Select from 'src/components/select';
import Flags from 'src/config/flags';
import { emptyArray } from 'src/constants';
import { TeamShift, defaultCustomShiftTimes, getTeamShifts, makeShift } from 'src/pages/teams/constants';
import { formatDateHtml, formatTimeHtml, guid, timeHtmlFormat } from 'src/utils';
import { BookingEventTitle, normalizeBookingProperties } from '../booking';
import ShiftSelector from '../components/shiftSelector';
import { BookingDraftStatus, BookingType } from '../constants';
import bookingStyles from '../index.module.scss';
import DocumentsDialog from './documentsDialog';
import styles from './index.module.scss';

const IconRow = ({ children }) => <div className={styles.iconRow}>{children}</div>;

const BookingForm = ({
  // Options
  teamOptions = emptyArray,
  careSectorOptions = emptyArray,
  educationOptions = emptyArray,

  // Conditional visibility
  showTeam = true,
  showCareSector = true,
  showEducation = true,
  showDateTime = true,
  showHourlyRate = true,
  showManageDocuments = false,

  // Initial values
  draftEvent,
  postingBookingEvent,
  booking,

  customShiftTimes: initialCustomShiftTimes = defaultCustomShiftTimes,
  onChangeCustomShiftTimes = _.noop,

  /**
   * The minimum time (in minutes) that new bookings must be into the future.
   * To accept bookings from the current time, use 0, and to accept bookings 15 minutes from now, use 15.
   * @type {Number?}
   */
  minStartOffsetMinutes = 0,

  // Only admins can modify startTime, endTime, unbillableHours in the past, that is, they can request a duration change of any booking type.
  isDurationRequest = false,

  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation('page_planning');
  const [initialized, setInitialized] = useState(false);
  const draftBooking = draftEvent && draftEvent.booking;
  const isNewBooking =
    (draftBooking && draftEvent.meta.draftStatus === BookingDraftStatus.New) || (booking && !booking.id);
  const isDirect = (booking || draftBooking)?.type === BookingType.Direct;
  const isRegular = (booking || draftBooking)?.type === BookingType.Regular;

  const normalizedBookingProperties = useMemo(
    () => draftBooking || normalizeBookingProperties(draftEvent || booking || postingBookingEvent?.booking) || {},
    [booking, draftBooking, draftEvent, postingBookingEvent]
  );

  const now = moment();
  const today = moment(now).startOf('day');

  const {
    startDate: initialStartDate = today, // TODO: check if having a default is desired for *all* use cases of this dialog
    startTime: initialStartTime,
    endTime: initialEndTime,
    unbillableHours: initialUnbillableHours,
    team: initialTeam,
    teamId: initialTeamId,
    careSectorId: initialCareSectorId,
    degree: initialDegree,
    postingId,
    bookingId,
    information,
    hourlyRate: initialHourlyRate,
    documents: initialDocuments = emptyArray,
    caregiverId: initialCaregiverId,
    caregiver: initialCaregiver,
    ...initialRestOfBooking
  } = normalizedBookingProperties;

  // TODO: use date/time combination logic from `confirm` function also to compute start/end date/time values

  const [teamId, setTeamId] = useState(initialTeamId);
  const [degree, setDegree] = useState(initialDegree);
  const [careSectorId, setCareSectorId] = useState(initialCareSectorId);

  const initialStartDateTime = moment(initialStartDate)
    .startOf('day')
    .add(moment(initialStartTime).format(timeHtmlFormat));
  const initialEndDateTime = moment(initialStartDate).startOf('day').add(moment(initialEndTime).format(timeHtmlFormat));
  if (initialEndDateTime.isBefore(initialStartDateTime)) {
    initialEndDateTime.add(1, 'day');
  }

  const [startDate, setStartDate] = useState(initialStartDate);
  const [startTime, setStartTime] = useState(initialStartTime);
  const startDateTime = moment(startDate).startOf('day').add(moment(startTime, timeHtmlFormat).format(timeHtmlFormat));

  const [endTime, setEndTime] = useState(initialEndTime);
  const endDateTime = moment(startDate).startOf('day').add(moment(endTime, timeHtmlFormat).format(timeHtmlFormat));
  if (endDateTime.isBefore(startDateTime)) {
    endDateTime.add(1, 'day');
  }

  const [hasUnpaidBreak, setHasUnpaidBreak] = useState(!!initialUnbillableHours || false);
  const [unbillableMinutes, setUnbillableMinutes] = useState(initialUnbillableHours * 60);
  const [hourlyRate, setHourlyRate] = useState(initialHourlyRate);
  const [documentIds, setDocumentIds] = useState(initialDocuments.map((doc) => doc.id));
  const [caregiverId, setCaregiverId] = useState(initialCaregiverId);
  const [caregiver, setCaregiver] = useState(initialCaregiver);
  const [showDocumentsDialog, setShowDocumentsDialog] = useState(false);
  const [reasonForDurationRequest, setReasonForDurationRequest] = useState('');

  // TODO: when applying a positive `minStartOffsetMinutes`, just before midnight, no times may be valid. We'll need to handle this situation:
  //  - current time is 23:55
  //  - minimum offset is +15 minutes,
  //  - actual minimum date/time is _tomorrow_  at 00:10
  //  - so: actually for today no time is valid at all.
  // The time input currently has no way of doing this, but instead of making that an "always error" component we may want to choose a different solution.
  const minDateTime = isDurationRequest
    ? null
    : moment(now).add(minStartOffsetMinutes, 'minute').set({ second: 0, millisecond: 0 });

  const minDate = minDateTime ? moment(minDateTime).startOf('day') : null;

  // Require a minimum time value if there is a minimum date/time _and_ the current start date value in on that minimum date.
  const minTime = minDateTime && minDateTime.isSame(startDate, 'day') ? formatTimeHtml(minDateTime) : null;

  // Restrict max date to 2 weeks from now for Direct bookings
  const maxDate = Flags.enableDirectBookingMaxDate && isDirect ? moment(today).add(2, 'weeks') : null;

  const startDateTimeIsValid =
    startDateTime.isValid() &&
    (!minDateTime || startDateTime.isSameOrAfter(minDateTime, 'minute')) &&
    (!maxDate || startDateTime.isSameOrBefore(maxDate, 'day'));

  const endDateTimeIsValid = endDateTime.isValid();

  const startDateTimeIsDifferent = startDateTime.diff(initialStartDateTime) !== 0;
  const endDateTimeIsDifferent = endDateTime.diff(initialEndDateTime) !== 0;
  const unpaidBreakIsDifferent = Math.abs(initialUnbillableHours * 60 - (hasUnpaidBreak ? unbillableMinutes : 0)) > 0.5;

  const selectedTeam = teamOptions.find((t) => t.data.id === teamId);

  const availableDocuments = selectedTeam ? selectedTeam.data.documents : emptyArray;
  const filteredDocuments = useMemo(
    () =>
      availableDocuments &&
      availableDocuments.filter(
        (teamDoc) =>
          teamDoc.isActive ||
          (booking && booking.documents && booking.documents.some((bookingDoc) => bookingDoc.id === teamDoc.id))
      ),
    [availableDocuments, booking]
  );

  // Empty selected documents when the team is changed. Required because the documents belong to the team.
  useEffect(() => setDocumentIds([]), [teamId]);

  const readOnly = !onConfirm;

  const canSave =
    !readOnly &&
    (isDurationRequest
      ? startDateTimeIsValid &&
        endDateTimeIsValid &&
        (startDateTimeIsDifferent || endDateTimeIsDifferent || unpaidBreakIsDifferent)
      : draftEvent || booking
      ? teamId &&
        degree &&
        careSectorId &&
        startDateTimeIsValid &&
        endDateTimeIsValid &&
        (!isDirect || Boolean(hourlyRate))
      : postingBookingEvent
      ? teamId && startDateTimeIsValid && endDateTimeIsValid
      : false);

  const [shiftKey, setShiftKey] = useState(TeamShift.Early);
  const [customShiftTimes, setCustomShiftTimes] = useState(initialCustomShiftTimes);

  const team =
    initialTeam && teamId === initialTeam.id
      ? initialTeam
      : teamId
      ? teamOptions.find((option) => option.value === teamId)?.data || null
      : null;

  const shifts = useMemo(() => getTeamShifts(team, customShiftTimes), [customShiftTimes, team]);

  const selectShift = useCallback(
    (shiftKey, shiftTimes = shifts[shiftKey]) => {
      setShiftKey(shiftKey);

      setStartTime(shiftTimes.start);
      setEndTime(shiftTimes.end);
    },
    [shifts]
  );

  const autoSelectShift = useCallback(
    (start, end, shifts_ = shifts) => {
      const shiftTimes = makeShift(start, end);
      const shiftKey = _.findKey(shifts_, shiftTimes);
      if (shiftKey) {
        setShiftKey(shiftKey);
      } else {
        setShiftKey(TeamShift.Custom);

        if (shiftTimes) {
          setCustomShiftTimes(shiftTimes);
          onChangeCustomShiftTimes(shiftTimes);
        }
      }
    },
    [onChangeCustomShiftTimes, shifts]
  );

  const updateStartDate = (date) => {
    setStartDate(date);
  };

  const updateStartTime = (time) => {
    setStartTime(time);
    autoSelectShift(time, endTime);
  };

  const updateEndTime = (time) => {
    setEndTime(time);
    autoSelectShift(startTime, time);
  };

  const updateHourlyRate = (hourlyRate) => {
    setHourlyRate(hourlyRate);
  };

  const applyTeamDefaults = useCallback(
    (team, force = false) => {
      if (force || !careSectorId) {
        setCareSectorId(team.careSectorId);
      }

      const shifts = getTeamShifts(team, customShiftTimes);
      if ((force || (!startTime && !endTime)) && shiftKey !== TeamShift.Custom) {
        selectShift(shiftKey, shifts[shiftKey]);
      } else {
        autoSelectShift(startTime, endTime, shifts);
      }
    },
    [autoSelectShift, careSectorId, customShiftTimes, endTime, selectShift, shiftKey, startTime]
  );

  // Select team, and apply applicable team values to the booking
  // When force is false, only fields that are currently empty will get a new value
  const selectTeam = useCallback(
    (teamId, force = true) => {
      setTeamId(teamId);
      applyTeamDefaults(teamOptions.find((option) => option.value === teamId).data, force);
    },
    [applyTeamDefaults, teamOptions, setTeamId]
  );

  // Auto-fill applicable fields on initialize
  useEffect(() => {
    if (!initialized) {
      if (team) {
        // TODO: don't apply defaults for existing bookings when reusing this form
        applyTeamDefaults(team, false);
      } else if (teamOptions.length === 1) {
        selectTeam(teamOptions[0].value, false);
      }

      setInitialized(true);
    }
  }, [applyTeamDefaults, careSectorId, initialized, selectTeam, team, teamOptions]);

  // Reset caregiver when certain fields change
  useEffect(() => {
    setCaregiverId();
    setCaregiver();
  }, [teamId, careSectorId, degree, startDate, startTime, endTime, hourlyRate]);

  const cancel = () => onCancel();

  const confirmDraftBookingEvent = ({ start, end, unbillableHours }) => {
    const duration = moment.duration(end.diff(start)).asHours();

    // convert regular booking to draft for when planner wants to find another caregiver
    const meta = {
      isDraft: true,
      draftStatus: BookingDraftStatus.New,
      draftId: guid(),
      isNewAlert: false,
      isHistory: moment(end).isBefore(new Date()),
    };

    const event = {
      start: start.toDate(),
      end: end.toDate(),

      booking: {
        ...initialRestOfBooking,
        bookingDateTime: start.toDate(),
        duration,
        unbillableHours,
        teamId,
        team,
        careSectorId,
        postingId,
        degree,
        documentIds,
        caregiverId,
        caregiver,

        // TODO: these fields are only required while creating bookings, refactor this away when coming up with a good internal model
        startDate: formatDateHtml(start),
        startTime: formatTimeHtml(start),
        endTime: formatTimeHtml(end),
      },

      meta: {
        ...(draftEvent?.meta || meta),
        draftStatus: BookingDraftStatus.Added,
      },

      className: bookingStyles.addedDraft,
    };

    event.title = <BookingEventTitle event={event} />;

    onConfirm(event);
  };

  const confirmDurationRequest = ({ start, end, unbillableHours }) => {
    const durationRequest = {
      bookingId,
      startTime: moment(start).toISOString(true),
      endTime: moment(end).toISOString(true),
      unbillableHours,
      reason: reasonForDurationRequest,
    };

    onConfirm(durationRequest);
  };

  const confirmPostingBookingEvent = ({ start, end, unbillableHours }) => {
    const postingEvent = {
      booking: {
        ...initialRestOfBooking,
        startDate: start.toDate(),
        endDate: end.toDate(),
        bookingId: bookingId,
        postingId: postingId,
        unbillableHours: unbillableHours,
      },
    };

    onConfirm(postingEvent);
  };

  // Using for creating/editing a direct booking and creating a direct booking from a rejected booking
  const confirmDirectBooking = ({ start, end, unbillableHours }) => {
    const directBookingEvent = {
      ...initialRestOfBooking,
      beginTime: formatTimeHtml(start),
      endTime: formatTimeHtml(end),
      bookingDate: start.toDate(),
      hourlyRate,
      degree,
      unbillableHours,
      careSectorId,
      bookingId: booking.type === BookingType.Direct ? bookingId : null, // null for new booking
      districtTeamId: teamId,
      documentIds,
      information,
      caregiverId,
      caregiver,
    };

    onConfirm(directBookingEvent);
  };

  // TODO: design a solid data format for bookings vs draft bookings vs events (the current version is a bit messy)
  // TODO: this function has large overlap with functionality in bookings.js (and depends on the same shape/values), need to merge/move there
  const confirm = () => {
    const startTimeMoment = moment(startTime, 'HH:mm:ss'); // NB: this will actually parse times in 'HH:mm' format correctly as well
    const endTimeMoment = moment(endTime, 'HH:mm:ss');

    const start = moment(startDate).set({
      hour: startTimeMoment.hour(),
      minute: startTimeMoment.minute(),
      second: 0,
      millisecond: 0,
    });

    const end = moment(startDate).set({
      hour: endTimeMoment.hour(),
      minute: endTimeMoment.minute(),
      second: 0,
      millisecond: 0,
    });

    if (start.isAfter(end)) {
      end.add(1, 'day');
    }

    const unbillableHours = hasUnpaidBreak ? unbillableMinutes / 60 : 0;

    if (isDurationRequest) {
      confirmDurationRequest({ start, end, unbillableHours });
    } else if (draftEvent || isRegular) {
      confirmDraftBookingEvent({ start, end, unbillableHours });
    } else if (postingBookingEvent) {
      confirmPostingBookingEvent({ start, end, unbillableHours });
    } else if (isDirect) {
      confirmDirectBooking({ start, end, unbillableHours });
    }
  };

  return (
    <div className={styles.bookingMiniForm}>
      <FormRow>
        {showTeam && (
          <IconRow>
            <Users />
            <Select
              readOnly={readOnly}
              options={teamOptions}
              value={teamId}
              onChange={selectTeam}
              placeholder={t('dialogs.bookingForm.chooseTeamPlaceholder', 'Kies een team...')}
            />
          </IconRow>
        )}

        {showCareSector && (
          <IconRow>
            <Briefcase />
            <Select
              readOnly={readOnly}
              options={careSectorOptions}
              value={careSectorId}
              onChange={setCareSectorId}
              placeholder={t('dialogs.bookingForm.chooseHealthCareProviderPlaceholder', 'Kies een zorgsector...')}
            />
          </IconRow>
        )}
      </FormRow>

      <FormRow>
        {showEducation && (
          <IconRow>
            <Award />
            <Select
              readOnly={readOnly}
              options={educationOptions}
              value={degree}
              onChange={setDegree}
              placeholder={t('dialogs.bookingForm.chooseEducationCoursePlaceholder', 'Kies een opleiding...')}
            />
          </IconRow>
        )}
        {showHourlyRate && (
          <IconRow>
            <CreditCard />
            <InputCurrency
              readOnly={readOnly}
              placeholder={t('dialogs.bookingForm.hourlyRateInCurrencyPlaceholder', 'Uurtarief in euros')}
              onChange={updateHourlyRate}
              value={hourlyRate}
            />
          </IconRow>
        )}
      </FormRow>

      {showDateTime && (
        <div className={styles.flexRow}>
          <IconRow>
            <Calendar />
            <DatePicker
              value={startDate}
              // disable when it's a draftEvent, a postingBookingEvent or when a booking already exists.
              disabled={Boolean(readOnly || draftEvent || postingBookingEvent || (booking.id && !isDirect))}
              minDate={minDate}
              maxDate={maxDate}
              placeholder={t('dialogs.bookingForm.bookingDatePlaceholder', 'Boekingsdatum')}
              onChange={updateStartDate}
            />
          </IconRow>

          <div className={styles.timesRow}>
            <ShiftSelector
              readOnly={readOnly}
              shifts={shifts}
              value={shiftKey}
              onChange={selectShift}
              disabled={!team}
            />

            <div className={styles.inputTimes}>
              <InputTime
                readOnly={readOnly}
                value={teamId ? formatTimeHtml(startTime) : ''}
                min={minTime}
                onChange={updateStartTime}
                placeholder={t('dialogs.bookingForm.startTime', 'Starttijd')}
                disabled={!teamId}
              />
              <span className={styles.dash}>-</span>
              <InputTime
                readOnly={readOnly}
                value={teamId ? formatTimeHtml(endTime) : ''}
                onChange={updateEndTime}
                placeholder={t('dialogs.bookingForm.endTime', 'Eindtijd')}
                disabled={!teamId}
              />
            </div>
          </div>

          <div className={styles.checkboxRow}>
            <Checkbox
              readOnly={readOnly}
              value={hasUnpaidBreak}
              onChange={setHasUnpaidBreak}
              label={t('dialogs.bookingForm.includingUnpaidBreak', 'Inclusief onbetaalde pauze (in minuten)')}
              disabled={Boolean(postingBookingEvent)}
            ></Checkbox>
            {hasUnpaidBreak && (
              <InputNumber
                readOnly={readOnly}
                onChange={setUnbillableMinutes}
                value={unbillableMinutes}
                min={0}
                step={1}
              />
            )}
          </div>

          {isDurationRequest && (
            <InputText placeholder={t('dialogs.bookingForm.reason', 'Reden')} onChange={setReasonForDurationRequest} />
          )}
        </div>
      )}
      {teamId && showManageDocuments && (
        <div className={styles.addDoc}>
          <PlusButton small onClick={() => setShowDocumentsDialog(true)} />
          <label>{t('dialogs.bookingForm.addOrRemoveDocuments', 'Documenten toevoegen of verwijderen')}</label>
        </div>
      )}

      {showDocumentsDialog && (
        <DocumentsDialog
          readOnly={readOnly}
          options={filteredDocuments}
          onChange={setDocumentIds}
          value={documentIds}
          onClose={() => setShowDocumentsDialog(false)}
          showActions
        />
      )}

      <div className={styles.actions}>
        <Button secondary onClick={cancel}>
          {t('dialogs.bookingForm.cancel', 'Annuleren')}
        </Button>
        <Button disabled={!canSave} onClick={confirm}>
          {isNewBooking ? t('dialogs.bookingForm.add', 'Toevoegen') : t('dialogs.bookingForm.edit', 'Aanpassen')}
        </Button>
      </div>
    </div>
  );
};

export default BookingForm;
