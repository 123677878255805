import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';
import CareAgreementForPostingReaction from './agreement';
import EditPostingReaction from './edit';
import ListPostingReactions from './list';
import ViewPostingReaction from './view';

const PostingReactions = ({ role, posting, reactions }) => {
  const { t } = useTranslation('page_postings');
  const navigate = useNavigate();
  // const { hasRole } = useUserInformation();

  // const isRecruiter = hasRole('Recruiter');
  // const isCaregiver = hasRole('Caregiver');
  // TODO: this is an ugly quick workaround to get reaction editing to work, work out a better solution (and update postings/index.js to forget about this role prop)
  const isAdmin = role === 'Admin';
  const isRecruiter = role === 'Recruiter';
  const isCaregiver = role === 'Caregiver';

  return (
    <Routes>
      {/*
      <Route path="add" element={
        <AddPostingReaction/>
      }/>
      <Route path=":reactionId" element={
        <ViewPostingReaction />
      }/>
      <Route path=":reactionId/edit" element={
        <EditPostingReaction />
      }/>
      */}
      {(isRecruiter || isAdmin) && <Route index element={<ListPostingReactions posting={posting} />} />}
      {isCaregiver && (
        <>
          {/* <Route path="mine"> */}
          <Route
            index
            element={
              <ViewPostingReaction
                posting={posting.data}
                reactions={reactions}
                placeholder={t('reactions.index.noResponseYet', 'Je hebt nog niet gereageerd')}
              />
            }
          />
          <Route path=":reactionId/edit" element={<EditPostingReaction onCloseForm={() => navigate('')} />} />
        </>
      )}
      {(isCaregiver || isRecruiter || isAdmin) && (
        <Route path=":reactionId/agreement" element={<CareAgreementForPostingReaction />} />
      )}
    </Routes>
  );
};

export default PostingReactions;
