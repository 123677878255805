import clsx from 'clsx';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Form, FormRow } from '../form/form';
import LabeledFormInput from '../form/labeled_form_input';
import InputText from '../input_text';
import AzureStaticMap from '../map/map';
import SelectCountry from '../select/select_country';
import styles from './address.module.scss';
import { fields } from './constants';

const AddressForm = ({
  address,
  showExtra = true,
  showCountry = true,
  lockCountry = false,
  onChange,
  className,
  compact = true,
}) => {
  const { t } = useTranslation('components');
  const updateModel = (key) => (value) =>
    onChange({
      ...address,
      [key]: value,
    });

  const bindModel = (label, key, defaultValue, component, props = {}) => (
    <LabeledFormInput
      component={component}
      label={label}
      value={_.defaultTo(_.get(address, key), defaultValue)}
      onChange={updateModel(key)}
      {...props}
    />
  );

  return (
    <Form className={clsx(styles.form, className)} compact={compact}>
      <div className={clsx(styles.info)}>
        <FormRow>
          {bindModel(t('street', 'Straat'), fields.street, '', InputText)}
          {bindModel(t('houseNumber', 'Huisnummer'), fields.houseNumber, '', InputText)}
        </FormRow>
        {showExtra && (
          <FormRow>
            {bindModel(t('additionalAddressInformation', 'Extra adresinformatie'), fields.extension, '', InputText)}
          </FormRow>
        )}
        <FormRow>
          {bindModel(t('postcode', 'Postcode'), fields.postcode, '', InputText)}
          {bindModel(t('city', 'Plaats'), fields.city, '', InputText)}
        </FormRow>
        {showCountry && (
          <FormRow>
            {bindModel(t('country', 'Land'), fields.countryCode, null, SelectCountry, { readOnly: lockCountry })}
          </FormRow>
        )}
      </div>

      <div className={styles.map}>
        <AzureStaticMap lat={address.lat} lng={address.lng} width={300} height={300} alt={t('location', 'Locatie')} />
      </div>
    </Form>
  );
};

export default AddressForm;
