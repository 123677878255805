import clsx from 'clsx';
import moment from 'moment';
import { useState } from 'react';
import { Award, Briefcase, Clock, Coffee, CreditCard, FileText, Users } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'src/components/button/button';
import { YesNoDialog } from 'src/components/dialog/dialog';
import Information from 'src/components/information';
import Avatar from 'src/components/user/avatar';
import { useUserInformation } from 'src/hooks/users';
import { formatBookingTimeInfoFromStartAndDuration, formatCurrency, formatDuration } from 'src/utils/format';
import { duplicateBooking, normalizeBookingProperties } from '../booking';
import BookingLabel from '../components/bookingLabel';
import {
  BookingBillingStatus,
  BookingStatus,
  BookingStatusHistoryLabels,
  BookingStatusLabels,
  BookingType,
} from '../constants';
import styles from './bookingDetails.module.scss';
import DirectBookingDialog from './directBookingDialog';
import DocumentsDialog from './documentsDialog';
import DraftBookingDialog from './draftBookingDialog';
import EditBookingDialog from './editBookingDialog';

const BookingDetails = ({
  bookingEvent,
  revokeBooking,
  educationOptions,
  saveDirectRequest,
  saveDurationRequest,
  teamOptions,
  careSectorOptions,
  confirmDraftEvent,
  onEdit,
  onClose,
}) => {
  const { t } = useTranslation(['page_planning', 'common']);
  const { hasRole } = useUserInformation();

  const isAdmin = hasRole('Admin');

  const normalizedBooking = normalizeBookingProperties(bookingEvent.booking);

  const isFutureBooking = moment(normalizedBooking.startTime).isAfter(moment());
  const is48hFutureBooking = moment(normalizedBooking.startTime).isAfter(moment().add(48, 'hours'));

  // Contact information is allowed to be visible only once the caregiver accepted the booking
  // (cancelled bookings were accepted at one point too)
  const canSeeContactInfo = [BookingStatus.Accepted, BookingStatus.CancelledByCaregiver].includes(
    normalizedBooking.status
  );

  // Bookings can be cancelled by the planner as long as the selected caregiver (non-direct bookings) or any caregiver (direct bookings)
  // hasn't responded yet (i.e., status is Created), and they are in the future, and we received a `revokeBooking` function.
  const canBeCancelledByPlanner =
    isFutureBooking && normalizedBooking.status === BookingStatus.Created && revokeBooking;

  // Accepted bookings can be cancelled by the caregiver up to 48 hours before it takes place,
  // in other words: they can be cancelled when the booking date/time is at least 48 hours into the future.
  // TODO: why is this here? This component (and pretty much the entire Home) is not used by caregivers???
  const canBeCancelledByCaregiver = is48hFutureBooking && normalizedBooking.status === BookingStatus.Accepted;

  // Editing a direct booking is allowed when the booking's status is Created, and not yet accepted.
  const canEditDirectBooking =
    isFutureBooking &&
    normalizedBooking.type === BookingType.Direct &&
    normalizedBooking.status === BookingStatus.Created &&
    Boolean(saveDirectRequest);

  // Making a direct booking or finding another caregiver from a booking is allowed if the booking has been rejected
  // and it is in the future.
  // TODO: do we also want to allow finding another caregiver if they do not confirm the booking within a reasonable amount of time?
  const canCreateDirectBookingOrFindAnotherCaregiver =
    isFutureBooking && normalizedBooking.status === BookingStatus.Rejected && Boolean(confirmDraftEvent);

  // Hours can only be forced by admins in period where a caregiver nor planner
  // can change or revoke the booking
  // TODO Use a state that tells that caregiver did not work on the booking
  const canForceBookingDuration =
    isAdmin &&
    normalizedBooking.status === BookingStatus.Accepted &&
    [
      BookingBillingStatus.BillingNotStarted,
      BookingBillingStatus.CaregiverApproved,
      BookingBillingStatus.InDispute,
      BookingBillingStatus.WillNotBeBilled,
      BookingBillingStatus.BothPartiesApproved,
    ].includes(normalizedBooking.billingStatus) &&
    !is48hFutureBooking;

  const team =
    normalizedBooking.team ||
    (teamOptions && teamOptions.find((opt) => opt.value === normalizedBooking.teamId)?.data) ||
    null;

  const [showEditBookingDurationDialog, setShowEditBookingDurationDialog] = useState(false);

  const [showEditDirectBookingDialog, setShowEditDirectBookingDialog] = useState(false);

  const [dialogData, setDialogData] = useState();

  const [draftBooking, setDraftBooking] = useState();

  const [showDocumentsDialog, setShowDocumentsDialog] = useState(false);

  const showDialog = (labelQuestion, onConfirm) =>
    setDialogData({
      labelQuestion,
      onConfirm,
      onCancel: () => setDialogData(),
    });

  const confirmRevokeBooking = () =>
    showDialog(
      t('dialogs.bookingDetails.confirmRevokeBookingDialogQuestion', {
        defaultValue: 'Weet je zeker dat je de boeking van {{bookingTime}} wilt intrekken?',
        bookingTime: formatBookingTimeInfoFromStartAndDuration(normalizedBooking.startTime, normalizedBooking.hours),
      }),
      () => revokeBooking(normalizedBooking.id)
    );

  const confirmCancelBooking = () =>
    showDialog(
      t('dialogs.bookingDetails.confirmCancelBookingDialogQuestion', {
        defaultValue: 'Weet je zeker dat je de boeking van {{bookingTime}} wilt verwijderen?',
        bookingTime: formatBookingTimeInfoFromStartAndDuration(normalizedBooking.startTime, normalizedBooking.hours),
      }),
      () => revokeBooking(normalizedBooking.id)
    );

  const confirmSaveDirectRequest = (directRequest) =>
    showDialog(t('dialogs.bookingDetails.confirmDirectRequestDialogQuestion', 'Directverzoek bevestigen'), () =>
      saveDirectRequest(directRequest)
    );

  const confirmUpdateDirectRequest = (directRequest) =>
    showDialog(
      t('dialogs.bookingDetails.confirmUpdateDirectRequestDialogQuestion', 'Directverzoek bijwerken bevestigen'),
      () => saveDirectRequest(directRequest)
    );

  const confirmSaveDurationRequest = (durationRequest) => {
    showDialog(
      t(
        'dialogs.bookingForm.confirmDurationRequestDialogQuestion',
        'Weet u zeker dat u een wijziging van de duur wilt aanvragen?'
      ),
      () => saveDurationRequest(durationRequest)
    );
  };

  const findAnotherCaregiver = () => {
    setDraftBooking(duplicateBooking(normalizedBooking));
  };

  const createDirectBooking = () => {
    setDraftBooking(duplicateBooking(normalizedBooking), {
      type: BookingType.Direct,
    });
  };

  const statusLabel = (bookingEvent.meta.isHistory ? BookingStatusHistoryLabels(t) : BookingStatusLabels(t))[
    normalizedBooking.status
  ];

  const { caregiver } = normalizedBooking;
  const caregiverFullName = caregiver ? `${caregiver.firstName} ${caregiver.lastName}` : null;

  return (
    <div className={styles.container}>
      {caregiver ? (
        <section className={styles.userInfo} data-has-icon data-user-id={caregiver.id}>
          <div className={styles.userImageContainer}>
            <Avatar src={caregiver.profilePictureUrl} alt={caregiverFullName} title={caregiverFullName} medium />
          </div>

          <div className={styles.info}>
            <h3>{caregiverFullName}</h3>
            {canSeeContactInfo && (
              <>
                <div>
                  {caregiver.email ? (
                    <a href={`mailto:${caregiver.email}`} target="_blank" rel="noopener noreferrer">
                      {caregiver.email}
                    </a>
                  ) : (
                    t('dialogs.bookingDetails.noEmailSpecified', 'Geen email opgegeven')
                  )}
                </div>
                <div>
                  {caregiver.email ? (
                    <a href={`tel:${caregiver.phoneNumber}`} target="_blank" rel="noopener noreferrer">
                      {caregiver.phoneNumber}
                    </a>
                  ) : (
                    t('dialogs.bookingDetails.noPhoneNumberProvided', 'Geen telefoonnummer opgegeven')
                  )}
                </div>
                <div>{caregiver.highestDegreeOfEducation}</div>
              </>
            )}
            <div className={styles.infoList}>
              <BookingLabel status={normalizedBooking.status}>{statusLabel}</BookingLabel>
            </div>
          </div>
        </section>
      ) : (
        <div className={clsx(styles.infoList, styles.labels)}>
          <BookingLabel status={normalizedBooking.status}>{statusLabel}</BookingLabel>

          {normalizedBooking.type === BookingType.Direct &&
            ![BookingStatus.Accepted, BookingStatus.CancelledByCaregiver].includes(normalizedBooking.status) && (
              <BookingLabel type={normalizedBooking.type}>
                {t('dialogs.bookingDetails.directRequest', 'Direct-verzoek')}
              </BookingLabel>
            )}
        </div>
      )}

      <h3 className={styles.sectionTitle}>
        {t('dialogs.bookingDetails.bookingInformationHeader', 'Boekingsinformatie')}
      </h3>

      <section className={styles.bookingInfo}>
        <div className={styles.body}>
          <div className={styles.infoList} data-has-icon>
            <Users />
            {team ? (
              <div>
                <div>
                  <span>{team.name ?? t('dialogs.bookingDetails.noAccess', '(geen toegang)')} </span>
                </div>
                <span className={styles.smallInfo}>{team.typeOfCare}</span>
              </div>
            ) : !normalizedBooking.teamId ? (
              <div>-</div>
            ) : (
              <div>
                <i>{t('dialogs.bookingDetails.noInformation', '(geen informatie)')}</i>{' '}
                <Information>
                  {t(
                    'dialogs.bookingDetails.notPlannerOfThisTeam',
                    'Deze boeking is voor een team waar je geen planner van bent'
                  )}
                </Information>
              </div>
            )}
          </div>
          <div className={styles.infoList} data-has-icon>
            <Briefcase />
            <span>{normalizedBooking.careSector?.name || normalizedBooking.careSectorName || '-'}</span>
          </div>
          <div className={styles.infoList} data-has-icon>
            <Award />
            <span>{normalizedBooking.degree || (caregiver && caregiver.highestDegreeOfEducation) || '-'}</span>
          </div>
          <div className={styles.infoList} data-has-icon>
            <Clock />
            {formatBookingTimeInfoFromStartAndDuration(normalizedBooking.startTime, normalizedBooking.hours)}
            <span className={styles.bookingDuration}>
              <i>
                ({formatDuration(normalizedBooking.hours)} {t('dialogs.bookingDetails.hours', 'uur')})
              </i>
            </span>
          </div>
          {normalizedBooking.unbillableHours > 0 && (
            <div className={styles.infoList} data-has-icon>
              <Coffee />
              <span>
                <i>
                  (
                  {t('dialogs.bookingDetails.inclUnbillableHours', {
                    defaultValue: 'incl. {{unbillableHours}} {{hours}} onbetaalde pauze',
                    hours: t('common:hour', { count: normalizedBooking.unbillableHours }),
                    unbillableHours: formatDuration(normalizedBooking.unbillableHours),
                  })}
                  )
                </i>
              </span>
            </div>
          )}

          <div className={styles.infoList} data-has-icon>
            <CreditCard />
            <div>
              <div>Totaal: {formatCurrency(normalizedBooking.totalPrice)}</div>
              <div className={styles.smallInfo}>
                <span>
                  {formatCurrency(normalizedBooking.hourlyRate)}{' '}
                  {t('dialogs.bookingDetails.hourlyRateUnit', 'p/u voor')}{' '}
                </span>
                <span>
                  {formatDuration(normalizedBooking.hours)} {t('dialogs.bookingDetails.hours', 'uur')}
                </span>
              </div>
            </div>
          </div>

          <div className={styles.infoList} data-has-icon>
            <FileText />
            <Link to={`/bookings/${normalizedBooking.id}/agreement/`} target="_blank">
              {t(
                normalizedBooking.status === BookingStatus.Accepted
                  ? 'dialogs.bookingDetails.viewAgreement'
                  : 'dialogs.bookingDetails.viewAgreementPreview'
              )}
            </Link>
          </div>

          {normalizedBooking.documents.length > 0 && (
            <div className={styles.infoList} data-has-icon>
              <FileText />
              <span className={styles.toggleDocs} onClick={setShowDocumentsDialog}>
                {t('dialogs.bookingDetails.viewDocuments')}
              </span>
            </div>
          )}
        </div>
        <p className={styles.id}>
          {t('dialogs.bookingDetails.id', 'Id')}: {normalizedBooking.id}
        </p>
      </section>

      <section className={styles.actions}>
        {canBeCancelledByPlanner && (
          <Button invert onClick={confirmRevokeBooking}>
            {t('dialogs.bookingDetails.withdrawBooking', 'Boeking intrekken')}
          </Button>
        )}
        {canBeCancelledByCaregiver && (
          <Button invert onClick={confirmCancelBooking}>
            {t('dialogs.bookingDetails.cancelBooking', 'Boeking annuleren')}
          </Button>
        )}

        {canCreateDirectBookingOrFindAnotherCaregiver && (
          <>
            <Button onClick={createDirectBooking}>
              {t('dialogs.bookingDetails.createDirectRequest', 'Maak een Direct-verzoek')}
            </Button>
            <Button invert onClick={findAnotherCaregiver}>
              {t('dialogs.bookingDetails.findAnotherHealthCareProvider', 'Zoek een andere zorgverlener')}
            </Button>
          </>
        )}

        {canEditDirectBooking && (
          <Button onClick={setShowEditDirectBookingDialog}>
            {t('dialogs.bookingDetails.editBooking', 'Boeking bewerken')}
          </Button>
        )}

        {canForceBookingDuration && (
          <Button onClick={setShowEditBookingDurationDialog}>
            {t('dialogs.bookingDetails.buttonLabelChangeDuration', 'Uren forceren')}
          </Button>
        )}

        {onEdit && <Button onClick={onEdit}>{t('dialogs.bookingDetails.editBooking', 'Boeking bewerken')}</Button>}
      </section>

      {dialogData && <YesNoDialog {...dialogData} />}

      {showEditDirectBookingDialog ? (
        <EditBookingDialog
          booking={normalizedBooking}
          teamOptions={teamOptions}
          careSectorOptions={careSectorOptions}
          educationOptions={educationOptions}
          showManageDocuments
          onConfirm={confirmUpdateDirectRequest}
          onCancel={() => setShowEditDirectBookingDialog(false)}
        />
      ) : showEditBookingDurationDialog ? (
        <EditBookingDialog
          booking={normalizedBooking}
          isDurationRequest
          showTeam={false}
          showCareSector={false}
          showEducation={false}
          showHourlyRate={false}
          onCancel={() => setShowEditBookingDurationDialog(false)}
          onConfirm={confirmSaveDurationRequest}
        />
      ) : draftBooking ? (
        draftBooking.type === BookingType.Direct ? (
          <DirectBookingDialog
            booking={draftBooking}
            educationOptions={educationOptions}
            onConfirm={confirmSaveDirectRequest}
            onCancel={() => setDraftBooking()}
          />
        ) : (
          <DraftBookingDialog
            // filled in data
            booking={draftBooking}
            // options
            teamOptions={teamOptions}
            careSectorOptions={careSectorOptions}
            educationOptions={educationOptions}
            // event handlers
            onConfirm={confirmDraftEvent}
            onCancel={() => setDraftBooking()}
            onClose={onClose}
          />
        )
      ) : null}

      {showDocumentsDialog && (
        <DocumentsDialog options={normalizedBooking.documents} onClose={() => setShowDocumentsDialog(false)} />
      )}
    </div>
  );
};

export default BookingDetails;
