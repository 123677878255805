import _ from 'lodash';
import { useMemo } from 'react';
import Calendar from 'src/components/calendar';
import { emptyArray } from 'src/constants';
import styles from './index.module.scss';

const BookingCalendar = ({
  date,
  onNavigate,
  events = emptyArray,
  eventFilters = emptyArray,
  draftEvents = emptyArray,
  draftEventsListComponent: DraftEventsListComponent,
  draftEvent,
  toolbars = emptyArray,
  ...props
}) => {
  // Combine provided event filters into a single callable filter
  const combinedFilterFunc = useMemo(() => _.flow(eventFilters), [eventFilters]);

  // Filter events using the combined filters
  const filteredEvents = useMemo(() => combinedFilterFunc(events), [combinedFilterFunc, events]);

  // Combine filtered events + draft events into a list of events to show
  const visibleEvents = useMemo(() => {
    const result = filteredEvents.concat(draftEvents);
    return draftEvent && !_.some(draftEvents, ['meta.draftId', draftEvent.meta.draftId])
      ? result.concat(draftEvent)
      : result;
  }, [draftEvent, draftEvents, filteredEvents]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.main}>
        {toolbars}

        <Calendar
          className={styles.calendar}
          // Allow selecting dates, i.e., creating events
          selectable
          // When events don't fit in the view, allow a popup to show up with more results
          popup
          // omit 'agenda' view for now because its range isn't properly constrained to a month like we require
          // views={['month', 'week', 'day']}
          // in fact, omit everything but month view for now, other views need fixing as well
          views={['month']}
          // Active date. This is used to e.g. show the correct month or week
          date={date}
          // Events to show
          events={visibleEvents}
          // Handle navigation
          onNavigate={onNavigate}
          // onRangeChange={onRangeChange}
          // Handle selecting a date slot or a range of date slots
          // onSelectSlot={onSelectSlot}
          // Show all events instead of hiding some behind a "show more" popup
          showAllEvents={true}
          // Forward remaining props to Calendar
          {...props}
        />
      </div>

      {!_.isEmpty(draftEvents) && DraftEventsListComponent && (
        <div className={styles.sidebar}>
          <DraftEventsListComponent drafts={draftEvents} />
        </div>
      )}
    </div>
  );
};

export default BookingCalendar;
