import clsx from 'clsx';
import InputSearch from 'src/components/input_search';
import Select from 'src/components/select';
import styles from './index.module.scss';

const Filter = ({ type = 'select', ...props }) => {
  switch (type) {
    case 'select':
      return <Select {...props} showClear />;

    case 'search':
      return <InputSearch minLength={props.minLength || 0} {...props} />;

    default:
      return `invalid filter type: ${type}`;
  }
};

const FilterToolbar = ({ label, filters, className }) => {
  return (
    <div className={clsx(styles.toolbar, styles.filterToolbar, className)}>
      {label && <label>{label}</label>}
      {filters.map(Filter)}
    </div>
  );
};

export default FilterToolbar;
