import { useTranslation } from 'react-i18next';
import Dialog from 'src/components/dialog';
import BookingForm from './bookingForm';

const StartEndTimeDialog = (props) => {
  const { t } = useTranslation('page_planning');
  return (
    <Dialog
      showActions={false}
      labelQuestion={t('dialogs.startEndTime.completeTimesDialogQuestion', 'Vul de tijden aan')}
      // required for supporting "click behind to close"
      onCancel={props.onCancel}
    >
      <BookingForm showTeam={false} showCareSector={false} showEducation={false} showHourlyRate={false} {...props} />
    </Dialog>
  );
};

export default StartEndTimeDialog;
