import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import InputPassword from 'src/components/input_password/input_password';
import { useAuth0 } from 'src/providers/auth0';
import { useGateway } from 'src/providers/gateway';
import styles from './reset-password.module.scss';

const ResetPassword = () => {
  const { t } = useTranslation('page_resetPassword');
  const { api } = useGateway();
  const { getToken } = useAuth0();
  const [isChanged, setIsChanged] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [newPassword, setNewPassword] = useState();
  const [newPasswordRepeated, setNewPasswordRepeated] = useState();
  const [newPasswordIsValid, setNewPasswordIsValid] = useState(false);
  const [newPasswordRepeatedIsValid, setNewPasswordRepeatedIsValid] = useState(false);

  const allowSubmit =
    !submitted &&
    newPassword &&
    newPasswordIsValid &&
    newPasswordRepeated &&
    newPasswordRepeatedIsValid &&
    newPassword === newPasswordRepeated;

  const submitNewPassword = async () => {
    setSubmitted(true);

    try {
      await api.users.updatePassword({ password: newPassword });
      setIsChanged(true);

      await getToken({ cacheMode: 'off' });
    } catch (err) {
      console.error('Error updating password:', err);
    }
  };

  return (
    <div className={styles.container}>
      {isChanged ? (
        <div>
          <p>{t('successfullyChangedPassword', 'Je hebt succesvol je wachtwoord veranderd.')}</p>
        </div>
      ) : (
        <>
          <div style={{ display: 'inline-flex', flexDirection: 'column', width: '45%' }}>
            <label>{t('newPassword', 'Nieuw wachtwoord')}</label>
            <InputPassword
              autoComplete="new-password"
              onChange={setNewPassword}
              onValidate={setNewPasswordIsValid}
              show
            />
          </div>
          <div style={{ display: 'inline-flex', flexDirection: 'column', width: '45%' }}>
            <label>{t('repeatPassword', 'Herhaal wachtwoord')}</label>
            <InputPassword
              autoComplete="new-password"
              onChange={setNewPasswordRepeated}
              onValidate={setNewPasswordRepeatedIsValid}
              show
            />
          </div>
          <Button
            disabled={!allowSubmit}
            onClick={() => {
              submitNewPassword();
            }}
          >
            {t('changePassword', 'Wachtwoord veranderen')}
          </Button>

          {newPassword !== newPasswordRepeated && (
            <h5 style={{ color: 'red' }}>{t('passwordsDoNotMatch', 'Nieuwe wachtwoorden komen niet overeen.')}</h5>
          )}
        </>
      )}
    </div>
  );
};

export default ResetPassword;
