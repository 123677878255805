import {
  GetIndividualCareOverviewOutput,
  IndividualCareReactionInput,
  IndividualCareReactionModel,
  IndividualCareRequestInput,
  IndividualCareRequestModel,
  IndividualCareRequestStatus,
  IndividualCareRequestType,
} from '@tallkingconnect/gateway';
import {
  QueryClient,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { emptyObject } from 'src/constants';
import { DefaultIndividualCareRequestStatusVisibility } from 'src/constants/individualCare';
import { useGateway } from 'src/providers/gateway';
import { invalidateCachedEntriesBy } from './helpers';
import { useUserInformation } from './users';

export function useDefaultIndividualCareStatuses() {
  const { hasRole } = useUserInformation();

  // Determine what statuses can be shown to the user
  return useMemo(
    () =>
      _.uniq([
        ...(hasRole('Admin') ? DefaultIndividualCareRequestStatusVisibility.admin : []),
        ...(hasRole('CareConsultant') ? DefaultIndividualCareRequestStatusVisibility.careConsultant : []),
        ...(hasRole('Caregiver') ? DefaultIndividualCareRequestStatusVisibility.caregiver.mine : []),
      ]),
    [hasRole]
  );
}

export function useIndividualCareRequest(id: string) {
  const { api } = useGateway();

  return useQuery({
    queryKey: ['individualCareRequest', { id }] as [string, { id: string }],
    queryFn: ({ queryKey: [, { id }] }) => api.individualCare.getIndividualCareRequest(id),
  });
}

export function useIndividualCareReaction(id: string) {
  const { api } = useGateway();

  return useQuery({
    queryKey: ['individualCareReaction', { id }] as [string, { id: string }],
    queryFn: ({ queryKey: [, { id }] }) => api.individualCare.getIndividualCareReaction(id),
  });
}

export function useIndividualCareRequests(
  { type, statuses }: { type: IndividualCareRequestType; statuses: IndividualCareRequestStatus[] },
  opts?: Partial<UseQueryOptions> | object
) {
  const { api } = useGateway();

  const select = useCallback(
    (data: GetIndividualCareOverviewOutput) =>
      type ? data.careRequests?.filter((req) => req.requestType === type) : data.careRequests,
    [type]
  );

  return useQuery({
    queryKey: ['individualCareRequests', { statuses }] as [string, { statuses: IndividualCareRequestStatus[] }],
    queryFn: ({ queryKey: [, { statuses }] }) => api.individualCare.getIndividualCareRequests(statuses),
    select,
    ...(opts ?? {}),
  });
}

export function useIndividualCareRequestMutation(
  opts: UseMutationOptions<IndividualCareRequestModel, unknown, IndividualCareRequestInput, unknown> = emptyObject
): UseMutationResult<IndividualCareRequestModel, unknown, IndividualCareRequestInput, unknown> {
  const { api } = useGateway();
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: api.individualCare.saveIndividualCareRequest,
    onSuccess: (data: IndividualCareRequestModel, variables: IndividualCareRequestInput, context: unknown) => {
      updateCachedIndividualCareRequests(queryClient, data);

      if (opts.onSuccess) {
        opts.onSuccess(data, variables, context);
      }
    },
  });
}

function updateCachedIndividualCareRequests(queryClient: QueryClient, req: IndividualCareRequestModel) {
  // Invalidate care requests
  queryClient.invalidateQueries({ queryKey: ['individualCareRequests'], exact: false });

  if (req.id) {
    // Update care request
    queryClient.setQueryData(['individualCareRequest', { id: req.id }], (prev?: IndividualCareRequestModel) =>
      prev ? { ...prev, ...req } : req
    );

    // Invalidate reactions for this care request
    invalidateCachedEntriesBy(
      queryClient,
      ['individualCareReaction'],
      (item: unknown) =>
        _.isMatch({ individualCareRequestId: req.id }, item as object) && !_.isMatch({ id: req.id }, item as object)
    );
  }
}

export function useIndividualCareReactionMutation(
  opts: UseMutationOptions<IndividualCareReactionModel, unknown, IndividualCareReactionInput, unknown> = emptyObject
): UseMutationResult<IndividualCareReactionModel, unknown, IndividualCareReactionInput, unknown> {
  const { api } = useGateway();
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: api.individualCare.saveIndividualCareReaction,
    onSuccess: (data: IndividualCareReactionModel, variables: IndividualCareReactionInput, context: unknown) => {
      updateCachedIndividualCareReactions(queryClient, data);

      if (opts.onSuccess) {
        opts.onSuccess(data, variables, context);
      }
    },
  });
}

function updateCachedIndividualCareReactions(queryClient: QueryClient, reaction: IndividualCareReactionModel) {
  if (!reaction.id) {
    // Invalidate care requests
    queryClient.invalidateQueries({ queryKey: ['individualCareRequests'], exact: false });
  } else {
    // Update care reaction
    queryClient.setQueryData(['individualCareReaction', { id: reaction.id }], (prev?: IndividualCareReactionModel) =>
      prev ? { ...prev, ...reaction } : reaction
    );

    if (reaction.individualCareRequestId) {
      // TODO invalidate only changed care request
      queryClient.invalidateQueries({ queryKey: ['individualCareRequests'], exact: false });

      // Invalidate sibling reactions
      invalidateCachedEntriesBy(
        queryClient,
        ['individualCareReaction'],
        (item: unknown) =>
          _.isMatch({ individualCareRequestId: reaction.individualCareRequestId }, item as object) &&
          !_.isMatch({ id: reaction.id }, item as object)
      );
    }
  }
}
