import { useTranslation } from 'react-i18next';
import { defaultLocale, localeOptions } from 'src/config/i18n';
import Select from './select';

const SelectLocale = (props) => {
  const { i18n } = useTranslation();

  return (
    <Select
      options={localeOptions}
      value={i18n.resolvedLanguage || i18n.language || defaultLocale}
      onChange={i18n.changeLanguage}
      {...props}
    />
  );
};

export default SelectLocale;
