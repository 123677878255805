import i18n from 'i18next';
import Detector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { initReactI18next } from 'react-i18next';
import Flags from './flags';
import languageVersions from './i18n.versions.js';

export const defaultLocale = 'nl';

export const debugLanguage = 'cimode';

export const localeOptions = [
  { value: 'en', label: 'English' },
  { value: 'nl', label: 'Nederlands' },
  Flags.debugI18N && { value: debugLanguage, label: '[debug]' },
].filter(Boolean);

export const i18nPromise = i18n
  .use(initReactI18next)
  .use(Detector)
  .use(ChainedBackend)
  .init({
    debug: Flags.debugI18N,

    compatibilityJSON: 'v4',

    // Set loading strategy to `languageOnly`, meaning 'en-US' will load 'en' language file.
    load: 'languageOnly',

    lng: Flags.enableI18N ? undefined : defaultLocale,
    fallbackLng: Flags.debugI18N ? 'en' : false,

    ns: 'common',
    defaultNS: 'common',

    interpolation: {
      escapeValue: false, // not needed for react
    },

    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'b', 'strong', 'i', 'em', 'p'],
      useSuspense: true,
    },

    backend: {
      backends: [LocalStorageBackend, HttpBackend],
      backendOptions: [
        // LocalStorageBackend options
        {
          expirationTime: Flags.cacheI18NMilliseconds,
          defaultVersion: 'unknown',
          versions: languageVersions,
        },

        // HttpBackend options
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
      ],
    },
  });

export default i18n;
