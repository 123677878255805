type ValueResolver<T> = (src: unknown) => T;

export const parseEnvBool = (
  key: string,
  defaultValue: boolean | ValueResolver<boolean> = false
): boolean | ValueResolver<boolean> => {
  const value = (process.env[key] ?? '').trim().toLowerCase();

  return ['true', 'on', '1'].some((v) => v === value)
    ? true
    : ['false', 'off', '0'].some((v) => v === value)
    ? false
    : defaultValue;
};

export const parseEnvNumber = (
  key: string,
  defaultValue: number | ValueResolver<number> = 0
): number | ValueResolver<number> => {
  const value = Number((process.env[key] ?? '').trim());

  return !Number.isNaN(value) ? value : defaultValue;
};
