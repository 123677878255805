import { Outlet } from 'react-router-dom';
import Header from 'src/components/header/header';
import { MenuItem } from 'src/types/navigation';
import styles from './default.module.scss';
import MyFooter from './footer';

interface DefaultLayoutProps {
  minimal?: boolean;
  title?: string;
  menuItems?: MenuItem[];
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ minimal = false, title, menuItems = [] }) => {
  return (
    <div className={styles.container}>
      <Header minimal={minimal} title={title} menuItems={menuItems} />

      <main>
        <Outlet />
      </main>

      <MyFooter />
    </div>
  );
};

export default DefaultLayout;
