import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import styles from './rejection_profile.module.scss';

const RejectionProfile = (props) => {
  const { t } = useTranslation('components');
  return (
    <section className={styles.container}>
      <h2>{t('adjustmentsAreNeeded', 'Aanpassingen zijn nodig')}</h2>

      <p>
        {t(
          'followingAdjustmentsStillRequiredToApproveProfile',
          'De volgende aanpassingen zijn nog benodigd om het profiel goed te kunnen keuren.'
        )}
      </p>
      {!_.isEmpty(props.reasonForRejection) && (
        <>
          <h4>{t('general', 'Algemeen')}:</h4>
          <ul>
            <li>{props.reasonForRejection}</li>
          </ul>
        </>
      )}
      {!_.isEmpty(props.rejectedDocuments) && (
        <>
          <h4>
            {t('document', {
              defaultValue: 'Document',
              count: props.rejectedDocuments.length,
            })}
            :
          </h4>
          <ul>
            {props.rejectedDocuments.map((doc) => (
              <li key={doc.id} className={styles.rejectedDocument}>
                <div>
                  <label>{doc.screeningDocType}</label>
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
    </section>
  );
};

export default RejectionProfile;
