import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { CaregiverProfileStatusStyles } from './constants';
import styles from './screening.module.scss';

const ScreeningStatusLabel = ({ status }) => {
  const { t } = useTranslation('page_admin');
  return (
    <div className={clsx(styles.screeningStatusLabel, CaregiverProfileStatusStyles[status])}>
      {t(`caregiverProfileStatusLabels.${status}`)}
    </div>
  );
};

export default ScreeningStatusLabel;
