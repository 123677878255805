/**
 * Locale-aware string compare.
 *
 * @param {string} a
 * @param {string} b
 * @returns
 */
export const compareStrings = (a, b) => (a || '').localeCompare(b || '', { sensitivity: 'accent' });

/**
 * Comparator generator. Generates a comparator that compares objects by the given key.
 *
 * @param {string} key Key to compare by
 * @returns {Function} Comparator
 */
export const createCompareByKey = (key) => (a, b) => compareStrings(a[key], b[key]);
