import { buildEnum, buildOptions } from 'src/utils';
import styles from './screening.module.scss';

// Ordered ScreeningFilterPresetValues enum values
const ScreeningFilterPresetValues = ['All', 'ReadyForScreening', 'Rejected', 'Expired'];

export const ScreeningFilterPresetLabels = (t) => t('constants.ScreeningFilterPresetLabels', { returnObjects: true });

/**
 * ScreeningFilterPreset enum
 *
 * @type {Object.<string, string>}
 * @property All
 * @property ReadyForScreening
 * @property Rejected
 * @property Expired
 */
export const ScreeningFilterPreset = buildEnum(ScreeningFilterPresetValues);

/**
 * ScreeningFilterPresetOptions for select
 *
 * @type {Array.<Object>}
 * @property All
 * @property ReadyForScreening
 * @property Rejected
 * @property Expired
 */
export const ScreeningFilterPresetOptions = (t) =>
  buildOptions(ScreeningFilterPresetValues, ScreeningFilterPresetLabels(t));

const CaregiverProfileStatusValues = [
  'Deactivated',
  'Active',
  'Expired',
  'ReadyForScreening',
  'Rejected',
  'Onboarding',
  'NoOnboarding_DeactivatedOrNotApproved',
  'NoOnboarding_Active',
];

/**
 * CaregiverProfileStatus enum
 *
 * @type {Object.<string, string>}
 */
export const CaregiverProfileStatus = buildEnum(CaregiverProfileStatusValues);

export const CaregiverProfileStatusStyles = {
  [CaregiverProfileStatus.Deactivated]: styles.deactivated,
  [CaregiverProfileStatus.Active]: styles.active,
  [CaregiverProfileStatus.Expired]: styles.expiredDocuments,
  [CaregiverProfileStatus.ReadyForScreening]: styles.readyForScreening,
  [CaregiverProfileStatus.Rejected]: styles.rejected,
  [CaregiverProfileStatus.Onboarding]: styles.onboarding,
  [CaregiverProfileStatus.NoOnboarding_DeactivatedOrNotApproved]: styles.noOnboarding_deactivatedOrNotApproved,
  [CaregiverProfileStatus.NoOnboarding_Active]: styles.noOnboarding_active,
};
