import invariant from 'invariant';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Error from 'src/components/error/error';
import LoadingOverlay from 'src/components/loader/loader';
import { useCareAgreementPreviewInformation } from 'src/hooks/bookings';
import CareAgreementDocument from '../documents/templates/nl/careAgreement';
import styles from './agreement.module.scss';

const CareAgreement: React.FC = () => {
  const { t } = useTranslation('page_postings');
  const { id } = useParams<string>();
  invariant(id, 'id undefined');
  const { data: model, isLoading, error } = useCareAgreementPreviewInformation(id);

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <h1>{t('agreement.title')}</h1>
        {isLoading ? (
          <LoadingOverlay />
        ) : error ? (
          <Error>{error.message}</Error>
        ) : (
          <CareAgreementDocument model={model} />
        )}
      </div>
    </div>
  );
};

export default CareAgreement;
