import invariant from 'invariant';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { useMedia } from 'react-use';
import App from './App';
import LoadingOverlay from './components/loader/loader';
import Auth0ProviderWithRedirectCallback from './providers/auth0';
import GatewayProvider from './providers/gateway';
import { QueryClientProvider } from './providers/query';
import * as serviceWorker from './serviceWorker';

// Configure internationalization first
// TODO: currently we're blocking all rendering until at least some i18n is loaded, this needs improvement
import { i18nPromise } from './config/i18n';

// Configure moment before it's being used anywhere
import './config/moment';

import './index.module.scss';

// prime react
// 1: prime react customizations
import './styles/primereact/themes/tallkingconnect/theme.scss';
// 2. prime react core
import 'primereact/resources/primereact.css';
// 3. prime icons
import 'primeicons/primeicons.css';

const AppRoot: React.FC = () => {
  const isPrinting = useMedia('print');

  return (
    <Suspense fallback={<LoadingOverlay />}>
      <BrowserRouter>
        <QueryClientProvider enableDevTools={!isPrinting}>
          <Auth0ProviderWithRedirectCallback>
            <GatewayProvider>
              <App />
            </GatewayProvider>
          </Auth0ProviderWithRedirectCallback>
        </QueryClientProvider>
      </BrowserRouter>
    </Suspense>
  );
};

i18nPromise.then(() => {
  const container = document.getElementById('app-root');
  invariant(container, 'Could not find app-root');

  const root = createRoot(container);
  root.render(<AppRoot />);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
