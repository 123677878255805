import { Check } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import { ReactComponent as BlobRight } from 'assets/icons/blob-right.svg';
import UserHelpBar from 'src/components/user_help_bar/user_help_bar';
import styles from './onboarding.module.scss';

const WelcomeScreen = ({ name, proceed }) => {
  const { t } = useTranslation('page_onboarding');

  return (
    <Trans t={t} i18nKey="welcomeScreen">
      <div className={styles.screeningStatusWelcome}>
        <div className={styles.backgroundImage}></div>
        <div className={styles.welcomeContentContainer}>
          <h1>Welkom {{ name }}!</h1>
          <div className={styles.screeningStatusContainer}>
            <div className={styles.content}>
              <div className={styles.step}>
                <Check />
                <label>Je TallKing Connect profiel is aangemaakt</label>
                <p>Je hebt ter bevestiging een e-mail ontvangen.</p>
              </div>
              <div className={styles.step} data-current>
                <BlobRight />
                <label>Volgende stap: Gegevens aanvullen</label>
                <p>
                  Voordat je via TallKing Connect aan de slag kunt hebben we een aantal beroeps- en bedrijfsgegevens van
                  je nodig.
                </p>
              </div>
              <div className={styles.step}>
                <label>Screening door TallKing Connect</label>
                <p>
                  Zodra je alles hebt aangeleverd kunnen we de <strong>screening</strong> starten. Als alles in orde is
                  kun je via TallKing Connect <strong>aan de slag</strong>.
                </p>
              </div>
            </div>
          </div>
          <Button onClick={proceed}>Vul je gegevens aan</Button>
          <UserHelpBar />
        </div>
      </div>
    </Trans>
  );
};

export default WelcomeScreen;
