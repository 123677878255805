import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import Card from 'src/components/card/card';
import { YesNoDialog } from 'src/components/dialog/dialog';
import LabeledFormInput from 'src/components/form/labeled_form_input';
import InputEmail from 'src/components/input_email/input_email';
import InputPassword from 'src/components/input_password/input_password';
import InputText from 'src/components/input_text/input_text';
import LoadingOverlay from 'src/components/loader/loader';
import Select from 'src/components/select/select';
import useCommonData from 'src/hooks/useCommonData';
import { useGateway } from 'src/providers/gateway';
import styles from './create_planner_account.module.scss';

const newPlannerAccount = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  companyId: '',
};

const PlannerAccountForm = () => {
  const { t } = useTranslation('page_admin');
  const { api } = useGateway();
  const {
    data: { companies },
    isLoading: commonDataLoading,
  } = useCommonData();

  const [dialogCreatePlannerAccount, setDialogCreatePlannerAccount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState();
  const [emailIsUnique, setEmailIsUnique] = useState();
  const [passwordIsValid, setPasswordIsValid] = useState();

  const [model, setModel] = useState(newPlannerAccount);

  const checkIsEmailUnique = async (email) => {
    setEmailIsUnique();
    if (email !== '') {
      try {
        const data = await api.universal.emailIsUnique({ email });
        setEmailIsUnique(data.value);
      } catch (err) {
        console.error('Error checking email uniqueness:', err);
      }
    }
  };

  const updateModel = _.curry((param, value) => {
    setModel((prevState) => ({
      ...prevState,
      [param]: value,
    }));
  });

  const bindModel = (label, key, defaultValue, component, props = {}) => (
    <LabeledFormInput
      component={component}
      label={label}
      value={_.defaultTo(_.get(model, key), defaultValue)}
      onChange={updateModel(key)}
      {...props}
    />
  );

  const createPlannerAccount = async () => {
    setIsLoading(true);

    try {
      await api.admin.createPlannerAccount(model);

      setModel(newPlannerAccount);
      setEmailIsValid();
      setEmailIsUnique();
      setPasswordIsValid();
    } catch (err) {
      alert(`Error creating planner account: ${err.message}`);
      console.error('Error creating planner account:', err);
    } finally {
      setIsLoading(false);
    }
  };

  // Check if account has all fields filled. When filled enable the 'create planning account' button
  useEffect(() => {
    setValid(Object.values(model).every((value) => value !== '') && emailIsValid && emailIsUnique && passwordIsValid);
  }, [model, emailIsValid, emailIsUnique, passwordIsValid]);

  return (
    <div className={styles.container}>
      {commonDataLoading || isLoading ? (
        <LoadingOverlay />
      ) : (
        <>
          <h1 style={{ textAlign: 'center' }}>
            {t('createPlannerAccount.headingCreatePlannerAccount', 'Planner-account aanmaken')}
          </h1>
          <Card compact>
            {companies &&
              bindModel(t('createPlannerAccount.company', 'Bedrijf'), 'companyId', '', Select, {
                options: companies,
                placeholder: t('createPlannerAccount.chooseCompany', 'Kies een bedrijf'),
              })}

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {bindModel(t('createPlannerAccount.firstName', 'Voornaam'), 'firstName', '', InputText, {
                placeholder: t('createPlannerAccount.firstName', 'Voornaam'),
              })}
              {bindModel(t('createPlannerAccount.lastName', 'Achternaam'), 'lastName', '', InputText, {
                placeholder: t('createPlannerAccount.lastName', 'Achternaam'),
              })}
            </div>

            {bindModel(t('createPlannerAccount.email', 'E-mailadres'), 'email', '', InputEmail, {
              placeholder: t('createPlannerAccount.email', 'E-mailadres'),
              onBlur: checkIsEmailUnique,
              onValidate: setEmailIsValid,
              errorMessage:
                emailIsUnique === false
                  ? t('createPlannerAccount.emailAlreadyExists', 'Dit e-mailadres bestaat al')
                  : null,
            })}

            {bindModel(t('createPlannerAccount.password', 'Wachtwoord'), 'password', '', InputPassword, {
              placeholder: t('createPlannerAccount.createPasswordPlaceholder', 'Maak een wachtwoord aan'),
              autoComplete: 'new-password',
              show: true,
              onValidate: setPasswordIsValid,
            })}

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                disabled={!valid}
                onClick={() => {
                  setDialogCreatePlannerAccount(true);
                }}
              >
                {t('createPlannerAccount.createAccount', 'Account toevoegen')}
              </Button>
            </div>
            {dialogCreatePlannerAccount && (
              <YesNoDialog
                onConfirm={() => {
                  createPlannerAccount();
                  setDialogCreatePlannerAccount(false);
                }}
                onCancel={() => {
                  setDialogCreatePlannerAccount(false);
                }}
                labelQuestion={
                  <Trans t={t} i18nKey="createPlannerAccount.dialogCreatePlannerAccountQuestion">
                    Je gaat een planner-account toevoegen gekoppeld aan{' '}
                    <b>{{ label: _.find(companies, { value: model.companyId }).label }}</b> met e-mailadres{' '}
                    <b>{{ email: model.email }}</b>.
                    <br />
                    Weet je het zeker?
                  </Trans>
                }
              />
            )}
          </Card>
        </>
      )}
    </div>
  );
};

export default PlannerAccountForm;
