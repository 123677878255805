import { buildEnum } from 'src/utils/enum';

// Ordered NotificationStatuses enum values
export const NotificationStatusValues = [
  'New',
  'Sent',
  'NotSentBecauseOfError',
  'ReceiptOk',
  'ReceiptNotOk',
  'Read',
  'Dismissed',
];

/**
 * Notification statuses enum
 *
 * @type {Object.<string, string>}
 */
export const NotificationStatuses = buildEnum(NotificationStatusValues);

export const VisibleNotificationStatusValues = NotificationStatusValues.filter((v) => v !== 'Dismissed');
