export const fields = {
  street: 'street',
  houseNumber: 'houseNumber',
  extension: 'extension',
  postcode: 'postcode',
  city: 'city',
  countryCode: 'countryCode',
};

export const requiredAddressFields = [
  fields.street,
  fields.houseNumber,
  fields.postcode,
  fields.city,
  fields.countryCode,
];

export const locationFields = ['lat', 'lng'];
