import clsx from 'clsx';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CaregiverFilterRating,
  CaregiverFilterRatingBestFitOrder,
  CaregiverFilterRatingDisplayOrder,
} from 'src/pages/planning/constants';
import { formatCurrency, formatDate } from 'src/utils/format';
import Chevron from '../chevron/chevron';
// import DropDown from '../dropdown/dropdown';
import Favorite from '../favorite';
import Information from '../information';
import InputSearch from '../input_search';
import Avatar from '../user/avatar';
import styles from './select.module.scss';

const CaregiverTile = ({ caregiver, selected, districtTeamId, onClick }) => {
  const { t } = useTranslation('components');
  const { disabled } = caregiver;

  return (
    <li
      disabled={disabled}
      className={clsx(styles.caregiverTile, disabled && styles.disabled, selected ? 'selected' : 'unselected')}
      onClick={disabled ? undefined : onClick}
    >
      <div className={styles.tags}>
        {!caregiver.isActive && <div className={clsx(styles.tag, styles.inactive)}>{t('common:tag.inactive')}</div>}
        {caregiver.isBlocked && <div className={clsx(styles.tag, styles.blocked)}>{t('common:tag.blocked')}</div>}
        {caregiver.isExpiredToday ? (
          <div className={clsx(styles.tag, styles.expiryError)}>
            {t('common:tag.expiredAt', { date: formatDate(caregiver.expirationDate) })}
          </div>
        ) : caregiver.isExpiredAtBookingDate ? (
          <div className={clsx(styles.tag, styles.expiryWarning)}>
            {t('common:tag.expiresAt', { date: formatDate(caregiver.expirationDate) })}
          </div>
        ) : null}
        {selected && <div className={clsx(styles.tag, styles.selected)}>{t('common:tag.selected')}</div>}
      </div>

      {/* TODO: assuming "availability" can be determined from filter rating is incorrect: it is wrong for search results */}
      {caregiver.filterRating === CaregiverFilterRating.poor ? (
        <p className={styles.warning}>{t('noSpecifiedAvailability', 'Geen opgegeven beschikbaarheid')}</p>
      ) : caregiver.filterRating === CaregiverFilterRating.searchedbutinadequate ? (
        <p className={styles.error}>{t('doesntmeetRequirements', 'Voldoet niet aan de eisen')}</p>
      ) : null}

      <div className={styles.userImageContainer}>
        <img src={caregiver.profilePictureUrl} title={caregiver.title} alt={caregiver.title} />
        <Favorite
          isFavorite={caregiver.isFavorite}
          favObject={{
            districtTeamId,
            favoriteCaregiverId: caregiver.id,
          }}
        />
      </div>

      <div className={styles.caregiverInfo}>
        <div className={styles.name}>{caregiver.label}</div>
        <div className={styles.meta}>
          <span className={styles.education}>{caregiver.highestDegreeOfEducation}</span>
          <span> - </span>
          <span className={styles.sector}>{_.get(caregiver, 'careSector.name', t('unknown', '(onbekend)'))}</span>
        </div>
        <div className={styles.meta}>
          <span className={styles.rate}>{formatCurrency(caregiver.hourlyRate)}</span>
        </div>
      </div>
    </li>
  );
};

const SelectCaregiver = ({
  isLoading,
  noResults,
  caregiverByBookingOptions,
  caregiverByNameOptions,
  districtTeamId,
  // The value used by the select
  caregiverId,
  // The entire currently selected caregiver object.
  // This is required in order to be able to show a previously selected caregiver without having to reload caregivers
  // from the backend, hopefully including the one pointed to by `caregiverId`.
  caregiver,
  placeholder,
  onChange,
  onSearch,
  preSelect = false,
}) => {
  const { t } = useTranslation('components');
  const triggerRef = useRef(null);
  const popupRef = useRef(null);

  const [expanded, setExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const caregiverOptions = useMemo(
    () => _.concat(caregiverByBookingOptions, caregiverByNameOptions),
    [caregiverByBookingOptions, caregiverByNameOptions]
  );

  const selectedItem = useMemo(
    () => caregiver || _.find(caregiverOptions, { value: caregiverId }) || { value: caregiverId },
    [caregiver, caregiverOptions, caregiverId]
  );

  const selectedItemLabel = useMemo(
    () =>
      selectedItem.value
        ? `${selectedItem.label} - ${formatCurrency(selectedItem.hourlyRate)} - ${
            selectedItem.highestDegreeOfEducation
          } - ${_.get(selectedItem, 'careSector.name', t('unknown', '(onbekend)'))}`
        : t('chooseHealthCareProvider', 'Kies een zorgverlener...'),
    [selectedItem, t]
  );

  const [groups, bestFit] = useMemo(() => {
    const groups = _.groupBy(caregiverOptions, 'filterRating');

    // Favorites are interesting. They need to be sorted by filter rating (best first etc), and then duplicates need to be removed
    // (those can be introduced due to combining matches based on booking options and matches from search by name)
    groups.favorite = _.sortBy(_.uniqBy(_.filter(caregiverOptions, 'isFavorite'), 'id'), [
      (caregiver) => {
        const index = CaregiverFilterRatingBestFitOrder.indexOf(caregiver.filterRating);
        return index !== -1 ? index : Infinity;
      },
    ]);

    const sortedGroups = Object.entries(groups)
      .sort(([a], [b]) => CaregiverFilterRatingDisplayOrder.indexOf(a) - CaregiverFilterRatingDisplayOrder.indexOf(b))
      .map(([key, items]) => ({
        key,
        ...t('caregiverFilterRating', { returnObjects: true })[key],
        items,
      }));

    const bestFit = Object.entries(groups)
      // filter out items that have inadequate filter rating, according to the best fit order
      .filter(([key]) => CaregiverFilterRatingBestFitOrder.includes(key))

      // sort according to best fit order
      .sort(([a], [b]) => CaregiverFilterRatingBestFitOrder.indexOf(a) - CaregiverFilterRatingBestFitOrder.indexOf(b))

      // pick first non-disabled item
      .reduce((result, [, items]) => result || _.head(items.filter((item) => !item.disabled)), undefined);

    return [sortedGroups, bestFit];
  }, [caregiverOptions, t]);

  const toggleExpanded = (event) => {
    // Check if the click occurred outside of our popup
    if (!popupRef.current.contains(event.target)) {
      setExpanded(!expanded);
    }
  };

  const onClickOutside = useCallback(
    (event) => {
      // Check if the click occurred outside of our select and popup
      if (expanded && !triggerRef.current.contains(event.target)) {
        setExpanded(false);
      }
    },
    [expanded]
  );

  useEffect(() => {
    const element = popupRef.current;
    const style = element.style;

    // reset custom positioning style before measuring natural viewport
    style.left = '';
    style.right = '';
    style.top = '';
    style.bottom = '';

    const naturalPosition = element.getBoundingClientRect();

    if (naturalPosition.x + naturalPosition.width > window.innerWidth) {
      style.left = '';
      style.right = 0;
    } else {
      style.left = 0;
      style.right = '';
    }
    if (naturalPosition.y + naturalPosition.height > window.innerHeight) {
      style.top = 'auto';
      style.bottom = '100%';
    } else {
      style.top = '100%';
      style.bottom = 'auto';
    }

    window.addEventListener('click', onClickOutside, true);

    // Return cleanup function
    return () => {
      window.removeEventListener('click', onClickOutside, true);
    };
  }, [onClickOutside]);

  useEffect(() => {
    if (preSelect && (!caregiverId || caregiverId === '') && bestFit) {
      onChange(bestFit.value, bestFit);
    }
  });

  const renderCaregiverTile = (caregiver) => (
    <CaregiverTile
      key={caregiver.id}
      caregiver={caregiver}
      selected={selectedItem?.value === caregiver.id}
      districtTeamId={districtTeamId}
      onClick={() => {
        onChange(caregiver.id, caregiver);
        setExpanded(false);
      }}
    />
  );

  // TODO: ideally this component would actually use DropDown which is made for this kind of thing.
  // The code below is a first attempt (incomplete due to later additions to the actual rendering though)

  // return (
  //   <DropDown chevron className={styles.caregiverContainer}>
  //     <div className={styles.caregiverLabel}>
  //       {selectedItem && selectedItem.profilePictureUrl && <Avatar src={selectedItem.profilePictureUrl} />}
  //       <label className={clsx(selectedItem && styles.placeholder)}>
  //         {isLoading ? 'Zoeken...' : selectedItem ? selectedItemLabel : placeholder}
  //       </label>
  //     </div>

  //     {/* <ul className={clsx(styles.optionsList, expanded && styles.expanded)} ref={popupRef}> */}
  //     <ul className={clsx(styles.optionsList, styles.expanded)} ref={popupRef}>
  //       <div className={styles.optGroup}>
  //         <InputSearch
  //           value={searchQuery}
  //           onChange={setSearchQuery}
  //           onSearch={() => {
  //             onSearch(searchQuery);
  //           }}
  //           placeholder="Zoek een zorgverlener..."
  //         />
  //         {noResults ? (
  //           <li className={styles.noResults}>Er zijn geen zoekresultaten...</li>
  //         ) : (
  //           groups.searched.map(renderItem)
  //         )}
  //       </div>

  //       {groups.others
  //         .filter((group) => group.items.length > 0)
  //         .map((group) => (
  //           <div key={group.label} className={styles.optGroup}>
  //             <h2>
  //               {group.label}
  //               {group.info && <Information>{group.info}</Information>}
  //             </h2>
  //             {group.items.map(renderItem)}
  //           </div>
  //         ))}
  //     </ul>
  //   </DropDown>
  // );

  const [[searchResults], otherResults] = _.partition(groups, { key: CaregiverFilterRating.searchedbyname });

  return (
    <div className={styles.caregiverContainer}>
      <div className={styles.selectBoxSelector} onClick={toggleExpanded} ref={triggerRef}>
        <div className={styles.caregiverLabel}>
          {selectedItem && selectedItem.profilePictureUrl && <Avatar src={selectedItem.profilePictureUrl} />}
          <label className={clsx(selectedItem && styles.placeholder)}>
            {isLoading ? t('search', 'Zoeken...') : selectedItem ? selectedItemLabel : placeholder}
          </label>
        </div>

        <ul className={clsx(styles.optionsList, expanded && styles.expanded)} ref={popupRef}>
          <div className={styles.optGroup}>
            <InputSearch
              value={searchQuery}
              onChange={setSearchQuery}
              onSearch={() => {
                onSearch(searchQuery);
              }}
              placeholder={t('findHealthCareProvider', 'Zoek een zorgverlener...')}
              className={styles.search}
            />
            {noResults ? (
              <li className={styles.noResults}>{t('noSearchResults', 'Er zijn geen zoekresultaten...')}</li>
            ) : (
              searchResults && searchResults.items.map(renderCaregiverTile)
            )}
          </div>

          {otherResults.map((group) => (
            <div key={group.label} className={styles.optGroup}>
              <h2>
                {group.label}
                {group.info && <Information>{group.info}</Information>}
              </h2>
              {group.items.map(renderCaregiverTile)}
            </div>
          ))}
        </ul>

        <Chevron dir={expanded ? 'up' : 'down'} />
      </div>
    </div>
  );
};

export default SelectCaregiver;
