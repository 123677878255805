import moment from 'moment';
import { useTranslation } from 'react-i18next';
import A from 'src/components/a';
import Footer from 'src/components/footer/footer';

const MyFooter: React.FC = () => {
  const { t } = useTranslation('page_default');
  return (
    <Footer>
      <span>
        © {moment().format('YYYY')} {t('defaultLogin.allRightsReserved')} -{' '}
        <a href="mailto:hallo@tallkingconnect.com">hallo@tallkingconnect.com</a> - {t('defaultLogin.address')} -{' '}
        {t('defaultLogin.companyNumber')} -{' '}
        <A href={'https://tallkingresults.com/privacy-beleid/'}>{t('defaultLogin.privacyStatement.name')}</A>
      </span>
    </Footer>
  );
};

export default MyFooter;
