import { buildEnum } from 'src/utils';

// Ordered OnboardingScreeningDocumentStatus enum values
const ScreeningDocumentStatusValues = ['New', 'Approved', 'Rejected', 'Expired'];

/**
 * BookingType enum
 *
 * @type {Object.<string, string>}
 * @property New
 * @property Approved
 * @property Rejected
 * @property Expired
 */
export const ScreeningDocumentStatus = buildEnum(ScreeningDocumentStatusValues);
