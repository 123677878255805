import _ from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddressForm from 'src/components/address/form';
import { validateAddress } from 'src/components/address/util';
import Button from 'src/components/button/button';
import Card from 'src/components/card/card';
import LabeledFormInput from 'src/components/form/labeled_form_input';
import InputEmail from 'src/components/input_email/input_email';
import { InputText } from 'src/components/input_text/input_text';
import LoadingOverlay from 'src/components/loader/loader';
import Select from 'src/components/select';
import { useGateway } from 'src/providers/gateway';
import { extractCompanyAddress } from 'src/utils/address';
import { LegalCompanyTypesOptions } from './constants';
import styles from './index.module.scss';

const newCompanyAccount = {
  displayName: '',
  legalForm: '',
  legalName: '',
  billingDepartment: '',
  billingStreet: '',
  billingHouseNumber: '',
  billingExtension: '',
  billingPostcode: '',
  billingCity: '',
  billingCountryCode: process.env.REACT_APP_DEFAULT_COUNTRY_CODE || '',
  billingEmailAddress: '',
  companyNumber: '',
};

const CompanyAccountForm = () => {
  const { t } = useTranslation('page_admin');
  const { api } = useGateway();
  const [model, setModel] = useState(newCompanyAccount);
  const address = extractCompanyAddress(model);
  const valid = Object.entries(model).every(([key, value]) => key === 'billingExtension' || value !== '');

  const [isLoading, setIsLoading] = useState(false);

  const updateModel = _.curry((param, value) => {
    setModel((prevState) => ({
      ...prevState,
      [param]: value,
    }));
  });

  const updateAddress = (address) => {
    setModel((prevState) =>
      Object.entries(address).reduce(
        (nextState, [key, value]) => {
          switch (key) {
            case 'lat':
            case 'lng':
              nextState[key] = value;
              break;
            default:
              nextState[`billing${_.upperFirst(key)}`] = value;
              break;
          }
          return nextState;
        },
        { ...prevState }
      )
    );

    // Update location
    debouncedUpdateLocation(address);
  };

  const updateLocation = useCallback(
    async (address) => {
      const { valid } = validateAddress(address);

      if (valid) {
        try {
          const newAddress = await api.universal.geocode(address);
          setModel((prevState) => ({
            ...prevState,
            lat: newAddress.lat,
            lng: newAddress.lng,
          }));
        } catch (err) {
          console.error('Error geocoding:', err);
          setModel((prevState) => ({
            ...prevState,
            lat: null,
            lng: null,
          }));
        }
      } else {
        setModel((prevState) => ({
          ...prevState,
          lat: null,
          lng: null,
        }));
      }
    },
    [api]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateLocation = useCallback(_.debounce(updateLocation, 1000), [updateLocation]);

  const bindModel = (label, key, defaultValue, component, props = {}) => (
    <LabeledFormInput
      component={component}
      label={label}
      value={_.defaultTo(_.get(model, key), defaultValue)}
      onChange={updateModel(key)}
      {...props}
    />
  );

  const saveCompanyAccount = async () => {
    setIsLoading(true);

    try {
      await api.admin.saveCompany(model);

      setModel(newCompanyAccount);
    } catch (err) {
      alert(`Error creating organization account: ${err.message}`);
      console.error('Error creating organisation account:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        {t('createCompanyAccount.headingCreateCompanyAccount', 'Bedrijfsaccount aanmaken')}
      </h1>
      <Card compact>
        <div className={styles.row}>
          {bindModel(t('createCompanyAccount.companyName', 'Bedrijfsnaam'), 'displayName', '', InputText, {
            placeholder: t('createCompanyAccount.companyNamePlaceholder', 'Bedrijfsnaam'),
          })}
          {bindModel(
            t('createCompanyAccount.companyLegalName', 'Wettelijke bedrijfsnaam'),
            'legalName',
            '',
            InputText,
            {
              placeholder: t('createCompanyAccount.companyLegalNamePlaceholder', 'Wettelijke bedrijfsnaam'),
              information: t(
                'createCompanyAccount.companyLegalNameInformation',
                'Voorbeeld: bedrijfsnaam: "Zorghotel"; wettelijke bedrijfsnaam: "Stichting Zorghotel Zevenaar"'
              ),
            }
          )}
        </div>

        <div className={styles.row}>
          {bindModel(t('createCompanyAccount.companyType', 'Bedrijfsvorm'), 'legalForm', '', Select, {
            placeholder: t('createCompanyAccount.companyTypePlaceholder', 'Kies een bedrijfsvorm'),
            options: LegalCompanyTypesOptions,
          })}
          {bindModel(t('createCompanyAccount.companyNumber', 'KvK-nummer'), 'companyNumber', '', InputText, {
            placeholder: t('createCompanyAccount.companyNumberPlaceholder', 'KvK-nummer'),
          })}
        </div>

        <div className={styles.row}>
          {bindModel(
            t('createCompanyAccount.department', 'Naam facturatieafdeling'),
            'billingDepartment',
            '',
            InputText,
            {
              placeholder: t('createCompanyAccount.departmentPlaceholder', 'Facturatieafdeling'),
            }
          )}
          {bindModel(t('createCompanyAccount.email', 'E-mailadres facturatie'), 'billingEmailAddress', '', InputEmail, {
            placeholder: t('createCompanyAccount.emailPlaceholder', 'E-mailadres facturatie'),
          })}
        </div>

        <AddressForm address={address} onChange={updateAddress} />

        <div className={styles.buttonWrapper}>
          <Button disabled={!valid} onClick={() => saveCompanyAccount()}>
            {t('createCompanyAccount.save', 'Organisatie toevoegen')}
          </Button>
        </div>
      </Card>
      {isLoading && <LoadingOverlay />}
    </div>
  );
};

export default CompanyAccountForm;
