import { NotificationStatus } from '@tallkingconnect/gateway';
import { useQuery } from '@tanstack/react-query';
import Flags from 'src/config/flags';
import { useGateway } from 'src/providers/gateway';

const useNotifications = (statuses: NotificationStatus[]) => {
  const { api } = useGateway();

  return useQuery({
    queryKey: ['notifications', { statuses }] as [string, { statuses: NotificationStatus[] }],
    queryFn: ({ queryKey: [, { statuses }] }) => api.notifications.getUserNotifications({ statuses }),
    refetchInterval: Flags.enableAutoRefreshNotifications ? 60000 : undefined,
  });
};

export default useNotifications;
