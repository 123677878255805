import clsx from 'clsx';
import moment from 'moment';
import { useMemo } from 'react';
import {
  Calendar as BigCalendar,
  DayPropGetter,
  EventPropGetter,
  SlotPropGetter,
  momentLocalizer,
} from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { emptyObject } from 'src/constants';
import { Toolbar } from './Toolbar';
import { useCombinedPropGetter } from './util';

import { DateCellWrapper } from './DateCellWrapper';
import { DateHeader } from './DateHeader';
import styles from './calendar.module.scss';
import './calendar.scss'; // this one *must not* be a module
import { EventAccessors, ResourceAccessors, type CalendarProps, type Event, type Resource } from './types';

const dayOfWeekClasses = [
  styles.sunday,
  styles.monday,
  styles.tuesday,
  styles.wednesday,
  styles.thursday,
  styles.friday,
  styles.saturday,
];

/**
 * Returns React properties for days.
 */
const defaultDayPropGetter: DayPropGetter = (date, _resourceId) => ({
  className: clsx(dayOfWeekClasses[date.getDay()]),
});

/**
 * Returns React properties for events.
 *
 * @type {EventPropGetter}
 */
const defaultEventPropGetter: EventPropGetter<Event> = (_event, _start, _end, _isSelected) => ({});

/**
 * Returns React properties for slots.
 */
const defaultSlotPropGetter: SlotPropGetter = (_date, _resourceId) => ({});

/**
 * Returns React properties for slot groups.
 */
const defaultSlotGroupPropGetter: SlotPropGetter = () => ({});

/**
 * Accessor configuration for retrieving properties from our event objects.
 * All of these values can either be a string or a function taking an event and outputting the desired value.
 */
const eventAccessors: EventAccessors = {
  allDayAccessor: 'allDay',
  startAccessor: 'start',
  endAccessor: 'end',
  titleAccessor: 'title',
  tooltipAccessor: 'tooltip',
  resourceAccessor: 'resource', // e.g., a location for the event
};

/**
 * Accessor configuration for retrieving properties from resource objects.
 * Similar to event accessors.
 */
const resourceAccessors: ResourceAccessors = {
  resourceIdAccessor: 'id',
  resourceTitleAccessor: 'title',
};

const localizer = momentLocalizer(moment);

/**
 * Our calendar is based on ReactBigCalendar. This component is just a thin wrapper on top of it.
 *
 * @see [API docs]{@link http://jquense.github.io/react-big-calendar/examples/index.html#api}
 */
const Calendar: React.FC<CalendarProps> = ({
  events,
  dayPropGetter,
  eventPropGetter,
  slotPropGetter,
  slotGroupPropGetter,
  components: otherComponents = emptyObject,
  localizer: _localizer,
  messages: _messages,
  ...props
}) => {
  const { t, i18n } = useTranslation('components');

  const messages = useMemo<CalendarProps['messages']>(
    () => ({
      ...(t('calendar', { returnObjects: true }) as object),
      showMore: (total) => t('calendar.more', { total }),
    }),
    [t]
  );

  const components = useMemo<CalendarProps['components']>(
    () => ({
      ...otherComponents,
      dateHeader: DateHeader,
      dateCellWrapper: DateCellWrapper,
      toolbar: Toolbar,
    }),
    [otherComponents]
  );

  return (
    <div className={styles.container}>
      <BigCalendar<Event, Resource>
        localizer={localizer}
        culture={i18n.resolvedLanguage}
        messages={messages}
        {...eventAccessors}
        {...resourceAccessors}
        dayPropGetter={useCombinedPropGetter(defaultDayPropGetter, dayPropGetter)}
        eventPropGetter={useCombinedPropGetter(defaultEventPropGetter, eventPropGetter)}
        slotPropGetter={useCombinedPropGetter(defaultSlotPropGetter, slotPropGetter)}
        slotGroupPropGetter={useCombinedPropGetter(defaultSlotGroupPropGetter, slotGroupPropGetter)}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore We provide extended components, which RBC support fine, but the types don't handle this correctly. See also types.ts
        components={components}
        events={events}
        {...props}
      />
    </div>
  );
};
export default Calendar;
