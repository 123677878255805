import _ from 'lodash';
import { buildEnum, buildOptions, filterI18nObject } from 'src/utils';

// Ordered PostingStatus enum values
const PostingStatusValues = [
  'New',
  'Active',
  'Pending',
  'Inactive',
  'CancelledByPlanner',
  'Accepted',
  'CancelledByCaregiver',
];

/**
 * PostingStatus labels
 *
 * @type {Object.<string, string>}
 */
// TODO Remove? Replaced with i18n t-function
export const PostingStatusLabels = {
  New: 'Concept',
  Active: 'Actief',
  Pending: 'Wachten op reactie zorgverlener',
  Inactive: 'Inactief',
  CancelledByPlanner: 'Ingetrokken',
  Accepted: 'Geaccepteerd door zorgverlener',
  CancelledByCaregiver: 'Geannuleerd door zorgverlener',
};

/**
 * PostingStatus short labels
 *
 * @type {Object.<string, string>}
 */
export const PostingStatusShortLabels = {
  New: 'Concept',
  Active: 'Actief',
  Pending: 'Wachten...',
  Inactive: 'Inactief',
  CancelledByPlanner: 'Ingetrokken',
  Accepted: 'Geaccepteerd',
  CancelledByCaregiver: 'Geannuleerd',
};

/**
 * PostingStatus enum
 *
 * @type {Object.<string, string>}
 * @property New
 * @property Active
 * @property Pending
 * @property Inactive
 * @property CancelledByPlanner
 * @property Accepted
 * @property CancelledByCaregiver
 */
export const PostingStatus = buildEnum(PostingStatusValues);

/**
 * PostingStatus select options
 *
 * @type {Array.<Object>}
 * @property New
 * @property Active
 * @property Pending
 * @property Inactive
 * @property CancelledByPlanner
 * @property Accepted
 * @property CancelledByCaregiver
 */
export const PostingStatusOptions = buildOptions(PostingStatusValues, PostingStatusLabels);

export const DefaultPostingStatusVisibility = {
  recruiter: [PostingStatus.New, PostingStatus.Active, PostingStatus.Pending, PostingStatus.Inactive],
  caregiver: {
    all: [PostingStatus.Active],
    mine: [
      PostingStatus.Active,
      PostingStatus.CancelledByPlanner,
      PostingStatus.Pending,
      PostingStatus.Accepted,
      PostingStatus.CancelledByCaregiver,
    ],
  },
  admin: [
    PostingStatus.New,
    PostingStatus.Active,
    PostingStatus.Pending,
    PostingStatus.Inactive,
    PostingStatus.CancelledByPlanner,
    PostingStatus.Accepted,
    PostingStatus.CancelledByCaregiver,
  ],
};

/**
 * Statuses that allow reacting to a posting, or modifying the reaction.
 */
export const PostingStatusReactable = [PostingStatus.Active, PostingStatus.Pending];

export const emptyPosting = Object.freeze({
  address: {
    countryCode: process.env.REACT_APP_DEFAULT_COUNTRY_CODE || '',
  },
  documents: [],
});

/**
 * Document types for documents that can be attached to a posting.
 * NB: these values are not enforced anywhere in the backend, and we can extend this list whenever we need.
 */
const PostingDocumentTypeValues = [
  'JobDescription',
  'Terms',
  // 'IntakeForm', // disabled after initial feedback from Gretha
  // 'SelfDeclaration', // disabled after initial feedback from Gretha
  'Other',
];

export const PostingDocumentTypeLabels = (t) => filterI18nObject(t, 'constants.postingDocumentType', 'label');

export const PostingDocumentTypeInformation = (t) => filterI18nObject(t, 'constants.postingDocumentType', 'info');

export const PostingDocumentType = buildEnum(PostingDocumentTypeValues);

export const PostingDocumentTypeOptions = (t) =>
  buildOptions(PostingDocumentTypeValues, PostingDocumentTypeLabels(t), PostingDocumentTypeInformation(t));

// Convenience method to get translated document type, with a fallback for unrecognized types.
export const getPostingDocumentTypeLabel = (type, t) => _.get(PostingDocumentTypeLabels(t), type, type);
