import clsx from 'clsx';
import { isValidPhoneNumber } from 'libphonenumber-js';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'src/config/i18n';
import Information from '../information/information';
import { PhoneField } from '../input_text/input_field';
import styles from './input_number.module.scss';

/**
 * Very simple phone regex, way too simple to actually catch all situations,
 * but useful for a temporary workaround until we actually support country-specific
 * phone numbers.
 *
 * TODO: remove when migrating to country-specific phone number validation.
 */
const SIMPLE_PHONE_REGEX = /^((\+|00)\d{1,3}|0[1-9]+)([ .-]?\(\d{1,4}\)([ .-]?\d){5,}|([ .-]?\d){8,})$/;

const InputPhone = ({ country = 'NL', className, onValidate = _.noop, ...props }) => {
  const { t } = useTranslation('components');
  const [valid, setValid] = useState(null);

  const validate = (value) => {
    // TODO: remove workaround for missing country info (language != country), just use `isValidPhoneNumber`
    const isValid =
      i18n.resolvedLanguage.localeCompare(country, { sensitivity: 'base' }) === 0
        ? isValidPhoneNumber(value.trim(), country)
        : SIMPLE_PHONE_REGEX.test(value.trim());

    setValid(isValid);
    onValidate(isValid);
  };

  return (
    <div className={clsx(styles.containerPhone, className)}>
      <PhoneField onValidate={validate} valid={valid} {...props} />
      {valid === false && (
        <div className="errorMessage">
          <Information error>{t('pleaseEnterCorrectPhoneNumber', 'Vul een correct telefoonnummer in')}</Information>
        </div>
      )}
    </div>
  );
};

export default InputPhone;
