import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LoadingOverlay from 'src/components/loader/loader';
import { usePostingReaction } from 'src/hooks/postings';
import PostingReactionForm from './form';
import styles from './reactions.module.scss';

const EditPostingReaction = ({ onCloseForm }) => {
  const { t } = useTranslation('page_postings');
  const { reactionId } = useParams();
  const reaction = usePostingReaction(reactionId);

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <h1>{t('reactions.edit.editComment', 'Reactie bewerken')}</h1>
        {reaction.isFetched ? (
          <PostingReactionForm reaction={reaction.data} onClose={onCloseForm} />
        ) : (
          <LoadingOverlay />
        )}
      </div>
    </div>
  );
};

export default EditPostingReaction;
