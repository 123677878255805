import clsx from 'clsx';
import styles from './user.module.scss';

import { User } from 'react-feather';

interface AvatarProps {
  src?: string | null;
  large?: boolean;
  medium?: boolean;
  small?: boolean;
  border?: boolean;
  alt?: string;
  title?: string;
  className?: string | string[];
}

const Avatar: React.FC<AvatarProps> = ({
  src,
  large,
  medium,
  small,
  border,
  alt = '',
  title = '',
  className,
  ...props
}) => {
  return (
    <div
      className={clsx(
        styles.profileImage,
        large && styles.large,
        medium && styles.medium,
        small && styles.small,
        (border || (!src && border !== false)) && styles.border,
        className
      )}
      title={title}
      {...props}
    >
      {src ? <img src={src} alt={alt} /> : <User />}
    </div>
  );
};

export default Avatar;
