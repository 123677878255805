import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Check, Clock, Eye, Repeat, X } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import { useGateway } from 'src/providers/gateway';
import { formatDateHtml, formatDateTime, nullifyMinDate } from 'src/utils';
import A from '../a';
import { IconButton } from '../button/button';
import DatePicker from '../datepicker/datepicker';
import Dialog from '../dialog/dialog';
import InputTextArea from '../input_textarea/input_textarea';
import LoadingOverlay from '../loader/loader';
import { ScreeningDocumentStatus } from './constants';
import PostponeExpirationDateDialog from './postponeExpirationDateDialog';
import ReplaceDocumentDialog from './replacement_dialog';
import styles from './verdict_document_item.module.scss';

const ScreeningDocumentStatusStyles = {
  [ScreeningDocumentStatus.New]: styles.new,
  [ScreeningDocumentStatus.Approved]: styles.approved,
  [ScreeningDocumentStatus.Rejected]: styles.rejected,
  [ScreeningDocumentStatus.Expired]: styles.expired,
};

const VerdictDocumentItem = (props) => {
  const { t } = useTranslation(['components', 'common']);
  const { api } = useGateway();

  const [showVerdictDialog, setShowVerdictDialog] = useState(null);
  const [showReplaceDialog, setShowReplaceDialog] = useState(false);
  const [showPostponeDialog, setShowPostponeDialog] = useState(false);
  const [documentItem, setDocumentItem] = useState(props.document);
  const [isLoading, setIsLoading] = useState(false);
  const [reasonForRejection, setReasonForRejection] = useState(null);

  const [documentIsReplaced, setDocumentIsReplaced] = useState(false);

  const needsUpload = documentItem.status === ScreeningDocumentStatus.New && _.isEmpty(documentItem.url);
  const needsExpirationDate = documentItem.screeningDocType !== 'Diploma';

  const expirationDate = nullifyMinDate(documentItem.expirationDate);

  // Only allow approval of a doc when the expiration date is valid (in the future)
  const expirationDateIsValid = !needsExpirationDate || moment(expirationDate).isAfter(moment());

  // Reset document to props.document whenever it changes there
  useEffect(() => {
    setDocumentItem(props.document);
  }, [props.document]);

  const saveScreeningDocumentVerdict = async (verdict) => {
    setIsLoading(true);

    try {
      await api.onboardingScreenings.saveScreeningDocumentVerdict({
        id: documentItem.id,
        expirationDate: expirationDate ? formatDateHtml(expirationDate) : undefined,
        status: verdict,
        reasonForRejection: [reasonForRejection, documentItem.reasonForRejection].filter(Boolean).join('\n'),
      });
      await props.onSaveScreeningDocumentVerdict();
    } catch (err) {
      console.error('Error saving verdict:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const postponeDocument = (postponementDate) => {
    setDocumentItem({
      ...documentItem,
      postponementDate,
    });
    setShowPostponeDialog(false);
  };

  const replaceDocument = (result) => {
    if (result) {
      setDocumentItem(result);
      setDocumentIsReplaced(true);
    }
    setShowReplaceDialog(false);
  };

  return (
    <>
      {isLoading && <LoadingOverlay />}

      {showVerdictDialog && (
        <Dialog
          onConfirm={() => {
            saveScreeningDocumentVerdict(showVerdictDialog);
            setShowVerdictDialog(false);
          }}
          onCancel={() => {
            setShowVerdictDialog(false);
          }}
          invertAction
          labelQuestion={
            showVerdictDialog === ScreeningDocumentStatus.Approved ? (
              <Trans t={t} i18nKey="aboutToApproveDocument">
                Je staat op het punt om het document <b>{{ screeningDocType: documentItem.screeningDocType }}</b> goed
                te keuren.
                <br />
                <br />
                Weet je het zeker?
              </Trans>
            ) : (
              <Trans t={t} i18nKey="aboutToRejectDocument">
                Je staat op het punt om het document <b>{{ screeningDocType: documentItem.screeningDocType }}</b> af te
                keuren.
                <br />
                <br />
                Geef hiervoor een reden op.
              </Trans>
            )
          }
          labelCancel={t('cancel', 'Annuleren')}
          labelConfirm={
            showVerdictDialog === ScreeningDocumentStatus.Approved
              ? t('approve', 'Goedkeuren')
              : t('reject', 'Afkeuren')
          }
        >
          {showVerdictDialog === ScreeningDocumentStatus.Rejected && (
            <>
              {documentItem.reasonForRejection && (
                <div className={styles.reasonField} style={{ whiteSpace: 'pre-wrap' }}>
                  {documentItem.reasonForRejection}
                </div>
              )}
              <InputTextArea
                placeholder={t('disapprovalReasonPlaceholder', 'Geef hier je reden voor afkeuren op...')}
                onChange={(value) =>
                  setReasonForRejection(
                    `[${formatDateTime(undefined, { format: t('common:dateTimeFormat') })}] ${value}`
                  )
                }
              />
            </>
          )}
        </Dialog>
      )}

      {showPostponeDialog && (
        <PostponeExpirationDateDialog
          documentId={documentItem.id}
          documentType={documentItem.screeningDocType}
          expirationDate={documentItem.expirationDate}
          onConfirm={postponeDocument}
          onCancel={() => setShowPostponeDialog(false)}
        />
      )}

      {showReplaceDialog && (
        <ReplaceDocumentDialog
          screeningId={props.screeningId}
          documentType={documentItem.screeningDocType}
          onConfirm={replaceDocument}
          onCancel={() => setShowReplaceDialog(false)}
        />
      )}

      <li
        className={clsx(
          styles.documentItem,
          ScreeningDocumentStatusStyles[documentItem.status],
          ![ScreeningDocumentStatus.New, ScreeningDocumentStatus.Rejected].includes(documentItem.status) &&
            !expirationDateIsValid &&
            styles.invalidExpirationDate
        )}
      >
        {/* Display an eye icon if a file url is available, or display nothing */}
        {documentItem.url ? (
          <A href={documentItem.url}>
            <Eye />
          </A>
        ) : (
          <div />
        )}

        <div className={styles.title}>
          <div>{documentItem.screeningDocType}</div>
          {documentItem.status === ScreeningDocumentStatus.Rejected && <label>{documentItem.reasonForRejection}</label>}
        </div>

        {/* When a profile is ready for screening, display a date input field for the expiration date,
            and when it's not ready for screening, display the reason this document was rejected */}
        {!needsExpirationDate ? (
          <div></div>
        ) : needsUpload ? (
          <div>-</div>
        ) : (
          <DatePicker
            // Document expiration date can only be changed when profile hasn't been screened yet or when uploading a new document
            disabled={props.screeningCompleted && !documentIsReplaced}
            placeholder={t('common:dateFormat')}
            value={expirationDate || ''}
            onChange={(value) => {
              setDocumentItem((prevState) => ({
                ...prevState,
                expirationDate: value,
              }));
              props.onUpdate &&
                props.onUpdate({
                  ...documentItem,
                  expirationDate: value,
                });
            }}
          />
        )}

        <div>
          {/* Approve document */}
          <IconButton
            icon={<Check />}
            small
            secondary
            disabled={documentItem.status === ScreeningDocumentStatus.Approved || !expirationDateIsValid}
            onClick={() => {
              setShowVerdictDialog(ScreeningDocumentStatus.Approved);
            }}
          />

          {/* Reject document */}
          <IconButton
            icon={<X />}
            small
            secondary
            disabled={documentItem.status === ScreeningDocumentStatus.Rejected}
            onClick={() => {
              setShowVerdictDialog(ScreeningDocumentStatus.Rejected);
            }}
          />

          {/* Postpone document expiration date */}
          <IconButton
            icon={<Clock />}
            small
            secondary
            disabled={!needsExpirationDate || !expirationDate || Boolean(documentItem.postponementDate)}
            onClick={() => {
              setShowPostponeDialog(true);
            }}
          />

          {/* Replace document */}
          <IconButton
            icon={<Repeat />}
            small
            secondary
            onClick={() => {
              setShowReplaceDialog(true);
            }}
          />
        </div>
      </li>
    </>
  );
};

export default VerdictDocumentItem;
