import { GetCaregiversByNameInput, SaveBookingModel } from '@tallkingconnect/gateway';
import { useQuery } from '@tanstack/react-query';
import { useGateway } from 'src/providers/gateway';

export function useCaregiversForBooking(booking: SaveBookingModel) {
  const { api } = useGateway();

  return useQuery({
    queryKey: ['caregiversForBooking', { booking }] as [string, { booking: SaveBookingModel }],
    queryFn: ({ queryKey: [, { booking }] }) => api.bookings.getCaregiversForBooking(booking),
  });
}

export function useCaregiversByName(input: GetCaregiversByNameInput) {
  const { api } = useGateway();

  return useQuery({
    queryKey: ['caregiversByName', { input }] as [string, { input: GetCaregiversByNameInput }],
    queryFn: ({ queryKey: [, { input }] }) => api.caregivers.getCaregiversByName(input),
    enabled: (input.searchName?.length ?? 0) >= (Number(process.env.REACT_APP_CAREGIVER_SEARCH_MINIMUM_CHARS) ?? 3),
  });
}
