import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import A from 'src/components/a';
import Button from 'src/components/button/button';
import InputCheckbox from 'src/components/input_checkbox/input_checkbox';
import InputEmail from 'src/components/input_email/input_email';
import InputPhone from 'src/components/input_number/input_phone';
import InputPassword from 'src/components/input_password/input_password';
import InputText from 'src/components/input_text/input_text';
import LoadingOverlay from 'src/components/loader/loader';
import UserHelpBar from 'src/components/user_help_bar/user_help_bar';
import { useAuth0 } from 'src/providers/auth0';
import { useGateway } from 'src/providers/gateway';
import { buildUrl } from 'src/utils';

//
// Note on HubSpot integration:
//
// We have wrapped the registration form in a <form> tag just to test whether this works for HubSpot.
// For proper HubSpot integration, it is preferred though to actually use HubSpot's "submit data to a form API",
// instead of relying on its "non-HubSpot forms" feature.
// See: https://knowledge.hubspot.com/forms/use-non-hubspot-forms
//

const Register = () => {
  const { t } = useTranslation(['pages_register', 'common']);

  const navigate = useNavigate();
  const { api } = useGateway(true);

  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
    userType: 'Caregiver',
    companyId: '1',
    subscribedToNewsletter: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [valid, setValid] = useState();
  const [userAgreedToTerms, setUserAgreedToTerms] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState();
  const [emailIsUnique, setEmailIsUnique] = useState();
  const [phoneInputIsValid, setPhoneInputIsValid] = useState();
  const [passwordInputIsValid, setPasswordInputIsValid] = useState();

  const { loginWithRedirect } = useAuth0();

  const updateUserInfo = _.curry((param, value) => {
    setUserInfo((prevState) => ({
      ...prevState,
      [param]: value,
    }));
  });

  const checkIsEmailUnique = async (email) => {
    setEmailIsUnique();
    if (/^.+@.+\..+$/.test(email)) {
      try {
        const data = await api.universal.emailIsUnique({ email }, { headers: { Authorization: 'stub' } });
        setEmailIsUnique(data.value);
      } catch (err) {
        console.error('Error checking email uniqueness:', err);
      }
    }
  };

  const createUser = async () => {
    setIsLoading(true);

    try {
      await api.users.createCaregiverAccount(userInfo, { headers: { Authorization: 'stub' } });

      // TODO: show an information page or popup about the redirect (well, login) that will need to happen next.
      // This is a major change w.r.t. the previous workflow, but auto login using username and password
      // is strongly discouraged by Auth0 and other parties as this poses some security risks.

      try {
        loginWithRedirect({
          authorizationParams: {
            login_hint: userInfo.email,
            // The next line only works with routes that have been whitelisted in the Auth0 configuration
            redirect_uri: buildUrl('/'),
          },
        });
      } catch (err) {
        navigate('/onboarding');
      }
    } catch (err) {
      console.error('Error creating caregiver account:', err);
      setIsLoading(false);
      return;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    createUser();
  };

  useEffect(() => {
    setValid(
      Object.values(userInfo).every((value) => value !== '') &&
        userAgreedToTerms &&
        emailIsUnique &&
        emailIsValid &&
        phoneInputIsValid &&
        passwordInputIsValid // also checks password length
    );
  }, [userInfo, userAgreedToTerms, emailIsUnique, emailIsValid, phoneInputIsValid, passwordInputIsValid]);

  const TermsOfUseLabel = () => (
    <Trans
      t={t}
      i18nKey="common:termsOfUse.label"
      defaults="Ik ga <b>akkoord</b> met de <a>algemene voorwaarden</a>"
      values={{ link: t('termsOfUse.link') }}
      components={{ b: <b />, a: <A href={t('common:termsOfUse.link')} /> }}
    />
  );

  return (
    <>
      {isLoading && <LoadingOverlay />}

      <form method="post" name="Home:Register">
        <div data-login={true}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h1>{t('label.header', 'Aanmelden als TallKing Connect zorgverlener')}</h1>
            <UserHelpBar />

            <div>
              <p>
                <b>{t('label.required', 'Let op: alle velden zijn verplicht.')}</b>
              </p>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: '45%' }}>
                <label>{t('label.firstName', 'Voornaam')}</label>
                <InputText
                  name="firstName"
                  value={userInfo.firstName}
                  onChange={updateUserInfo('firstName')}
                  autoFocus
                />
              </div>
              <div style={{ width: '45%' }}>
                <label>{t('label.lastName', 'Achternaam')}</label>
                <InputText name="lastName" value={userInfo.lastName} onChange={updateUserInfo('lastName')} />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: '45%' }}>
                <label>{t('label.phone', 'Telefoonnummer')}</label>
                <InputPhone
                  name="phonenumber"
                  value={userInfo.phoneNumber}
                  onChange={updateUserInfo('phoneNumber')}
                  onValidate={setPhoneInputIsValid}
                />
              </div>

              <div style={{ width: '45%' }}>
                <label>{t('label.zipCode', 'Postcode')}</label>
                <InputText name="postcode" value={userInfo.postcode} onChange={updateUserInfo('postcode')} />
              </div>
            </div>
            <div>
              <label>{t('label.email', 'E-mailadres')}</label>
              <InputEmail
                name="email"
                value={userInfo.email}
                onChange={updateUserInfo('email')}
                onValidate={setEmailIsValid}
                onBlur={checkIsEmailUnique}
                errorMessage={emailIsUnique === false ? t('errorMessage.email', 'Dit e-mailadres bestaat al') : null}
                placeholder={t('placeholder.email', 'E-mailadres')}
              />
            </div>
            <div>
              <label>{t('label.password', 'Wachtwoord')}</label>
              <InputPassword
                name="password"
                value={userInfo.password}
                onChange={updateUserInfo('password')}
                autoComplete="new-password"
                show
                onValidate={setPasswordInputIsValid}
              />
            </div>
            <div>
              <InputCheckbox
                name="userAgreedToTerms"
                value={userInfo.userAgreedToTerms}
                onChange={setUserAgreedToTerms}
                label={<TermsOfUseLabel />}
              />
            </div>
            <div>
              <InputCheckbox
                name="subscribedToNewsletter"
                value={userInfo.subscribedToNewsletter}
                onChange={updateUserInfo('subscribedToNewsletter')}
                label={t('label.newsletter', 'Schrijf mij in voor de TallKing Connect Nieuwsbrief')}
              />
            </div>

            <Button type="submit" name="submit" disabled={!valid} onClick={handleSubmit}>
              {t('button.signIn', 'Aanmelden')}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Register;
