import { formatTimeHtml } from 'src/utils';

export const DytterCompanyId = 1;

export const defaultShiftTimes = Object.freeze({
  morningBeginTime: '07:00:00',
  morningEndTime: '13:00:00',
  eveningBeginTime: '13:00:00',
  eveningEndTime: '18:00:00',
});

export const defaultCustomShiftTimes = Object.freeze({
  start: '00:00:00',
  end: '00:00:00',
});

export const emptyTeam = Object.freeze({
  countryCode: process.env.REACT_APP_DEFAULT_COUNTRY_CODE || '',
  ...defaultShiftTimes,
  plannerIds: [],
  approverIds: [],
});

// Shift enum.
// Note that this enum isn't actually used in the backend.
// The backend does use 2 shifts for each team (early and late, called "morning" and "evening" in fact);
// we are using an additional "custom" one so we can differentiate between set shifts and custom times in the interface.
export const TeamShift = Object.freeze({
  Early: 'Early',
  Late: 'Late',
  Custom: 'Custom',
});

export const makeShift = (start, end) => ({ start: formatTimeHtml(start), end: formatTimeHtml(end) });

export const getTeamShifts = (team, customShiftTimes) => ({
  [TeamShift.Early]: team ? makeShift(team.morningBeginTime, team.morningEndTime) : null,
  [TeamShift.Late]: team ? makeShift(team.eveningBeginTime, team.eveningEndTime) : null,
  [TeamShift.Custom]: customShiftTimes,
});
