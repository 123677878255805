import { compareStrings } from './compare';

/**
 * Sort select options.
 *
 * @param options Unsorted options
 * @param {function?} getSortString Function to get the sort string of the option. Defaults to `String(option.label)`
 * @returns {} Sorted options
 */
/*
 * NB: it is tempting to just use lodash, i.e. `const sortOptions = (options) => _.sortBy(options, 'label')`,
 * however as lodash doesn't use `localeCompare`, this may yield unexpected results when the strings contain accents.
 */
export const sortOptions = (
  options: unknown[],
  getSortString: (option: unknown) => string = (option) => String((option as { label: string }).label)
): unknown[] =>
  options
    .map((option) => [getSortString(option), option])
    .sort(([a], [b]) => compareStrings(a as string, b as string))
    .map(([, option]) => option);
