import { Query, QueryClient, QueryKey } from '@tanstack/react-query';
import invariant from 'invariant';
import _ from 'lodash';

export interface SelectOption<T = string> {
  value: string;
  label: string;
  data: T;
}

export interface IdNameObject {
  id?: string | null;
  name?: string | null;
}

export type ArrayWithIdAndName = [id: string, name: string | null];

function createSelectOptionFromString(item: string): SelectOption<string> {
  return {
    value: item,
    label: item,
    data: item,
  };
}

function createSelectOptionFromStringArray(item: ArrayWithIdAndName): SelectOption<undefined> {
  invariant(item[1], '!item[1]');

  return {
    value: item[0],
    label: item[1],
    data: undefined,
  };
}

function createSelectOptionFromObject<T extends IdNameObject>(item: T): SelectOption<T> {
  invariant(item.id && item.name, '!item.id || !item.name');

  return {
    value: item.id,
    label: item.name,
    data: item,
  };
}

/**
 * Transforms data into a format that is usable in our `Select` component. Those should contain at least the following
 * properties:
 * - value: value that should be used (often an id)
 * - label: label to display (typically a string)
 *
 * Additionally, the result will contain a `data` property containing the original object (when not a string).
 *
 * This function currently supports either string input or object input.
 * For object input, the label is taken from the `name` property.
 *
 * @param item Item to transform
 * @returns Object that can be used in our `Select` component.
 */
function createSelectOption(item: string | ArrayWithIdAndName | IdNameObject) {
  return _.isString(item)
    ? createSelectOptionFromString(item)
    : _.isArray(item)
    ? createSelectOptionFromStringArray(item)
    : createSelectOptionFromObject(item);
}

export function createSelectOptions(items: string[]): SelectOption<string>[];
export function createSelectOptions(items: Record<string, string | null>): SelectOption<string>[];
export function createSelectOptions(items: IdNameObject[]): SelectOption<IdNameObject>[];
export function createSelectOptions(items: Record<string, string | null> | string[] | IdNameObject[]) {
  return (_.isArray(items) ? items : Object.entries(items)).map((item) => createSelectOption(item));
}

export function invalidateCachedEntriesBy(
  queryClient: QueryClient,
  queryKey: QueryKey,
  predicate: (data: unknown) => boolean
) {
  (queryClient.getQueryCache().findAll({ queryKey, exact: false }) as Query[]).forEach(({ queryKey, state }) => {
    if (predicate(state.data)) {
      queryClient.invalidateQueries({ queryKey });
    }
  });
}
