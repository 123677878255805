import { Api, HttpClient } from '@tallkingconnect/gateway';

import {
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import invariant from 'invariant';
import _ from 'lodash';
import { createContext, useContext } from 'react';
import { useEffectOnce } from 'react-use';
import { useAuth0 } from './auth0';

/*
 * Upstream API type
 */

type UpstreamApi = Api<unknown>;

/*
 * Unwrapped API, which just returns the data from the Axios responses.
 *
 * Note: the generator that generates our API can be configured to just return the data,
 * however this removes access to response headers, http codes (such as 401 which we handle) etc,
 * so let's stick to this unwrapper.
 */

type UnwrappedApi = Pick<UpstreamApi, 'http'> & {
  [ModuleKey in keyof Omit<UpstreamApi, 'http'>]: {
    [FunctionKey in keyof UpstreamApi[ModuleKey]]: UpstreamApi[ModuleKey][FunctionKey] extends (
      ...args: infer TArgs
    ) => Promise<AxiosResponse<infer TResult>>
      ? (...args: TArgs) => Promise<TResult>
      : never;
  };
};

/**
 * Create an API derivative that returns the unwrapped output, i.e., just returning the `data` component of the `AxiosResponse`.
 */
function unwrapApi(api: UpstreamApi): UnwrappedApi {
  const { http, ...modules } = api;

  return {
    http,
    ..._.mapValues(modules, (module) =>
      _.mapValues(
        module,
        <TArgs extends unknown[], TResult>(fn: (...args: TArgs) => Promise<AxiosResponse<TResult>>) =>
          async function (...args: TArgs) {
            const response = await fn(...args);
            return response.data;
          }
      )
    ),
  } as UnwrappedApi;
}

// Alternatively, we could employ the following class instead of the `HttpClient` class provided by the generated API.
// This achieves the same effect, however because the types of the `Api` are hardcoded, they will no longer match when
// we do this. Therefore, the `unwrapApi` approach is slightly preferred.

// class HttpClientUnwrapped extends HttpClient {
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   public request = async <T = any, _E = any>(params: FullRequestParams): Promise<T> =>
//     super.request(params).then((response) => response.data);
// }

/*
 * Gateway context type, hook and provider.
 */

type TGatewayContext = { api: UnwrappedApi };

const GatewayContext = createContext<TGatewayContext>({} as TGatewayContext);
GatewayContext.displayName = 'GatewayContext';

export const useGateway = () => useContext(GatewayContext);

const GatewayProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { getToken } = useAuth0();

  const baseURL = process.env.REACT_APP_PLATFORM_GATEWAY_URL;
  const timeout = Number(process.env.REACT_APP_PLATFORM_GATEWAY_TIMEOUT);

  useEffectOnce(() => {
    console.info('[Gateway] Using:', { baseURL, timeout });
  });

  const http = new HttpClient({
    baseURL,
    timeout,
    headers: {
      Accept: 'application/json',
    },
  });

  const axiosClient: AxiosInstance = http.instance;

  const requestHandler = async (request: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    invariant(request.headers, '!request.headers');

    request.headers.ClientName = process.env.REACT_APP_PLATFORM_GATEWAY_CLIENT_NAME || '@tallkingconnect/home';

    if (!request.headers.Authorization) {
      const accessToken = await getToken();
      request.headers.Authorization = `Bearer ${accessToken}`;
    }

    console.debug(
      `[Gateway] ${request.method?.toUpperCase()} ${request.url} with token: ${(
        request.headers.Authorization as string
      )?.slice(-32)}`
    );

    return request;
  };

  const responseHandler = (response: AxiosResponse): AxiosResponse => {
    console.debug(
      `[Gateway] ${response.config.method?.toUpperCase()} ${response.config.url} => HTTP ${response.status}`
    );

    return response;
  };

  // TODO: could be replaced with axios-retry?
  const errorHandler = async (error: AxiosError): AxiosPromise => {
    if (error.response) {
      invariant(error.config, '!error.config');

      const originalRequest: AxiosRequestConfig & { isRetry?: boolean } = error.config;
      const httpStatus = error.response.status;

      console.error(
        `[Gateway] (!) ${originalRequest.method?.toUpperCase()} ${originalRequest.url} => HTTP ${httpStatus}`
      );

      // If the API responds with 401 (unauthorized) this means that our login is invalid,
      // either because of wrong credentials, or because of an expired token.
      if (httpStatus === 401 && !originalRequest.isRetry) {
        console.debug('[Gateway] Possibly token expired, retrying ...');

        try {
          const newToken = await getToken();

          const retryRequest = {
            ...originalRequest,
            isRetry: true,
            headers: {
              ...originalRequest.headers,
              Authorization: `Bearer ${newToken}`,
            },
          };

          return axiosClient.request(retryRequest);
        } catch (err) {
          console.error('[Gateway] Retry failed:', err);
          throw err;
        }
      } else {
        throw error;
      }
    } else {
      console.error('[Gateway] Unhandled Axios error:', error);
      throw error;
    }
  };

  axiosClient.interceptors.request.use(requestHandler, errorHandler);
  axiosClient.interceptors.response.use(responseHandler, errorHandler);

  const api = unwrapApi(new Api(http));

  return <GatewayContext.Provider value={{ api }}>{children}</GatewayContext.Provider>;
};

export default GatewayProvider;
