import clsx from 'clsx';
import _ from 'lodash';
import { useState } from 'react';
import { TextField } from './input_field';

export const InputText = ({ className, onValidate = _.noop, ...props }) => {
  const [valid, setValid] = useState(null);

  return (
    <div className={clsx(className)}>
      <TextField
        valid={valid}
        onValidate={(valid) => {
          setValid(valid);
          onValidate(valid);
        }}
        {...props}
      />
    </div>
  );
};

export default InputText;
