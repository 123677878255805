import clsx from 'clsx';
import _ from 'lodash';
import { useState } from 'react';
import { XCircle } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import { formatDate, formatDuration, formatTime } from 'src/utils';
import styles from './index.module.scss';

const DraftBookingItem = ({ draft: initialDraft, onDelete }) => {
  const { t } = useTranslation(['page_planning', 'common']);
  const [draft] = useState(initialDraft);
  const { team, caregiver } = draft.booking;

  return (
    <li className={styles.draft}>
      <div>
        <div>
          <Trans t={t} i18nKey="draftBookingList.times">
            Op <span>{{ times: formatDate(draft.start, { format: t('common:dateFormat') }) }}</span> van{' '}
            <span>{{ draftStart: formatTime(draft.start) }}</span> - <span>{{ draftEnd: formatTime(draft.end) }}</span>
          </Trans>
          {draft.booking.unbillableHours > 0
            ? ' ' +
              t('draftBookingList.withBreak', {
                defaultValue: 'met een pauze van {{unbillableHours}} minuten',
                unbillableHours: draft.booking.unbillableHours * 60,
              })
            : ''}
        </div>
        {team && (
          <div>
            {t('draftBookingList.for', 'voor')} <span>{team.name}</span>
          </div>
        )}
        <div>{draft.careSector}</div>
        {caregiver && (
          <div>
            - {caregiver.firstName} {caregiver.lastName}
          </div>
        )}
      </div>
      <div
        onClick={onDelete}
        className={clsx(styles.iconLink, styles.delete)}
        title={t('draftBookingList.remove', 'Verwijderen')}
      >
        <XCircle />
      </div>
    </li>
  );
};

const DraftBookingList = ({ drafts, onProceed, onDeleteItem }) => {
  const { t } = useTranslation('page_planning');
  return (
    <div className={styles.draftBookingList}>
      <h3>{t('draftBookingList.newBooking', 'Nieuwe boekingen')}</h3>
      <h5>
        {t('draftBookingList.bookingsCount', {
          defaultValue: '{{count}} boekingen',
          count: drafts.length,
        })}
        {' | '}
        {t('draftBookingList.BookingDuration', {
          defaultValue: '{{time}} uur',
          time: formatDuration(_.sumBy(drafts, 'booking.duration')),
          count: _.sumBy(drafts, 'booking.duration'),
        })}
      </h5>
      <ul>
        {drafts.map((draft) => (
          <DraftBookingItem key={draft.meta.draftId} draft={draft} onDelete={() => onDeleteItem(draft.meta.draftId)} />
        ))}
      </ul>
      <div className={styles.actions}>
        <Button onClick={onProceed}>{t('draftBookingList.complete', 'Voltooien')}</Button>
      </div>
    </div>
  );
};

export default DraftBookingList;
