import { PostingStatus } from '@tallkingconnect/gateway';
import { useState } from 'react';
import { Copy, Edit, FileText, Settings, ToggleLeft, ToggleRight, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { YesNoDialog } from 'src/components/dialog/dialog';
import DropDown from 'src/components/dropdown/dropdown';
import { useSavePostingMutation } from 'src/hooks/postings';

const ActionsMenu = ({ posting, onChangeModel }) => {
  const { t } = useTranslation('page_postings');

  const [dialogData, setDialogData] = useState(null);

  const postingMutation = useSavePostingMutation({
    onSuccess: (data) => onChangeModel(data),
  });

  const saveStatus = (nextStatus /*: PostingStatus*/) => {
    postingMutation.mutate({
      posting: {
        ...posting,
        status: nextStatus,
      },
    });
  };

  const showDialog = (labelQuestion, onConfirm) =>
    setDialogData({
      labelQuestion,
      onConfirm,
      onCancel: () => setDialogData(),
    });

  const deactivate = () =>
    showDialog(
      t('actionsMenu.areYouSureToDeactivateThisApplication', 'Weet je zeker dat je deze aanvraag wilt deactiveren?'),
      () => saveStatus(PostingStatus.Inactive)
    );
  const activate = () =>
    showDialog(
      t('actionsMenu.areYouSureToActivateThisApplication', 'Weet je zeker dat je deze aanvraag wilt activeren?'),
      () => saveStatus(PostingStatus.Active)
    );
  const retract = () =>
    showDialog(
      t('actionsMenu.areYouSureToWithdrawThisApplication', 'Weet je zeker dat je deze aanvraag wilt intrekken?'),
      () => saveStatus(PostingStatus.CancelledByPlanner)
    );

  return (
    <>
      {dialogData && <YesNoDialog {...dialogData} />}
      <DropDown>
        <Settings />
        <ul>
          {[PostingStatus.Accepted].includes(posting.status) && (
            <li data-has-icon>
              <FileText />
              <Link to="agreement">{t('actionsMenu.showAgreement')}</Link>
            </li>
          )}
          <li data-has-icon>
            <Edit />
            <Link to="edit">{t('actionsMenu.edit', 'Bewerken')}</Link>
          </li>
          <li data-has-icon>
            <Copy />
            <Link to="clone">{t('actionsMenu.duplicate', 'Dupliceren')}</Link>
          </li>
          {/* <li data-has-icon>
              <Link to={`${location.pathname}/prolong`}>Verlengen</Link>
          </li> */}
          {[PostingStatus.Active].includes(posting.status) && (
            <li data-has-icon>
              <ToggleLeft />
              <span data-clickable onClick={deactivate}>
                {t('actionsMenu.deactivate', 'Deactiveren')}
              </span>
            </li>
          )}
          {[PostingStatus.Concept, PostingStatus.Inactive].includes(posting.status) && (
            <li data-has-icon>
              <ToggleRight />
              <span data-clickable onClick={activate}>
                {t('actionsMenu.activate', 'Activeren')}
              </span>
            </li>
          )}
          {![PostingStatus.CancelledByPlanner, PostingStatus.CancelledByCaregiver].includes(posting.status) && (
            <li data-has-icon>
              <Trash2 />
              <span data-clickable onClick={retract}>
                {t('actionsMenu.withdraw', 'Intrekken')}
              </span>
            </li>
          )}
        </ul>
      </DropDown>
    </>
  );
};

export default ActionsMenu;
