import { GetMyDistrictTeamsOutput } from '@tallkingconnect/gateway';
import { useQuery } from '@tanstack/react-query';
import { emptyArray, emptyObject } from 'src/constants';
import { useGateway } from 'src/providers/gateway';
import { sortOptions } from 'src/utils/select';
import { createSelectOptions } from './helpers';

function selectMyTeams(data: GetMyDistrictTeamsOutput) {
  return {
    teams: createSelectOptions(data.districtTeams ?? (emptyArray as [])),
    selectableAccounts: sortOptions(
      (data.selectableAccounts ?? emptyArray).map((item) => ({
        value: item.id,
        label: `${item.firstName} ${item.lastName}`,
        data: item,
      }))
    ),
    selectableCompanies: sortOptions(createSelectOptions(data.selectableCompanies ?? emptyObject)),
    careSectors: sortOptions(createSelectOptions(data.careSectors ?? (emptyArray as []))),
  };
}

export function useMyTeams() {
  const { api } = useGateway();

  return useQuery({
    queryKey: ['caregiversByName'] as [string],
    queryFn: api.districtTeams.getMyDistrictTeams,
    select: selectMyTeams,
  });
}
