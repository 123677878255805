import { Outlet } from 'react-router-dom';
import Logo from 'src/components/logo';
import SelectLocale from 'src/components/select/select_locale';
import Flags from 'src/config/flags';
import MyFooter from './footer';
import styles from './login.module.scss';

const LoginLayout: React.FC = () => {
  return (
    <div className={styles.loginContainer}>
      <div className={styles.backgroundImage}></div>
      <div className={styles.componentContainer}>
        <header>
          <Logo className={styles.logo} />
          {Flags.enableI18N && <SelectLocale className={styles.selectLocale} />}
        </header>

        <main>
          <Outlet />
        </main>
      </div>

      <MyFooter />
    </div>
  );
};

export default LoginLayout;
