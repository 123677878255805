import clsx from 'clsx';
import styles from './calendar.module.scss';
import { CalendarProps, Navigate, ToolbarProps, View } from './types';

type ViewNamesGroupProps = {
  views: View[];
  view: View;
  messages: NonNullable<CalendarProps['messages']>;
  onView: NonNullable<CalendarProps['onView']>;
};

const ViewNamesGroup: React.FC<ViewNamesGroupProps> = ({ views, view: currentViewName, messages, onView }) => (
  <span className="rbc-btn-group">
    {(views ?? []).map((viewName) => (
      <button
        type="button"
        key={viewName}
        className={clsx({ 'rbc-active': currentViewName === viewName })}
        onClick={() => onView(viewName)}
      >
        {messages[viewName]}
      </button>
    ))}
  </span>
);

export const Toolbar: React.FC<ToolbarProps> = ({
  // date, // available, but not used here
  label,
  localizer: { messages },
  onNavigate,
  onView,
  view,
  views,
}) => {
  const viewNames = Array.isArray(views) ? views : (Object.keys(views) as View[]);

  return (
    <div className={clsx('rbc-toolbar', styles.toolbar)}>
      <div className={styles.left}>
        <span className={clsx('rbc-btn-group')}>
          <button type="button" onClick={() => onNavigate(Navigate.PREVIOUS)} aria-label={messages.previous}>
            {messages.previous}
          </button>
          <button type="button" onClick={() => onNavigate(Navigate.TODAY)} aria-label={messages.today}>
            {messages.today}
          </button>
          <button type="button" onClick={() => onNavigate(Navigate.NEXT)} aria-label={messages.next}>
            {messages.next}
          </button>
        </span>
      </div>

      <div className={styles.middle}>
        <span className="rbc-toolbar-label">{label}</span>
      </div>

      <div className={styles.right}>
        <button type="button" onClick={() => onNavigate(Navigate.REFRESH)}>
          {messages.refresh}
        </button>

        {viewNames.length > 1 && <ViewNamesGroup view={view} views={viewNames} messages={messages} onView={onView} />}
      </div>
    </div>
  );
};
