import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoaderBar from '../loader/loader_bar';
import styles from './input_file.module.scss';

const InputFile = (props) => {
  const { t } = useTranslation('components');
  const [errorMessage, setErrorMessage] = useState();
  const [filename, setFilename] = useState();
  const [maxSize] = useState(props.maxSize);

  const onChangeHandler = async (event) => {
    event.persist();
    const fileObject = await getFileObjectHandler(event);
    props.onChange(fileObject);
  };

  const formatBytes = (bytes, decimals) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024,
      dm = decimals <= 0 ? 0 : decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const getFileObjectHandler = (event) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      const file = event.target.files[0];

      if (maxSize && file) {
        if (file.size > maxSize) {
          const errorText = t('maximumFileSizeExceeded', {
            maxSize: formatBytes(maxSize, 0),
            defaultValue: 'Maximum bestandsgrootte van {{maxSize}} is overschreden, gebruik een kleiner bestand...',
          });
          setFilename(file.name);
          setErrorMessage(errorText);
          return reject(errorText);
        }
      }
      if (file === null || file === undefined) {
        setFilename();
        setErrorMessage();
        return null;
      }

      const fileObject = {
        contentType: file.type !== '' ? file.type : 'application/octet-stream',
        documentBase64String: '',
        screeningDocType: props.id,
        title: file.name,
      };

      reader.readAsDataURL(file);
      reader.onload = () => {
        setFilename(file.name);
        setErrorMessage();
        fileObject.documentBase64String = reader.result.split('base64,')[1];
        return resolve(fileObject);
      };
      reader.onerror = reject;
    });

  return (
    <div className={styles.inputFileField}>
      <input
        type="file"
        accept={props.accept || '*'}
        id={props.id}
        className={styles.container}
        onChange={onChangeHandler}
      />
      <div>{filename || t('chooseFile', 'Kies een bestand')}</div>
      {errorMessage && <label>{errorMessage}</label>}
      {props.isLoading && <LoaderBar style={{ bottom: '.8rem', position: 'absolute' }} />}
    </div>
  );
};

export default InputFile;
