import clsx from 'clsx';
import { useState } from 'react';
import { Star } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useGateway } from 'src/providers/gateway';
import styles from './index.module.scss';

// TODO: refactor this component, especially the way `favoriteObjectToSend` exists and it used is smelly
const Favorite = ({ isFavorite, favObject }) => {
  const { t } = useTranslation('components');
  const { api } = useGateway();

  const [favorite, setFavorite] = useState(isFavorite);
  const [favoriteObjectToSend] = useState(favObject);

  const toggleFavoriteCaregiver = async () => {
    try {
      await api.districtTeams.toggleDistrictTeamFavoriteCaregiver(favoriteObjectToSend);
      // TODO: backend should return the correct status, right now we get race conditions when toggling fast in a row (or in different browser tabs)
      setFavorite(!favorite);
    } catch (err) {
      console.error('Error toggling favorite caregiver');
    }
  };

  return (
    <div
      className={clsx(styles.container, 'favoriteCaregiver', favorite && 'active')}
      title={favorite ? t('unbookmark', 'Geen favoriet meer?') : t('bookmark', 'Favoriet maken?')}
      onClick={(e) => {
        e.stopPropagation();
        toggleFavoriteCaregiver();
      }}
    >
      <Star />
    </div>
  );
};

export default Favorite;
