import { buildEnum, buildOptions, filterI18nObject } from 'src/utils';

// Ordered BookingType enum values
const BookingTypeValues = ['Regular', 'Direct', 'Posting', 'Correction'];

/**
 * BookingType enum
 *
 * @type {Object.<string, string>}
 * @property Regular
 * @property Direct
 * @property Posting
 * @property Correction
 */
export const BookingType = buildEnum(BookingTypeValues);

// Ordered BookingStatus enum values
const BookingStatusValues = ['Created', 'Accepted', 'Rejected', 'CancelledByCaregiver', 'CancelledByPlanner'];

// Ordered BookingBillingStatus enum values
const BookingBillingStatusValues = [
  'BillingNotStarted',
  'CaregiverApproved',
  'InDispute',
  'BothPartiesApproved',
  'Billed',
  'WillNotBeBilled',
];

/**
 * BookingStatus labels for current bookings
 *
 * @type {Object.<string, string>}
 */
export const BookingStatusLabels = (t) => t('constants.bookingStatusLabels', { returnObjects: true });

/**
 * BookingStatus labels for historical bookings (all bookings in the past)
 *
 * @type {Object.<string, string>}
 */
export const BookingStatusHistoryLabels = (t) => t('constants.bookingStatusHistoryLabels', { returnObjects: true });

/**
 * BookingStatus enum
 *
 * @type {Object.<string, string>}
 * @property Created
 * @property Accepted
 * @property Rejected
 * @property CancelledByCaregiver
 * @property CancelledByPlanner
 */
export const BookingStatus = buildEnum(BookingStatusValues);

export const BookingBillingStatus = buildEnum(BookingBillingStatusValues);

/**
 * BookingStatus select options
 *
 * @type {Array.<Object>}
 * @property Created
 * @property Accepted
 * @property Rejected
 * @property CancelledByCaregiver
 * @property CancelledByPlanner
 */
export const BookingStatusOptions = (t) => buildOptions(BookingStatusValues, BookingStatusLabels(t));

// Ordered BookingDraftStatus enum values (frontend only)
export const BookingDraftStatusValues = ['New', 'Added'];

/**
 * BookingDraftStatus enum (frontend only)
 *
 * @type {Object.<string, string>}
 * @property New
 * @property Added
 */
export const BookingDraftStatus = buildEnum(BookingDraftStatusValues);

export const DefaultBookingStatusVisibility = {
  planner: BookingStatusValues,
  admin: BookingStatusValues,
};

// Ordered CaregiverFilterRating values.
// Note that 'favorite' isn't an actual filter rating and is left out of this list for that reason.
export const CaregiverFilterRatingValues = ['best', 'good', 'poor', 'searchedbyname', 'searchedbutinadequate'];

export const CaregiverFilterRatingBestFitOrder = ['best', 'good', 'favorite', 'searchedbyname'];

export const CaregiverFilterRatingDisplayOrder = [
  'searchedbyname',
  'favorite',
  'best',
  'good',
  'poor',
  'searchedbutinadequate',
];

/**
 * CaregiverFilterRating enum
 *
 * @type {Array.<Object>}
 * @property best
 * @property good
 * @property poor
 * @property searchedbyname
 * @property searchedbutinadequate
 */
export const CaregiverFilterRating = buildEnum(CaregiverFilterRatingValues);

/**
 * CaregiverFilterRating labels
 *
 * @type {Object.<string, string>}
 */
// TODO Remove? Replaced with i18n t-function.
export const CaregiverFilterRatingLabels = (t) => filterI18nObject(t, 'constants.caregiverFilterRating', 'label');

/**
 * CaregiverFilterRating information
 *
 * @type {Object.<string, string>}
 */
// TODO Remove? Replaced with i18n t-function.
export const CaregiverFilterRatingInfo = (t) => filterI18nObject(t, 'constants.caregiverFilterRating', 'info');

export const compareFilterRating = (a, b) =>
  CaregiverFilterRatingValues.indexOf(a) - CaregiverFilterRatingValues.indexOf(b);
