import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Error from 'src/components/error/error';
import LoadingOverlay from 'src/components/loader/loader';
import { useAgreementDetails } from 'src/hooks/documents';
import CareAgreementDocument from '../documents/templates/nl/careAgreement';
import styles from './postings.module.scss';
import invariant from 'invariant';

const PostingAgreement = () => {
  const { t } = useTranslation('page_postings');
  const { postingId } = useParams();
  invariant(postingId, 'postingId undefined');
  const { data: model, isLoading, error } = useAgreementDetails(postingId);

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <h1>{t('agreement.title')}</h1>
        {isLoading ? (
          <LoadingOverlay />
        ) : error ? (
          <Error>{error.message}</Error>
        ) : (
          <CareAgreementDocument model={model} />
        )}
      </div>
    </div>
  );
};

export default PostingAgreement;
