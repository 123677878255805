import { useTranslation } from 'react-i18next';
import Dialog from 'src/components/dialog';
import BookingForm from './bookingForm';

const EditBookingDialog = (props) => {
  const { t } = useTranslation('page_planning');
  return (
    <Dialog
      showActions={false}
      labelQuestion={t('dialogs.editBookingDialog.completeBookingData', 'Vul de boeking-gegevens aan')}
      // required for supporting "click behind to close"
      onCancel={props.onCancel}
    >
      <BookingForm {...props} />
    </Dialog>
  );
};

// Popout doesn't position correctly yet (items hidden behind other components). Let's fix this later.
// const EditBookingDialog = ({ /*onConfirm, onCancel,*/ ...props }) => {
//   return (
//     <PopOut
//       labelQuestion="Vul de boeking-gegevens aan"
//       // onConfirm={onConfirm}
//       // onCancel={onCancel}
//       visible // TODO: make this controllable
//       setVisible={(visible) => {
//         if (!visible) {
//           props.onCancel();
//         }
//       }}
//     >
//       <BookingMiniForm {...props} />
//     </PopOut>
//   );
// };

export default EditBookingDialog;
