import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import A from '../a';
import { Form, FormRow } from '../form/form';
import { LabeledFormInputView } from '../form/labeled_form_input';
import AzureStaticMap, { buildBingMapUrl } from '../map/map';
import styles from './address.module.scss';
import { fields } from './constants';

const infoTemplate = [
  { key: 'street', items: [fields.street, fields.houseNumber] },
  { key: 'line2', items: [fields.extension] },
  { key: 'place', items: [fields.postcode, fields.city] },
  { key: 'country', items: [fields.countryCode] },
];

const AddressView = ({
  address,
  showEmpty = false,
  pinTitle = '',
  pinNotes = null,
  mapWidth = 300,
  mapHeight = 300,
  className,
}) => {
  const { t } = useTranslation('components');
  return (
    <Form className={clsx(styles.form, className)} comfortable>
      <div className={styles.info}>
        <FormRow>
          <LabeledFormInputView
            className={styles.flatAddress}
            label={t('details', 'Adresgegevens')}
            value={infoTemplate.map((row) => (
              <div key={row.key}>
                {row.items.map((modelKey) => {
                  const value = address[modelKey];
                  return (showEmpty || value) && <span key={modelKey}>{value}</span>;
                })}
              </div>
            ))}
          />
        </FormRow>
      </div>

      <div className={styles.map}>
        <A
          href={buildBingMapUrl({
            lat: address.lat,
            lng: address.lng,
            pinTitle,
            pinNotes:
              pinNotes ||
              infoTemplate
                .map((row) =>
                  row.items
                    .map((modelKey) => address[modelKey] || (showEmpty ? '' : null))
                    .filter(Boolean)
                    .join(' ')
                )
                .filter(Boolean)
                .join('\n'),
          })}
        >
          <AzureStaticMap
            lat={address.lat}
            lng={address.lng}
            width={mapWidth}
            height={mapHeight}
            alt={t('location', 'Locatie')}
          />
        </A>
      </div>
    </Form>
  );
};

export default AddressView;
