import ms from 'ms';
import { parseEnvBool, parseEnvNumber } from 'src/utils/env';

/**
 * @file
 * The flags in this file can be used to enable or disable certain pieces of functionality, or workarounds, globally.
 */

type FlagValue = boolean | number;

type FlagValueResolver = (flags: TFlags) => FlagValue;

type Flag = {
  name: string;
  defaultValue: FlagValue | FlagValueResolver;
  value: FlagValue;
  description?: string;
};

type TFlagStorage = Record<string, Flag>;
type TFlags = Record<string, FlagValue>;

const FlagStorage: TFlagStorage = {};
const Flags: TFlags = {};

export default Flags;

const resolveValue = (defaultValue: FlagValue | FlagValueResolver) =>
  typeof defaultValue === 'function' ? defaultValue(Flags) : defaultValue;

// TODO: use persistent storage
const defineFlag = (name: string, defaultValue: FlagValue | FlagValueResolver, description?: string) => {
  const flag: Flag = {
    name,
    defaultValue,
    value: resolveValue(defaultValue),
    description,
  };

  FlagStorage[name] = flag;

  Object.defineProperty(Flags, name, {
    get() {
      return flag.value;
    },
    set(value) {
      flag.value = value;
    },
  });
};

Object.defineProperty(Flags, 'reset', {
  value: (name: string) => {
    Flags[name] = resolveValue(FlagStorage[name].defaultValue);
  },
  writable: false,
});

/*
 * Feature flags
 */

defineFlag(
  'enableIndividualCareRequests',
  parseEnvBool('REACT_APP_ENABLE_INDIVIDUAL_CARE_REQUESTS', true),
  'Enable care requests module (e.g. for ONVZ pilot)'
);

defineFlag(
  'enableAutoRefreshNotifications',
  parseEnvBool('REACT_APP_ENABLE_AUTO_REFRESH_NOTIFICATIONS', true),
  'Enable auto-refresh for notifications. Turn off during development to reduce network clutter and page refreshes.'
);
defineFlag(
  'enableAutoRefreshBookings',
  parseEnvBool('REACT_APP_ENABLE_AUTO_REFRESH_BOOKINGS', true),
  'Enable auto-refresh for bookings. Turn off during development to reduce network clutter and page refreshes.'
);

defineFlag('enableI18N', parseEnvBool('REACT_APP_ENABLE_I18N', false), 'Enable internationalization (i18n)');
defineFlag('debugI18N', parseEnvBool('REACT_APP_DEBUG_I18N', false), 'Debug internationalization (i18n)');
defineFlag(
  'cacheI18NMilliseconds',
  parseEnvNumber('REACT_APP_CACHE_I18N_MILLISECONDS', ms('24h')),
  'Max age of the translations cache before forcing a reload.'
);

// Flag introduced on 2022.05.06, disabled by default. We no longer want to apply the max date for direct bookings from this point.
defineFlag(
  'enableDirectBookingMaxDate',
  parseEnvBool('REACT_APP_ENABLE_DIRECT_BOOKING_MAX_DATE', false),
  'Enable max date for Direct bookings.'
);

defineFlag(
  'enableGetDistrictTeamsForWorkingRadiusCount',
  parseEnvBool('REACT_APP_ENABLE_GET_DISTRICT_TEAMS_FOR_WORKING_RADIUS_COUNT', false),
  'Enable option for screeners to retrieve number of district teams within working range on the screening profile page.'
);
