import clsx from 'clsx';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatDate, formatDateTime } from 'src/utils';
import Information from '../information/information';
import InputCheckbox from '../input_checkbox';
import Select from '../select/select';
import SelectCountry from '../select/select_country';
import styles from './form.module.scss';

export const LabeledFormInput = ({ component: Component, label, value, className, information, ...props }) => {
  return (
    <div className={clsx(styles.labeledFormInput, className)}>
      {Component === InputCheckbox ? (
        <Component label={label} value={value} {...props} />
      ) : (
        <>
          <div className={styles.labelWrapper}>
            <label>{label}</label>
            {information && <Information>{information}</Information>}
          </div>

          {[Select, SelectCountry].includes(Component) && props.multiple ? (
            <Component value={_.isNil(value) ? [] : _.castArray(value)} {...props} />
          ) : (
            <Component value={value} {...props} />
          )}
        </>
      )}
    </div>
  );
};

export const LabeledFormInputView = ({
  label,
  value,
  className,
  isBoolean = false,
  isCurrency = false,
  isDateTime = false,
  options,
}) => {
  const { t } = useTranslation('common');
  if (!_.isNil(value)) {
    if (_.isDate(value)) {
      value = isDateTime
        ? formatDateTime(value, { format: t('dateTimeFormat') })
        : formatDate(value, { format: t('dateFormat') });
    } else if (isCurrency) {
      value = formatCurrency(value);
    } else if (options) {
      value = _.get(_.find(options, { value }), 'label');
    }
  }

  return (
    <div className={clsx(styles.labeledFormInput, className)}>
      {isBoolean ? (
        <InputCheckbox label={label} value={value} readOnly />
      ) : (
        <>
          <label>{label}</label>
          <div className={styles.value}>{value}</div>
        </>
      )}
    </div>
  );
};

export default LabeledFormInput;
