import clsx from 'clsx';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PlusButton } from 'src/components/button/button';
import FilterToolbar from 'src/components/filter/filterToolbar';
import LoadingOverlay from 'src/components/loader/loader';
import Avatar from 'src/components/user/avatar';
import { usePostings } from 'src/hooks/postings';
import { useUserInformation } from 'src/hooks/users';
import { formatCurrency, formatDate } from 'src/utils';
import { sortOptions } from 'src/utils/select';
import { DefaultPostingStatusVisibility, PostingStatus, PostingStatusOptions } from './constants';
import styles from './postings.module.scss';

const PostingStatusStyles = {
  [PostingStatus.New]: null, //styles.new,
  [PostingStatus.Active]: null, //styles.active,
  [PostingStatus.Pending]: null, //styles.pending,
  [PostingStatus.Inactive]: styles.inactive,
  [PostingStatus.CancelledByPlanner]: styles.cancelled,
  [PostingStatus.Accepted]: null, //styles.accepted,
  [PostingStatus.CancelledByCaregiver]: styles.cancelled,
};

const RateIndication = ({ rate, currency = '€' }) => <div>{formatCurrency(rate, 2, currency)}</div>;

const ReactionsBadge = ({ count, ...props }) => {
  const { t } = useTranslation('page_postings');

  return count > -100 ? (
    <div
      className={styles.reactionsBadge}
      title={t('list.reactions', { count, defaultValue: '{{count}} reactions' })}
      {...props}
    >
      {count}
    </div>
  ) : (
    <div />
  );
};

const CaregiverBadge = ({ caregiver, ...props }) => (
  <Avatar
    src={caregiver.profilePictureUrl}
    title={`${caregiver.firstName} ${caregiver.lastName}`}
    className={styles.caregiverBadge}
    {...props}
  />
);

const ListHeader = () => {
  const { t } = useTranslation('page_postings');
  return (
    <li className={styles.header}>
      <div>{t('list.fromTo', 'Van / tot')}</div>
      <div>{t('list.hoursCompensation', 'Uren / vergoeding')}</div>
      <div>{t('list.by', 'Door')}</div>
      <div>{t('list.sinceState', 'Sinds / status')}</div>
      <div>{t('list.reactions', 'Reacties')}</div>
    </li>
  );
};

const ListItem = ({
  posting: {
    id,
    startDate,
    endDate,
    hoursPerWeek,
    suggestedRate,
    teamName,
    location,
    availableSince,
    status,
    reactionCount = 0,
    chosenCaregiver,
  },
}) => {
  const { t } = useTranslation(['page_postings', 'common']);
  const navigate = useNavigate();
  const { hasRole } = useUserInformation();

  const FormattedDate = ({ date }) => <div>{formatDate(date, { format: t('common:dateFormat') })}</div>;

  // Opening the reaction list is only available to recruiters and admins
  const openReactions =
    hasRole('Recruiter') || hasRole('Admin')
      ? (e) => {
          navigate(`${id}/reactions`);
          e.stopPropagation();
        }
      : undefined;

  return (
    <li className={clsx(styles.row, PostingStatusStyles[status])} onClick={() => navigate(id)}>
      <div>
        <FormattedDate date={startDate} />
        <FormattedDate date={endDate} />
      </div>
      <div>
        <div className={styles.hoursPerWeek}>{hoursPerWeek}</div>
        <RateIndication rate={suggestedRate} />
      </div>
      <div>
        <div>{teamName}</div>
        <div>({location})</div>
      </div>
      <div>
        <FormattedDate date={availableSince} />
        <div>{t(`constants.postingStatusLabels.${status}`)}</div>
      </div>
      <div>
        {chosenCaregiver ? (
          <CaregiverBadge caregiver={chosenCaregiver} onClick={openReactions} />
        ) : (
          <ReactionsBadge count={reactionCount} onClick={openReactions} />
        )}
      </div>
    </li>
  );
};

const AddPostingButton = () => {
  const navigate = useNavigate();

  return <PlusButton small onClick={() => navigate('add')} />;
};

const ListPostings = ({ postings = [], filters }) => {
  const { t } = useTranslation('page_postings');
  const { hasRole, data: user } = useUserInformation();
  const canCreatePostings = hasRole('Recruiter') || hasRole('Admin');
  const canViewPostings = canCreatePostings || !user.profileInactive;

  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <h1>{t('list.title', 'Aanvragen voor langdurige inzet')}</h1>
        {canCreatePostings && <AddPostingButton />}
      </div>
      <FilterToolbar key="filters" filters={filters} />
      <ul className={styles.list}>
        {canViewPostings ? (
          postings && postings.length ? (
            <>
              <ListHeader />
              {postings.map((posting) => (
                <ListItem key={posting.id} posting={posting} />
              ))}
            </>
          ) : (
            <div className={styles.empty}>{t('list.empty', 'Geen aanvragen om weer te geven')}</div>
          )
        ) : (
          <div className={styles.empty}>
            {t(
              'list.inactive',
              'Je profiel is inactief, dus je kunt niet reageren op aanvragen. Check je profiel om de reden te zien.'
            )}
          </div>
        )}
      </ul>
    </div>
  );
};

const Page = () => {
  const { hasRole } = useUserInformation();
  const { t } = useTranslation('page_postings');

  // Determine what statuses can be shown to the user
  const allowedStatuses = useMemo(
    () =>
      _.uniq([
        ...(hasRole('Admin') ? DefaultPostingStatusVisibility.admin : []),
        ...(hasRole('Recruiter') ? DefaultPostingStatusVisibility.recruiter : []),
        ...(hasRole('Caregiver') ? DefaultPostingStatusVisibility.caregiver.mine : []),
      ]),
    [hasRole]
  );

  const [teamOptions, setTeamOptions] = useState([]);
  const [visibleTeams, setVisibleTeams] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState(
    (JSON.parse(localStorage.getItem('postings.selectedStatuses')) || []).filter((status) =>
      allowedStatuses.includes(status)
    )
  );

  const visibleStatuses = !_.isEmpty(selectedStatuses) ? selectedStatuses : allowedStatuses;

  // Store status filter
  useEffect(() => {
    if (selectedStatuses) {
      localStorage.setItem('postings.selectedStatuses', JSON.stringify(selectedStatuses));
    }
  }, [selectedStatuses]);

  const postings = usePostings(visibleStatuses);

  // Fill team filter options with the teams from postings
  useEffect(() => {
    const teams = postings.data?.postings?.map((posting) => posting.teamName) ?? [];
    const options = _.uniqBy(teams).map((teamName) => ({
      value: teamName,
      label: teamName,
    }));

    setTeamOptions((prevTeamOptions) => {
      // Prevent selected items from disappearing from the list by adding them as additional options if needed
      const additionalOptions = prevTeamOptions.filter(
        (opt) => visibleTeams.includes(opt.value) && !options.find((nextOpt) => nextOpt.value === opt.value)
      );

      return sortOptions(options.concat(additionalOptions));
    });
  }, [postings.data?.postings, visibleTeams]);

  const filters = [
    {
      key: 'teamFilter',
      multiple: true,
      placeholder: t('common:filter.teams'),
      options: teamOptions,
      value: visibleTeams,
      onChange: setVisibleTeams,
    },
    {
      key: 'statusFilter',
      multiple: true,
      placeholder: t('common:filter.status'),
      options: PostingStatusOptions.filter((option) => allowedStatuses.includes(option.value)),
      value: selectedStatuses,
      onChange: setSelectedStatuses,
    },
  ].filter(Boolean);

  // Filter postings on selected teams
  const filteredPostings = useMemo(
    () =>
      postings.data?.postings?.filter(
        (posting) => !posting.teamName || _.isEmpty(visibleTeams) || visibleTeams.includes(posting.teamName)
      ),
    [postings.data?.postings, visibleTeams]
  );

  return (
    <div className={styles.page}>
      {postings.isFetching && <LoadingOverlay />}
      <ListPostings postings={filteredPostings} filters={filters} />
    </div>
  );
};

export default Page;
