import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import DatePicker from 'src/components/datepicker/datepicker';
import { YesNoDialog } from 'src/components/dialog/dialog';
import LoadingOverlay from 'src/components/loader/loader';
import { useGateway } from 'src/providers/gateway';
import { formatDateHtml, formatDateTime } from 'src/utils';
import styles from './billing.module.scss';

const Billing = () => {
  const { t } = useTranslation(['page_admin', 'common']);
  const { api } = useGateway();

  const [initialLoad, setInitialLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [billingData, setBillingData] = useState({});
  // No default value for this one, we require the user to fill in a specific date.
  const [billUntil, setBillUntil] = useState();
  const [dialogVisible, setDialogVisible] = useState(false);

  const startBilling = async () => {
    setIsLoading(true);

    try {
      const data = await api.billing.startBilling({ billUntil });
      setBillingData(data);
      setBillUntil();
    } catch (err) {
      console.error('Error starting billing:', err);

      // TODO: improve on this rudimentary alert, see #1748
      alert(t('billing.error', { err, defaultValue: 'Er ging iets mis: {{err}}' }));
    } finally {
      setIsLoading(false);
    }
  };

  const getBillingData = useCallback(async () => {
    try {
      const data = await api.billing.getLastBillingCycleDate();
      setBillingData(data);
    } catch (err) {
      console.error('Error retrieving billing date:', err);
    }
  }, [api]);

  useEffect(() => {
    if (initialLoad === false) {
      getBillingData();
      setInitialLoad(true);
    }
  }, [getBillingData, initialLoad]);

  return (
    <div className={styles.container}>
      {isLoading && <LoadingOverlay />}

      <h1 style={{ textAlign: 'center' }}>{t('billing.headerBilling', 'Facturatie')}</h1>
      <p>
        {t(
          'billing.startInvoicingDescription',
          'Via dit formulier start je de facturatie. Hierbij worden alle boekingen met een boekingsdatum tot en met de opgegeven datum, die zijn goedgekeurd door beide partijen, of als correctieboeking zijn ingevoerd, verwerkt en naar WeFact verstuurd.'
        )}
      </p>
      {dialogVisible && (
        <YesNoDialog
          onCancel={() => {
            setDialogVisible(false);
          }}
          onConfirm={startBilling}
          labelQuestion={t('billing.startBillingDialog', 'Weet je zeker dat je de facturatie wilt starten?')}
        />
      )}

      <div>
        <label>{t('billing.dateLabel', 'Factureren t/m')}</label>
      </div>
      <DatePicker
        placeholder={t('common:dateFormat')}
        value={billUntil ? formatDateHtml(billUntil) : ''}
        maxDate={moment()}
        onChange={setBillUntil}
      />

      <Button
        disabled={!billUntil}
        onClick={() => {
          setDialogVisible(true);
        }}
      >
        {t('billing.startBillingButton', 'Start facturatie')}
      </Button>
      <p>
        <Trans t={t} i18nKey="billing.lastInvoiced">
          Laatst gefactureerd op:{' '}
          <b>
            {{
              billedOn: billingData.billedOn
                ? formatDateTime(billingData.billedOn, { format: t('common:dateTimeFormat') })
                : '...',
            }}
          </b>{' '}
          door <b>{{ billedBy: billingData.billedBy || '...' }}</b>.
        </Trans>
        <br />
        {t('billing.caregiverBillsCount', 'Facturen voor zorgverleners')}:{' '}
        <b>{billingData.caregiverBillsCount || '...'}</b>
        <br />
        {t('billing.organisationBillsCount', 'Facturen voor zorgorganisaties')}:{' '}
        <b>{billingData.organisationBillsCount || '...'}</b>
      </p>
    </div>
  );
};

export default Billing;
