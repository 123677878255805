import { ExternalLink as ExternalLinkIcon } from 'react-feather';
import A from '../a';
import styles from './index.module.scss';

const ExternalLink = ({ url, label }) => (
  <A href={url} className={styles.externalLink}>
    <ExternalLinkIcon />
    {label}
  </A>
);

export default ExternalLink;
