import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from 'src/types/navigation';
import Logo from '../logo';
import NavigationMenu from '../navigation_menu/navigationMenu';
import Notifications from '../notifications/notifications';
import User from '../user/user';
import styles from './header.module.scss';

interface HeaderProps {
  minimal?: boolean;
  title?: string;
  menuItems?: MenuItem[];
}

const Header: React.FC<HeaderProps> = ({ minimal = false, title = '', menuItems = [] }) => {
  const { t } = useTranslation('components');

  const [navigationMenuOpen, setNavigationMenuOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const toggleNavigationMenu = () => setNavigationMenuOpen(!navigationMenuOpen);
  const toggleNotificationsOpen = () => setNotificationsOpen(!notificationsOpen);

  // TODO: enable this later, when we have accent colors for all buttons etc enabled as well
  // const isAdmin = hasRole('Admin');
  // const isOrg = ['CompanyAdmin', 'Planner', 'Recruiter'].some((role) => hasRole(role));
  // const isCaregiver = hasRole('Caregiver');
  // const logoAccent = isAdmin ? 'default' : isOrg ? 'organisation' : isCaregiver ? 'caregiver' : 'default';

  const logoAccent = null;
  // const logoAccent = 'organisation';

  return (
    <header className={styles.container}>
      <div className={styles.left}>
        {!minimal && (
          <>
            <NavigationMenu menuItems={menuItems} open={navigationMenuOpen} toggleOpen={toggleNavigationMenu} />
            <h1>{title}</h1>
          </>
        )}
      </div>

      <div className={styles.middle}>
        <Logo accent={logoAccent} className={styles.logo} />
      </div>

      <div className={styles.right}>
        <Notifications
          toggleOpen={toggleNotificationsOpen}
          open={notificationsOpen}
          title={t('notifications', 'Notificaties')}
        />
        <User />
      </div>
    </header>
  );
};

export default Header;
