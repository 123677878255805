import {
  Auth0Provider,
  Auth0ProviderOptions,
  GetTokenSilentlyOptions,
  useAuth0 as useAuth0_real,
  User,
  withAuthenticationRequired,
  WithAuthenticationRequiredOptions,
} from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { buildUrl } from 'src/utils/url';

const auth0ProviderProps: Omit<Auth0ProviderOptions, 'children'> = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',

  authorizationParams: {
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    redirect_uri: window.location.origin,
    scope: 'openid profile email offline_access',
    display: 'popup',
    ui_locales: 'nl en',
  },

  // Enable refresh tokens (also requires 'offline_access' in `authorizationParams.scope`).
  useRefreshTokens: true,

  // When using a refresh token fails, try using a fallback.
  useRefreshTokensFallback: true,

  // Store cache in localStorage. This ensure that logins remain valid upon page reloads (F5).
  cacheLocation: 'localstorage',
};

const Auth0ProviderWithRedirectCallback: React.FC<React.PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback: Auth0ProviderOptions['onRedirectCallback'] = (appState) => {
    navigate(appState?.returnTo ?? '/');
  };

  return (
    <Auth0Provider {...auth0ProviderProps} onRedirectCallback={onRedirectCallback}>
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithRedirectCallback;

export const useAuth0 = () => {
  const auth0 = useAuth0_real();
  const queryClient = useQueryClient();

  useEffect(() => {
    const queryKey = ['auth', 'user'];
    const currentUser = auth0.user;
    const storedUser = queryClient.getQueryData(queryKey) as User;

    // Update stored user
    if (currentUser !== storedUser) {
      // If the user id (User.sub) differs, clear the cache
      if (currentUser?.sub !== storedUser?.sub) {
        queryClient.clear();
      }
      queryClient.setQueryData(queryKey, currentUser);
    }
  }, [queryClient, auth0.user]);

  return {
    ...auth0,

    isLoaded: !auth0.isLoading && (auth0.isAuthenticated || Boolean(auth0.error)),

    async getToken({
      cacheMode = 'on',
      detailedResponse = false,
    }: { cacheMode?: GetTokenSilentlyOptions['cacheMode']; detailedResponse?: boolean } = {}) {
      return await auth0.getAccessTokenSilently({
        authorizationParams: auth0ProviderProps.authorizationParams,
        cacheMode,
        detailedResponse,
      });
    },

    logout(pathname = '/') {
      return auth0.logout({
        logoutParams: {
          returnTo: buildUrl(pathname) as string,
        },
      });
    },
  };
};

interface ProtectedRouteProps extends WithAuthenticationRequiredOptions {
  component: React.ComponentType<object>;
  roles: string[];
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component, ...options }) => {
  const Component = withAuthenticationRequired(component, options);
  return <Component />;
};

interface AnonymousRouteProps {
  component: React.ComponentType<object>;
}

export const AnonymousRoute: React.FC<AnonymousRouteProps> = ({ component: Component }) => {
  const { isAuthenticated, isLoading } = useAuth0_real();

  // return isAuthenticated && !isLoading ? <Navigate to="/" /> : <Component />;
  return isAuthenticated && !isLoading ? <Link to="/">Redirect</Link> : <Component />;
};
