import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import HamburgerBar from '../hamburgerbar/hamburgerbar';
import styles from './navigationMenu.module.scss';

const NavigationMenu = ({ menuItems, toggleOpen, open }) => {
  const { t } = useTranslation('common');

  return (
    <div open={open} className={styles.container}>
      <HamburgerBar onClick={toggleOpen} open={open} />

      <div>
        <ul className={styles.list}>
          {menuItems &&
            (menuItems.length > 0 ? (
              menuItems.map((item) => (
                <li key={item.path}>
                  <NavLink
                    end
                    to={item.path}
                    onClick={toggleOpen}
                    className={({ isActive }) => clsx({ [styles.active]: isActive })}
                  >
                    {item.title}
                  </NavLink>
                </li>
              ))
            ) : (
              <li className={styles.empty}>
                <p>{t('emptyMenu', 'Momenteel zijn er geen menu items...')}</p>
              </li>
            ))}
        </ul>
      </div>
      <div className={styles.overlay} onClick={toggleOpen}></div>
    </div>
  );
};

export default NavigationMenu;
