import { useTranslation } from 'react-i18next';
import Dialog from 'src/components/dialog';
import BookingForm from './bookingForm';

const DraftBookingDialog = (props) => {
  const { t } = useTranslation('page_planning');
  return (
    <Dialog
      showActions={false}
      labelQuestion={t('dialogs.draftBookingDialog.completeBookingData', 'Vul de boeking-gegevens aan')}
      // required for supporting "click behind to close"
      onCancel={props.onCancel}
    >
      <BookingForm showHourlyRate={false} showManageDocuments {...props} />
    </Dialog>
  );
};

export default DraftBookingDialog;
