import { IndividualCareRequestStatus } from '@tallkingconnect/gateway';
import { useState } from 'react';
import { Edit, Settings, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { YesNoDialog } from 'src/components/dialog/dialog';
import DropDown from 'src/components/dropdown/dropdown';
import { useIndividualCareRequestMutation } from 'src/hooks/individualCare';

const ActionsMenu = ({ careRequest, onChangeModel }) => {
  const { t } = useTranslation('page_individualCare');
  const location = useLocation();

  const [dialogData, setDialogData] = useState(null);

  const mutation = useIndividualCareRequestMutation({
    onSuccess: (data) => onChangeModel(data),
  });

  const saveStatus = (nextStatus) => {
    mutation.mutate({
      careRequest: {
        ...careRequest,
        status: nextStatus,
      },
    });
  };

  const showDialog = (labelQuestion, onConfirm) =>
    setDialogData({
      labelQuestion,
      onConfirm,
      onCancel: () => setDialogData(),
    });

  const retract = () =>
    showDialog(
      t('actionsMenu.areYouSureToWithdrawThisApplication', 'Weet je zeker dat je deze aanvraag wilt intrekken?'),
      () => saveStatus(IndividualCareRequestStatus.CancelledByCareConsultant)
    );

  return (
    <>
      {dialogData && <YesNoDialog {...dialogData} />}
      <DropDown>
        <Settings />
        <ul>
          <li data-has-icon>
            <Edit />
            <Link to={`${location.pathname}/edit`}>{t('actionsMenu.edit', 'Bewerken')}</Link>
          </li>
          {![IndividualCareRequestStatus.CancelledByCareConsultant].includes(careRequest.status) && (
            <li data-has-icon>
              <Trash2 />
              <span data-clickable onClick={retract}>
                {t('actionsMenu.withdraw', 'Intrekken')}
              </span>
            </li>
          )}
        </ul>
      </DropDown>
    </>
  );
};

export default ActionsMenu;
