import clsx from 'clsx';
import { Portal } from 'react-portal';
import Logo from '../logo';
import styles from './loader.module.scss';

export const Loader = ({ className }) => (
  <Logo wordmark={false} margin={false} animate className={clsx(styles.logomark, className)} />
);

export const LoadingOverlay = ({ message = undefined }) => (
  <Portal>
    <div className={styles.overlay}>
      {message && <div className={styles.message}>{message}</div>}
      <Loader />
    </div>
  </Portal>
);

export default LoadingOverlay;
