import { addLocale, locale as setLocale, updateLocaleOption } from 'primereact/api';

const locales = {
  // src: https://github.com/primefaces/primelocale/blob/main/nl.json
  // changes:
  // - converted day and month names to lowercase
  nl: {
    startsWith: 'Begint met',
    contains: 'Bevat',
    notContains: 'Bevat niet',
    endsWith: 'Eindigt met',
    equals: 'Is gelijk aan',
    notEquals: 'Is niet gelijk aan',
    noFilter: 'Geen filter',
    lt: 'Kleiner dan',
    lte: 'Kleiner dan of gelijk aan',
    gt: 'Groter dan',
    gte: 'Groter dan of gelijk aan',
    dateIs: 'Datum is',
    dateIsNot: 'Datum is niet',
    dateBefore: 'Datum is voor',
    dateAfter: 'Date is na',
    custom: 'anders',
    clear: 'verwijderen',
    apply: 'toepassen',
    matchAll: 'Selecteer alle',
    matchAny: 'Selecteer elke',
    addRule: 'Voeg regel toe',
    removeRule: 'Verwijder regel',
    accept: 'Ja',
    reject: 'Nee',
    choose: 'Kies',
    upload: 'Upload',
    cancel: 'Stoppen',
    dayNames: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
    daynamesshort: ['zon', 'ma', 'di', 'woe', 'do', 'vrij', 'zat'],
    daynamesmin: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
    monthnames: [
      'januari',
      'februari',
      'maart',
      'april',
      'mei',
      'juni',
      'juli',
      'augustus',
      'september',
      'oktober',
      'november',
      'december',
    ],
    monthnamesshort: ['jan', 'feb', 'mar', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
    today: 'Vandaag',
    weekHeader: 'Wk',
    firstDayOfWeek: 0,
    dateFormat: 'dd-mm-yy',
    weak: 'Zwak',
    medium: 'Middel',
    strong: 'Sterk',
    passwordPrompt: 'Typ een wachtwoord',
    emptyFilterMessage: 'Geen beschikbare opties',
    emptyMessage: 'Geen resultaten gevonden',
    aria: {
      trueLabel: 'Waar',
      falseLabel: 'Onwaar',
      nullLabel: 'Niks geselecteerd',
      pageLabel: 'Pagina',
      firstPageLabel: 'Eerste pagina',
      lastPageLabel: 'Laatste pagina',
      nextPageLabel: 'Volgende pagina',
      previousPageLabel: 'Vorige pagina',
    },
  },
};

Object.entries(locales).forEach(([key, value]) => addLocale(key, value));

// Override `firstDayOfWeek` to 1 (Monday) from the default 0 (Sunday) for all locales
const firstDayOfWeek = 1;
for (const locale of ['en', ...Object.keys(locales)]) {
  updateLocaleOption('firstDayOfWeek', firstDayOfWeek, locale);
}

export const setupPrimeReact = (locale) => {
  setLocale(locale);
};
