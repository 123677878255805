import _ from 'lodash';

/**
 * Get subkeys from translation object
 * @example
 * // our translation object may look like this
 * {
 *  "postingDocumentType": {
 *    "JobDescription": {
 *      "label": "Beschreibung der Bestellung",
 *      "info": "Ausführliche Beschreibung der Aufgabe, die sich an die ZZP'er richtet."
 *    },
 *   "Terms": {
 *      "label": "Zusätzliche Bedingungen",
 *      "info": "Zusätzliche Bedingungen, die für diesen Auftrag gelten."
 *   }
 *  }
 * }
 *
 * // returns {"JobDescription": "Beschreibung der Bestellung", "Terms": "Zusätzliche Bedingungen"}
 * filterI18nObjectByType(t, "postingDocumentType", "label");
 */
export const filterI18nObject = (t, key, subkey) => _.map(t(key, { returnObjects: true }), (obj) => obj[subkey]);
