import clsx from 'clsx';
import { guid } from 'src/utils';
import { CheckboxField } from '../input_text/input_field';
import styles from './input_checkbox.module.scss';

const InputCheckbox = ({ className, label, id = guid(), ...props }) => (
  <div className={clsx(styles.container, className)}>
    <CheckboxField id={id} {...props} />
    <label htmlFor={id}>{label}</label>
    <div className={styles.checkIcon} />
  </div>
);

export default InputCheckbox;
