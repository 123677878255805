import clsx from 'clsx';
import _ from 'lodash';
import { useState } from 'react';
import { TextField } from '../input_text/input_field';
import styles from './input_tax.module.scss';

// Regex for Dutch tax numbers (aka BTW numbers)
const RE_TAX_NL = /^(?:NL)?[0-9]{9}B[0-9]{2}$/;

const InputTax = ({ className, required = false, onValidate = _.noop, ...props }) => {
  const [valid, setValid] = useState(null);

  const validate = (value) => {
    // The value must either be a valid tax number, or (only if not required) an empty string
    const isValid = (!required && value === '') || RE_TAX_NL.test(value);

    setValid(isValid);
    onValidate(isValid);
  };

  return (
    <div className={clsx(styles.container, className)}>
      <TextField pattern={RE_TAX_NL} valid={valid} onValidate={validate} {...props} />
    </div>
  );
};

export default InputTax;
