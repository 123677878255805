import { IndividualCareRequestStatus } from '@tallkingconnect/gateway';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/button/button';
import Error from 'src/components/error/error';
import { Form, FormActions, FormRow, FormSpacer } from 'src/components/form/form';
import { LabeledFormInputView } from 'src/components/form/labeled_form_input';
import LoadingOverlay from 'src/components/loader/loader';
import { useOnboarding, useUserInformation } from 'src/hooks/users';
import { formatDateTime } from 'src/utils';
import ActionsMenu from '../actionsMenu';
import styles from '../individualCare.module.scss';
import ReactionForm from '../reactions/form';

const IndicationRequestView = ({ indicationRequest = {}, onClose }) => {
  const { t } = useTranslation(['page_individualCare', 'common']);
  const navigate = useNavigate();
  const { hasRole, data: user } = useUserInformation();
  const [showReactionForm, setShowReactionForm] = useState(false);

  const reactionFormRef = useRef(null);
  const showMaximumRate = hasRole('Admin') || hasRole('CareConsultant');

  const isCaregiver = hasRole('Caregiver');
  const onboarding = useOnboarding({ enabled: isCaregiver });

  const reaction = _.find(indicationRequest.reactions, { caregiver: { id: user.id } });

  // User can react if they are caregiver and haven't reacted already
  const canReact = isCaregiver && !reaction;

  // hourlyRate will not be visible for the CareConsultant or admin without a value.
  const showHourlyRate = isCaregiver || indicationRequest.agreedRate > 0;

  // If Caregiver reacted show rate of reaction
  // else show minimum of Caregivers own rate and maximum rate.
  // Show agreed rate when careConsultant or admin
  const hourlyRate = isCaregiver
    ? reaction
      ? reaction.suggestedRate
      : onboarding.isSuccess
      ? Math.min(indicationRequest.maximumRate, onboarding.data.personalia?.hourlyRate)
      : null
    : indicationRequest.agreedRate;

  const closeReactionForm = (isSaved) => {
    setShowReactionForm(false);
    if (isSaved) {
      // refresh page
      navigate(0);
    }
  };

  useEffect(() => {
    if (showReactionForm) {
      reactionFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showReactionForm]);

  const bindModel = (label, key, props = {}) => (
    <LabeledFormInputView label={label} value={_.defaultTo(_.get(indicationRequest, key), '-')} {...props} />
  );

  return (
    <Form>
      <FormRow>
        {bindModel(t('view.incidentIdentifier', 'Referentienummer'), 'incidentIdentifier')}
        {bindModel(t('view.teamName', 'Team'), 'teamName')}
        <LabeledFormInputView
          label={t('view.status', 'Status')}
          value={t(`constants.individualCareRequestStatusLabels.${indicationRequest.status}`)}
        />
      </FormRow>
      <FormRow>
        {bindModel(t('view.postcode', 'Postcode'), 'postcode')}
        <LabeledFormInputView
          label={t('view.deadline', 'Deadline')}
          value={formatDateTime(indicationRequest.targetDate, { format: t('common:dateFormat') })}
        />
        {showHourlyRate ? (
          <LabeledFormInputView label={t('view.hourlyRate', 'Uurtarief')} value={hourlyRate} isCurrency={true} />
        ) : (
          <FormSpacer />
        )}
      </FormRow>
      <FormRow>
        {bindModel(t('view.description', 'Opmerking'), 'careRequestInfo')}
        <FormSpacer />
        {showMaximumRate ? (
          bindModel(t('view.maximumRate', 'Maximum uurtarief'), 'maximumRate', { isCurrency: true })
        ) : (
          <FormSpacer />
        )}
      </FormRow>
      <FormRow>
        <LabeledFormInputView
          label={t('view.createdAt', 'Aangemaakt op')}
          value={formatDateTime(indicationRequest.createdAt, { format: t('common:dateTimeFormat') })}
        />
        <LabeledFormInputView label={t('view.createdBy', 'Aangemaakt door')} value={indicationRequest.createdBy} />
        <LabeledFormInputView
          label={t('view.updatedOn', 'Laatst aangepast op')}
          value={formatDateTime(indicationRequest.updatedAt, { format: t('common:dateTimeFormat') })}
        />
      </FormRow>
      {showReactionForm && (
        <FormRow>
          <div ref={reactionFormRef}>
            <ReactionForm
              onClose={closeReactionForm}
              reaction={{
                individualCareRequestId: indicationRequest.id,
              }}
              hourlyRate={hourlyRate}
            />
          </div>
        </FormRow>
      )}
      <FormActions>
        <Button secondary onClick={onClose}>
          {t('view.back', 'Terug')}
        </Button>
        {canReact && !showReactionForm && (
          <Button onClick={() => setShowReactionForm(true)}>{t('view.reply', 'Reageer')}</Button>
        )}
      </FormActions>
    </Form>
  );
};

const Page = ({ indicationRequest: initialIndicationRequest, error }) => {
  const { t } = useTranslation('page_individualCare');
  const navigate = useNavigate();
  const { hasRole } = useUserInformation();

  const [indicationRequest, setIndicationRequest] = useState(initialIndicationRequest);

  const canEdit = hasRole('CareConsultant') || hasRole('Admin');

  useEffect(() => {
    if (initialIndicationRequest) {
      setIndicationRequest(initialIndicationRequest);
    }
  }, [initialIndicationRequest]);

  const closeForm = () => navigate('/individualcare/indicationrequest');

  const updateModel = (nextModel) => {
    if (nextModel.status === IndividualCareRequestStatus.CancelledByCareConsultant) {
      closeForm();
    } else {
      setIndicationRequest(nextModel);
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.toolbar}>
          <h1>{t('view.applicationForIndicationRequest', 'Aanvraag voor indicatiestelling')}</h1>
          {indicationRequest && canEdit && <ActionsMenu careRequest={indicationRequest} onChangeModel={updateModel} />}
        </div>
        {error ? (
          <Error>{t('common:error.loadError')}</Error>
        ) : indicationRequest ? (
          <IndicationRequestView indicationRequest={indicationRequest} onClose={closeForm} />
        ) : (
          <LoadingOverlay />
        )}
      </div>
    </div>
  );
};

export default Page;
