import moment from 'moment';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGateway } from 'src/providers/gateway';
import { formatDateHtml, minDateAsDate } from 'src/utils';
import DatePicker from '../datepicker/datepicker';
import Dialog from '../dialog/dialog';
import InputFile from '../input_file/input_file';

const ReplaceDocumentDialog = ({ screeningId, documentType, onConfirm, onCancel }) => {
  const { t } = useTranslation('components');
  const { api } = useGateway();

  const [doc, setDoc] = useState();
  const [expirationDate, setExpirationDate] = useState('');

  const needsExpirationDate = documentType !== 'Diploma';

  // Only allow approval of a doc when the expiration date is valid (in the future)
  const expirationDateIsValid = !needsExpirationDate || moment(expirationDate).isAfter(moment());

  const valid = Boolean(doc) && expirationDateIsValid;

  const confirm = async () => {
    try {
      const data = await api.onboardingScreenings.uploadScreeningDocumentAsAdmin({
        onboardingScreeningId: screeningId,
        screeningDocType: documentType,
        expirationDate: needsExpirationDate ? formatDateHtml(expirationDate) : minDateAsDate,
        documentBase64String: doc.documentBase64String,
        contentType: doc.contentType,
        title: doc.title,
      });
      onConfirm(data);
    } catch (err) {
      console.error('Error uploading document:', err);
    }
  };

  const cancel = () => {
    onCancel();
  };

  return (
    <Dialog
      disabledConfirm={!valid}
      onConfirm={confirm}
      onCancel={cancel}
      invertAction
      labelQuestion={
        <Trans t={t} i18nKey="aboutToReplaceDocument">
          Je staat op het punt om het document <b>{{ documentType }}</b> te vervangen.
          <br />
          <br />
          Geef het nieuwe document en een nieuwe vervaldatum op.
        </Trans>
      }
      labelCancel={t('cancel', 'Annuleren')}
      labelConfirm={t('replace', 'Vervangen')}
    >
      <InputFile maxSize={10000000} onChange={setDoc} />
      {needsExpirationDate && (
        <div>
          <DatePicker minDate={moment().startOf('day')} value={expirationDate} onChange={setExpirationDate} />
        </div>
      )}
    </Dialog>
  );
};

export default ReplaceDocumentDialog;
