import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LoadingOverlay from 'src/components/loader/loader';
import { useIndividualCareRequest } from 'src/hooks/individualCare';
import styles from '../individualCare.module.scss';
import IndicationRequestForm from './form';

const EditIndicationRequest = () => {
  const { t } = useTranslation('page_individualCare');
  const { indicationRequestId } = useParams();
  const indicationRequest = useIndividualCareRequest(indicationRequestId);

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <h1>{t('titles.editIndicationRequest', 'Indicatie aanvraag aanpassen')}</h1>
        {indicationRequest.isFetched ? (
          <IndicationRequestForm edit indicationRequest={indicationRequest.data} />
        ) : (
          <LoadingOverlay />
        )}
      </div>
    </div>
  );
};

export default EditIndicationRequest;
