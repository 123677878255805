import clsx from 'clsx';
import { Check, Plus, Search, X } from 'react-feather';
import styles from './button.module.scss';
import { ButtonHTMLAttributes } from 'react';

interface ButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className'> {
  small?: boolean;
  extraSmall?: boolean;
  round?: boolean;
  invert?: boolean;
  secondary?: boolean;
  accept?: boolean;
  reject?: boolean;
  remove?: boolean;
  hasIcon?: boolean;
  className?: string | string[];
}

interface IconButtonProps extends ButtonProps {
  icon: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = ({
  type = 'button',
  small,
  extraSmall,
  round,
  invert,
  secondary,
  accept,
  reject,
  remove,
  className,
  hasIcon,
  children,
  ...props
}) => (
  <button
    type={type}
    className={clsx(
      styles.button,
      small && styles.small,
      extraSmall && styles.extraSmall,
      round && styles.round,
      invert && styles.inverted,
      secondary && styles.secondary,
      accept && styles.accept,
      reject && styles.reject,
      remove && styles.remove,
      className
    )}
    data-has-icon={hasIcon}
    {...props}
  >
    {children}
  </button>
);

export default Button;

export const IconButton: React.FC<IconButtonProps> = ({ icon, round = true, className, ...props }) => (
  <Button hasIcon={false} className={clsx(styles.iconButton, className)} round={round} {...props}>
    {icon}
  </Button>
);

export const CheckButton: React.FC<ButtonProps> = (props) => IconButton({ ...props, icon: <Check /> });
export const CrossButton: React.FC<ButtonProps> = (props) => IconButton({ ...props, icon: <X /> });
export const PlusButton: React.FC<ButtonProps> = (props) => IconButton({ ...props, icon: <Plus /> });
export const SearchButton: React.FC<ButtonProps> = (props) => IconButton({ ...props, icon: <Search /> });
