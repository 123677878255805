import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { emptyObject } from 'src/constants';
import { formatDateHtml, formatTime, formatTimeHtml, guid } from 'src/utils';
import { BookingDraftStatus, BookingStatus, BookingType } from './constants';
import styles from './index.module.scss';

const BookingTypeStyles = {
  [BookingType.Regular]: null, //styles.regular,
  [BookingType.Direct]: styles.direct,
  [BookingType.Posting]: null, //styles.posting,
  [BookingType.Correction]: null, //styles.correction,
};

const BookingStatusStyles = {
  [BookingStatus.Created]: styles.created,
  [BookingStatus.Accepted]: styles.accepted,
  [BookingStatus.Rejected]: styles.rejected,
  [BookingStatus.CancelledByCaregiver]: styles.cancelledByCaregiver,
  [BookingStatus.CancelledByPlanner]: styles.cancelledByPlanner,
};

const BookingDraftStatusStyles = {
  [BookingDraftStatus.New]: styles.newDraft,
  [BookingDraftStatus.Added]: styles.addedDraft,
};

export const BookingEventTitle = ({
  event: {
    start,
    end,
    booking: { type, caregiver },
    meta: { isDraft = false } = emptyObject,
    compact,
    className,
  },
}) => {
  const { t } = useTranslation('page_planning');
  return (
    <div className={clsx(styles.booking, className)}>
      <div className={styles.orb} />
      <div className={clsx(styles.info, compact && styles.compact)}>
        <span className={styles.bookingTimes}>
          {formatTime(start)} - {formatTime(end)}
        </span>{' '}
        {caregiver ? (
          <span>
            {caregiver.firstName} {caregiver.lastName}
          </span>
        ) : type === BookingType.Direct ? (
          <span className={styles.directOpen}>{t('booking.directRequest', 'Direct-verzoek')}</span>
        ) : isDraft ? (
          <span>{t('booking.newBooking', 'Nieuwe boeking')}</span>
        ) : null}
      </div>
    </div>
  );
};

export const NewDraftBookingEventTitle = () => {
  const { t } = useTranslation('page_planning');
  return (
    <div className={clsx(styles.booking, styles.newDraft)}>
      <span>{t('booking.completeBooking', 'Vul de boeking aan')}</span>
    </div>
  );
};

// We could also provide our own custom Event component to BigCalendar. This will then be used for *all* events.
// export const BookingEvent = ({
//   event,
//   continuesPrior,
//   continuesAfter,
//   title,
//   allDay,
//   localizer,
//   slotStart,
//   slotEnd,
// }) => <div className={clsx(styles.booking, event.className)}>...</div>;

const getBookingEventClassName = (booking, meta) =>
  clsx(
    _.get(BookingTypeStyles, booking.type),
    _.get(BookingStatusStyles, booking.status),
    meta.isDraft && _.get(BookingDraftStatusStyles, meta.draftStatus),
    meta.isHistory && styles.isHistory,
    meta.isNewAlert && styles.newAlert
  );

export const createBookingEvent = (booking) => {
  let start;
  let end;

  // For posting planning, bookings have a different format than for regular planning :S
  if (booking.bookingStart || booking.bookingEnd) {
    start = moment(booking.bookingStart);
    end = moment(booking.bookingEnd);
  } else {
    start = moment(booking.bookingDateTime);
    end = start.clone().add(booking.hours, 'hours');
  }

  const meta = {
    isNewAlert: booking.newAlertStatus,
    isHistory: end.isBefore(new Date()),
  };

  const className = getBookingEventClassName(booking, meta);

  const event = {
    allDay: false,
    start,
    end,
    // tooltip: 'Booking',
    resource: null,

    booking,
    meta,

    className,
  };
  event.title = <BookingEventTitle event={event} />;

  return event;
};

export const createDraftBookingEvent = ({ start, end, useTime = false, type = BookingType.Regular }) => {
  const booking = {
    type,
    startDate: formatDateHtml(start),
    duration: 0,
    unbillableHours: 0,
  };

  if (useTime) {
    booking.startTime = formatTimeHtml(start);
    booking.endTime = formatTimeHtml(end);
  }

  const meta = {
    isDraft: true,
    draftStatus: BookingDraftStatus.New,
    draftId: guid(),
    isNewAlert: false,
    isHistory: moment(end).isBefore(new Date()),
  };

  const className = getBookingEventClassName(booking, meta);

  const event = {
    allDay: false,
    start,
    end,
    title: <NewDraftBookingEventTitle />,
    resource: null,
    booking,
    meta,
    className,
  };

  return event;
};

export const createDraftPostingBookingEvent = ({ start, end, useTime = false, posting, caregiver, team }) => {
  const event = createDraftBookingEvent({ start, end, useTime, type: BookingType.Posting });

  event.booking = {
    ...event.booking,

    posting,
    postingId: posting.id,

    team,
    teamId: team.id,

    degree: posting.requiredDegree,
    careSectorId: posting.careSectorId,

    caregiver,
    caregiverId: caregiver.id,
  };

  return event;
};

export const draftBookingEventToNewBooking = (event) => {
  const { booking } = event;

  return {
    id: null, // new -> null,
    bookingDate: formatDateHtml(event.start),
    beginTime: formatTimeHtml(event.start),
    endTime: formatTimeHtml(event.end),
    careSectorId: booking.careSectorId,
    districtTeamId: booking.teamId,
    degree: booking.degree,
    hasFixedPrice: false,
    // fixedPrice: null, // TODO: backend cannot handle null for this value
    unbillableHours: booking.unbillableHours || 0,
    caregiverId: booking.caregiverId,
    documentIds: booking.documentIds,
  };
};

// Normalize booking properties.
// Note that this is only required for properties that aren't already normalized upon loading, i.e. in our custom hooks
export const normalizeBookingProperties = (booking) => {
  // Can't normalize nothing
  if (!booking) {
    return null;
  }

  // Start with common properties
  // Note that not all of these properties are always present, it depends on how they were loaded
  // TODO: needs refactoring in the backend so the booking properties are more consistent.
  const normalized = _.pick(booking, [
    'id',
    'type',
    'status',
    'billingStatus',
    'hours',
    'unbillableHours',
    'hourlyRate',
    'totalPrice',
    'information',
    'documents',
    'teamId',
    'team',
    'careSectorId',
    'careSector',
    'careSectorName',
    'caregiverId',
    'caregiver',
    'degree',

    // Posting bookings only
    'postingId',
    'posting',
  ]);

  // TODO: both booking.id and booking.bookingId are being used, we need to pick one at some point
  normalized.id = normalized.bookingId = booking.id || booking.bookingId || null;

  if (!normalized.team && booking.districtTeam) {
    normalized.team = booking.districtTeam;
  }

  if (!normalized.teamId) {
    normalized.teamId = normalized.team?.id || booking.districtTeamId || null;
  }

  if (!normalized.careSectorId && normalized.careSector) {
    normalized.careSectorId = normalized.careSector.id;
  }

  normalized.startDate = booking.startDate || booking.start || booking.bookingStart || booking.bookingDateTime;
  normalized.startTime = booking.startTime || booking.start || booking.bookingStart || booking.bookingDateTime;
  normalized.endTime =
    booking.endTime ||
    booking.end ||
    booking.bookingEnd ||
    (booking.hours !== 0 ? moment(booking.bookingDateTime).add(booking.hours, 'hours').toDate() : null);

  return normalized;
};

export const duplicateBooking = (booking, { keepCaregiver = false, ...bookingProps } = {}) => {
  const fieldsToOmit = ['id', 'status', 'billingStatus'];

  if (!keepCaregiver) {
    fieldsToOmit.push('caregiverId', 'caregiver', 'totalPrice');
  }

  return _.merge(_.omit(booking, fieldsToOmit), bookingProps);
};
