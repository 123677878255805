import { Route, Routes } from 'react-router';

// import AddTeam from './add';
// import EditTeam from './edit';
import ListTeams from './list';

const Teams = () => {
  return (
    <Routes>
      <Route index element={<ListTeams />} />
      <Route path="add" element={<ListTeams /> /* <AddTeam /> */} />
      <Route path=":teamId/edit" element={<ListTeams /> /* <EditTeam /> */} />
    </Routes>
  );
};

export default Teams;
