import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { Eye, Plus, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import A from 'src/components/a';
import { IconButton } from 'src/components/button/button';
import { YesNoDialog } from 'src/components/dialog/dialog';
import { Form, FormRow, FormSpacer } from 'src/components/form/form';
import LabeledFormInput from 'src/components/form/labeled_form_input';
import Information from 'src/components/information';
import InputFile from 'src/components/input_file/input_file';
import Select from 'src/components/select/select';
import { formatDate, guid } from 'src/utils';
import InputCheckbox from '../input_checkbox';
import { InputText } from '../input_text/input_text';
import styles from './index.module.scss';

const DocumentsForm = ({ label, documents, onChange, typeOptions = null, showActiveToggle = false }) => {
  const { t } = useTranslation(['components', 'common']);
  const [dialogData, setDialogData] = useState(null);

  const showDialog = (labelQuestion, onConfirm) =>
    setDialogData({
      labelQuestion,
      onConfirm,
      onCancel: () => setDialogData(),
    });

  const showDeleteDocumentDialog = (id) => {
    return showDialog(
      t('areYouSureToDeleteThisDocumentDialogQuestion', 'Weet je zeker dat je dit document wilt verwijderen?'),
      () => deleteDocument(id)
    );
  };

  const addDocument = () => {
    onChange(documents.concat([{ id: guid(), type: '', data: null, isActive: true }]));
  };

  const updateDocument = (next) => {
    onChange(documents.map((doc) => (doc.id === next.id ? { ...doc, ...next } : doc)));
  };

  const deleteDocument = (id) => {
    onChange(documents.filter((doc) => doc.id !== id));
  };

  const setActive = (docId, toggled) => {
    onChange(documents.map((doc) => (doc.id === docId ? { ...doc, isActive: toggled } : doc)));
  };

  return (
    <>
      {dialogData && <YesNoDialog {...dialogData} />}
      <Form className={styles.documentsForm}>
        <FormRow className={styles.toolbar}>
          <h3>{label}</h3>
          <IconButton
            small
            icon={<Plus />}
            title={t('addDocument', 'Document toevoegen')}
            onClick={() => addDocument()}
          />
        </FormRow>
        {documents.length === 0 ? (
          <FormRow>
            <div className={styles.empty}>-</div>
          </FormRow>
        ) : (
          documents.map((doc) =>
            doc.url ? (
              <div key={doc.id} className={styles.docList}>
                <FormRow key={doc.id} className={styles.docList}>
                  <div className={styles.iconLink}>
                    <div className={styles.documentAction}>
                      <InputCheckbox onChange={() => setActive(doc.id, !doc.isActive)} value={doc.isActive} />
                    </div>
                    <Eye />
                    <div className={styles.text}>
                      <A href={doc.url}>{doc.type}</A>
                      <div>{formatDate(moment(doc.createdAt), { format: t('common:dateFormat') })}</div>
                    </div>
                  </div>
                  <FormSpacer />
                  <DeleteButton onClick={() => showDeleteDocumentDialog(doc.id)} />
                </FormRow>
              </div>
            ) : (
              <DocumentMiniForm
                key={doc.id}
                data={doc}
                onChange={updateDocument}
                onDelete={() => (doc.blobData || doc.type ? showDeleteDocumentDialog(doc.id) : deleteDocument(doc.id))}
                typeOptions={typeOptions}
                showActiveToggle={showActiveToggle}
              />
            )
          )
        )}
      </Form>
    </>
  );
};

const DocumentMiniForm = ({ data, onChange, onDelete, typeOptions = null, showActiveToggle = false }) => {
  const { t } = useTranslation('components');
  const updateModel = (key) => (value) => {
    const next = { ...data, [key]: value };
    onChange(next);
  };

  const bindModel = (label, key, defaultValue, component, { information, ...props } = {}) => (
    <LabeledFormInput
      component={component}
      label={
        <>
          {label}
          {information && <Information>{information}</Information>}
        </>
      }
      value={_.defaultTo(_.get(data, key), defaultValue)}
      onChange={updateModel(key)}
      {...props}
    />
  );
  return (
    <FormRow className={styles.documentMiniForm}>
      {bindModel(t('file', 'Bestand'), 'blobData', '', InputFile, { maxSize: 10000000 })}
      {typeOptions
        ? bindModel(t('description', 'Omschrijving'), 'type', '', Select, {
            options: typeOptions,
            className: styles.selectWithTypeOptions,
          })
        : bindModel(t('description', 'Omschrijving'), 'type', '', InputText, {
            className: styles.selectWithTypeOptions,
          })}

      <div className={styles.documentAction}>
        {showActiveToggle ? (
          bindModel(t('active', 'Actief'), 'isActive', true, InputCheckbox, {
            className: styles.checkbox,
          })
        ) : (
          <FormSpacer />
        )}

        {onDelete ? (
          <div className={styles.documentAction}>
            <DeleteButton onClick={onDelete} />
          </div>
        ) : (
          <FormSpacer />
        )}
      </div>
    </FormRow>
  );
};

const DeleteButton = ({ onClick }) => {
  const { t } = useTranslation('components');
  return (
    <IconButton
      small
      secondary
      remove
      icon={<Trash2 />}
      title={t('deleteDocument', 'Document verwijderen')}
      onClick={onClick}
    />
  );
};

export default DocumentsForm;
