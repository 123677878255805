import { useState } from 'react';
import { Edit, Settings, ToggleLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { YesNoDialog } from 'src/components/dialog/dialog';
import DropDown from 'src/components/dropdown/dropdown';
import { useUserInformation } from 'src/hooks/users';
import { useGateway } from 'src/providers/gateway';

const ActionsMenu = ({ team, className }) => {
  const { t } = useTranslation('page_teams');
  const navigate = useNavigate();
  const location = useLocation();

  const { hasRole } = useUserInformation();
  const { api } = useGateway();

  const [dialogData, setDialogData] = useState(null);

  const canDeactivateTeams = hasRole('Admin') || hasRole('CompanyAdmin');

  const deactivateTeam = async () => {
    try {
      await api.districtTeams.setDistrictTeamInactive({ id: team.id });

      // refresh
      navigate(0);
    } catch (err) {
      console.error('Error deactivating team:', err);
    }
  };

  const showDialog = (labelQuestion, onConfirm) =>
    setDialogData({
      labelQuestion,
      onConfirm,
      onCancel: () => setDialogData(),
    });

  const deactivate = () =>
    showDialog(
      t('actionsMenu.areYouSureToDeactivateThisTeamDialogQuestion', 'Weet je zeker dat je dit team wilt deactiveren?'),
      () => deactivateTeam()
    );

  return (
    <>
      {dialogData && <YesNoDialog {...dialogData} />}
      <DropDown className={className}>
        <Settings />
        <ul>
          <li data-has-icon>
            <Edit />
            <Link to={`${location.pathname}/${team.id}/edit`}>{t('actionsMenu.edit', 'Bewerken')}</Link>
          </li>
          {team.isActive && canDeactivateTeams && (
            <li data-has-icon>
              <ToggleLeft />
              <span data-clickable onClick={deactivate}>
                {t('actionsMenu.deactivate', 'Deactiveren')}
              </span>
            </li>
          )}
        </ul>
      </DropDown>
    </>
  );
};

export default ActionsMenu;
