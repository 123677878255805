import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputCheckbox from 'src/components/input_checkbox';
import LoadingOverlay from 'src/components/loader/loader';
import Avatar from 'src/components/user/avatar';
import { formatDate } from 'src/utils';
import { PostingReactionStatus } from './constants';
import styles from './reactions.module.scss';
import ReviewForm from './reviewForm';

const PostingReactionStatusStyles = {
  [PostingReactionStatus.New]: styles.new,
  [PostingReactionStatus.Active]: styles.active,
  [PostingReactionStatus.Pending]: styles.pending,
  [PostingReactionStatus.Rejected]: styles.rejected,
  [PostingReactionStatus.CancelledByCaregiver]: styles.cancelled,
  [PostingReactionStatus.Accepted]: styles.accepted,
};

const ListHeader = () => {
  const { t } = useTranslation('page_postings');
  return (
    <li className={styles.header}>
      <div>{t('reactions.list.caregiver', 'Zorgverlener')}</div>
      <div>{t('reactions.list.education', 'Opleiding')}</div>
      <div>{t('reactions.list.sinceStatus', 'Sinds / Status')}</div>
      <div>{t('reactions.list.interesting', 'Interessant')}</div>
    </li>
  );
};

const ListItem = ({ reaction: initialReaction = {}, toggleExpanded, expanded = false }) => {
  const { t } = useTranslation(['page_postings', 'common']);
  const [reaction, setReaction] = useState(initialReaction);

  const updateModel = (nextModel) => {
    setReaction(nextModel);
  };

  return (
    <>
      <li className={clsx(styles.row, PostingReactionStatusStyles[reaction.status])} onClick={toggleExpanded}>
        <div className={styles.flexRow}>
          <Avatar src={reaction.caregiver.profilePictureUrl} />
          <div>
            {reaction.caregiver.firstName} {reaction.caregiver.lastName}
          </div>
        </div>
        <div>{reaction.caregiver.highestDegreeOfEducation}</div>
        <div className={styles.stack}>
          <div>{formatDate(reaction.createdAt, { format: t('common:dateFormat') })}</div>
          <div title={t(`constants.postingReactionStatusLabels.${reaction.status}`)}>
            {t(`constants.postingReactionStatusShortLabels.${reaction.status}`)}
          </div>
        </div>
        <div>
          <InputCheckbox value={reaction.interesting} readOnly />
        </div>
      </li>
      <li className={clsx(styles.details, expanded && styles.expanded)}>
        <ReviewForm reaction={reaction} onChange={updateModel} />
      </li>
    </>
  );
};

const List = ({ reactions = [] }) => {
  const { t } = useTranslation('page_postings');
  const [expandedId, setExpandedId] = useState(null);

  const toggleExpandedId = (id) => setExpandedId(expandedId === id ? null : id);

  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <h1>{t('reactions.list.title', 'Reacties op aanvraag langdurige inzet')}</h1>
      </div>
      <ul className={styles.list}>
        <ListHeader />
        {reactions && reactions.length ? (
          reactions.map((reaction) => (
            <ListItem
              key={reaction.id}
              reaction={reaction}
              toggleExpanded={() => toggleExpandedId(reaction.id)}
              expanded={reaction.id === expandedId}
            />
          ))
        ) : (
          <div className={styles.empty}>{t('reactions.list.empty', 'Geen reacties om weer te geven')}</div>
        )}
      </ul>
    </div>
  );
};

const Page = ({ posting }) => (
  <div className={styles.page}>
    {posting.isLoading ? <LoadingOverlay /> : <List reactions={posting.data.reactions} />}
  </div>
);

export default Page;
