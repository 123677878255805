import { IndividualCareRequestStatus } from '@tallkingconnect/gateway';
import { buildOptions } from 'src/utils';

export const IndividualCareRoutes = {
  indication: '/individualcare/indicationrequest',
  care: '/individualcare/carerequest',
};

// Ordered IndividualCareRequestStatus enum values
const IndividualCareRequestStatusValues = [
  IndividualCareRequestStatus.New,
  IndividualCareRequestStatus.Active,
  IndividualCareRequestStatus.Pending,
  IndividualCareRequestStatus.Inactive,
  IndividualCareRequestStatus.CancelledByCareConsultant,
  IndividualCareRequestStatus.Accepted,
  IndividualCareRequestStatus.CancelledByCaregiver,
];

/**
 * IndividualCareRequestStatus labels
 *
 * @type {Object.<string, string>}
 */
// TODO Remove? Replaced with i18n t-function
export const IndividualCareRequestStatusLabels = {
  [IndividualCareRequestStatus.New]: 'Concept',
  [IndividualCareRequestStatus.Active]: 'Actief',
  [IndividualCareRequestStatus.Pending]: 'Wachten op reactie zorgverlener',
  [IndividualCareRequestStatus.Inactive]: 'Inactief',
  [IndividualCareRequestStatus.CancelledByCareConsultant]: 'Ingetrokken',
  [IndividualCareRequestStatus.Accepted]: 'Geaccepteerd door zorgverlener',
  [IndividualCareRequestStatus.CancelledByCaregiver]: 'Geannuleerd door zorgverlener',
};

/**
 * IndividualCareRequestStatus short labels
 *
 * @type {Object.<string, string>}
 */
export const IndividualCareRequestStatusShortLabels = {
  [IndividualCareRequestStatus.New]: 'Concept',
  [IndividualCareRequestStatus.Active]: 'Actief',
  [IndividualCareRequestStatus.Pending]: 'Wachten...',
  [IndividualCareRequestStatus.Inactive]: 'Inactief',
  [IndividualCareRequestStatus.CancelledByCareConsultant]: 'Ingetrokken',
  [IndividualCareRequestStatus.Accepted]: 'Geaccepteerd',
  [IndividualCareRequestStatus.CancelledByCaregiver]: 'Geannuleerd',
};

/**
 * IndividualCareRequestStatus select options
 *
 * @type {Array.<Object>}
 * @property New
 * @property Active
 * @property Pending
 * @property Inactive
 * @property CancelledByCareConsultant
 * @property Accepted
 * @property CancelledByCaregiver
 */
export const IndividualCareRequestStatusOptions = buildOptions(
  IndividualCareRequestStatusValues,
  IndividualCareRequestStatusLabels
);

export const DefaultIndividualCareRequestStatusVisibility = {
  careConsultant: [
    IndividualCareRequestStatus.New,
    IndividualCareRequestStatus.Active,
    IndividualCareRequestStatus.CancelledByCareConsultant,
    IndividualCareRequestStatus.Pending,
    IndividualCareRequestStatus.Accepted,
    IndividualCareRequestStatus.CancelledByCaregiver,
  ],
  caregiver: {
    all: [IndividualCareRequestStatus.Active],
    mine: [
      IndividualCareRequestStatus.Active,
      IndividualCareRequestStatus.CancelledByCareConsultant,
      IndividualCareRequestStatus.Pending,
      IndividualCareRequestStatus.Accepted,
      IndividualCareRequestStatus.CancelledByCaregiver,
    ],
  },
  admin: [
    IndividualCareRequestStatus.New,
    IndividualCareRequestStatus.Active,
    IndividualCareRequestStatus.Pending,
    IndividualCareRequestStatus.Inactive,
    IndividualCareRequestStatus.CancelledByCareConsultant,
    IndividualCareRequestStatus.Accepted,
    IndividualCareRequestStatus.CancelledByCaregiver,
  ],
};

/**
 * Statuses that allow reacting to a posting, or modifying the reaction.
 */
export const IndividualCareRequestStatusReactable = [
  IndividualCareRequestStatus.Active,
  IndividualCareRequestStatus.Pending,
];
