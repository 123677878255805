import { useTranslation } from 'react-i18next';
import styles from '../individualCare.module.scss';
import IndicationRequestForm from './form';

const AddIndicationRequest = () => {
  const { t } = useTranslation('page_IndividualCare');
  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <h1>{t('titles.addIndicationRequest', 'Indicatie aanvraag toevoegen')}</h1>
        <IndicationRequestForm />
      </div>
    </div>
  );
};
export default AddIndicationRequest;
