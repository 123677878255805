import _ from 'lodash';
import { useCallback, useState } from 'react';
import { Eye, Phone } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import A from 'src/components/a';
import Button from 'src/components/button/button';
import Dialog, { IconDialog } from 'src/components/dialog/dialog';
import Error from 'src/components/error/error';
import { Form, FormActions, FormRow } from 'src/components/form/form';
import LabeledFormInput, { LabeledFormInputView } from 'src/components/form/labeled_form_input';
import Information from 'src/components/information';
import InputCheckbox from 'src/components/input_checkbox';
import InputCurrency from 'src/components/input_number/input_currency';
import InputTextArea from 'src/components/input_textarea';
import LoadingOverlay from 'src/components/loader/loader';
import {
  usePostingReactionAgreementPreview,
  usePostingReactionIsInterestingMutation,
  usePostingReactionNotesMutation,
  usePostingReactionResponseMutation,
} from 'src/hooks/postings';
import { useUserInformation } from 'src/hooks/users';
import CareAgreementDocument from 'src/pages/documents/templates/nl/careAgreement';
import { formatDateTime } from 'src/utils';
import { PostingReactionStatus } from './constants';
import styles from './reactions.module.scss';

const AgreementPreview = ({ reactionId, agreedRate, onClose }) => {
  const { t } = useTranslation(['page_postings', 'common']);
  const { data: model, isLoading, error } = usePostingReactionAgreementPreview(reactionId);

  return (
    <Dialog
      wide
      onCancel={onClose}
      labelQuestion={t('reactions.reviewForm.exampleAgreement')}
      labelCancel={t('common:button.close')}
      labelConfirm={false}
    >
      {isLoading ? (
        <LoadingOverlay />
      ) : error ? (
        <Error>{error.message}</Error>
      ) : (
        <div style={{ maxHeight: '50vh', overflow: 'auto' }}>
          <CareAgreementDocument model={{ ...model, caregiverHourlyRate: agreedRate }} />
        </div>
      )}
    </Dialog>
  );
};

const AcceptDialog = ({ reaction, onConfirm, onCancel }) => {
  const { t } = useTranslation('page_postings');
  const [agreedRate, setAgreedRate] = useState('');
  const [note, setNote] = useState('');

  const [showPreview, setShowPreview] = useState(false);

  return (
    <>
      <IconDialog
        className={styles.acceptDialog}
        labelQuestion={t(
          'reactions.reviewForm.areYouSureToSelectThisHealthCareProvider',
          'Weet je zeker dat je deze zorgverlener wilt selecteren?'
        )}
        onConfirm={() => onConfirm({ agreedRate, note })}
        onCancel={onCancel}
        disabledConfirm={!agreedRate}
        invertAction
      >
        <div className={styles.stretch}>
          <Form stretch>
            <FormRow>
              <LabeledFormInput
                className={styles.fullWidth}
                label={t('reactions.reviewForm.agreedHourlyRate', 'Afgesproken uurtarief')}
                component={InputCurrency}
                value={agreedRate}
                onChange={setAgreedRate}
              />
            </FormRow>

            <FormRow>
              <LabeledFormInput
                className={styles.fullWidth}
                label={t('reactions.reviewForm.explanationTowardsCaregiver', 'Toelichting richting zorgverlener')}
                component={InputTextArea}
                value={note}
                onChange={setNote}
              />
            </FormRow>

            <Button secondary disabled={!agreedRate} onClick={() => setShowPreview(true)} style={{ margin: 'auto' }}>
              {t('reactions.reviewForm.showExampleAgreement')}
            </Button>
          </Form>
        </div>
      </IconDialog>

      {showPreview && (
        <AgreementPreview reactionId={reaction.id} rate={agreedRate} onClose={() => setShowPreview(false)} />
      )}
    </>
  );
};

const RejectDialog = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation('page_postings');
  const [note, setNote] = useState('');

  return (
    <IconDialog
      className={styles.acceptDialog}
      labelQuestion={t(
        'reactions.reviewForm.areYouSureToRejectThisHealthCareProvider',
        'Weet je zeker dat je deze zorgverlener wilt afwijzen?'
      )}
      onConfirm={() => onConfirm({ note })}
      onCancel={onCancel}
      invertAction
    >
      <div className={styles.stretch}>
        <Form stretch>
          <FormRow>
            <LabeledFormInput
              className={styles.fullWidth}
              label={t('reactions.reviewForm.explanationTowardsCaregiver', 'Toelichting richting zorgverlener')}
              component={InputTextArea}
              value={note}
              onChange={setNote}
            />
          </FormRow>
        </Form>
      </div>
    </IconDialog>
  );
};

const ReviewForm = ({ reaction, onChange }) => {
  const { t } = useTranslation(['page_postings', 'common']);
  const { hasRole } = useUserInformation();

  const [dialogData, setDialogData] = useState(null);

  const noteMutation = usePostingReactionNotesMutation({
    onSuccess: () => {
      console.info('Updated successfully');
    },
    onError: (err) => {
      console.error('Error saving note:', err);
    },
  });

  const interestingMutation = usePostingReactionIsInterestingMutation({
    onSuccess: () => {
      console.info('Updated successfully');
    },
    onError: (err) => {
      console.error('Error marking reaction as interesting:', err);
    },
  });

  const responseMutation = usePostingReactionResponseMutation({
    onSuccess: (data) => {
      console.info('Updated successfully');
      onChange(data);
    },
    onError: (err) => {
      console.error('Error responding to reaction:', err);
    },
  });

  // Save notes using debounced function that only fires after the specified number of milliseconds of inactivity
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveNotes = useCallback(
    _.debounce((notes) => {
      noteMutation.mutate({ id: reaction.id, notes });
    }, 1000),
    [reaction.id]
  );

  const updateNotes = (nextValue) => {
    saveNotes(nextValue, reaction);
    onChange({
      ...reaction,
      privateNotes: nextValue,
    });
  };

  const toggleInteresting = () => {
    const interesting = !reaction.interesting;
    interestingMutation.mutate({ id: reaction.id, interesting });
    onChange({
      ...reaction,
      interesting,
    });
  };

  const saveReactionResponse = ({ accepted, note, agreedRate }) => {
    responseMutation.mutate({
      reactionId: reaction.id,
      accepted,
      note,
      agreedRate,
    });
  };

  const showDialog = (component, onConfirm) =>
    setDialogData({
      component,
      reaction,
      onConfirm,
      onCancel: () => setDialogData(),
    });

  const showAcceptDialog = () => {
    showDialog(AcceptDialog, ({ agreedRate, note }) => saveReactionResponse({ accepted: true, agreedRate, note }));
  };

  const showRejectDialog = () => {
    showDialog(RejectDialog, ({ note }) => saveReactionResponse({ accepted: false, agreedRate: -1, note }));
  };

  const isRecruiter = hasRole('Recruiter');
  const canAcceptOrDenyCaregiver = isRecruiter && [PostingReactionStatus.Active].includes(reaction.status);
  const processing = noteMutation.isLoading || interestingMutation.isLoading || responseMutation.isLoading;

  return (
    <>
      {dialogData && <dialogData.component {...dialogData} />}
      <Form>
        <FormRow>
          <LabeledFormInputView
            label={t('reactions.reviewForm.cv', 'Curriculum Vitae')}
            value={
              reaction.resumeUrl ? (
                <span className={styles.iconLink}>
                  <Eye />
                  <A href={reaction.resumeUrl}>Inzien</A>
                </span>
              ) : (
                '-'
              )
            }
          />
          <LabeledFormInputView
            label={t('reactions.reviewForm.phoneNumber', 'Telefoonnummer')}
            value={
              <span className={styles.iconLink}>
                <Phone />
                <a href={`tel:${reaction.caregiver.phoneNumber}`} className="phone">
                  {reaction.caregiver.phoneNumber}
                </a>
              </span>
            }
          />
          <LabeledFormInputView
            label={t('reactions.reviewForm.status', 'Status')}
            value={t(`constants.postingReactionStatusLabels.${reaction.status}`)}
          />
        </FormRow>
        <FormRow>
          <LabeledFormInputView
            label={t('reactions.reviewForm.explanationCaregiver', 'Toelichting zorgverlener')}
            value={reaction.description}
          />
        </FormRow>
        <FormRow>
          <LabeledFormInput
            label={
              <Trans t={t} i18nKey="reactions.reviewForm.notes">
                Notities
                <Information right>Deze notities zijn privé en niet zichtbaar voor de zorgverlener</Information>
              </Trans>
            }
            component={InputTextArea}
            value={reaction.privateNotes}
            // disabled={!isRecruiter}
            onChange={updateNotes}
          />
        </FormRow>
        <FormRow>
          <LabeledFormInput
            label="Interessant"
            component={InputCheckbox}
            value={reaction.interesting}
            // disabled={!isRecruiter}
            onChange={toggleInteresting}
          />
        </FormRow>
        <FormRow>
          <LabeledFormInputView
            label={t('reactions.reviewForm.createdAt', 'Aangemaakt op')}
            value={formatDateTime(reaction.createdAt, { format: t('common:dateTimeFormat') })}
          />
          <LabeledFormInputView
            label={t('reactions.reviewForm.updatedOn', 'Laatst aangepast op')}
            value={formatDateTime(reaction.updatedAt, { format: t('common:dateTimeFormat') })}
          />
        </FormRow>

        {canAcceptOrDenyCaregiver && (
          <FormActions>
            <Button disabled={processing} invert onClick={() => showRejectDialog()}>
              {t('reactions.reviewForm.reject', 'Afwijzen')}
            </Button>
            <Button disabled={processing} onClick={() => showAcceptDialog()}>
              {t('reactions.reviewForm.select', 'Selecteren')}
            </Button>
          </FormActions>
        )}
      </Form>
    </>
  );
};

export default ReviewForm;
