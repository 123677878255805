import { useRef, useState } from 'react';
import { Edit } from 'react-feather';
import { useTranslation } from 'react-i18next';
import ReactCrop, { centerCrop, makeAspectCrop, type Crop, type PixelCrop } from 'react-image-crop';
import 'react-image-crop/src/ReactCrop.scss';
import { useDebounce } from 'react-use';
import Avatar from '../user/avatar';
import { canvasPreview } from './canvasPreview';
import styles from './input_file.module.scss';

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

interface InputFileProfilePictureProps {
  value?: string;
  defaultValue?: string;
  onChange: (dataUrl: string) => void;
}

const InputFileProfilePicture: React.FC<InputFileProfilePictureProps> = ({
  value,
  defaultValue = undefined,
  onChange,
}) => {
  const { t } = useTranslation('components');

  const [imgSrc, setImgSrc] = useState<string>();
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [srcAspect, setSrcAspect] = useState(1);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [cropImgSrc, setCropImgSrc] = useState<string>();
  const scale = 1;
  const rotate = 0;
  const aspect = 1;

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCropImgSrc(undefined);
      setCrop(undefined); // Makes crop preview update between images.

      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    setSrcAspect(width / height);

    if (aspect) {
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounce(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        // Render crop
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);

        // Extract crop
        const canvas: HTMLCanvasElement = previewCanvasRef.current;
        const dataUrl = canvas.toDataURL();

        // Raise awareness!
        setCropImgSrc(dataUrl);
        onChange(dataUrl);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  return (
    <div className={styles.inputFileFieldProfilePhoto}>
      <div className={styles.cropperDisplayAndInteraction}>
        <label>{t('currentProfilePicture', 'Huidige profielfoto')}</label>
        <div className={styles.displayProfileImage}>
          <Avatar large src={cropImgSrc || value || defaultValue} />
          <div className={styles.editIcon}>
            <Edit />
          </div>
          <input type="file" accept="image/*" className={styles.container} onChange={onSelectFile} />
        </div>

        {imgSrc ? <div>&nbsp;</div> : <p>{t('chooseProfilePicture', 'Kies je profielfoto')}</p>}
      </div>

      {imgSrc && (
        <div className={styles.cropper}>
          <ReactCrop
            circularCrop
            keepSelection
            crop={crop}
            aspect={aspect}
            onChange={(_crop, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            style={{ aspectRatio: srcAspect }}
          >
            <img
              ref={imgRef}
              crossOrigin="anonymous"
              alt={t('cropMe', 'Crop me!') ?? ''}
              src={imgSrc}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </div>
      )}
      {!!completedCrop && (
        <canvas
          ref={previewCanvasRef}
          style={{
            border: '1px solid black',
            objectFit: 'contain',
            width: completedCrop.width,
            height: completedCrop.height,

            // We don't actually want to see it
            position: 'absolute',
            top: '-200vh',
            visibility: 'hidden',
          }}
        />
      )}

      {/* {props.isLoading && <LoaderBar style={{bottom: '.8rem', position: 'absolute'}} />} */}
    </div>
  );
};

export default InputFileProfilePicture;
