import { useTranslation } from 'react-i18next';
import styles from '../individualCare.module.scss';
import CareRequestForm from './form';

const AddCareRequest = () => {
  const { t } = useTranslation('page_IndividualCare');
  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <h1>{t('titles.addCareRequest', 'Zorgvraag toevoegen')}</h1>
        <CareRequestForm />
      </div>
    </div>
  );
};

export default AddCareRequest;
