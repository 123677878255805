import {
  CalendarBookingMapped,
  DirectBookingMapped,
  GetMyBookingsForCalendarOutput,
  GetMyOpenDirectBookingsOutput,
} from '@tallkingconnect/gateway';
import { useQuery } from '@tanstack/react-query';
import invariant from 'invariant';
import _ from 'lodash';
import moment from 'moment';
import ms from 'ms';
import Flags from 'src/config/flags';
import { emptyObject } from 'src/constants';
import { BookingType } from 'src/pages/planning/constants';
import { useGateway } from 'src/providers/gateway';
import { formatDateHtml } from 'src/utils';

type NormalizedBooking<TBooking> = TBooking & {
  start: Date;
  end: Date;
};

// TODO: remove once backend returns normalized bookings
function transformCalendarBookings(
  bookings?: CalendarBookingMapped[] | null
): NormalizedBooking<CalendarBookingMapped>[] | undefined {
  return bookings?.map((booking) => {
    invariant(booking.bookingDateTime, '!booking.bookingDateTime');

    const start = new Date(booking.bookingDateTime);

    return {
      ...booking,
      start,
      end: moment(start).add(booking.hours, 'hours').toDate(),
    };
  });
}

function selectMyBookingsForCalendar(data: GetMyBookingsForCalendarOutput) {
  return {
    bookings: transformCalendarBookings(data.calendarBookings),
    teams: data.districtTeams,
    careSectors: data.careSectors,
    degrees: data.degrees,
  };
}

export function useMyBookingsForCalendar(date: string | Date) {
  const { api } = useGateway();

  if (_.isDate(date)) {
    date = formatDateHtml(date);
  }

  return useQuery({
    queryKey: ['plannerBookings', { date }] as [string, { date: string }],
    queryFn: ({ queryKey: [, { date }] }) => api.bookings.getMyBookingsForCalendar(date),
    staleTime: ms('1m'),
    refetchInterval: Flags.enableAutoRefreshBookings ? ms('1m') : undefined,
    select: selectMyBookingsForCalendar,
    placeholderData: emptyObject,
  });
}

// TODO: remove once backend returns normalized bookings
function transformDirectBookings(
  bookings?: DirectBookingMapped[] | null
): NormalizedBooking<DirectBookingMapped>[] | undefined {
  return bookings?.map((booking) => {
    return {
      ...booking,
      type: BookingType.Direct,
      start: moment(booking.bookingDateTime).toDate(),
      end: moment(booking.bookingDateTime).add(booking.hours, 'hours').toDate(),
    } as NormalizedBooking<DirectBookingMapped>;
  });
}

function selectMyOpenDirectBookings(data: GetMyOpenDirectBookingsOutput) {
  return {
    bookings: transformDirectBookings(data.directBookings),
    teams: data.districtTeams,
  };
}

export function useMyOpenDirectBookings() {
  const { api } = useGateway();

  return useQuery({
    queryKey: ['directBookings'],
    queryFn: api.directBookings.getMyOpenDirectBookings,
    staleTime: ms('1m'),
    refetchInterval: Flags.enableAutoRefreshBookings ? ms('1m') : undefined,
    select: selectMyOpenDirectBookings,
    placeholderData: emptyObject,
  });
}

export function useCareAgreementPreviewInformation(id: string) {
  const { api } = useGateway();

  return useQuery({
    queryKey: ['careAgreementInformation', { id }] as [string, { id: string }],
    queryFn: ({ queryKey: [, { id }] }) => api.bookings.getCareAgreementPreviewInformation(id),
  });
}
