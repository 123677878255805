import clsx from 'clsx';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Check, X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import LoadingOverlay from 'src/components/loader/loader';
import Avatar from 'src/components/user/avatar';
import { useGateway } from 'src/providers/gateway';
import { formatBookingTimeInfoFromStartAndDuration, formatDate, formatDuration } from 'src/utils';
import RejectHoursDialog from '../reject_hours_dialog/reject_hours_dialog';
import styles from './booking_duration.module.scss';

const BookingDuration = () => {
  const { t } = useTranslation(['page_companyAdmin', 'common']);
  const { api } = useGateway();

  const [needsLoad, setNeedsLoad] = useState(true);
  const [durationRequests, setDurationRequests] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [durationRequestToReject, setDurationRequestToReject] = useState();

  const canSave = !isLoading;

  const getDurationRequests = useCallback(async () => {
    setIsLoading(true);

    try {
      const data = await api.durationRequests.getDurationRequests();
      setDurationRequests(data.durationRequests);
    } catch (err) {
      console.error('Error loading duration requests:', err);
      setDurationRequests([]);
    } finally {
      setIsLoading(false);
    }
  }, [api]);

  const postDurationRequest = useCallback(
    async (durationRequest) => {
      setIsLoading(true);

      try {
        await api.durationRequests.respondToDurationRequest(durationRequest);
        setNeedsLoad(true);
      } catch (err) {
        console.error('Error responding to duration request:', err);
        setDurationRequests([]);
      } finally {
        setDurationRequestToReject();
        setIsLoading(false);
      }
    },
    [api]
  );

  useEffect(() => {
    if (needsLoad) {
      setNeedsLoad(false);
      getDurationRequests();
    }
  }, [getDurationRequests, needsLoad]);

  return (
    <div className={styles.container}>
      {isLoading && <LoadingOverlay />}

      <p>
        {t(
          'bookingDuration.hoursToBeApprovedDescription',
          'Deze lijst bevat goed te keuren uren nadat de zzp’er een verzoek heeft gedaan om de duur van een dienst aan te passen. Hier keur je de aanvraag van de zzp’er goed of doe je een voorstel tot correctie. Vermeld hier voor de duidelijkheid een reden bij.'
        )}
      </p>

      <ul className={styles.requestList}>
        <li className={styles.headerRow}>
          <div>{t('bookingDuration.caregiver', 'Zorgverlener')}</div>
          <div>{t('bookingDuration.bookingInformation', 'Boekingsinformatie')}</div>
          <div></div>
        </li>
        {durationRequests &&
          (durationRequests.length > 0 ? (
            durationRequests.map((item) => (
              <li key={item.durationRequestId} className={styles.requestRow}>
                <div className={styles.durationInfo}>
                  <div className={styles.caregiver}>
                    <Avatar src={item.caregiver.profilePictureUrl} />
                    <div>
                      <div className={styles.name}>
                        {item.caregiver.firstName} {item.caregiver.lastName}
                      </div>
                    </div>
                  </div>
                  <div className={styles.bookingInfo}>
                    <ul>
                      <li>
                        <label>{t('bookingDuration.booking', 'Boeking')}:</label>
                        <span>{item.bookingId}</span>
                      </li>

                      <li>
                        <label>{t('bookingDuration.team', 'Team')}:</label>
                        <span>{item.districtTeamName}</span>
                      </li>

                      <li>
                        <label>{t('bookingDuration.date', 'Datum')}:</label>
                        <span>{formatDate(item.bookingDateTime, { format: t('common:dateFormat') })}</span>
                      </li>

                      <li>
                        <label>{t('bookingDuration.startTimeEndTime', 'Begintijd - Eindtijd')}:</label>
                        <span>{formatBookingTimeInfoFromStartAndDuration(item.bookingDateTime, item.hours)}</span>
                      </li>

                      <li>
                        <label>{t('bookingDuration.originalDuration', 'Originele duur')}:</label>
                        <span>
                          {formatDuration(item.hours)}
                          {item.unbillableHours > 0 && (
                            <>
                              {t('bookingDuration.includingNminBreak', {
                                breakMin: item.unbillableHours * 60,
                                defaultValue: 'incl {{breakMin}} min pauze',
                              })}
                            </>
                          )}
                        </span>
                      </li>

                      <li>
                        <label>{t('bookingDuration.adjustedDuration', 'Aangepaste duur')}:</label>
                        <span>
                          {formatDuration(item.durationRequestHours)}
                          {item.durationRequestUnbillableHours > 0 && (
                            <>
                              {' '}
                              {t('bookingApproval.includingNminBreak', {
                                breakMin: item.durationRequestUnbillableHours * 60,
                                defaultValue: 'incl {{breakMin}} min pauze',
                              })}
                            </>
                          )}
                        </span>
                      </li>

                      <li>
                        <label>{t('bookingDuration.description', 'Omschrijving')}:</label>
                        <span className={clsx(item.description && styles.empty)}>
                          {item.description || t('bookingDuration.noDescriptionGiven', 'Geen omschrijving meegegeven')}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className={styles.action}>
                    <Button
                      secondary
                      disabled={!canSave}
                      onClick={() => {
                        postDurationRequest({
                          bookingId: item.bookingId,
                          durationRequestId: item.durationRequestId,
                          suggestedHours: item.hours,
                          suggestedUnbillableHours: item.unbillableHours,
                          accepted: true,
                          reasonForRejection: '',
                        });
                      }}
                    >
                      <Check />
                    </Button>
                    <Button
                      secondary
                      onClick={() => {
                        setDurationRequestToReject({
                          bookingId: item.bookingId,
                          durationRequestId: item.durationRequestId,
                          accepted: false,
                          originalBookingDurationRequest: item,
                          reasonForRejection: '',
                        });
                      }}
                    >
                      <X />
                    </Button>
                  </div>
                </div>
              </li>
            ))
          ) : (
            <li className={clsx(styles.requestRow, styles.emptyRow)}>
              <p>
                {t(
                  'bookingDuration.noRequestsToChangeBookingDuration',
                  'Momenteel zijn er geen verzoeken voor wijzigen van boekingsduur.'
                )}
              </p>
            </li>
          ))}
      </ul>

      {durationRequestToReject && (
        <RejectHoursDialog
          disabledConfirm={!canSave}
          caregiver={durationRequestToReject.originalBookingDurationRequest.caregiver}
          startDateTime={durationRequestToReject.originalBookingDurationRequest.bookingDateTime}
          endDateTime={moment(durationRequestToReject.originalBookingDurationRequest.bookingDateTime).add(
            durationRequestToReject.originalBookingDurationRequest.hours,
            'hours'
          )}
          duration={durationRequestToReject.originalBookingDurationRequest.hours}
          onConfirm={({ duration, unbillableHours, reason }) => {
            postDurationRequest({
              ...durationRequestToReject,
              suggestedHours: duration,
              suggestedUnbillableHours: unbillableHours,
              reasonForRejection: reason,
            });
            setDurationRequestToReject();
          }}
          onCancel={() => {
            setDurationRequestToReject();
          }}
        />
      )}
    </div>
  );
};

export default BookingDuration;
