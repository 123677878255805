import styles from './hamburgerbar.module.scss';

const HamburgerBar = (props) => (
  <div {...props} className={styles.container}>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default HamburgerBar;
