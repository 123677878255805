import { useTranslation } from 'react-i18next';
import PostingForm from './form';
import styles from './postings.module.scss';

const AddPosting = () => {
  const { t } = useTranslation('page_postings');
  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <h1>{t('addApplicationForLongTermCommitment', 'Aanvraag langdurige inzet toevoegen')}</h1>
        <PostingForm />
      </div>
    </div>
  );
};

export default AddPosting;
