import clsx from 'clsx';
import _ from 'lodash';
import { Clock, Sunrise, Sunset } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { TeamShift } from 'src/pages/teams/constants';
import styles from './index.module.scss';

const ShiftSelector = ({ shifts, value: selectedShift, disabled, onChange }) => {
  const { t } = useTranslation('page_planning');
  const shiftOptions = Object.freeze([
    { value: TeamShift.Early, label: t('components.shiftSelector.earlyShift', 'Vroege dienst'), icon: Sunrise },
    { value: TeamShift.Late, label: t('components.shiftSelector.lateShift', 'Late dienst'), icon: Sunset },
    { value: TeamShift.Custom, label: t('components.shiftSelector.customTimes', 'Aangepaste tijden'), icon: Clock },
  ]);
  return (
    <div className={styles.shiftSelector}>
      {shiftOptions.map(({ icon: Icon, value, label }) => (
        <Icon
          key={value}
          title={label}
          className={clsx(value === selectedShift && styles.active)}
          onClick={() => {
            if (!disabled) {
              onChange(value, _.get(shifts, value));
            }
          }}
        />
      ))}
    </div>
  );
};

export default ShiftSelector;
