import Flags from './config/flags';

/*
 * Available top-level routes.
 *
 * Properties:
 * - path: URL path.
 * - i18nKey: title of the page
 * - roles: who have access to this route
 */

const routes = [
  { path: '/login', i18nKey: 'login' },
  { path: '/register', i18nKey: 'signUp' },
  {
    path: '/onboarding',
    i18nKey: 'onboarding',
    roles: ['Caregiver'],
  },
  {
    path: '/caregiver-environment',
    i18nKey: 'caregiverEnvironment',
    roles: ['Caregiver'],
  },
  {
    path: '/admin/create-company-account',
    i18nKey: 'createCompanyAccount',
    roles: ['Admin'],
  },
  {
    path: '/admin/create-planner-account',
    i18nKey: 'createPlannerAccount',
    roles: ['Admin'],
  },
  {
    path: '/teams',
    i18nKey: 'teams',
    roles: ['Admin', 'CompanyAdmin', 'Planner'],
  },
  {
    path: '/screening',
    i18nKey: 'screening',
    roles: ['Admin'],
  },
  {
    path: '/planning',
    i18nKey: 'planning',
    roles: ['Planner'],
  },
  {
    path: '/hours',
    i18nKey: 'approveHours',
    roles: ['Approver'],
  },
  {
    path: '/bookings/corrections/add',
    i18nKey: 'correctionBooking',
    roles: ['Admin'],
  },
  {
    path: '/postings',
    i18nKey: 'postings',
    roles: ['Recruiter', 'Caregiver', 'Admin'],
  },
  Flags.enableIndividualCareRequests && {
    path: '/individualcare',
    i18nKey: 'individualCare',
    roles: ['CareConsultant', 'Caregiver', 'Admin'],
  },
  {
    path: '/admin/billing',
    i18nKey: 'billing',
    roles: ['Admin'],
  },
].filter(Boolean);

export default routes;

export const findRouteByPath = (path) => routes.find((r) => r.path === path);

export const findNearestRouteByPath = (path) => {
  while (path) {
    const route = findRouteByPath(path);

    if (route) {
      return route;
    }

    const index = path.lastIndexOf('/');
    path = index < 0 ? null : path.substring(0, index);
  }

  return null;
};
