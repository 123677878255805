import _ from 'lodash';

/**
 * Builds an enum of { [value]: "value" }
 * @param {Array.<string>} values Ordered values
 * @returns {Object.<string, string>}
 */
export const buildEnum = (values) => Object.freeze(_.keyBy(values));

/**
 * Builds a list of options for use in our Select component.
 *
 * @param {Array.<string>} values Ordered values
 * @param {Object.<string, string>} labels Dictionary of { [value]: "label "}
 * @returns {Array.<Object>}
 */
export const buildOptions = (values, labels, information) =>
  Object.freeze(
    values.map((value) => {
      const option = {
        value,
        label: labels[value],
      };

      if (information) {
        option.information = information[value];
      }

      return option;
    })
  );
