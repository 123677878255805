import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useParams } from 'react-router-dom';
import LoadingOverlay from 'src/components/loader/loader';
import TabContainer from 'src/components/tabs/tabs';
import { emptyObject } from 'src/constants';
import { useIndividualCareRequest } from 'src/hooks/individualCare';
import { useUserInformation } from 'src/hooks/users';
import CareReactions from '../reactions';
import AddCareRequest from './add';
import EditCareRequest from './edit';
import ListCareRequests from './list';
import ViewCareRequest from './view';

const CareRequestsWithReactions = () => {
  const { t } = useTranslation('page_postings');
  const { hasRole, data: user } = useUserInformation();
  const { careRequestId } = useParams();

  const initialCareRequest = useIndividualCareRequest(careRequestId);
  const [careRequest, setCareRequest] = useState(emptyObject);
  useEffect(() => {
    if (initialCareRequest.isFetched) {
      setCareRequest({
        data: initialCareRequest.data,
        error: initialCareRequest.error,
        isFetched: initialCareRequest.isFetched,
      });
    }
  }, [initialCareRequest.data, initialCareRequest.error, initialCareRequest.isFetched]);

  const isCareConsultant = hasRole('CareConsultant');
  const isCaregiver = hasRole('Caregiver');
  const isAdmin = hasRole('Admin');

  return careRequest.isFetched ? (
    <TabContainer
      type="link"
      tabs={[
        {
          index: true,
          label: t('index.details', 'Details'),
          component: ViewCareRequest,
          careRequest: careRequest.data,
          error: careRequest.error,
        },
        (isCareConsultant || isAdmin) && {
          path: 'reactions/*',
          label: t('index.reactions', 'Reacties'),
          component: CareReactions,
          role: isAdmin ? 'Admin' : 'CareConsultant',
          careRequest,
        },
        isCaregiver && {
          path: 'reactions/mine/*',
          label: t('index.myResponse', 'Mijn reactie'),
          component: CareReactions,
          role: 'Caregiver',
          careRequest,
          reactions: _.filter(_.get(careRequest.data, 'reactions'), { caregiver: { id: user.id } }),
        },
      ].filter(Boolean)}
    />
  ) : (
    <LoadingOverlay />
  );
};

const Care = () => {
  return (
    <Routes>
      <Route index element={<ListCareRequests />} />
      <Route path="add" element={<AddCareRequest />} />
      <Route path=":careRequestId/edit" element={<EditCareRequest />} />
      <Route path=":careRequestId/*" element={<CareRequestsWithReactions />} />
    </Routes>
  );
};

export default Care;
