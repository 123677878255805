import invariant from 'invariant';
import _ from 'lodash';
import { useState } from 'react';
import { Lock } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CheckButton, CrossButton } from 'src/components/button/button';
import { IconDialog } from 'src/components/dialog/dialog';
import { Form, FormActions, FormRow } from 'src/components/form/form';
import { LabeledFormInputView } from 'src/components/form/labeled_form_input';
import InputTextArea from 'src/components/input_textarea';
import { IndividualCareRequestStatusReactable } from 'src/constants/individualCare';
import { useUserInformation } from 'src/hooks/users';
import { useGateway } from 'src/providers/gateway';
import { formatCurrency, formatDateTime } from 'src/utils';
import ActionsMenu from './actionsMenu';
import { IndividualCareReactionStatus } from './constants';
import styles from './reactions.module.scss';

const AcceptOfferDialog = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation('page_individualCare');

  return (
    <IconDialog
      className={styles.acceptOfferDialog}
      onConfirm={onConfirm}
      onCancel={onCancel}
      labelQuestion={t('reactions.view.acceptRequestDialogQuestion', 'Weet je zeker dat je wilt accepteren?')}
    ></IconDialog>
  );
};

const RejectOfferDialog = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation('page_individualCare');
  const [note, setNote] = useState('');

  return (
    <IconDialog
      onConfirm={() => onConfirm(note)}
      onCancel={onCancel}
      labelQuestion={t('reactions.view.doNotAgreeBecauseDialogQuestion', 'Ik ga niet akkoord, omdat:')}
    >
      <InputTextArea value={note} onChange={setNote} />
    </IconDialog>
  );
};

const ReactionResponseForm = ({ reaction, careRequest }) => {
  const { t } = useTranslation('page_individualCare');
  const { api } = useGateway();
  const navigate = useNavigate();

  const [dialogData, setDialogData] = useState(null);
  const [processing, setProcessing] = useState(false);

  const showDialog = (component, onConfirm, props = {}) =>
    setDialogData({
      component,
      onConfirm,
      onCancel: () => setDialogData(),
      ...props,
    });

  const showAcceptDialog = () => {
    showDialog(AcceptOfferDialog, () => respondToOffer(true), { careRequest });
  };

  const showRejectDialog = () => {
    showDialog(RejectOfferDialog, (note) => respondToOffer(false, note));
  };

  const respondToOffer = async (accepted, note = '') => {
    setProcessing(true);
    try {
      await api.individualCare.respondToIndividualCareOffer({
        reactionId: reaction.id,
        accepted,
        note,
      });
      navigate(0);
    } catch (error) {
      console.error('Error processing request:', error);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      {dialogData && <dialogData.component {...dialogData} />}
      <Form className={styles.responseForm}>
        <FormRow>
          <Trans t={t} i18nKey="reactions.view.offerReceived">
            <p>
              Er is geantwoord door de zorgconsulent n.a.v. je reactie!
              <br />
              Je bent geselecteerd tegen het uurtarief van{' '}
              <strong>{{ agreedRate: formatCurrency(careRequest.agreedRate || 0) }}</strong>
              <br />
              Ga je hiermee akkoord?
            </p>
          </Trans>
        </FormRow>
        <FormActions>
          <CrossButton disabled={processing} onClick={showRejectDialog} />
          <CheckButton disabled={processing} onClick={showAcceptDialog} />
        </FormActions>
      </Form>
    </>
  );
};

const ReactionView = ({ reaction: initialReaction = {}, careRequest, onChange = _.noop }) => {
  const { t } = useTranslation(['page_individualCare', 'common']);
  const { hasRole } = useUserInformation();

  const [reaction, setReaction] = useState(initialReaction);

  const isCaregiver = hasRole('Caregiver');
  const canEdit = isCaregiver && reaction && IndividualCareRequestStatusReactable.includes(careRequest.status);
  const showLock = isCaregiver && !canEdit;

  const updateModel = (nextModel) => {
    setReaction(nextModel);
    onChange(nextModel);
  };

  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <h1>Reactie</h1>
        {canEdit && <ActionsMenu reaction={reaction} onChangeModel={updateModel} />}
        {showLock && (
          <span title={t('reactions.view.editingNotPossible', 'Bewerken niet (meer) mogelijk')}>
            <Lock />
          </span>
        )}
      </div>
      <Form>
        <FormRow>
          <LabeledFormInputView
            label={t('reactions.view.state', 'Status')}
            value={t(`constants.individualCareReactionStatusLabels.${reaction.status}`)}
          />
        </FormRow>

        {[IndividualCareReactionStatus.Pending].includes(reaction.status) && (
          <FormRow>
            <ReactionResponseForm reaction={reaction} careRequest={careRequest} />
          </FormRow>
        )}

        <FormRow>
          <LabeledFormInputView label={t('reactions.view.note', 'Toelichting')} value={reaction.description} />
        </FormRow>

        <FormRow>
          <LabeledFormInputView
            label={t('reactions.view.createdAt', 'Aangemaakt op')}
            value={formatDateTime(reaction.createdAt, { format: t('common:dateTimeFormat') })}
          />
          <LabeledFormInputView
            label={t('reactions.view.updatedOn', 'Laatst aangepast op')}
            value={formatDateTime(reaction.updatedAt, { format: t('common:dateTimeFormat') })}
          />
        </FormRow>
      </Form>
    </div>
  );
};

const EmptyView = ({ placeholder }) => (
  <div className={styles.container}>
    <div className={styles.placeholder}>{placeholder}</div>
  </div>
);

const Page = ({ reaction, reactions, placeholder = '', ...props }) => {
  const { t } = useTranslation('page_individualCare');
  invariant(!reaction || !reactions, t('reactions.view.cantProvideBothReationAndReactionProps'));

  if (reaction) {
    reactions = [reaction];
  }

  return (
    <div className={styles.page}>
      {reactions.length ? (
        reactions.map((reaction) => <ReactionView key={reaction.id} reaction={reaction} {...props} />)
      ) : (
        <EmptyView placeholder={placeholder} />
      )}
    </div>
  );
};

export default Page;
