/*
	Copyright (C) 2023, Dytter. All rights reserved.
*/

import clsx from 'clsx';
import invariant from 'invariant';
import styles from './index.module.scss';
import { useMemo } from 'react';

const logoMargin = 125;

const logoMarkViewBox = {
  x: 0,
  y: 0,
  w: 621.96,
  h: 621.96,
};

const wordMarkViewBox = {
  x: 600,
  y: 0,
  w: 1740,
  h: 662,
};

const fullLogoViewBox = {
  x: 0,
  y: 0,
  w: 2340,
  h: 662,
};

const defaultTextColor = styles.defaultTextColor;
const defaultAccentColor = styles.defaultAccentColor;

/**
 * Valid accent colors.
 */
export type LogoAccent = 'default';
export const validAccents: LogoAccent[] = ['default'];

const Defs: React.FC<React.SVGProps<SVGDefsElement>> = () => (
  <defs>
    <symbol className={styles.char} id="C" width="447" height="800" viewBox="0 0 447 800">
      <path d="M413 189c-2 -64 -23 -119 -59 -152c-32 -30 -69 -45 -123 -45c-66 0 -109 26 -138 61c-47 56 -52 134 -52 303s5 247 52 303c29 35 72 61 138 61c54 0 93 -16 125 -46c36 -33 55 -87 57 -150h-112c-1 26 -5 52 -17 69c-10 16 -26 26 -53 26s-43 -11 -53 -27 c-22 -34 -25 -106 -25 -236s3 -202 25 -236c10 -16 26 -27 53 -27s43 10 53 26c12 18 16 44 17 70h112z" />
    </symbol>
    <symbol className={styles.char} id="E" width="396" height="800" viewBox="0 0 396 800">
      <path d="M359 0h-299v712h299v-101h-187v-202h161v-101h-161v-207h187v-101z" />
    </symbol>
    <symbol className={styles.char} id="N" width="499" height="800" viewBox="0 0 499 800">
      <path d="M439 0h-99l-168 423v-423h-112v712h100l167 -423v423h112v-712z" />
    </symbol>
    <symbol className={styles.char} id="O" width="462" height="800" viewBox="0 0 462 800">
      <path d="M421 356c0 -169 -4 -247 -52 -303c-29 -35 -72 -61 -138 -61s-109 26 -138 61c-47 57 -52 134 -52 303s5 246 52 303c29 35 72 61 138 61s109 -26 138 -61c48 -56 52 -134 52 -303zM309 356c0 130 -3 202 -24 236c-10 16 -27 27 -54 27s-43 -11 -53 -27 c-21 -34 -25 -106 -25 -236s4 -202 25 -236c10 -16 26 -27 53 -27s44 11 54 27c21 34 24 106 24 236z" />
    </symbol>
    <symbol className={styles.char} id="T" width="393" height="800" viewBox="0 0 393 800">
      <path d="M372 611h-120v-611h-112v611h-119v101h351v-101z" />
    </symbol>
  </defs>
);

type BackgroundProps = {
  /**
   * Background color
   */
  color?: string;
};

const Background: React.FC<React.SVGProps<SVGRectElement> & BackgroundProps> = ({
  color,
  className,
  x,
  y,
  width,
  height,
  ...props
}) => <rect className={clsx(styles.bg, className)} x="0" y="0" width="100%" height="100%" fill={color} {...props} />;

type LogoMarkProps = React.SVGProps<SVGGElement> & {
  /**
   * Accent color
   */
  color?: string;

  /**
   * Whether to animate the logomark
   */
  animate?: boolean;
};

const LogoMark: React.FC<LogoMarkProps> = ({ animate, className, color = defaultAccentColor, ...props }) => (
  <g className={clsx(styles.logoMark, animate && styles.animate, className)} {...props}>
    <g className={styles.crown} fill={color}>
      <polygon points="37.02 223.94 157.15 544.78 157.15 442.23 105.87 313.8 37.02 223.94" />
      <polygon points="157.15 544.78 310.98 149.72 310.98 74.27 157.15 442.23 157.15 544.78" />
      <polygon points="464.8 544.78 310.98 149.72 310.98 74.27 464.8 442.23 464.8 544.78" />
      <polygon points="584.93 223.94 464.8 544.78 464.8 442.23 516.08 313.8 584.93 223.94" />
      <polygon points="584.93 223.94 310.98 424.72 310.98 462.25 516.08 313.8 584.93 223.94" />
      <polygon points="37.02 223.94 310.98 424.72 310.98 462.25 105.87 313.8 37.02 223.94" />
      <circle cx="53.74" cy="185.89" r="27.47" />
      <circle cx="568.21" cy="185.89" r="27.47" />
    </g>
  </g>
);

type WordMarkProps = React.SVGProps<SVGGElement> & {
  /**
   * Accent color
   */
  color?: string;

  /**
   * Text color
   */
  textColor?: string;
};

const WordMark: React.FC<WordMarkProps> = ({
  className,
  color = defaultAccentColor,
  textColor = defaultTextColor,
  ...props
}) => (
  <g className={clsx(styles.wordMark, className)} {...props}>
    <g className={styles.tall} fill={textColor}>
      <polygon points="878.79 102.75 661.35 102.75 661.35 165.33 735.07 165.33 735.07 543.82 804.45 543.82 804.45 165.33 878.79 165.33 878.79 102.75" />
      <path d="m971.75,212.41c-26.64,0-53.28,11.15-68.77,26.64-20.44,20.44-27.87,44.6-27.87,70.62h65.66c0-13.63,3.1-22.93,9.91-30.36,4.96-5.58,11.77-9.29,21.68-9.29,20.45,0,31.6,13.62,31.6,37.78v45.22h-29.12c-37.78,0-58.84,8.06-74.96,22.3-19.82,17.34-30.35,43.37-30.35,73.1,0,32.83,6.82,57.6,19.82,73.09,16.73,19.21,34.07,27.26,61.32,27.26,16.73,0,27.25-4.35,37.17-11.15,7.44-4.96,14.25-13.63,19.82-21.69v27.88h61.95v-230.44c0-35.31-11.15-59.47-28.49-76.81-16.73-17.34-42.13-24.16-69.38-24.16Zm32.22,235.39c0,26.64-14.87,43.37-34.7,43.37-22.3,0-34.07-16.11-34.07-45.22,0-13.01,4.96-29.12,13.01-36.55,9.3-8.67,19.82-9.91,34.07-9.91h21.69v48.32Z" />
      <path d="m1196.02,440.98V102.75h-65.67v341.95c0,39.03,12.39,63.8,32.84,79.29,21.68,16.73,47.08,19.82,77.43,19.82v-58.85c-30.35,0-44.6-14.86-44.6-43.98Z" />
      <path d="m1347.2,440.98V102.75h-65.67v341.95c0,39.03,12.39,63.8,32.84,79.29,21.68,16.73,47.08,19.82,77.43,19.82v-58.85c-30.35,0-44.6-14.86-44.6-43.98Z" />
    </g>
    <g className={styles.king} fill={color}>
      <polygon points="1674.91 102.75 1604.91 102.75 1508.89 310.28 1508.89 102.75 1439.51 102.75 1439.51 543.82 1508.89 543.82 1508.89 430.46 1542.34 362.32 1613.58 543.82 1688.53 543.82 1586.32 285.51 1674.91 102.75" />
      <rect x="1725.13" y="226.04" width="65.66" height="317.78" />
      <rect x="1721.41" y="103.38" width="72.48" height="72.48" />
      <path d="m1972.94,212.41c-11.77,0-23.54,3.1-32.83,8.67-8.06,4.34-16.11,13.01-21.69,20.44v-24.16h-63.18v326.46h65.66v-234.78c0-25.4,14.25-37.78,32.22-37.78s32.22,12.38,32.22,37.78v234.78h65.66v-235.39c0-37.79-5.58-55.76-18.58-70.62-14.87-17.34-32.83-25.4-59.47-25.4Z" />
      <path d="m2238.72,217.36v21.06c-4.95-6.81-12.39-13.62-19.2-17.34-9.91-5.58-21.69-8.67-33.46-8.67-27.88,0-41.5,6.82-56.37,24.16-20.44,24.16-26.01,61.95-26.01,131.94s5.58,107.17,26.01,131.33c14.87,17.34,29.12,24.16,56.37,24.16,13.01,0,22.92-1.85,31.59-7.43,6.2-3.72,13.01-10.54,18.58-17.97v55.75c0,20.45-2.47,32.22-9.29,40.27-5.58,6.19-13.63,10.53-23.54,10.53s-17.96-3.71-23.53-9.91c-6.82-8.05-9.3-18.58-9.3-30.97h-65.04c0,27.26,7.43,52.04,27.25,71.86,17.97,17.34,43.37,25.4,70.62,25.4s53.89-9.91,70.62-26.64c17.97-17.96,27.88-42.74,27.88-84.87V217.36h-63.19Zm-2.48,210.62c0,13.63-3.1,22.92-9.29,29.73-5.58,6.2-14.25,9.3-23.54,9.3-11.77,0-17.96-4.96-24.16-13.01-9.91-13.02-9.91-44.61-9.91-84.87s0-71.86,9.91-84.87c6.2-8.06,12.39-13.63,24.16-13.63,9.3,0,17.34,3.1,22.92,9.3,6.2,6.81,9.91,16.72,9.91,30.35v117.7Z" />
    </g>
  </g>
);

type DivisionMarkProps = React.SVGProps<SVGGElement> & {
  /**
   * Text color
   */
  textColor?: string;
};

const DivisionMark: React.FC<DivisionMarkProps> = ({ className, textColor = defaultTextColor, ...props }) => (
  <g className={styles.division} fill={textColor} {...props}>
    <g id="division" transform="translate(1848 175.75) scale(1 -1)">
      <use href="#C" x="0" y="0" />
      <use href="#O" x="447" y="0" />
      <use href="#N" x="909" y="0" />
      <use href="#N" x="1408" y="0" />
      <use href="#E" x="1907" y="0" />
      <use href="#C" x="2295" y="0" />
      <use href="#T" x="2742" y="0" />
    </g>
  </g>
);

type LogoProps = React.SVGProps<SVGGElement> & {
  /**
   * Whether to show the logomark
   */
  logomark?: boolean;

  /**
   * Whether to show the wordmark
   */
  wordmark?: boolean;

  /**
   * Whether to show the division
   */
  division?: boolean;

  /**
   * Whether to animate the logo
   */
  animate?: boolean;

  /**
   * Accent color
   */
  accent?: LogoAccent | null;

  /**
   * Enable monochrome mode
   * @type {Boolean?}
   */
  mono?: boolean;

  /**
   * Enable light (off white) mode
   */
  light?: boolean;

  /**
   * Enable white mode
   */
  white?: boolean;

  /**
   * Background color
   */
  bgColor?: string;

  /**
   * Size (pixels)
   */
  size?: number | string;

  /**
   * Whether to apply default logo margins
   */
  margin?: boolean;

  /**
   * Extra css classes to apply
   */
  className?: string | string[];
};

const Logo: React.FC<LogoProps> = ({
  logomark = true,
  wordmark = true,
  division = true,
  animate = false,
  accent = undefined,
  mono = false,
  light = false,
  white = false,
  bgColor,
  size = 48,
  margin = false,
  className = undefined,
}) => {
  invariant(!accent || validAccents.includes(accent), "Invalid value provided for 'accent'");

  if (typeof size === 'number' && isFinite(size)) {
    size = `${size}px`;
  }

  const { viewBox } = useMemo(() => {
    const marginSize = margin ? logoMargin : 0;

    const viewBoxValues =
      logomark && wordmark ? fullLogoViewBox : logomark ? logoMarkViewBox : wordmark ? wordMarkViewBox : null;

    if (viewBoxValues === null) {
      throw new Error('Unsupported property combination');
    }
    const viewBox = [
      viewBoxValues.x - marginSize,
      viewBoxValues.y - marginSize,
      viewBoxValues.w + marginSize,
      viewBoxValues.h + marginSize,
    ].join(' ');

    return {
      viewBox,
    };
  }, [logomark, wordmark, margin]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox={viewBox}
      height={size}
      className={clsx(
        styles.logo,
        accent && styles[accent],
        mono && styles.mono,
        light && styles.light,
        white && styles.white,
        className
      )}
    >
      {division && <Defs />}
      {bgColor && <Background color={bgColor} />}
      {logomark && <LogoMark animate={animate} />}
      {wordmark && <WordMark />}
      {division && <DivisionMark />}
    </svg>
  );
};

export default Logo;
