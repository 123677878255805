import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputSearch from 'src/components/input_search/input_search';
import LoadingOverlay from 'src/components/loader/loader';
import Select from 'src/components/select/select';
import { useGateway } from 'src/providers/gateway';
import { ScreeningFilterPreset, ScreeningFilterPresetOptions } from './constants';
import styles from './screening.module.scss';
import ScreeningList from './screeningList';
import ScreeningProfile from './screening_profile';

const Screening = ({ actionRequired = false }) => {
  const { t } = useTranslation('page_admin');
  const { api } = useGateway();
  const [isLoading, setIsLoading] = useState(false);

  // TODO: untangle spaghetti:
  // - we have screeningList which is a list of (less detailed) profiles
  // - we have toScreenProfile which is one detailed profile. Strictly speaking we don't need that one in here.
  // - we have caregiver, which is a single (less detailed) profile
  // - component structure or naming needs improvements: screeningTabs > screening > (screeningList, screening_profile)

  const [screeningList, setScreeningList] = useState([]);

  // Search value input. Only applied in the actual query when the search button is hit.
  const [searchValueInput, setSearchValueInput] = useState('');
  // Search value used in the actual query.
  const [searchValue, setSearchValue] = useState('');

  const [toScreenProfile, setToScreenProfile] = useState(null);
  const [openedOnboardingProfileId, setOpenedOnboardingProfileId] = useState(null);
  const [filter, setFilter] = useState();

  const caregiver = screeningList.find((item) => item.id === openedOnboardingProfileId);

  const search = () => setSearchValue(searchValueInput);

  // Reset search value to actually used search value when changing the filter
  // NB: `searchValue` *MUST NOT* be a dependency!
  useEffect(() => {
    setSearchValueInput(searchValue);
  }, [filter]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCaregivers = useCallback(async () => {
    if (actionRequired || !_.isEmpty(searchValue)) {
      setIsLoading(true);

      try {
        const data = await api.caregivers.getCaregivers({
          filterPreset: actionRequired ? filter || ScreeningFilterPreset.All : null,
          searchValue: _.isEmpty(searchValue) ? undefined : searchValue,
        });
        setScreeningList(data);
      } catch (err) {
        console.error('Error getting screenings:', err);
        setScreeningList([]);
      } finally {
        setIsLoading(false);
      }
    }
  }, [actionRequired, api, filter, searchValue]);

  // Auto load data on initial load and whenever `getCaregivers` changes:
  useEffect(() => {
    getCaregivers();
  }, [getCaregivers]);

  const getOnboardingOfCaregiver = async (id) => {
    setIsLoading(true);

    try {
      const data = await api.onboardingScreenings.getOnboardingOfCaregiver({ caregiverId: id });
      if (data) {
        setToScreenProfile(data);
      }
    } catch (err) {
      console.error('Error loading onboarding:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      {isLoading && <LoadingOverlay />}

      <div className={styles.search}>
        {actionRequired && (
          <Select
            options={ScreeningFilterPresetOptions(t).filter((preset) => preset.value !== ScreeningFilterPreset.All)}
            placeholder={t('screening.screening.filterByStatus', 'Filteren op status')}
            onChange={setFilter}
            value={filter}
            className={styles.select}
            showClear
          />
        )}
        <InputSearch
          value={searchValueInput}
          onChange={setSearchValueInput}
          onSearch={search}
          placeholder={t('screening.screening.searchHereByNamePlaceholder', 'Zoek hier op naam')}
          minLength={0}
        />
      </div>

      {toScreenProfile ? (
        <ScreeningProfile
          profileId={openedOnboardingProfileId}
          profile={toScreenProfile}
          caregiverStatus={caregiver?.status}
          onClose={(changed) => {
            setToScreenProfile(null);
            if (changed) {
              getCaregivers();
            }
          }}
        />
      ) : (
        <ScreeningList
          screeningList={screeningList}
          getOnboardingOfCaregiver={getOnboardingOfCaregiver}
          setOpenedOnboardingProfileId={setOpenedOnboardingProfileId}
        />
      )}
    </div>
  );
};

export default Screening;
