import { useTranslation } from 'react-i18next';
import TabContainer from 'src/components/tabs/tabs';
import styles from './approve_hours.module.scss';
import BookingApproval from './booking_approval/booking_approval';
import BookingDuration from './booking_duration/booking_duration';

const ApproveHours = () => {
  const { t } = useTranslation('page_companyAdmin');

  return (
    <TabContainer
      tabs={[
        {
          path: 'approve',
          label: t('approveHours.approveHours', 'Uren goedkeuren'),
          component: BookingApproval,
        },
        {
          path: 'duration',
          label: t('approveHours.approveChangedServices', 'Gewijzigde diensten goedkeuren'),
          component: BookingDuration,
        },
      ]}
      defaultPath="approve"
      className={styles.container}
    />
  );
};

export default ApproveHours;
