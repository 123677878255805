import invariant from 'invariant';
import { useParams } from 'react-router-dom';
import Error from 'src/components/error/error';
import LoadingOverlay from 'src/components/loader/loader';
import { usePostingReactionAgreementPreview } from 'src/hooks/postings';
import CareAgreementDocument from 'src/pages/documents/templates/nl/careAgreement';

const CareAgreementForPostingReaction = () => {
  const { reactionId } = useParams();
  invariant(reactionId, 'reactionId undefined');
  const { data: model, isLoading, error } = usePostingReactionAgreementPreview(reactionId);

  return isLoading ? (
    <LoadingOverlay />
  ) : error ? (
    <Error>{error.message}</Error>
  ) : (
    <CareAgreementDocument model={model} />
  );
};

export default CareAgreementForPostingReaction;
