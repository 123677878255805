import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { useTranslation } from 'react-i18next';

const momentToDates = (moments) => {
  return moments.map((m) => moment(m).toDate());
};

const datesToIsoString = (dates) => {
  return dates.map((d) => moment(d).toISOString(true));
};

// TODO support translation
const DatePicker = ({ value, minDate, maxDate, selectionMode, onChange, ...props }) => {
  const { t } = useTranslation('common');

  const min = minDate ? moment(minDate).toDate() : null;
  const max = maxDate ? moment(maxDate).toDate() : null;

  selectionMode = selectionMode ?? 'single';

  const dates = value
    ? selectionMode === 'multiple' || selectionMode === 'range'
      ? momentToDates(value)
      : moment(value).toDate()
    : value;

  const onChangeHandler = (event) => {
    const value = event.value;
    const moments = value
      ? selectionMode === 'multiple' || selectionMode === 'range'
        ? datesToIsoString(value)
        : moment(value).toISOString(true)
      : value;
    onChange(moments);
  };

  return (
    <Calendar
      //Todo: remove style when primeReact is also used for other components
      style={{ marginBottom: '0.5rem' }}
      value={dates}
      onChange={onChangeHandler}
      minDate={min}
      maxDate={max}
      selectionMode={selectionMode}
      dateFormat={t('primeDateFormat', 'dd-mm-yy')}
      showOtherMonths={false}
      selectOtherMonths={true}
      {...props}
    />
  );
};

export default DatePicker;
