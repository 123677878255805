import { Check } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as BlobRight } from 'assets/icons/blob-right.svg';
import UserHelpBar from 'src/components/user_help_bar/user_help_bar';
import styles from './onboarding.module.scss';

const AwaitingScreening = ({ name }) => {
  const { t } = useTranslation('page_onboarding');

  return (
    <div>
      <Trans t={t} i18nKey="awaitingScreening">
        <h1>Dankjewel, {{ name }}!</h1>
        <div className={{ screeningStatusContainer: styles.screeningStatusContainer }}>
          <div className={{ content: styles.content }}>
            <div className={{ step: styles.step }}>
              <Check />
              <label>Je gegevens zijn aangevuld!</label>
            </div>
            <div className={{ step: styles.step }}>
              <BlobRight />
              <label>Huidige stap: Screening door TallKing Connect</label>
              <p>
                Op dit moment screenen wij jouw gegevens. Je ontvangt uiterlijk{' '}
                <strong>binnen 3 werkdagen via e-mail bericht</strong>.
                <br />
                <br />
                Als alles in orde is kan je via TallKing Connect <strong>aan de slag</strong>!
              </p>
            </div>
            <section>
              <UserHelpBar />
            </section>
          </div>
          <div className={{ image: styles.image }}></div>
        </div>
      </Trans>
    </div>
  );
};

export default AwaitingScreening;
