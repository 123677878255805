import { buildOptions } from 'src/utils';

// Ordered LegalCompanyTypes enum values
const LegalCompanyTypesValues = [
  'Eenmanszaak',
  'VOF',
  'CV',
  'Maatschap',
  'BV',
  'NV',
  'Vereniging',
  'Coop',
  'Stichting',
];

/**
 * LegalCompanyTypes labels
 *
 * @type {Object.<string, string>}
 */
export const LegalCompanyTypesLabels = {
  Eenmanszaak: 'Eenmanszaak',
  VOF: 'Vennootschap Onder Firma',
  CV: 'Commanditaire Vennootschap',
  Maatschap: 'Maatschap',
  BV: 'Besloten Vennootschap',
  NV: 'Naamloze Vennootschap',
  Vereniging: 'Vereniging',
  Coop: 'Coöperatie en onderlinge waarborgmaatschappij',
  Stichting: 'Stichting',
};

/**
 * LegalCompanyTypes select options
 *
 * @type {Array.<Object>}
 * @property Eenmanszaak
 * @property VOF
 * @property CV
 * @property Maatschap
 * @property BV
 * @property NV
 * @property Vereniging
 * @property Coop
 * @property Stichting
 */
export const LegalCompanyTypesOptions = buildOptions(LegalCompanyTypesValues, LegalCompanyTypesLabels);
