import countries from 'i18n-iso-countries';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from './select';

// TODO: create build-time script to export only the supported countries and languages to our i18n json files
import localeEN from 'i18n-iso-countries/langs/en.json';
import localeNL from 'i18n-iso-countries/langs/nl.json';

// Configure supported languages here
const supportedLanguages = ['nl', 'en'];
countries.registerLocale(localeNL);
countries.registerLocale(localeEN);
// countries.registerLocale(localeDE);

// Set to `null` to load all countries
const supportedCountries = ['NL', 'BE', 'DE'];

export const countryOptionsByLanguage = supportedLanguages.reduce((acc, language) => {
  acc[language] = Object.entries(countries.getNames(language))
    .filter(([isoCode, _name]) => supportedCountries?.includes(isoCode) ?? true)
    .sort(([_isoCode1, name1], [_isoCode2, name2]) => name1.localeCompare(name2))
    .map(([isoCode, name]) => ({ value: isoCode, label: name }));
  return acc;
}, {});

const SelectCountry = ({ value, ...props }) => {
  const { i18n } = useTranslation();

  const options = useMemo(() => countryOptionsByLanguage[i18n.resolvedLanguage], [i18n]);

  // Compatibility: translate literal country back into language code
  if (value && value.length !== 2) {
    // console.log('Trying to find value', value);
    // console.log('Alpha2 codes:', countries.getAlpha2Codes());
    value = supportedLanguages.reduce((result, lang) => result || countries.getAlpha2Code(value, lang), null);
    // console.log('Found value?', value);
  }

  return <Select options={options} value={value} {...props} />;
};

export default SelectCountry;
