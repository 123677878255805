import { useMemo } from 'react';

export const combinePropGetters = (...propGetters) => {
  // Filter out null and undefined
  propGetters = propGetters.filter(Boolean);

  // Create function that takes an item as input and returns the combined result of all prop getters
  return (...args) =>
    propGetters.reduce(
      (result, propGetter) => ({
        ...result,
        ...propGetter(...args),
      }),
      {}
    );
};

export const useCombinedPropGetter = (...propGetters) =>
  useMemo(() => combinePropGetters(...propGetters), [propGetters]);
