import { useCallback, useState } from 'react';
import { Mail, Phone } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import { IconDialog } from 'src/components/dialog/dialog';
import { Form, FormActions, FormRow, FormSpacer } from 'src/components/form/form';
import LabeledFormInput, { LabeledFormInputView } from 'src/components/form/labeled_form_input';
import InputCheckbox from 'src/components/input_checkbox';
import InputTextArea from 'src/components/input_textarea';
import { useUserInformation } from 'src/hooks/users';
import { useGateway } from 'src/providers/gateway';
import { formatDateTime } from 'src/utils';
import { formatCurrency } from 'src/utils/format';
import { IndividualCareReactionStatus } from './constants';
import styles from './reactions.module.scss';

const AcceptDialog = ({ onConfirm, onCancel, agreedRate }) => {
  const { t } = useTranslation('page_individualCare');
  const [note, setNote] = useState('');
  return (
    <IconDialog
      className={styles.acceptDialog}
      labelQuestion={t(
        'reactions.reviewForm.areYouSureToSelectThisHealthCareProvider',
        'Weet je zeker dat je deze zorgverlener wilt selecteren?'
      )}
      onConfirm={() => onConfirm({ agreedRate, note })}
      onCancel={onCancel}
    >
      <div className={styles.stretch}>
        <Form stretch>
          <FormRow>
            <LabeledFormInputView
              label={t('reactions.reviewForm.hourlyRate', 'Uurtarief')}
              value={agreedRate}
              isCurrency={true}
            />
          </FormRow>
          <FormRow>
            <LabeledFormInput
              className={styles.fullWidth}
              label={t('reactions.reviewForm.explanationTowardsCaregiver', 'Toelichting richting zorgverlener')}
              component={InputTextArea}
              value={note}
              onChange={setNote}
            />
          </FormRow>
        </Form>
      </div>
    </IconDialog>
  );
};

const RejectDialog = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation('page_individualCare');
  const [note, setNote] = useState('');

  return (
    <IconDialog
      className={styles.acceptDialog}
      labelQuestion={t(
        'reactions.reviewForm.areYouSureToRejectThisHealthCareProvider',
        'Weet je zeker dat je deze zorgverlener wilt afwijzen?'
      )}
      onConfirm={() => onConfirm({ note })}
      onCancel={onCancel}
      invertAction
    >
      <div className={styles.stretch}>
        <Form stretch>
          <FormRow>
            <LabeledFormInput
              className={styles.fullWidth}
              label={t('reactions.reviewForm.explanationTowardsCaregiver', 'Toelichting richting zorgverlener')}
              component={InputTextArea}
              value={note}
              onChange={setNote}
            />
          </FormRow>
        </Form>
      </div>
    </IconDialog>
  );
};

const ReviewForm = ({ reaction, onChange }) => {
  const { t } = useTranslation(['page_individualCare', 'common']);
  const { hasRole } = useUserInformation();
  const { api } = useGateway();

  const [dialogData, setDialogData] = useState(null);
  const [processing, setProcessing] = useState(false); // loading, saving...
  const agreedRate = reaction.suggestedRate;

  const saveReactionResponse = useCallback(
    async ({ accepted, note, agreedRate }) => {
      setProcessing(true);
      try {
        const data = await api.individualCare.respondToIndividualCareReaction({
          reactionId: reaction.id,
          accepted,
          note,
          agreedRate,
        });
        onChange(data);
      } catch (err) {
        console.error('Error responding to reaction:', err);
      } finally {
        setProcessing(false);
      }
    },
    [api, onChange, reaction.id]
  );

  const saveInteresting = useCallback(
    async (interesting) => {
      setProcessing(true);
      try {
        const data = await api.individualCare.markIndividualCareReactionAsInteresting(reaction.id, {
          value: interesting,
        });
        console.info('Updated successfully', data);
      } catch (err) {
        console.error('Error marking reaction:', err);
      } finally {
        setProcessing(false);
      }
    },
    [api, reaction.id]
  );

  const toggleInteresting = () => {
    const nextValue = !reaction.interesting;
    saveInteresting(nextValue);
    onChange({
      ...reaction,
      interesting: nextValue,
    });
  };

  const showDialog = (component, onConfirm, agreedRate) =>
    setDialogData({
      component,
      onConfirm,
      onCancel: () => setDialogData(),
      agreedRate,
    });

  const showAcceptDialog = () => {
    showDialog(
      AcceptDialog,
      ({ agreedRate, note }) => saveReactionResponse({ accepted: true, agreedRate, note }),
      agreedRate
    );
  };

  const showRejectDialog = () => {
    showDialog(RejectDialog, ({ note }) => saveReactionResponse({ accepted: false, agreedRate: -1, note }));
  };

  const isCareConsultant = hasRole('CareConsultant');
  const canAcceptOrDenyCaregiver = isCareConsultant && [IndividualCareReactionStatus.Active].includes(reaction.status);
  return (
    <>
      {dialogData && <dialogData.component {...dialogData} />}
      <Form>
        <FormRow>
          <LabeledFormInputView
            label={t('reactions.reviewForm.phoneNumber', 'Telefoonnummer')}
            value={
              <span className={styles.iconLink}>
                <Phone />
                <a href={`tel:${reaction.caregiver.phoneNumber}`} className="phone">
                  {reaction.caregiver.phoneNumber}
                </a>
              </span>
            }
          />
          <LabeledFormInputView
            label={t('reactions.reviewForm.status', 'Status')}
            value={t(`constants.individualCareReactionStatusLabels.${reaction.status}`)}
          />
        </FormRow>
        <FormRow>
          <LabeledFormInputView
            label={t('reactions.reviewForm.email', 'Email')}
            value={
              <span className={styles.iconLink}>
                <Mail />
                <a href={`mailto:${reaction.caregiver.email}`} className="phone">
                  {reaction.caregiver.email}
                </a>
              </span>
            }
          />
        </FormRow>
        <FormRow>
          <LabeledFormInputView
            label={t('reactions.reviewForm.explanationCaregiver', 'Toelichting zorgverlener')}
            value={reaction.description}
          />
          <LabeledFormInputView
            label={t('reactions.reviewForm.hourlyRate', 'Uurtarief')}
            value={formatCurrency(reaction.suggestedRate)}
          />
        </FormRow>
        <FormRow>
          <LabeledFormInput
            label={t('reactions.reviewForm.interesting', 'Interessant')}
            component={InputCheckbox}
            value={reaction.interesting}
            onChange={toggleInteresting}
          />
          <FormSpacer />
        </FormRow>
        <FormRow>
          <LabeledFormInputView
            label={t('reactions.reviewForm.createdAt', 'Aangemaakt op')}
            value={formatDateTime(reaction.createdAt, { format: t('common:dateTimeFormat') })}
          />
          <LabeledFormInputView
            label={t('reactions.reviewForm.updatedOn', 'Laatst aangepast op')}
            value={formatDateTime(reaction.updatedAt, { format: t('common:dateTimeFormat') })}
          />
        </FormRow>

        {canAcceptOrDenyCaregiver && (
          <FormActions>
            <Button disabled={processing} invert onClick={() => showRejectDialog()}>
              {t('reactions.reviewForm.reject', 'Afwijzen')}
            </Button>
            <Button disabled={processing} onClick={() => showAcceptDialog()}>
              {t('reactions.reviewForm.select', 'Selecteren')}
            </Button>
          </FormActions>
        )}
      </Form>
    </>
  );
};

export default ReviewForm;
