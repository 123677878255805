import _ from 'lodash';
import moment from 'moment';
import { useMemo, useRef, useState } from 'react';
import { Award, Briefcase, Calendar, Clock, Coffee, Edit, User, Users } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Button, { IconButton } from 'src/components/button/button';
import { SelectCaregiver } from 'src/components/select';
import { emptyArray, emptyObject } from 'src/constants';
import { useCaregiversByName, useCaregiversForBooking } from 'src/hooks/caregivers';
import { formatBookingTimeInfoFromStartAndEnd, formatCurrency, formatDate } from 'src/utils';
import { draftBookingEventToNewBooking } from '../booking';
import styles from './selectCaregiversDialog.module.scss';

const createCaregiverOption = (caregiver, booking) => {
  // TODO: use booking start or end date to check for expiration? Backend uses end date, which is more strict.

  const hasKnownExpirationDate = Boolean(caregiver.expirationDate);
  const isExpiredToday = hasKnownExpirationDate && moment().isAfter(caregiver.expirationDate, 'day');
  const isExpiredAtBookingDate =
    hasKnownExpirationDate && moment(booking?.bookingDate).isAfter(caregiver.expirationDate, 'day');

  return {
    value: caregiver.id,
    label: `${caregiver.firstName} ${caregiver.lastName}`,

    ...caregiver,

    isExpiredToday,
    isExpiredAtBookingDate,

    // TODO: change this when we have an updated workflow for allowing/disallowing booking caregivers with future expiration dates
    disabled: isExpiredToday,
  };
};

const ListHeader = () => {
  const { t } = useTranslation('page_planning', { keyPrefix: 'dialogs.selectCaregiversDialog' });
  return (
    <li className={styles.header}>
      <div>
        <Calendar />
        <span>{t('date')}</span>
      </div>
      <div>
        <Clock />
        <span>{t('time')}</span>
      </div>
      <div>
        <Coffee />
        <span>{t('unpaidBreak')}</span>
      </div>
      <div>
        <Users />
        <span>{t('team')}</span>
      </div>
      <div>
        <Briefcase />
        <span>{t('sector')}</span>
      </div>
      <div>
        <Award />
        <span>{t('education')}</span>
      </div>
      <div>
        <span>{t('actions')}</span>
      </div>
      <div>
        <User />
        <span>{t('caregiver')}</span>
      </div>
    </li>
  );
};

const caregiverKeys = ['caregiverId', 'caregiverId', 'caregiver'];
const omitCaregiver = (booking) => _.omit(booking, caregiverKeys);

const ListItem = ({ draft, careSectorOptions, educationOptions, teamOptions, onEdit, onSelectCaregiver }) => {
  const { t } = useTranslation(['page_planning', 'common']);
  const {
    start,
    end,
    booking: { teamId, careSectorId, degree, caregiverId, caregiver, unbillableHours },
  } = draft;

  const careSectorLabel = useMemo(
    () => _.find(careSectorOptions, { value: careSectorId })?.label,
    [careSectorId, careSectorOptions]
  );
  const educationLabel = useMemo(() => _.find(educationOptions, { value: degree })?.label, [degree, educationOptions]);
  const teamLabel = useMemo(() => _.find(teamOptions, { value: teamId })?.label, [teamId, teamOptions]);

  // Keep a private cached version of the booking without the caregiver. This is required because we need to compare
  // the previous version with the next version using `_.isEqual` (equal content) as opposed to React's default usage
  // of `Object.is` (exactly the same object).
  const bookingWithoutCaregiverCached = useRef();
  const bookingWithoutCaregiver = useMemo(() => {
    const next = omitCaregiver(draftBookingEventToNewBooking(draft));
    if (!_.isEqual(bookingWithoutCaregiverCached.current, next)) {
      bookingWithoutCaregiverCached.current = next;
    }
    return bookingWithoutCaregiverCached.current;
  }, [draft]);

  const { isLoading: caregiversLoading, data: { caregivers: caregiversByBooking = emptyArray } = emptyObject } =
    useCaregiversForBooking(bookingWithoutCaregiver);

  const caregiverByBookingOptions = useMemo(
    () => caregiversByBooking.map((cg) => createCaregiverOption(cg, bookingWithoutCaregiver)),
    [bookingWithoutCaregiver, caregiversByBooking]
  );

  const [searchQuery, setSearchQuery] = useState('');
  const searchParams = useMemo(
    () => ({
      searchName: searchQuery,
      districtTeamId: teamId,
      requiredDegree: degree,
      requiredSectorId: careSectorId,
    }),
    [careSectorId, degree, teamId, searchQuery]
  );

  const {
    isFetching: isSearching,
    isFetched: isSearched,
    data: { caregivers: caregiversByName = emptyArray } = emptyObject,
  } = useCaregiversByName(searchParams);

  const caregiverByNameOptions = useMemo(
    () => caregiversByName.map((cg) => createCaregiverOption(cg, bookingWithoutCaregiver)),
    [bookingWithoutCaregiver, caregiversByName]
  );

  const isLoading = caregiversLoading || isSearching;

  return (
    <li className={styles.row}>
      <div>{formatDate(start, { format: t('common:dateFormat') })}</div>
      <div>{formatBookingTimeInfoFromStartAndEnd(start, end)}</div>
      <div>{unbillableHours > 0 ? <>{Math.round(unbillableHours * 60)} min</> : '-'}</div>
      <div>{teamLabel}</div>
      <div>{careSectorLabel}</div>
      <div>{educationLabel}</div>
      <div>
        <IconButton
          small
          secondary
          icon={<Edit />}
          title={t('dialogs.selectCaregiversDialog.changeBooking', 'Boeking aanpassen')}
          onClick={onEdit}
        />
      </div>
      <SelectCaregiver
        onChange={onSelectCaregiver}
        onSearch={setSearchQuery}
        caregiverByBookingOptions={caregiverByBookingOptions}
        caregiverByNameOptions={caregiverByNameOptions}
        districtTeamId={teamId}
        caregiverId={caregiverId}
        caregiver={caregiver}
        isLoading={isLoading}
        noResults={isSearched && caregiversByName.length === 0}
        placeholder={t('dialogs.selectCaregiversDialog.chooseHealthCareProvider', 'Kies een zorgverlener')}
        // Automatically select an appropriate caregiver, but delay this until the caregivers are done loading
        preSelect={!caregiversLoading}
      />
    </li>
  );
};

const Footer = ({ drafts, onConfirm, onCancel }) => {
  const { t } = useTranslation('page_planning');
  const { [true]: bookingsWithCaregiver = [], [false]: bookingsWithoutCaregiver = [] } = _.groupBy(drafts, (draft) =>
    Boolean(draft.booking.caregiverId)
  );
  const totalAmount = bookingsWithCaregiver.reduce((sum, draft) => sum + draft.amount, 0);

  return (
    <div className={styles.footer}>
      <div className={styles.summary}>
        {bookingsWithoutCaregiver.length === 0 ? (
          <>
            {t('dialogs.selectCaregiversDialog.totalBookings', 'Totaal aan boekingen')}: {formatCurrency(totalAmount)}
          </>
        ) : (
          <>
            {t('dialogs.selectCaregiversDialog.stillOneBookingWithoutCaregiver', {
              defaultValue: 'Er zijn nog {{count}} boekingen zonder zorgverlener',
              count: bookingsWithoutCaregiver.length,
            })}
          </>
        )}
      </div>
      <Button secondary onClick={onCancel}>
        {t('dialogs.selectCaregiversDialog.backToCalendar', 'Terug naar kalender')}
      </Button>
      <Button onClick={onConfirm} disabled={bookingsWithoutCaregiver.length !== 0}>
        {t('dialogs.selectCaregiversDialog.booking', 'Boeking afronden')}
      </Button>
    </div>
  );
};

const SelectCaregiversDialog = ({
  drafts,
  onEdit,
  onChange,
  onConfirm,
  onCancel,
  teamOptions,
  careSectorOptions,
  educationOptions,
}) => {
  const { t } = useTranslation('page_planning');
  return (
    <div className={styles.container}>
      <h1>{t('dialogs.selectCaregiversDialog.linkCaregivers', 'Zorgverleners koppelen')}</h1>

      <div className={styles.list}>
        <ListHeader />
        {drafts.map((draft) => (
          <ListItem
            key={draft.meta.draftId}
            draft={draft}
            teamOptions={teamOptions}
            educationOptions={educationOptions}
            careSectorOptions={careSectorOptions}
            onEdit={() => {
              onEdit(draft);
            }}
            onSelectCaregiver={(caregiverId, caregiver) => {
              onChange(
                drafts.map((draft_) =>
                  draft_ === draft
                    ? {
                        ...draft_,
                        booking: { ...draft_.booking, caregiverId, caregiver },
                        amount: (draft_.booking.duration - draft_.booking.unbillableHours) * caregiver.hourlyRate,
                      }
                    : draft_
                )
              );
            }}
          />
        ))}
      </div>

      <Footer drafts={drafts} onConfirm={onConfirm} onCancel={onCancel} />
    </div>
  );
};

export default SelectCaregiversDialog;
