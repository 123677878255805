import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useGateway } from 'src/providers/gateway';

interface IDocument {
  type: string;
  url?: string;
  blobData?: string;
}

export function useDocumentsValidation(documents: IDocument[]) {
  const errors: [[number, string], string][] = [];
  const warnings: [[number, string], string][] = [];

  documents.forEach((doc, i) => {
    if (!doc.url) {
      // new document should have a valid type and blob data
      if (_.isEmpty(doc.type.trim())) {
        errors.push([[i, 'type'], 'required']);
      }
      if (_.isEmpty(doc.blobData)) {
        errors.push([[i, 'blobData'], 'required']);
      }
    }
  });

  return {
    errors,
    warnings,
    valid: errors.length === 0,
  };
}

export function useAgreementDetails(id: string) {
  const { api } = useGateway();

  return useQuery({
    queryKey: ['postingAgreement', { id }] as [string, { id: string }],
    queryFn: ({ queryKey: [, { id }] }) => api.documents.getAgreementDetails(id),
  });
}
