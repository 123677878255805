import clsx from 'clsx';
import _ from 'lodash';
import { useMemo } from 'react';
import { Phone, Sunrise, Sunset } from 'react-feather';
import { useTranslation } from 'react-i18next';
import AddressView from 'src/components/address/view';
import { Form, FormRow } from 'src/components/form/form';
import { LabeledFormInputView } from 'src/components/form/labeled_form_input';
import Avatar from 'src/components/user/avatar';
import { useUserInformation } from 'src/hooks/users';
import { formatTime } from 'src/utils';
import { extractTeamAddress } from 'src/utils/address';
import ActionsMenu from './actionsMenu';
import styles from './teams.module.scss';

const UsersList = ({ accounts }) => (
  <div className={styles.usersContainer}>
    <div className={styles.users}>
      {accounts.length > 0
        ? accounts.map((account, index) =>
            account ? (
              <Avatar
                key={account.value}
                src={account.data?.profilePictureUrl}
                title={account.label}
                alt={account.label}
                border
              />
            ) : (
              <Avatar key={index} title="???" />
            )
          )
        : '-'}
    </div>
  </div>
);

const ViewTeam = ({ team = {}, careSectors = [], selectableAccounts = [], selectableCompanies = [] }) => {
  const { t } = useTranslation('page_teams');
  const { hasRole } = useUserInformation();

  const isAdmin = hasRole('Admin');
  const isCompanyAdmin = hasRole('CompanyAdmin');
  const isPlanner = hasRole('Planner');
  const canEdit = isAdmin || isCompanyAdmin || isPlanner;

  const address = useMemo(() => extractTeamAddress(team), [team]);

  const bindModel = (label, key, props = {}) => (
    <LabeledFormInputView label={label} value={_.defaultTo(_.get(team, key), '-')} {...props} />
  );

  const company = useMemo(
    () => _.find(selectableCompanies, { value: team.companyId }),
    [team.companyId, selectableCompanies]
  );
  const careSector = useMemo(() => _.find(careSectors, { value: team.careSectorId }), [team.careSectorId, careSectors]);
  const planners = useMemo(
    () => (team.plannerIds || []).map((id) => _.find(selectableAccounts, { value: id })),
    [team.plannerIds, selectableAccounts]
  );
  const approvers = useMemo(
    () => (team.approverIds || []).map((id) => _.find(selectableAccounts, { value: id })),
    [team.approverIds, selectableAccounts]
  );

  return (
    <Form compact className={styles.view}>
      <FormRow className={styles.title}>
        <div>
          <h2>{team.name}</h2>
          {
            // TODO: currently only admins can select teams, so regular planners never see the company name.
            // In case planners are connected to multiple companies (the backend supports this!) we should maybe display
            // the company name for everyone. This will require a change in the backend because we currently don't have
            // that data available in the frontend though.
            selectableCompanies.length > 0 && (
              <div className={styles.teamSubtitle}>{company ? company.label : '...'}</div>
            )
          }
        </div>
        <div>{canEdit && <ActionsMenu team={team} />}</div>
      </FormRow>
      <FormRow>
        <AddressView address={address} mapWidth={176} mapHeight={120} className={styles.address} />
      </FormRow>
      <FormRow>
        <LabeledFormInputView
          className={styles.phoneNumber}
          label={t('view.phoneNumber', 'Telefoonnummer')}
          value={
            team.phoneNumber ? (
              <div>
                <Phone />
                <a className={clsx('phone', styles.phoneNumber)} href={`tel:${team.phoneNumber}`}>
                  {team.phoneNumber}
                </a>
              </div>
            ) : (
              <div title={t('view.phoneNumberNotFilledIn', 'Telefoonnummer niet ingevuld')}>
                <Phone />
                <div>-</div>
              </div>
            )
          }
        />
      </FormRow>
      <FormRow>
        <LabeledFormInputView label={t('view.sector', 'Sector')} value={careSector && careSector.label} />
        {bindModel(t('view.typeOfCare', 'Zorgtype'), 'typeOfCare')}
      </FormRow>
      <FormRow>
        {bindModel(t('view.costCentre', 'Kostenplaats'), 'costCentre')}
        {bindModel(t('view.taxNumber', 'BTW-nummer'), 'taxNumber')}
      </FormRow>
      <FormRow>{bindModel(t('view.generalInformation', 'Algemene informatie'), 'generalInformation')}</FormRow>
      <FormRow>
        <LabeledFormInputView
          className={styles.shift}
          label={t('view.earlyShift', 'Vroege dienst')}
          value={
            <>
              <Sunrise />
              <div>
                {team.morningBeginTime && team.morningEndTime ? (
                  <>
                    <span>{formatTime(team.morningBeginTime)}</span> - <span>{formatTime(team.morningEndTime)}</span>
                  </>
                ) : (
                  '-'
                )}
              </div>
            </>
          }
        />
        <LabeledFormInputView
          className={styles.shift}
          label={t('view.lateShift', 'Late dienst')}
          value={
            <>
              <Sunset />
              <div>
                {team.eveningBeginTime && team.eveningEndTime ? (
                  <>
                    <span>{formatTime(team.eveningBeginTime)}</span> - <span>{formatTime(team.eveningEndTime)}</span>
                  </>
                ) : (
                  '-'
                )}
              </div>
            </>
          }
        />
      </FormRow>

      <FormRow>
        <LabeledFormInputView label={t('view.planners', 'Planners')} value={<UsersList accounts={planners} />} />
      </FormRow>
      <FormRow>
        <LabeledFormInputView label={t('view.approvers', 'Accordeerders')} value={<UsersList accounts={approvers} />} />
      </FormRow>
    </Form>
  );
};

export default ViewTeam;
