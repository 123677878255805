import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider, PersistQueryClientProviderProps } from '@tanstack/react-query-persist-client';
import ms from 'ms';
import { useEffect } from 'react';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      staleTime: ms('5m'),

      // Garbage collection time, in milliseconds.
      // This value should be equal to or higher than the `maxAge` (default: 24h) option used by the persister.
      // See: https://tanstack.com/query/v5/docs/react/plugins/persistQueryClient
      gcTime: ms('24h'),
    },
  },
});

const persister = createSyncStoragePersister({
  key: 'reactQueryStorage',

  // LocalStorage persists between browser restarts, and is shared with all windows and tabs.
  storage: window.localStorage,

  // SessionStorage persists *only* for the tab session. It is not shared with any other tabs.
  // storage: window.sessionStorage,
});

type QueryProviderProps = Omit<PersistQueryClientProviderProps, 'client' | 'persistOptions'> & {
  enableDevTools?: boolean;
  enableLogger?: boolean;
  loggerOptions?: QueryLoggerProps;
};

export const QueryClientProvider: React.FC<QueryProviderProps> = ({
  enableDevTools = false,
  enableLogger = false,
  loggerOptions = {},
  children,
  ...props
}) => (
  <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }} {...props}>
    {children}
    {enableDevTools && <ReactQueryDevtools initialIsOpen={false} />}
    {enableLogger && <QueryLogger {...loggerOptions} />}
  </PersistQueryClientProvider>
);

// TODO: replace with actual imported type once https://github.com/TanStack/query/issues/5599 is resolved
type QueryCacheNotifyEvent = object;

interface QueryLoggerProps {
  filter?: (event: QueryCacheNotifyEvent) => boolean;
}

/**
 * Component to enable query cache event logging. For debugging only.
 */
export const QueryLogger: React.FC<QueryLoggerProps> = ({ filter }) => {
  const client = useQueryClient();
  const cache = client.getQueryCache();

  useEffect(
    () =>
      cache.subscribe((e) => {
        if (filter?.(e) ?? true) {
          console.log('[QueryCache]', e);
        }
      }),
    [cache, filter]
  );

  return null;
};
