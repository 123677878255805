import { useTranslation } from 'react-i18next';
import TabContainer from 'src/components/tabs/tabs';
import DirectBookingsList from '../direct/directBookingsList';
import DefaultPlanner from './defaultPlanner';
import styles from './index.module.scss';
import PostingPlanner from './postingPlanner';

const Planning = () => {
  const { t } = useTranslation('page_planning');

  return (
    <TabContainer
      className={styles.tabContainer}
      tabs={[
        {
          path: 'bookings/*',
          label: t('index.planner', 'Planner'),
          component: DefaultPlanner,
        },
        { path: 'postings/*', label: t('index.longTerm', 'Langdurig'), component: PostingPlanner },
        { path: 'direct/*', label: t('index.direct', 'Direct'), component: DirectBookingsList },
      ]}
      defaultPath="bookings"
    />
  );
};

export default Planning;
