import moment from 'moment';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Dialog from 'src/components/dialog/dialog';
import InputNumber from 'src/components/input_number/input_number';
import InputTextArea from 'src/components/input_textarea/input_textarea';
import { formatDateTime, formatDuration } from 'src/utils';
import styles from './reject_hours_dialog.module.scss';

const RejectHoursDialog = ({
  caregiver,
  startDateTime,
  endDateTime,
  duration,
  unbillableHours,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation(['page_companyAdmin', 'common']);
  const durationMoment = moment.duration(duration, 'hours');

  const [reason, setReason] = useState('');
  const [hours, setHours] = useState(durationMoment.hours());
  const [minutes, setMinutes] = useState(durationMoment.minutes());
  const [unbillableMinutes, setUnbillableMinutes] = useState(
    unbillableHours > 0 ? Math.round(unbillableHours * 60) : 0
  );

  return (
    <Dialog
      onConfirm={() => {
        onConfirm({
          duration: hours + minutes / 60,
          unbillableHours: unbillableMinutes / 60,
          reason,
        });
      }}
      onCancel={onCancel}
      invertAction
      labelQuestion={
        <>
          <p>
            <Trans t={t} i18nKey="rejectHoursDialog.labelQuestion.rejectHours">
              Je staat op het punt om de gewerkte uren van{' '}
              <b>
                {{ firstName: caregiver.firstName }} {{ lastName: caregiver.lastName }}
              </b>{' '}
              van <b>{{ startDateTime: formatDateTime(startDateTime, { format: t('common:dateTimeFormat') }) }}</b> tot{' '}
              <b>{{ endDateTime: formatDateTime(endDateTime, { format: t('common:dateTimeFormat') }) }}</b> met een
              duratie van{' '}
              <b>
                {{ durationHours: formatDuration(moment.duration(duration, 'hours')) }}{' '}
                {{ hours: t('common:hour', { count: duration }) }}
              </b>{' '}
              af te keuren.
            </Trans>
          </p>
          {Boolean(unbillableHours) && (
            <p>
              <Trans t={t} i18nKey="rejectHoursDialog.labelQuestion.unpaidBreak">
                Hier zat een pauze bij inbegrepen van <b>{{ unbillableMinutes: unbillableHours * 60 }}</b>{' '}
                {{ minutes: t('common:minute', { count: unbillableHours * 60 }) }}.
              </Trans>
            </p>
          )}
          <p>
            <Trans t={t} i18nKey="rejectHoursDialog.labelQuestion.reason">
              Geef hiervoor een reden op en stel een nieuwe duur voor.
            </Trans>
          </p>
        </>
      }
      labelCancel={t('rejectHoursDialog.labelCancel', 'Annuleren')}
      labelConfirm={t('rejectHoursDialog.labelConfirm', 'Afkeuren')}
      disabledConfirm={reason === ''}
      className={styles.container}
    >
      <div className={styles.formInput}>
        <label>{t('rejectHoursDialog.reason', 'Reden')}</label>
        <InputTextArea
          onChange={setReason}
          placeholder={t('rejectHoursDialog.reasonForDisapproval', 'Geef hier je reden voor afkeuren op...')}
        />
      </div>
      <div className="header">
        <b>{t('rejectHoursDialog.suggestedTotalDuration', 'Voorgestelde totale duur')}</b>
      </div>
      <div className="horizontal">
        <div className={styles.formInput}>
          <label>{t('rejectHoursDialog.hours', 'Uren')}</label>
          <InputNumber onChange={setHours} value={hours} step={1} />
        </div>
        <div className={styles.formInput}>
          <label>{t('rejectHoursDialog.minutes', 'Minuten')}</label>
          <InputNumber onChange={setMinutes} value={minutes} step={1} />
        </div>
      </div>
      <div className="header">
        <b>{t('rejectHoursDialog.suggestedBreak', 'Voorgestelde pauze')}</b>
      </div>
      <div className="horizontal">
        <div className={styles.formInput}>
          <label>{t('rejectHoursDialog.minutes', 'Minuten')}</label>
          <InputNumber onChange={setUnbillableMinutes} value={unbillableMinutes} min={0} step={1} />
        </div>
      </div>
    </Dialog>
  );
};
export default RejectHoursDialog;
