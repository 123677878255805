import { buildEnum, buildOptions } from 'src/utils';

// Ordered IndividualCareReactionStatus enum values
const IndividualCareReactionStatusValues = ['New', 'Active', 'Pending', 'Rejected', 'CancelledByCaregiver', 'Accepted'];

/**
 * IndividualCareReactionStatus labels
 *
 * @type {Object.<string, string>}
 */
export const IndividualCareReactionStatusLabels = (t) =>
  t('constants.individualCareReactionStatusLabels', { returnObjects: true });

/**
 * IndividualCareReactionStatusShortLabels short labels
 *
 * @type {Object.<string, string>}
 */
export const IndividualCareReactionStatusShortLabels = (t) =>
  t('constants.individualCareReactionStatusShortLabels', { returnObjects: true });

/**
 * IndividualCareReactionStatus enum
 *
 * @type {Object.<string, string>}
 * @property New
 * @property Active
 * @property Pending
 * @property Rejected
 * @property CancelledByCaregiver
 * @property Accepted
 */
export const IndividualCareReactionStatus = buildEnum(IndividualCareReactionStatusValues);

/**
 * IndividualCareReactionStatus select options
 *
 * @type {Array.<Object>}
 * @property New
 * @property Active
 * @property Pending
 * @property Rejected
 * @property CancelledByCaregiver
 * @property Accepted
 */
export const IndividualCareReactionStatusOptions = (t) =>
  buildOptions(IndividualCareReactionStatusValues, IndividualCareReactionStatusLabels(t));

export const IndividualCareReactionStatusEditable = {
  careConsultant: [
    IndividualCareReactionStatus.Active,
    IndividualCareReactionStatus.Accepted,
    IndividualCareReactionStatus.Rejected,
  ],
  admin: [
    IndividualCareReactionStatus.Active,
    IndividualCareReactionStatus.Accepted,
    IndividualCareReactionStatus.Rejected,
  ],
};
