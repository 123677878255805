import moment from 'moment';

// HTML / serialization formats.
// NB: using full date and full time formats so the values are accepted by both HTML and our REST APIs.
export const dateHtmlFormat = 'YYYY-MM-DD';
export const timeHtmlFormat = 'HH:mm:ss';
// Format for own timeInput component.
// TODO Remove when timeInput is a third party component
export const shortTimeHtmlFormat = 'HH:mm';
export const dateTimeHtmlFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';

// Display formats (short)
export const dateDisplayFormat = 'DD-MM-YYYY';
export const timeDisplayFormat = 'HH:mm';
export const dateTimeDisplayFormat = `${dateDisplayFormat} [om] ${timeDisplayFormat}`;
export const nextDayTimeDisplayFormat = '[(]DD MMM[)] HH:mm';

// Display formats (long)
export const dateLongDisplayFormat = 'DD MMMM YYYY';
export const dateTimeLongDisplayFormat = `${dateLongDisplayFormat} [om] ${timeDisplayFormat}`;

// Non-breaking space
const nbsp = String.fromCharCode(160);

const internalFormatDuration = (hours, minutes, padHours) =>
  `${padHours && hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;

/**
 * Formats a moment duration as H:MM or HH:MM (if `padHours` is `true`).
 * Note that there is no limit in the number of supported hours.
 *
 * @param {moment.Duration|number} duration
 * @param {boolean?} padHours
 */
export const formatDuration = (duration, padHours = false) =>
  Number.isFinite(duration)
    ? internalFormatDuration(Math.trunc(duration), Math.trunc(60 * duration) % 60, padHours)
    : internalFormatDuration(Math.trunc(duration.asHours()), Math.trunc(duration.asMinutes()) % 60, padHours);

/**
 * Formats a (moment) date into a string with format "DD-MM-YYYY"
 *
 * @param {moment.MomentInput} dateTime
 */
export const formatDate = (dateTime, { format = dateDisplayFormat } = {}) => moment(dateTime).format(format);

export const formatDateHtml = (dateTime, options = {}) => formatDate(dateTime, { ...options, format: dateHtmlFormat });

/**
 * Formats a (moment) datetime into a string with format "DD-MM-YYYY [om] HH:mm"
 *
 * @param {moment.MomentInput} dateTime
 */
export const formatDateTime = (dateTime, { format = dateTimeDisplayFormat } = {}) => moment(dateTime).format(format);

export const formatDateTimeHtml = (dateTime, options = {}) =>
  formatDate(dateTime, { ...options, format: dateTimeHtmlFormat });

/**
 * Formats a (moment) time into a string with format HH:mm
 *
 * @param {moment.MomentInput} time
 */
export const formatTime = (time, { format = timeDisplayFormat } = {}) =>
  moment(time, [dateTimeHtmlFormat, 'HH:mm:ss.fff', 'HH:mm:ss', 'HH:mm', format]).format(format);

export const formatTimeHtml = (time, options = {}) => formatTime(time, { ...options, format: timeHtmlFormat });

/**
 * Formats a number using the specified amount of decimals.
 *
 * @param {number} n
 * @param {number} decimals
 * @returns
 */
export const formatNumber = (n, decimals = 2) => (typeof n === 'number' ? n.toFixed(decimals) : n);

/**
 * Formats a currency using the specified amount of decimals and the specified currency symbol.
 *
 * @param {number} n
 * @param {number} decimals
 * @param {string} symbol
 * @returns
 */
export const formatCurrency = (n, decimals = 2, symbol = '€') => `${symbol}${nbsp}${formatNumber(n, decimals)}`;

/**
 * Formats booking time information, using start moment and end moment
 *
 * @param {moment} startMoment
 * @param {moment} endMoment
 * @returns
 */
const internalFormatBookingTimeInfo = (startMoment, endMoment) =>
  `${startMoment.format(timeDisplayFormat)} - ${endMoment.format(
    endMoment.isSame(startMoment, 'day') ? timeDisplayFormat : nextDayTimeDisplayFormat
  )}`;

/**
 * Formats booking time information, using start date/time and end date/time
 *
 * @param {moment.MomentInput} start
 * @param {moment.MomentInput} end
 */
export const formatBookingTimeInfoFromStartAndEnd = (start, end) =>
  internalFormatBookingTimeInfo(moment(start), moment(end));

/**
 * Formats booking time information, using start date/time and duration (in hours)
 *
 * @param {moment.MomentInput} start
 * @param {number} duration
 */

export const formatBookingTimeInfoFromStartAndDuration = (start, duration) =>
  internalFormatBookingTimeInfo(moment(start), moment(start).add(duration, 'hours'));
