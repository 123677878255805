import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LoadingOverlay from 'src/components/loader/loader';
import { useIndividualCareReaction } from 'src/hooks/individualCare';
import IndividualCareReactionForm from './form';
import styles from './reactions.module.scss';

const EditIndividualCareReaction = ({ onCloseForm }) => {
  const { t } = useTranslation('page_individualCare');
  const { reactionId } = useParams();
  const reaction = useIndividualCareReaction(reactionId);

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <h1>{t('reactions.edit.editComment', 'Reactie bewerken')}</h1>
        {reaction.isFetched ? (
          <IndividualCareReactionForm reaction={reaction.data} onClose={onCloseForm} />
        ) : (
          <LoadingOverlay />
        )}
      </div>
    </div>
  );
};

export default EditIndividualCareReaction;
