import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';
import EditCareReaction from './edit';
import ListCareReactions from './list';
import ViewCareReaction from './view';

const CareReactions = ({ role, careRequest, reactions }) => {
  const { t } = useTranslation('page_individualCare');
  const navigate = useNavigate();

  const isAdmin = role === 'Admin';
  const isCareConsultant = role === 'CareConsultant';
  const isCaregiver = role === 'Caregiver';

  return (
    <Routes>
      {(isCareConsultant || isAdmin) && <Route index element={<ListCareReactions careRequest={careRequest} />} />}
      {isCaregiver && (
        <>
          <Route
            index
            element={
              <ViewCareReaction
                careRequest={careRequest.data}
                reactions={reactions}
                placeholder={t('reactions.index.noResponseYet', 'Je hebt nog niet gereageerd')}
              />
            }
          />
          <Route path=":reactionId/edit" element={<EditCareReaction onCloseForm={() => navigate('')} />} />
        </>
      )}
    </Routes>
  );
};

export default CareReactions;
