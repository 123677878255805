import { ChevronRight, Edit } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import Avatar from 'src/components/user/avatar';
import { formatDate } from 'src/utils/format';
import { ScreeningFilterPreset } from './constants';
import styles from './screening.module.scss';
import ScreeningStatusLabel from './screeningStatusLabel';

const ScreeningList = ({ screeningList, setOpenedOnboardingProfileId, getOnboardingOfCaregiver }) => {
  const { t } = useTranslation(['page_admin', 'common']);
  return (
    <ul className={styles.screeningList}>
      <li className={styles.headerRow}>
        <div>{t('screening.screening.caregiver', 'Zorgverlener')}</div>
        <div>{t('screening.screening.created', 'Aangemaakt')}</div>
        <div>{t('screening.screening.lastModified', 'Laatst aangepast')}</div>
        <div></div>
      </li>
      {screeningList &&
        (screeningList.length > 0 ? (
          screeningList.map((screeningItem) => (
            <li key={screeningItem.id} className={styles.screeningRow}>
              <div className={styles.wrapper}>
                <div className={styles.person}>
                  <Avatar src={screeningItem.profilePictureUrl} />
                  {screeningItem.firstName} {screeningItem.lastName}
                </div>
                <ScreeningStatusLabel status={screeningItem.status} />
              </div>
              <div className={styles.date}>
                {formatDate(screeningItem.createdAt, { format: t('common:dateFormat') })}
              </div>
              <div className={styles.date}>
                {formatDate(screeningItem.updatedAt, { format: t('common:dateFormat') })}
              </div>
              <div className={styles.action}>
                <Button
                  secondary
                  onClick={() => {
                    setOpenedOnboardingProfileId(screeningItem.id);
                    getOnboardingOfCaregiver(screeningItem.id);
                  }}
                >
                  {screeningItem.status === ScreeningFilterPreset.ReadyForScreening ? <Edit /> : <ChevronRight />}
                </Button>
              </div>
            </li>
          ))
        ) : (
          <li className={`${styles.screeningRow} ${styles.emptyRow}`}>
            <p>
              {t(
                'screening.screening.noProfileFound',
                'Geen profielen gevonden die aan de huidige selectiecriteria voldoen.'
              )}
            </p>
          </li>
        ))}
    </ul>
  );
};

export default ScreeningList;
