import styles from './loader_bar.module.scss';

const LoaderBar = (props) => {
  return (
    <div className={styles.container} style={props.style}>
      <div className={styles.indicator}></div>
    </div>
  );
};

export default LoaderBar;
