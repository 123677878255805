import _ from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddressForm from 'src/components/address/form';
import { useAddressValidation, validateAddress } from 'src/components/address/util';
import Button from 'src/components/button/button';
import { YesNoDialog } from 'src/components/dialog/dialog';
import DocumentsForm from 'src/components/documents/form';
import { Form, FormActions, FormRow } from 'src/components/form/form';
import LabeledFormInput from 'src/components/form/labeled_form_input';
import Information from 'src/components/information';
import { InputTime } from 'src/components/input_datetime';
import { InputPhone } from 'src/components/input_number';
import InputTax from 'src/components/input_tax/input_tax';
import InputText from 'src/components/input_text';
import InputTextArea from 'src/components/input_textarea';
import LoadingOverlay from 'src/components/loader/loader';
import Select from 'src/components/select/select';
import { useUserInformation } from 'src/hooks/users';
import { useGateway } from 'src/providers/gateway';
import { extractTeamAddress } from 'src/utils/address';
import { emptyTeam } from './constants';
import styles from './teams.module.scss';

const useTeamModelValidation = (model, isAdmin) => {
  const requiredTeamFields = ['name', isAdmin && 'companyId', 'careSectorId'].filter(Boolean);

  const errors = [];
  const warnings = [];

  requiredTeamFields.forEach((key) => {
    if (_.isEmpty(model[key])) {
      errors.push([key, 'required']);
    }
  });

  return {
    errors,
    warnings,
    valid: errors.length === 0,
  };
};

const TeamForm = ({
  team: initialTeam = emptyTeam,
  careSectors,
  selectableCompanies,
  selectableAccounts,
  onSave,
  onCancel,
}) => {
  const { t } = useTranslation('page_teams');
  const { hasRole } = useUserInformation();
  const { api } = useGateway();

  const [processing, setProcessing] = useState(false);

  const [team, setTeam] = useState(initialTeam);
  const teamAddress = extractTeamAddress(team);

  const isAdmin = hasRole('Admin');
  const isCompanyAdmin = hasRole('CompanyAdmin');
  const canEditVitalDetails = isAdmin || isCompanyAdmin;

  const teamValidation = useTeamModelValidation(team || {}, isAdmin);
  const addressValidation = useAddressValidation(teamAddress);
  const valid = teamValidation.valid && addressValidation.valid;

  const saveButtonInfo = [
    !teamValidation.valid && t('form.mandatoryTeamDataIncomplete', 'Verplichte teamgegevens incompleet.'),
    !addressValidation.valid && t('form.addressDataIncomplete', 'Adresgegevens incompleet.'),
  ]
    .filter(Boolean)
    .join(' ');

  const [showConfirmCancel, setShowConfirmCancel] = useState(false);

  const updateModel = (key) => (value) =>
    setTeam((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  const updateAddress = (address) => {
    setTeam((prevState) => ({ ...prevState, ...address }));

    // Update location
    debouncedUpdateLocation(address);
  };

  const updateLocation = useCallback(
    async (address) => {
      const { valid } = validateAddress(address);

      if (valid) {
        try {
          const newAddress = await api.universal.geocode(address);
          setTeam((prevState) => ({ ...prevState, latitude: newAddress.lat, longitude: newAddress.lng }));
        } catch (err) {
          setTeam((prevState) => ({ ...prevState, latitude: null, longitude: null }));
        }
      } else {
        setTeam((prevState) => ({ ...prevState, latitude: null, longitude: null }));
      }
    },
    [api]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateLocation = useCallback(_.debounce(updateLocation, 1000), [updateLocation]);

  const bindModel = (label, key, defaultValue, component, props = {}) => (
    <LabeledFormInput
      component={component}
      label={label}
      value={_.defaultTo(_.get(team, key), defaultValue)}
      onChange={updateModel(key)}
      {...props}
    />
  );

  const save = async () => {
    setProcessing(true);

    try {
      const teamId = await api.districtTeams.saveDistrictTeam({
        districtTeamMapped: {
          ...team,
          // Flatten new file upload data
          documents: (team.documents || []).map(({ blobData = {}, ...doc }) => ({
            ...doc,
            ...blobData,
          })),
        },
      });

      updateModel({ id: teamId });

      onSave();
    } catch (err) {
      console.error('Error saving team:', err);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Form compact className={styles.form}>
        <FormRow>
          <h5>
            {t('form.generalTeamInformation', 'Algemene team-informatie')}{' '}
            {!teamValidation.valid && (
              <Information className={styles.errorInfo} error>
                {t('form.completeTheMissingData', 'Vul de ontbrekende gegevens aan...')}
              </Information>
            )}
          </h5>
        </FormRow>

        <FormRow>
          {bindModel(t('form.teamname', 'Teamnaam'), 'name', '', InputText, { readOnly: !canEditVitalDetails })}
        </FormRow>
        {isAdmin && (
          <FormRow>
            {bindModel(t('form.careOrganisation', 'Zorgorganisatie'), 'companyId', null, Select, {
              options: selectableCompanies,
            })}
          </FormRow>
        )}

        <FormRow>
          {bindModel(t('form.healthCareSector', 'Zorgsector'), 'careSectorId', null, Select, {
            options: careSectors || [],
            readOnly: !canEditVitalDetails,
          })}
          {bindModel(t('form.typeOfCare', 'Zorgtype'), 'typeOfCare', '', InputText, { readOnly: !canEditVitalDetails })}
        </FormRow>
        <FormRow>
          {bindModel(t('form.costCentre', 'Kostenplaats'), 'costCentre', '', InputText, {
            readOnly: !canEditVitalDetails,
          })}
          {bindModel(t('form.taxNumber', 'BTW-nummer'), 'taxNumber', '', InputTax, { readOnly: !canEditVitalDetails })}
        </FormRow>

        <FormRow>
          <h5>
            {t('form.addressAndContactDetails', 'Adres- en contactgegevens')}{' '}
            {!addressValidation.valid ? (
              <Information className={styles.errorInfo} error>
                {t('form.addressDetailsIncomplete', 'Adresgegevens incompleet...')}
              </Information>
            ) : (
              addressValidation.warnings.length > 0 && (
                <Information className={styles.errorInfo} error>
                  {t(
                    'form.somethingsWrongWithTheAddress',
                    'We konden geen kaartlocatie vinden voor het opgegeven adres, weet je zeker dat het adres correct is ingevuld?'
                  )}
                </Information>
              )
            )}
          </h5>
        </FormRow>

        <FormRow>
          <AddressForm compact address={teamAddress} onChange={updateAddress} />
        </FormRow>
        <FormRow>{bindModel(t('form.phoneNumber', 'Telefoonnummer'), 'phoneNumber', '', InputPhone)}</FormRow>

        <FormRow>
          <h5>{t('form.extra', 'Extra')}</h5>
        </FormRow>

        <FormRow>
          {bindModel(t('form.generalInformation', 'Algemene informatie'), 'generalInformation', '', InputTextArea)}
        </FormRow>
        {team.documents && (
          <DocumentsForm
            label={t('form.additionalDocuments', 'Aanvullende documenten')}
            documents={team.documents}
            onChange={updateModel('documents')}
            showActiveToggle
          />
        )}

        <FormRow>
          <h5>{t('form.services', 'Diensten')}</h5>
        </FormRow>

        <FormRow>
          {bindModel(t('form.earlyShiftFrom', 'Vroege dienst van'), 'morningBeginTime', '', InputTime)}
          {bindModel(t('form.to', 'tot'), 'morningEndTime', '', InputTime)}
          {bindModel(t('form.lateShiftFrom', 'Late dienst van'), 'eveningBeginTime', '', InputTime)}
          {bindModel(t('form.to', 'tot'), 'eveningEndTime', '', InputTime)}
        </FormRow>

        <FormRow>
          {bindModel(t('form.planners', 'Planners'), 'plannerIds', [], Select, {
            options: selectableAccounts,
            multiple: true,
            readOnly: !canEditVitalDetails,
          })}
        </FormRow>
        <FormRow>
          {bindModel(t('form.approvers', 'Accordeerders'), 'approverIds', [], Select, {
            options: selectableAccounts,
            multiple: true,
            readOnly: !canEditVitalDetails,
          })}
        </FormRow>

        <FormActions>
          <Button secondary onClick={() => setShowConfirmCancel(true)}>
            {t('form.cancel', 'Annuleren')}
          </Button>
          <Button disabled={!valid} onClick={save} title={saveButtonInfo}>
            {t('form.save', 'Opslaan')}
          </Button>
        </FormActions>

        {showConfirmCancel && (
          <YesNoDialog
            onConfirm={onCancel}
            onCancel={() => setShowConfirmCancel(false)}
            labelQuestion={t(
              'form.areYouSureChangesNotSavedDialogQuestion',
              'Weet je het zeker? Eventuele wijzigingen worden niet opgeslagen.'
            )}
          />
        )}
      </Form>

      {processing && <LoadingOverlay />}
    </>
  );
};

export default TeamForm;
