import clsx from 'clsx';
import _ from 'lodash';
import styles from './input_textarea.module.scss';

export const TextAreaField = ({
  className,
  valid = null,
  onChange = _.noop,
  onBlur = _.noop,
  onValidate = _.noop,
  getValue = (event) => event.target.value,
  ...props
}) => (
  <textarea
    type="text" // TODO: this shouldn't be required, but is currently used by the input @mixin in scss
    className={clsx(styles.input, className)}
    onChange={(event) => {
      const value = getValue(event);
      onValidate(value);
      onChange(value);
    }}
    onBlur={(event) => {
      const value = getValue(event);
      onValidate(value);
      onBlur(value);
    }}
    data-error={valid === false || null}
    {...props}
  />
);

export const InputTextArea = ({ rows = 4, ...props }) => <TextAreaField rows={rows} {...props} />;

export default InputTextArea;
