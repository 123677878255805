import { useTimeoutFn } from 'react-use';

interface NavigateExternallyProps {
  to: string;
  replace?: boolean;
  delay?: number;
  placeholder?: React.ReactElement;
}

const NavigateExternally: React.FC<NavigateExternallyProps> = ({
  to,
  replace = false,
  delay = 2000,
  placeholder = null,
}) => {
  useTimeoutFn(() => {
    if (replace) {
      window.location.replace(to);
    } else {
      window.location.assign(to);
    }
  }, delay);

  return placeholder;
};

export default NavigateExternally;
