import { useTranslation } from 'react-i18next';
import { Dialog } from 'src/components/dialog/dialog';
import BookingForm from './bookingForm';

const DirectBookingDialog = (props) => {
  const { t } = useTranslation('page_planning');
  return (
    <Dialog
      showActions={false}
      labelQuestion={t(
        props.booking?.id ? 'directBookingDialog.editDirectRequest' : 'directBookingDialog.createDirectRequest'
      )}
      onCancel={props.onCancel}
    >
      <BookingForm {...props} />
    </Dialog>
  );
};

export default DirectBookingDialog;
