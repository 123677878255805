import { useTranslation } from 'react-i18next';
import TabContainer from 'src/components/tabs/tabs';
import Screening from './screening';
import styles from './screening.module.scss';

const ScreeningTabs = () => {
  const { t } = useTranslation('page_admin');

  return (
    <TabContainer
      tabs={[
        {
          path: 'actionRequired',
          label: t('screening.screeningTabs.actionRequired', 'Actie vereist'),
          component: () => <Screening actionRequired />,
        },
        {
          path: 'all',
          label: t('screening.screeningTabs.allProfiles', 'Alle profielen'),
          component: () => <Screening />,
        },
      ]}
      defaultPath={'actionRequired'}
      className={styles.container}
    />
  );
};

export default ScreeningTabs;
