const countryCodeToCountry = {
  nl: 'The Netherlands',
  be: 'Belgium',
  de: 'Germany',
};

export const getCountryFromCode = (countryCode) => {
  const country = countryCodeToCountry[countryCode?.toLowerCase()];
  if (country) {
    return country;
  } else {
    throw new Error(`Unsupported country code: ${countryCode}`);
  }
};

export const extractCaregiverAddress = (personalia) => ({
  street: personalia.street,
  houseNumber: personalia.houseNumber,
  extension: personalia.extension,
  postcode: personalia.postcode,
  city: personalia.city,
  countryCode: personalia.countryCode,
  // TODO: `lat` and `lng` aren't actual model properties, but we can use them for display purposes. Fix this when the Company model is extended.
  lat: personalia.lat ?? 0,
  lng: personalia.lng ?? 0,
});

export const extractCompanyAddress = (company) => ({
  street: company.billingStreet,
  houseNumber: company.billingHouseNumber,
  extension: company.billingExtension,
  postcode: company.billingPostcode,
  city: company.billingCity,
  countryCode: company.billingCountryCode,
  // TODO: `lat` and `lng` aren't actual model properties, but we can use them for display purposes. Fix this when the Company model is extended.
  lat: company.lat ?? 0,
  lng: company.lng ?? 0,
});

// TODO: remove this when the backend uses proper addresses
export const extractTeamAddress = (team) => ({
  street: team.street,
  houseNumber: team.houseNumber,
  extension: team.extension,
  postcode: team.postcode,
  city: team.city,
  countryCode: team.countryCode,
  lat: team.latitude ?? 0,
  lng: team.longitude ?? 0,
});
