import clsx from 'clsx';
import { buildUrl } from 'src/utils';
import styles from './map.module.scss';

// Google static maps: https://developers.google.com/maps/gmp-get-started
// Google Static Map API requires billing to be set up. We don't have that currently for our
// developer@tallkingconnect.com account, and we should actually use Azure whenever possible.
export const buildGoogleStaticMapUrl = ({ lat, lng, width, height, scale }) =>
  buildUrl('https://maps.googleapis.com/maps/api/staticmap', {
    center: `${lat},${lng}`,
    zoom: 13,
    size: `${width * scale}x${height * scale}`,
    mapType: 'roadmap',
    markers: `color:red&label|label:X|${lat},${lng}`,
    key: process.env.REACT_APP_GOOGLE_MAPS_APIKEY,
  });

// Azure maps: https://docs.microsoft.com/en-us/rest/api/maps/render/getmapimage
export const buildAzureStaticMapUrl = ({ lat, lng, width, height, scale }) =>
  buildUrl('https://atlas.microsoft.com/map/static/png', {
    'subscription-key': process.env.REACT_APP_AZURE_MAPS_API_KEY,
    'api-version': '1.0',
    layer: 'basic', // options: basic, hybrid, labels
    style: 'main', // options: main, dark
    zoom: 11,
    center: `${lng},${lat}`,
    width: width * scale,
    height: height * scale,
    language: 'nl',
    pins: `default||${lng} ${lat}`,
  });

// Bing maps (interactive): https://docs.microsoft.com/en-us/bingmaps/articles/create-a-custom-map-url
export const buildBingMapUrl = ({ lat, lng, pinTitle, pinNotes, showTraffic = false }) =>
  buildUrl('https://bing.com/maps/default.aspx', {
    style: 'r',
    lvl: 16,
    cp: `${lat}~${lng}`,
    // rtp: `pos.${lat}_${lng}~mode=D`,
    sp: `point.${lat}_${lng}_${encodeURIComponent(pinTitle)}_${encodeURIComponent(pinNotes)}`,
    trfc: showTraffic,
  });

const roadPath =
  'M106.303 144.254L67.728 85.707-9.359 50.735M-22.83 211.083l77.715-11.073 21.271-5.51 30.148-50.246 26.128-9.045 31.656-38.69-24.621-46.73V9.595L117.86-16.032m14.571 151.242l58.789 19.596 15.074 71.35 73.862 40.198M54.884 200.01l-24.258 65.388';
export const PlaceholderImage = () => (
  <div className={styles.placeholder}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <path fill="#f2f2f2" d="M0 0h256v256H0z" />
      <g fill="none">
        <path d={roadPath} stroke="#ececec" strokeWidth="16" />
        <path d={roadPath} stroke="#fff" strokeWidth="12" />
      </g>
    </svg>
  </div>
);

const StaticMap = ({ buildMapUrl, lat, lng, width, height, scale = 1.5, alt = '', className, ...props }) => (
  <div className={clsx(styles.container, className)} style={{ width, height }}>
    {lat && lng ? (
      <img src={buildMapUrl({ lat, lng, width, height, scale })} alt={alt} className={styles.map} {...props} />
    ) : (
      <PlaceholderImage />
    )}
  </div>
);

export const AzureStaticMap = (props) => StaticMap({ buildMapUrl: buildAzureStaticMapUrl, ...props });
export const GoogleStaticMap = (props) => StaticMap({ buildMapUrl: buildGoogleStaticMapUrl, ...props });

export default AzureStaticMap;
