import clsx from 'clsx';
import { generatePath, Navigate, NavLink, Route, Routes, useParams } from 'react-router-dom';
import styles from './tabs.module.scss';

const TabBar = ({ type = 'button', tabs }) => {
  const params = useParams();

  return (
    <ul className={clsx(styles.tabBar, styles[type])}>
      {tabs.map(({ index = false, path, label }) => (
        <NavLink
          key={path ?? 'index'}
          to={generatePath(path?.replace(/\/\*$/, '') ?? '', params)}
          params={params}
          end={index}
          className={({ isActive }) => clsx({ [styles.active]: isActive })}
        >
          {label}
        </NavLink>
      ))}
    </ul>
  );
};

const TabContent = ({ tabs, defaultPath }) => (
  <div className={styles.tabContent}>
    <Routes>
      {tabs.map(({ index = false, path, label, component: Component, ...props }) => (
        <Route key={path ?? 'index'} index={index} path={path} element={<Component {...props} />} />
      ))}
      {defaultPath && <Route path="*" element={<Navigate to={defaultPath} />} />}
    </Routes>
  </div>
);

const TabContainer = ({ type = 'button', tabs, defaultPath, className }) => (
  <div className={clsx(styles.container, className)}>
    <TabBar tabs={tabs} type={type} />
    <TabContent tabs={tabs} defaultPath={defaultPath} />
  </div>
);

export default TabContainer;
