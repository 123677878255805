import { useTranslation } from 'react-i18next';
import styles from './verdict_documents_list.module.scss';
import VerdictDocumentItem from './verdict_document_item';

const VerdictDocumentList = ({
  documents = [],
  screeningId,
  readyForScreening,
  onSaveScreeningDocumentVerdict,
  onUpdate,
  screeningCompleted,
}) => {
  const { t } = useTranslation('components');
  return (
    <ul className={styles.documentsList}>
      <li className={styles.documentsListHeader}>
        <div>{t('view', 'Inzien')}</div>
        <div>{t('documentType', 'Documenttype')}</div>
        <div>{t('validUntil', 'Geldig tot')}</div>
        <div>{t('inspectPostponeReplace', 'Keuren, uitstel of vervangen')}</div>
      </li>

      {documents.map((doc) => (
        <VerdictDocumentItem
          key={doc.id}
          document={doc}
          screeningId={screeningId}
          readyForScreening={readyForScreening}
          onSaveScreeningDocumentVerdict={onSaveScreeningDocumentVerdict}
          onUpdate={onUpdate}
          screeningCompleted={screeningCompleted}
        />
      ))}
    </ul>
  );
};

export default VerdictDocumentList;
