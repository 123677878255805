import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import ms from 'ms';
import { useCallback } from 'react';
import { useGateway } from 'src/providers/gateway';

export function useUserInformation() {
  const { api } = useGateway();

  const query = useQuery({
    queryKey: ['userInformation'] as [string],
    queryFn: api.users.getUserInformation,
    staleTime: ms('1h'),
  });

  const hasRole = useCallback(
    (role?: string): boolean => {
      if (!query.data?.roles) {
        // Roles not loaded? Always false.
        return false;
      } else if (!role) {
        // No specific role? True once roles loaded.
        return true;
      } else {
        // Specific role? Check whether the user has this role.
        return query.data.roles.includes(role);
      }
    },
    [query.data?.roles]
  );

  return {
    ...query,
    hasRole,
  };
}

export function useOnboarding(opts?: Partial<UseQueryOptions> | object) {
  const { api } = useGateway();

  return useQuery({
    queryKey: ['onboarding'] as [string],
    queryFn: api.onboardingScreenings.getOnboardingScreeningInformation,
    staleTime: ms('5m'),
    ...(opts ?? {}),
  });
}
