import clsx from 'clsx';
import _ from 'lodash';
import { useState } from 'react';
import { Eye, EyeOff } from 'react-feather';
import { useTranslation } from 'react-i18next';
import styles from './input_password.module.scss';

const InputPassword = ({ className, show = false, onChange, onBlur = _.noop, onValidate = _.noop, ...props }) => {
  const { t } = useTranslation('components');
  const rules = [
    {
      name: 'length',
      descr: t('ruleLength', '8 karakters'),
      test: (v) => /(?=.{8})/.test(v),
    },
    {
      name: 'capital',
      descr: t('ruleCapital', '1 hoofdletter'),
      test: (v) => /(?=[A-Z]{1})/.test(v),
    },
    {
      name: 'digit',
      descr: t('ruleDigit', '1 cijfer'),
      test: (v) => /(?=[0-9]{1})/.test(v),
    },
    {
      name: 'special',
      descr: t('ruleSpecial', '1 speciaal karakter'),
      test: (v) => /(?=[^a-zA-Z0-9]{1})/.test(v),
    },
  ];
  const [privatePass, setPrivatePass] = useState(true);
  const [strength, setStrength] = useState(
    rules.reduce((state, rule) => {
      state[rule.name] = null;
      return state;
    }, {})
  );
  const [valid, setValid] = useState(null);

  const checkPasswordStrength = (event) => {
    const value = event.target.value;
    const strength = rules.reduce((state, rule) => {
      state[rule.name] = rule.test(value);
      return state;
    }, {});

    // Password is valid if all requirements are met (i.e., the above values are all true)
    const valid = Object.values(strength).every(Boolean);

    setStrength(strength);
    setValid(valid);

    switch (event.type) {
      case 'change':
        onChange(value);
        break;

      case 'blur':
        onBlur(value);
        break;

      default:
    }

    onValidate(valid);
  };

  return (
    <div className={styles.privatePass}>
      <input
        type={privatePass ? 'password' : 'text'}
        className={clsx(styles.container, className)}
        onChange={checkPasswordStrength}
        onBlur={checkPasswordStrength}
        data-error={valid === false || null}
        {...props}
      />
      {show && (
        <div className={styles.eye} onClick={() => setPrivatePass(!privatePass)}>
          {privatePass ? <Eye /> : <EyeOff />}
        </div>
      )}
      <div className={styles.strength}>
        <span>{t('minimum', 'Minimaal')}:</span>
        {rules.map((rule) => (
          <span key={rule.name} data-strength={strength[rule.name]}>
            {rule.descr}
          </span>
        ))}
      </div>
    </div>
  );
};

export default InputPassword;
