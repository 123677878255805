import clsx from 'clsx';
import styles from './chevron.module.scss';

type Direction = 'up' | 'down' | 'left' | 'right';

const validDirections: Direction[] = ['up', 'down', 'left', 'right'];

interface ChevronProps {
  disabled?: boolean;
  className?: string | string[];
  dir?: Direction;
}

const Chevron: React.FC<ChevronProps> = ({ disabled, className, dir = 'up', ...props }) => (
  <div
    className={clsx(
      styles.chevronIcon,
      validDirections.includes(dir) && styles[dir],
      disabled && styles.disabled,
      className
    )}
    {...props}
  />
);

export default Chevron;
