/**
 * Reusable empty object. Use this instead of `{}` to prevent React hooks from seeing a new object on every render,
 * which can cause depency checks to fail, which can in turn cause an infinite loop, potentially crashing the app.
 *
 * @example
 * // Don't do this
 * const { foo = {} } = props; // will trigger the next effect on every render due to {} being a new object everytime
 *
 * // Do this instead
 * const { foo = emptyObject } = props;
 *
 * useEffect(() => { ... }, [foo]); // hook with `foo` as a dependency
 */
export const emptyObject = Object.freeze({});

/**
 * Reusable empty array. Use this instead of `[]` to prevent React hooks from seeing a new array on every render,
 * which can cause depency checks to fail, which can in turn cause an infinite loop, potentially crashing the app.
 *
 * @example
 * // Don't do this
 * const { bar = [] } = props; // will trigger the next effect on every render due to [] being a new array everytime
 *
 * // Do this instead
 * const { bar = emptyArray } = props;
 *
 * useEffect(() => { ... }, [bar]); // hook with `bar` as a dependency
 */
export const emptyArray = Object.freeze([]);
