import { Trans, useTranslation } from 'react-i18next';
import A from 'src/components/a';

const ScreeningComplete = () => {
  const { t } = useTranslation('page_onboarding');

  return (
    <div style={{ textAlign: 'center' }}>
      <Trans t={t} i18nKey="screeningComplete">
        <h1>Gefeliciteerd!</h1>
        <p>Je hebt je onboarding afgerond!</p>
        <p>Vanaf nu kun je verder aan de slag in onze TallKing Connect app!</p>
        <p>
          Je kunt de app downloaden voor <A href={process.env.REACT_APP_CAREGIVER_APP_APPLE_APP_STORE}>iOS</A> of{' '}
          <A href={process.env.REACT_APP_CAREGIVER_APP_GOOGLE_PLAY_STORE}>Android</A>.
        </p>
      </Trans>
    </div>
  );
};

export default ScreeningComplete;
