import { BookingStatus, BookingType } from '@tallkingconnect/gateway';
import clsx from 'clsx';
import styles from './bookingLabel.module.scss';

const BookingStatusLabelStyles = {
  [BookingStatus.Created]: styles.created,
  [BookingStatus.Accepted]: styles.accepted,
  [BookingStatus.Rejected]: styles.rejected,
  [BookingStatus.CancelledByCaregiver]: styles.cancelledByCaregiver,
  [BookingStatus.CancelledByPlanner]: styles.cancelledByPlanner,
  [BookingStatus.CancelledByPlannerCloseToDeadline]: styles.cancelledByPlannerCloseToDeadline,
};

const BookingTypeStyles = {
  [BookingType.Regular]: null, //styles.regular,
  [BookingType.Direct]: styles.direct,
  [BookingType.Posting]: null, //styles.posting,
  [BookingType.Correction]: null, //styles.correction,
};

interface BookingLabelProps extends React.PropsWithChildren {
  status?: BookingStatus;
  type?: BookingType;
}

const BookingLabel: React.FC<BookingLabelProps> = ({ status, type, children }) => {
  return (
    <div className={clsx(status && BookingStatusLabelStyles[status], type && BookingTypeStyles[type])}>{children}</div>
  );
};

export default BookingLabel;
