import InputNumber from './input_number';
import styles from './input_number.module.scss';

const InputCurrency = ({ currency = '€', ...props }) => (
  <div className={styles.currency}>
    <InputNumber {...props} />
    <span>{currency}</span>
  </div>
);

export default InputCurrency;
