import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Button from 'src/components/button/button';
import { useAuth0 } from 'src/providers/auth0';

const Login = () => {
  const { loginWithPopup } = useAuth0();
  const { t } = useTranslation('page_login');

  return (
    <div data-login={true}>
      <Button onClick={loginWithPopup} style={{ display: 'block', margin: '0 auto', marginBottom: '1.5rem' }}>
        {t('login', 'Inloggen')}
      </Button>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Trans t={t} i18nKey="register">
          Geen account nog?{' '}
          <NavLink to="/register" end style={{ marginLeft: '.25rem' }}>
            Meld je aan!
          </NavLink>
        </Trans>
      </div>
    </div>
  );
};

export default Login;
