import clsx from 'clsx';
import _ from 'lodash';
import styles from './input_text.module.scss';

// Base input field
export const InputField = ({
  placeholder,
  type = 'text',
  // value,
  className,
  valid = null,
  onChange = _.noop,
  onBlur = _.noop,
  onValidate = _.noop,
  getValue = (event) => event.target.value,
  ...props
}) => (
  <input
    placeholder={placeholder}
    type={type}
    className={clsx(styles.input, className)}
    onChange={(event) => {
      const value = getValue(event);
      onValidate(value);
      onChange(value);
    }}
    onBlur={(event) => {
      const value = getValue(event);
      onValidate(value);
      onBlur(value);
    }}
    data-error={valid === false || null}
    // Forcibly setting a value breaks components that aren't yet fully controlled, disabled for now
    // value={_.isNil(value) ? '' : value}
    {...props}
  />
);

export const TextField = (props) => InputField({ type: 'text', ...props });
export const PasswordField = (props) => InputField({ type: 'password', ...props });
export const SearchField = (props) => InputField({ type: 'search', ...props });

export const NumberField = (props) =>
  InputField({
    type: 'number',
    getValue: (event) => {
      const value = event.target.value;
      return _.isNil(value) ? value : Number(value);
    },
    ...props,
  });

export const CheckboxField = ({ value, ...props }) =>
  InputField({
    type: 'checkbox',
    value: 'on',
    checked: value,
    getValue: (event) => event.target.checked,
    ...props,
  });

export const EmailField = (props) => InputField({ type: 'email', ...props });
export const PhoneField = (props) => InputField({ type: 'tel', ...props });

// export const DateField = (props) => InputField({ type: 'date', ...props });
// export const TimeField = (props) => InputField({ type: 'time', ...props });
// export const DateTimeField = (props) => InputField({ type: 'datetime-local', ...props });
