/*
 * Note: this file is mainly needed because the type definitions in @types/react-big-calendar are a manual effort to
 * provide typings for react-big-calendar. Some of the types are overly restrictive, especially with custom components
 * and views in mind. For example, RBC allows creating custom views, which would require custom navigation events to
 * properly support them. RBC is ignorant about extra values, however, the types restrict those to *only* the values
 * which exist in the current RBC source code. We *do* need custom events, e.g. to handle manual refresh gracefully.
 * Additionally, this requires extra messages, which again are restricted to the currently existing keys in the type
 * declaratation, instead of just providing a known set (the currently existing keys) and an open list of any keys
 * which could be added. Also, some of the types are plain wrong.
 *
 * This file is an attempt to at least provide *some* compatibility. It is by no means complete, which would require
 * way too much effort. Maybe when RBC starts providing their own type definitions, this could be removed. Until then,
 * let's simply ignore ts warnings selectively for those cases which are hard to solve.
 */

import {
  Navigate as BigNavigate,
  type CalendarProps as BigCalendarProps,
  type Components as BigComponents,
  type DateLocalizer as BigDateLocalizer,
  type Event as BigEvent,
  type Messages as BigMessages,
  type NavigateAction as BigNavigateAction,
  type ToolbarProps as BigToolbarProps,
  type View as BigView,
} from 'react-big-calendar';

export type Event = BigEvent & {
  tooltip?: string;
};

export type Resource = {
  id?: string;
  title?: string;
};

export interface Messages extends BigMessages {
  refresh?: string | undefined;
}

export type DateLocalizer = BigDateLocalizer & {
  messages?: Messages;
};

export type NavigateAction = BigNavigateAction | 'REFRESH';

export const Navigate: Record<string, NavigateAction> = {
  ...BigNavigate,
  REFRESH: 'REFRESH',
};

export interface ToolbarProps extends Omit<BigToolbarProps<Event, Resource>, 'localizer' | 'onNavigate'> {
  localizer: {
    messages: Messages;
  };
  onNavigate: (action: NavigateAction) => void;
}

export interface Components extends Omit<BigComponents<Event, Resource>, 'toolbar'> {
  toolbar?: React.FC<ToolbarProps>;
}

export interface CalendarProps
  extends Omit<BigCalendarProps<Event, Resource>, 'components' | 'localizer' | 'messages'> {
  components: Components;
  localizer?: DateLocalizer;
  messages: Messages;
}

export type EventAccessors = {
  allDayAccessor?: CalendarProps['allDayAccessor'];
  startAccessor?: CalendarProps['startAccessor'];
  endAccessor?: CalendarProps['endAccessor'];
  titleAccessor?: CalendarProps['titleAccessor'];
  tooltipAccessor?: CalendarProps['tooltipAccessor'];
  resourceAccessor?: CalendarProps['resourceAccessor'];
};

export type ResourceAccessors = {
  resourceIdAccessor?: CalendarProps['resourceIdAccessor'];
  resourceTitleAccessor?: CalendarProps['resourceTitleAccessor'];
};

export type View = BigView;
