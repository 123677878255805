import { Flag, Key, LogOut } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Flags from 'src/config/flags';
import { useUserInformation } from 'src/hooks/users';
import { useAuth0 } from 'src/providers/auth0';
import DropDown from '../dropdown/dropdown';
import SelectLocale from '../select/select_locale';
import Avatar from './avatar';
import styles from './user.module.scss';

const User: React.FC = () => {
  const { t } = useTranslation('components');
  const { logout, user: auth0User } = useAuth0();
  const { data: platformUser } = useUserInformation();

  return (
    <div className={styles.container}>
      <DropDown>
        <Avatar src={platformUser?.profilePictureUrl} alt={t('profile', 'Profile')} title={auth0User?.name} />

        <ul>
          {auth0User && (
            <li className={styles.userName}>
              <span>{auth0User?.name}</span>
            </li>
          )}

          {Flags.enableI18N && (
            <li data-has-icon>
              <Flag />
              <SelectLocale />
            </li>
          )}

          {auth0User && (
            <>
              <li data-has-icon>
                <Key />
                <Link to="/reset-password">{t('changePassword', 'Wachtwoord veranderen')}</Link>
              </li>
              <li data-has-icon>
                <LogOut />
                <Link to="/" onClick={() => logout?.()}>
                  {t('logout', 'Logout')}
                </Link>
              </li>
            </>
          )}
        </ul>
      </DropDown>
    </div>
  );
};

export default User;
