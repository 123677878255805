import clsx from 'clsx';
import { useState } from 'react';
import { Bell, Info, X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { emptyArray, emptyObject } from 'src/constants';
import useNotifications from 'src/hooks/useNotifications';
import { useGateway } from 'src/providers/gateway';
import { Button } from '../button/button';
import { Loader } from '../loader/loader';
import { VisibleNotificationStatusValues } from './constants';
import styles from './notifications.module.scss';

const Notifications = ({ title, toggleOpen, open }) => {
  const { t } = useTranslation('components');
  const { api } = useGateway();
  const navigate = useNavigate();
  const {
    data: { notifications = emptyArray } = emptyObject,
    isLoading,
    error,
    refetch,
  } = useNotifications(VisibleNotificationStatusValues);

  const [showActions, setShowActions] = useState(false);
  const toggleActions = () => setShowActions(!showActions);

  // TODO: don't rely on title, we need proper constants for this, or at least something that isn't language dependent
  const isCancelledBooking = (n) => n.title === t('cancelledBooking', 'Geannuleerde boeking.');

  const dismissNotifications = async () => {
    try {
      await api.notifications.dismissNotifications({ value: notifications.map((notification) => notification.id) });

      refetch();
    } catch (err) {
      console.error('Error dismissing notifications:', err);
    }
  };

  const dismissNotification = async (notification) => {
    const updatedNotification = {
      notificationId: notification.id,
      dismissedAt: new Date(),
    };

    try {
      await api.notifications.updateNotification(updatedNotification);

      refetch();
    } catch (err) {
      console.error('Error updating notifications:', err);
    }
  };

  const redirectToDirectPage = () => {
    navigate('/planning/direct');
    toggleOpen();
  };

  const redirectToPlannerPage = () => {
    navigate('/planning');
    toggleOpen();
  };

  return (
    <div className={clsx(styles.container, open && styles.open)}>
      <div className={styles.overlay} onClick={toggleOpen}></div>

      <div className={styles.notificationIcon} onClick={toggleOpen}>
        <Bell className={styles.bellIcon} />
        <div className={styles.count}>
          <span>{error ? '!' : notifications && notifications.length}</span>
        </div>
      </div>

      <div>
        <ul className={styles.list}>
          <div className={styles.navigationHeader}>
            <h3 className={styles.title}>{title}</h3>
            {notifications && notifications.length > 0 && (
              <span className={styles.clear} onClick={dismissNotifications}>
                {t('empty', 'Leegmaken')}
              </span>
            )}
          </div>

          {notifications &&
            notifications.map((notification) => (
              <li key={notification.id} className={styles.notificationListItem}>
                {/* TODO: Conditional icons, waiting to see based on what */}
                <Info className={styles.infoIcon} />
                <div className={styles.wrapper}>
                  <p className={styles.title}>{notification.title}</p>
                  <p className={styles.text}>{notification.body}</p>
                  {isCancelledBooking(notification) && (
                    <>
                      <span className={styles.actionsToggle} onClick={toggleActions}>
                        {t('actions', 'Acties')}
                      </span>

                      {showActions && (
                        <div className={styles.actions}>
                          <Button extraSmall onClick={redirectToDirectPage}>
                            {t('directRequest', 'Direct-verzoek')}
                          </Button>
                          <Button extraSmall invert onClick={redirectToPlannerPage}>
                            {t('findCaregivers', 'Zoek zorgverleners')}
                          </Button>
                        </div>
                      )}
                    </>
                  )}

                  <X className={styles.closeIcon} onClick={() => dismissNotification(notification)} />
                </div>
              </li>
            ))}

          {isLoading ? (
            <Loader className={styles.loader} />
          ) : error ? (
            <li className={styles.error}>
              <p>{t('notificationsError', 'Notificaties konden niet worden geladen...')}</p>
            </li>
          ) : (
            notifications &&
            notifications.length === 0 && (
              <li className={styles.empty}>
                <p>{t('noNotifications', 'Momenteel zijn er geen notificaties...')}</p>
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
};

export default Notifications;
