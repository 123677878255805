import { useState } from 'react';
import { Trash2 } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import { IconButton } from 'src/components/button/button';
import A from '../a';
import { YesNoDialog } from '../dialog/dialog';
import { ScreeningDocumentStatus } from '../screening/constants';
import styles from './show_document.module.scss';

const ShowDocument = (props) => {
  const { t } = useTranslation('components');
  const [dialogVisible, setDialogVisible] = useState(false);

  return (
    <>
      <div className={styles.container}>
        <h4>{props.uploadedDocument.screeningDocType}</h4>
        <div>
          <A href={props.uploadedDocument.url}>{props.uploadedDocument.title}</A>
          <IconButton
            secondary
            onClick={() => {
              setDialogVisible(true);
            }}
            icon={<Trash2 />}
          />
        </div>
        {props.uploadedDocument.status === ScreeningDocumentStatus.Rejected &&
          props.uploadedDocument.reasonForRejection && (
            <div className={styles.reasonForRejection}>
              <label>{t('reasonForRejection', 'Reden voor weigeren')}</label>
              <span>{props.uploadedDocument.reasonForRejection}</span>
            </div>
          )}
      </div>
      {dialogVisible && (
        <YesNoDialog
          onCancel={() => setDialogVisible(false)}
          onConfirm={() => {
            props.deleteScreeningDocument(props.uploadedDocument.id);
          }}
          labelQuestion={
            <Trans t={t} i18nKey="areYouSureDeleteDocumentDialogQuestion">
              Weet je zeker dat je het document <b>{{ screeningDocType: props.uploadedDocument.screeningDocType }}</b>{' '}
              met titel <b>{{ title: props.uploadedDocument.title }}</b> wilt verwijderen?
            </Trans>
          }
        />
      )}
    </>
  );
};

export default ShowDocument;
