import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useParams } from 'react-router-dom';
import LoadingOverlay from 'src/components/loader/loader';
import TabContainer from 'src/components/tabs/tabs';
import { useIndividualCareRequest } from 'src/hooks/individualCare';
import { useUserInformation } from 'src/hooks/users';
import CareReactions from '../reactions';
import AddIndicationRequest from './add';
import EditIndicationRequest from './edit';
import ListIndicationRequests from './list';
import ViewIndicationRequest from './view';

const IndicationRequestsWithReactions = () => {
  const { t } = useTranslation('page_individualCare');
  const { hasRole, data: user } = useUserInformation();
  const { indicationRequestId } = useParams();

  const indicationRequest = useIndividualCareRequest(indicationRequestId);

  const isCareConsultant = hasRole('CareConsultant');
  const isCaregiver = hasRole('Caregiver');
  const isAdmin = hasRole('Admin');

  return indicationRequest.isFetched ? (
    <TabContainer
      type="link"
      tabs={[
        {
          index: true,
          label: t('index.details', 'Details'),
          component: ViewIndicationRequest,
          indicationRequest: indicationRequest.data,
          error: indicationRequest.error,
        },
        (isCareConsultant || isAdmin) && {
          path: 'reactions/*',
          label: t('index.reactions', 'Reacties'),
          component: CareReactions,
          role: isAdmin ? 'Admin' : 'CareConsultant',
          careRequest: indicationRequest,
        },
        isCaregiver && {
          path: 'reactions/mine/*',
          label: t('index.myResponse', 'Mijn reactie'),
          component: CareReactions,
          role: 'Caregiver',
          careRequest: indicationRequest,
          reactions: _.filter(_.get(indicationRequest.data, 'reactions'), { caregiver: { id: user.id } }),
        },
      ].filter(Boolean)}
    />
  ) : (
    <LoadingOverlay />
  );
};

const Indication = () => {
  return (
    <Routes>
      <Route index element={<ListIndicationRequests />} />
      <Route path="add" element={<AddIndicationRequest />} />
      <Route path=":indicationRequestId/edit" element={<EditIndicationRequest />} />
      <Route path=":indicationRequestId/*" element={<IndicationRequestsWithReactions />} />
    </Routes>
  );
};

export default Indication;
