import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LoadingOverlay from 'src/components/loader/loader';
import { useIndividualCareRequest } from 'src/hooks/individualCare';
import styles from '../individualCare.module.scss';
import CareRequestForm from './form';

const EditCareRequest = () => {
  const { t } = useTranslation('page_IndividualCare');
  const { careRequestId } = useParams();
  const careRequest = useIndividualCareRequest(careRequestId);

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <h1>{t('titles.editCareRequest', 'Zorgvraag aanpassen')}</h1>
        {careRequest.isFetched ? <CareRequestForm edit careRequest={careRequest.data} /> : <LoadingOverlay />}
      </div>
    </div>
  );
};

export default EditCareRequest;
