import clsx from 'clsx';
import styles from './card.module.scss';

/**
 * Simple styled card component.
 *
 * @param {string?} className
 * @param {any} children
 * @param {Boolean?} login
 * @param {any} props
 */
const Card = ({ className, compact = false, login = false, ...props }) => (
  <div className={clsx(styles.container, compact && styles.compact, login && styles.login, className)} {...props} />
);

export default Card;
