import clsx from 'clsx';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Check, X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import LoadingOverlay from 'src/components/loader/loader';
import Avatar from 'src/components/user/avatar';
import { useGateway } from 'src/providers/gateway';
import { formatBookingTimeInfoFromStartAndDuration, formatDate, formatDuration } from 'src/utils';
import RejectHoursDialog from '../reject_hours_dialog/reject_hours_dialog';
import styles from './booking_approval.module.scss';

const BookingApproval = () => {
  const { t } = useTranslation(['page_companyAdmin', 'common']);
  const { api } = useGateway();

  const [needsLoad, setNeedsLoad] = useState(true);
  const [bookings, setBookings] = useState(null);
  const [bookingToReject, setBookingToReject] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const canSave = !isLoading;

  const getBookingsReadyForApproval = useCallback(async () => {
    setIsLoading(true);

    try {
      const data = await api.billing.getBookingsReadyForApproval();
      setBookings(data.bookingsReadyForApproval);
    } catch (err) {
      console.error('Error loading bookings:', err);
      setBookings([]);
    } finally {
      setIsLoading(false);
    }
  }, [api]);

  const approveBookingForBilling = useCallback(
    async (id) => {
      setIsLoading(true);

      try {
        await api.billing.approveBookingForBilling({ id });
        setNeedsLoad(true);
      } catch (err) {
        console.error('Error approving booking:', err);
      } finally {
        setIsLoading(false);
      }
    },
    [api]
  );

  const disapproveBookingForBilling = useCallback(
    async (booking) => {
      setIsLoading(true);

      try {
        await api.billing.disapproveBookingForBilling(booking);
        setNeedsLoad(true);
      } catch (err) {
        console.error('Error disapproving booking:', err);
      } finally {
        setIsLoading(false);
      }
    },
    [api]
  );

  useEffect(() => {
    if (needsLoad) {
      setNeedsLoad(false);
      getBookingsReadyForApproval();
    }
  }, [getBookingsReadyForApproval, needsLoad]);

  return (
    <div className={styles.container}>
      {isLoading && <LoadingOverlay />}

      <p>
        {t(
          'bookingApproval.description',
          'Deze lijst bevat goed te keuren uren. Hier keur je gewerkte diensten goed of doe je een voorstel tot correctie. Vermeld hier voor de duidelijkheid de reden bij.'
        )}
      </p>

      <ul className={styles.screeningList}>
        <li className={styles.headerRow}>
          <div>{t('bookingApproval.caregiver', 'Zorgverlener')}</div>
          <div>{t('bookingApproval.bookingInformation', 'Boekingsinformatie')}</div>
          <div></div>
        </li>
        {bookings &&
          (bookings.length > 0 ? (
            bookings.map((item) => (
              <li key={item.bookingId} className={styles.billingRow}>
                <div className={styles.person}>
                  <Avatar src={item.caregiver.profilePictureUrl} />
                  {item.caregiver.firstName} {item.caregiver.lastName}
                </div>
                <div className={styles.bookingInfo}>
                  <ul>
                    <li>
                      <label>{t('bookingDuration.booking', 'Boeking')}:</label>
                      <span>{item.bookingId}</span>
                    </li>

                    <li>
                      <label>{t('bookingApproval.team', 'Team')}:</label>
                      <span>{item.districtTeamName}</span>
                    </li>

                    <li>
                      <label>{t('bookingApproval.datum', 'Datum')}:</label>
                      <span>{formatDate(item.bookingDateTime, { format: t('common:dateFormat') })}</span>
                    </li>

                    <li>
                      <label>{t('bookingApproval.startTimeEndTime', 'Begintijd - Eindtijd')}:</label>
                      <span>{formatBookingTimeInfoFromStartAndDuration(item.bookingDateTime, item.hours)}</span>
                    </li>

                    <li>
                      <label>{t('bookingApproval.bookingDuration', 'Boekingsduur')}:</label>
                      <span>
                        {formatDuration(item.hours)}
                        {item.unbillableHours > 0 && (
                          <>
                            {' '}
                            {t('bookingApproval.includingNminBreak', {
                              breakMin: item.unbillableHours * 60,
                              defaultValue: 'incl {{breakMin}} min pauze',
                            })}
                          </>
                        )}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className={styles.action}>
                  <Button
                    secondary
                    disabled={!canSave}
                    onClick={() => {
                      approveBookingForBilling(item.bookingId);
                    }}
                  >
                    <Check />
                  </Button>
                  <Button
                    secondary
                    onClick={() => {
                      setBookingToReject(item);
                    }}
                  >
                    <X />
                  </Button>
                </div>
              </li>
            ))
          ) : (
            <li className={clsx(styles.billingRow, styles.emptyRow)}>
              <p>{t('bookingApproval.noBookingsToApprove', 'Momenteel zijn er geen boekingen om te accorderen.')}</p>
            </li>
          ))}
      </ul>

      {bookingToReject && (
        <RejectHoursDialog
          caregiver={bookingToReject.caregiver}
          startDateTime={bookingToReject.bookingDateTime}
          endDateTime={moment(bookingToReject.bookingDateTime).add(bookingToReject.hours, 'hours')}
          duration={bookingToReject.hours}
          disabledConfirm={!canSave}
          unbillableHours={bookingToReject.unbillableHours}
          onConfirm={({ duration, unbillableHours, reason }) => {
            disapproveBookingForBilling({
              bookingId: bookingToReject.bookingId,
              suggestedHours: duration,
              suggestedUnbillableHours: unbillableHours,
              reasonForRejection: reason,
            });
            setBookingToReject();
          }}
          onCancel={() => {
            setBookingToReject();
          }}
        />
      )}
    </div>
  );
};

export default BookingApproval;
