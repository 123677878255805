import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useParams } from 'react-router-dom';
import TabContainer from 'src/components/tabs/tabs';
import AddPosting from './add';
import ClonePosting from './clone';
import EditPosting from './edit';
import ListPostings from './list';
import PostingReactions from './reactions';
// import ViewPostingReaction from './reactions/view';
import LoadingOverlay from 'src/components/loader/loader';
import { emptyObject } from 'src/constants';
import { usePosting } from 'src/hooks/postings';
import { useUserInformation } from 'src/hooks/users';
import PostingAgreement from './agreement';
import ViewPosting from './view';

const PostingWithReactions = () => {
  const { t } = useTranslation('page_postings');
  const { hasRole, data: user } = useUserInformation();
  const { postingId } = useParams();

  const initialPosting = usePosting(postingId);
  const [posting, setPosting] = useState(emptyObject);
  useEffect(() => {
    if (initialPosting.isFetched) {
      setPosting({
        data: initialPosting.data,
        error: initialPosting.error,
        isFetched: initialPosting.isFetched,
      });
    }
  }, [initialPosting.data, initialPosting.error, initialPosting.isFetched]);

  const isRecruiter = hasRole('Recruiter');
  const isCaregiver = hasRole('Caregiver');
  const isAdmin = hasRole('Admin');

  return posting.isFetched ? (
    <TabContainer
      tabs={[
        {
          index: true,
          label: t('index.details', 'Details'),
          component: ViewPosting,
          posting: posting.data,
          error: posting.error,
        },
        (isRecruiter || isAdmin) && {
          path: 'reactions/*',
          label: t('index.reactions', 'Reacties'),
          component: PostingReactions,
          role: isAdmin ? 'Admin' : 'Recruiter',
          posting,
        },
        isCaregiver && {
          path: 'reactions/mine/*',
          label: t('index.myResponse', 'Mijn reactie'),
          // component: ViewPostingReaction,
          component: PostingReactions,
          role: 'Caregiver',
          posting,
          reactions: _.filter(_.get(posting.data, 'reactions'), { caregiver: { id: user.id } }),
          // placeholder: 'Je hebt nog niet gereageerd',
        },
      ].filter(Boolean)}
    />
  ) : (
    <LoadingOverlay />
  );
};

const Postings = () => {
  return (
    <Routes>
      <Route index element={<ListPostings />} />
      <Route path="add" element={<AddPosting />} />
      <Route path=":postingId/edit" element={<EditPosting />} />
      <Route path=":postingId/clone" element={<ClonePosting />} />
      <Route path=":postingId/agreement" element={<PostingAgreement />} />
      <Route path=":postingId/*" element={<PostingWithReactions />} />
    </Routes>
  );
};

export default Postings;
