import { useState } from 'react';
import { useGateway } from 'src/providers/gateway';
import InputFile from '../input_file/input_file';
import styles from './upload_document.module.scss';

const UploadDocument = (props) => {
  const { api } = useGateway();

  const [isLoading, setIsLoading] = useState(false);

  const postDocument = async (data) => {
    setIsLoading(true);

    try {
      await api.onboardingScreenings.uploadScreeningDocument(data);
      props.getScreeningInformationHandler();
    } catch (err) {
      console.error('Error uploading document:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeFileHandler = (data) => {
    if (typeof data === 'object') {
      postDocument(data);
    }
  };

  return (
    <div className={styles.container}>
      <h4>{props.displayName}</h4>
      <InputFile id={props.id} isLoading={isLoading} maxSize={10000000} onChange={onChangeFileHandler} />
    </div>
  );
};

export default UploadDocument;
