import _ from 'lodash';
import { locationFields, requiredAddressFields } from './constants';

export const validateAddress = (model, requiredFields = requiredAddressFields) => {
  const errors = [];
  const warnings = [];

  requiredFields.forEach((key) => {
    if (_.isEmpty(model[key])) {
      errors.push([key, 'required']);
    }
  });

  if (locationFields.some((key) => !_.isFinite(model[key]))) {
    warnings.push(['location', 'missing']);
  }

  return {
    errors,
    warnings,
    valid: errors.length === 0,
  };
};

export const useAddressValidation = validateAddress;
