import clsx from 'clsx';
import { AlertTriangle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Button from '../button/button';
import styles from './error.module.scss';

interface ErrorProps extends React.PropsWithChildren {
  className?: string | string[];

  onDismiss?: () => void;
}

const Error: React.FC<ErrorProps> = ({ className, onDismiss, ...props }) => {
  const { t } = useTranslation('common');

  return (
    <div className={clsx(styles.container, className)}>
      <AlertTriangle />
      <div>
        <div {...props} />
        <div className={styles.actions}>
          {onDismiss && (
            <Button remove small onClick={() => onDismiss()}>
              {t('button.dismiss')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Error;
