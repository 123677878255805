import { buildEnum, buildOptions } from 'src/utils';

// Ordered PostingReactionStatus enum values
const PostingReactionStatusValues = ['New', 'Active', 'Pending', 'Rejected', 'CancelledByCaregiver', 'Accepted'];

/**
 * PostingReactionStatus labels
 *
 * @type {Object.<string, string>}
 */
export const PostingReactionStatusLabels = (t) => t('constants.postingReactionStatusLabels', { returnObjects: true });

/**
 * PostingReactionStatus short labels
 *
 * @type {Object.<string, string>}
 */
export const PostingReactionStatusShortLabels = (t) =>
  t('constants.postingReactionStatusShortLabels', { returnObjects: true });

/**
 * PostingReactionStatus enum
 *
 * @type {Object.<string, string>}
 * @property New
 * @property Active
 * @property Pending
 * @property Rejected
 * @property CancelledByCaregiver
 * @property Accepted
 */
export const PostingReactionStatus = buildEnum(PostingReactionStatusValues);

/**
 * PostingReactionStatus select options
 *
 * @type {Array.<Object>}
 * @property New
 * @property Active
 * @property Pending
 * @property Rejected
 * @property CancelledByCaregiver
 * @property Accepted
 */
export const PostingReactionStatusOptions = (t) =>
  buildOptions(PostingReactionStatusValues, PostingReactionStatusLabels(t));

export const PostingReactionStatusEditable = {
  recruiter: [PostingReactionStatus.Active, PostingReactionStatus.Accepted, PostingReactionStatus.Rejected],
  caregiver: {
    mine: [PostingReactionStatus.Concept, PostingReactionStatus.Active, PostingReactionStatus.CancelledByCaregiver],
  },
  admin: [PostingReactionStatus.Active, PostingReactionStatus.Accepted, PostingReactionStatus.Rejected],
};
