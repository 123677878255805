import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import Chevron from '../chevron/chevron';
import styles from './dropdown.module.scss';
import invariant from 'invariant';

interface DropDownProps extends React.PropsWithChildren {
  chevron?: boolean;
  className?: string | string[];
}

const DropDown: React.FC<DropDownProps> = ({ chevron = false, children, className }) => {
  const triggerRef = useRef<HTMLDivElement>(null!);
  const popupRef = useRef<HTMLDivElement>(null!);

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    // Check if the click occurred outside of our popup
    // TODO: check if we actually need this extra check to make sure expand/collapse works good in all situations
    // if (!popupRef.current.contains(event.target)) {
    setExpanded(!expanded);
    // }
  };

  const onClickOutside = useCallback(
    (event: MouseEvent) => {
      // Check if the click occurred outside of our select and popup
      if (expanded && !triggerRef.current.contains(event.target as Element)) {
        setExpanded(false);
      }
    },
    [expanded]
  );

  useEffect(() => {
    const element = popupRef.current.querySelector('ul');
    invariant(element, 'No ul child found');

    const style = element.style;

    // reset custom positioning style before measuring natural viewport
    style.left = '';
    style.right = '';
    style.top = '';
    style.bottom = '';

    const naturalPosition = element.getBoundingClientRect();

    if (naturalPosition.x + naturalPosition.width > window.innerWidth) {
      style.left = '';
      style.right = '0';
    } else {
      style.left = '0';
      style.right = '';
    }
    if (naturalPosition.y + naturalPosition.height > window.innerHeight) {
      style.top = 'auto';
      style.bottom = '100%';
    } else {
      style.top = '100%';
      style.bottom = 'auto';
    }

    window.addEventListener('click', onClickOutside, true);

    // Return cleanup function
    return () => {
      window.removeEventListener('click', onClickOutside, true);
    };
  }, [onClickOutside]);

  return (
    <div
      ref={triggerRef}
      className={clsx(styles.container, expanded && styles.expanded, className)}
      onClick={toggleExpanded}
    >
      <div ref={popupRef}>{children}</div>
      {chevron && <Chevron dir={expanded ? 'up' : 'down'} />}
    </div>
  );
};

export default DropDown;
