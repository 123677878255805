import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { Check, X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Portal } from 'react-portal';
import Button from '../button/button';
import styles from './dialog.module.scss';

export const Dialog = ({
  onCancel,
  onConfirm,
  labelCancel,
  labelConfirm,
  labelQuestion,
  children,
  disabledConfirm = false,
  invertAction = false,
  style,
  className,
  wide = false,
  showActions = true,
}) => {
  const { t } = useTranslation('components');
  labelCancel ??= t('cancel', 'Annuleren');
  labelConfirm ??= t('agree', 'Akkoord');
  labelQuestion ??= t('areYouSureDialogQuestion', 'Weet je het zeker?');
  const [initial, setInitial] = useState(true);
  const [appear, setAppear] = useState(false);
  const containerRef = useRef();

  useEffect(() => {
    if (initial) {
      setInitial(false);
      setTimeout(() => {
        setAppear(true);
      }, 10);
    }
  }, [initial]);

  const handleConfirm = () => {
    setAppear(false);
    setTimeout(onConfirm, 300);
  };

  const handleCancel = () => {
    setAppear(false);
    setTimeout(onCancel, 300);
  };

  // Manually check mouse down and mouse up event to determine whether we should close the dialog.
  // This is required to work around an issue where a click that starts within the dialog but ends outside is
  // inadvertently registerd as a "click outside". This behavior would cause unwanted closure of the dialog when,
  // for instance, using the mouse to select text in (an input inside) the dialog and only letting go of the mouse
  // button when it's (far) outside of the dialog.
  const [mouseDownEvent, setMouseDownEvent] = useState();
  const handleMouseUp = (event) => {
    if (mouseDownEvent && event && event.target === mouseDownEvent.target && event.target === containerRef.current) {
      handleCancel();
    }
    setMouseDownEvent();
  };

  return (
    <Portal>
      <div
        className={clsx(styles.container, className)}
        data-appear={appear}
        style={style}
        ref={containerRef}
        onMouseDown={setMouseDownEvent}
        onMouseUp={handleMouseUp}
      >
        <div className={clsx(styles.dialog, wide && styles.wide)} data-appear={appear}>
          {labelQuestion && <div className={clsx(styles.labelQuestion)}>{labelQuestion}</div>}
          {children}
          {showActions && (
            <div className={styles.actions}>
              {labelCancel && (
                <Button invert={invertAction} secondary={!invertAction} onClick={handleCancel}>
                  {labelCancel}
                </Button>
              )}
              {labelConfirm && (
                <Button
                  invert={!invertAction}
                  secondary={invertAction}
                  onClick={handleConfirm}
                  disabled={disabledConfirm}
                >
                  {labelConfirm}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </Portal>
  );
};

export default Dialog;

export const YesNoDialog = (props) => {
  const { t } = useTranslation('components');
  return Dialog({
    labelConfirm: t('yes', 'Ja'),
    labelCancel: t('no', 'Nee'),
    ...props,
  });
};
export const OkCancelDialog = (props) => {
  const { t } = useTranslation('components');
  return Dialog({
    labelConfirm: t('agree', 'Akkoord'),
    labelCancel: t('cancel', 'Annuleren'),
    ...props,
  });
};
export const IconDialog = (props) => Dialog({ labelConfirm: <Check />, labelCancel: <X />, ...props });
