import _ from 'lodash';

/**
 * Compares two plain js dates and returns `true` if they are equal.
 *
 * @param {Date} a
 * @param {Date} b
 * @returns
 */
export const isSameJsDate = (a, b) => _.isDate(a) && _.isDate(b) && a.getTime() === b.getTime();

/**
 * Minimum allowed Date value (from .Net) as an offset from the Unix epoch.
 * @type {Date}
 */
export const minDateAsDate = new Date('0001-01-01T00:00:00.000Z');

/**
 * Minimum allowed DateTimeOffset value (from .Net) as an offset from the Unix epoch.
 * @type {Number}
 */
const minDateAsTime = minDateAsDate.getTime();

/**
 * Converts the minimum allowed DateTimeOffset value (from .Net) to `null`.
 * We need this helper because our backend usually stores datetime values as non-nullable, resulting in empty values
 * being saved as this minimum value instead of the more appropriate `null`.
 *
 * @param {Date} date
 * @returns {Date}
 */
export const nullifyMinDate = (date) => (date && new Date(date).getTime() > minDateAsTime ? date : null);
