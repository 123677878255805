import clsx from 'clsx';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Information from '../information/information';
import { EmailField } from '../input_text/input_field';
import styles from './input_email.module.scss';

// Regex for email addresses.
// Source: this SO answer: https://stackoverflow.com/a/201378
/* eslint-disable no-control-regex */
const RE_EMAIL =
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
/* eslint-enable no-control-regex */

const InputEmail = ({ className, onValidate = _.noop, errorMessage, ...props }) => {
  const { t } = useTranslation('components');
  const [valid, setValid] = useState(null);

  const validate = (value) => {
    const isValid = RE_EMAIL.test(value);

    setValid(isValid);
    onValidate(isValid);
  };

  const hasError = valid === false || errorMessage;

  return (
    <div className={clsx(styles.container, className)}>
      <EmailField onValidate={validate} valid={!hasError} {...props} />
      {hasError && (
        <div className="errorMessage">
          <Information error>
            {valid === false ? t('emailIncorrect', 'Dit is geen correct e-mailadres') : errorMessage}
          </Information>
        </div>
      )}
    </div>
  );
};

export default InputEmail;
