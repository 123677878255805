import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGateway } from 'src/providers/gateway';
import Dialog from '../dialog/dialog';
import Select from '../select/select';

const ChangeEducationDialog = ({ caregiverId, currentDegree, degrees, onConfirm, onCancel }) => {
  const { t } = useTranslation('components');
  const { api } = useGateway();
  const [chosenDegree, setChosenDegree] = useState(currentDegree);

  const confirm = async () => {
    try {
      await api.onboardingScreenings.setCaregiverEducation({
        caregiverId: caregiverId,
        desiredValue: chosenDegree,
      });
      onConfirm();
    } catch (err) {
      console.error('Error updating education:', err);
    }
  };

  const cancel = () => {
    onCancel();
  };

  return (
    <Dialog
      onConfirm={confirm}
      onCancel={cancel}
      invertAction
      labelQuestion={
        <Trans t={t} i18nKey="changeEducation">
          Je gaat de opleiding van deze persoon aanpassen.
          <br />
          Dit kan tot gevolg hebben dat sommige documenten overbodig zijn, of er nieuwe documenten aangeleverd moeten
          worden.
          <br />
          Als er nieuwe documenten vereist zijn, komt deze screening weer in de lijst ‘Te Keuren Profielen’.
        </Trans>
      }
      labelCancel={t('cancel', 'Annuleren')}
      labelConfirm={t('change', 'Veranderen')}
    >
      <Select
        onChange={setChosenDegree}
        options={degrees.map((degree) => ({ value: degree, label: degree }))}
        value={chosenDegree}
        placeholder={t('chooseEducationCourse', 'Kies een opleiding')}
      />
    </Dialog>
  );
};

export default ChangeEducationDialog;
