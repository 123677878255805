import styles from './footer.module.scss';

interface FooterProps extends React.PropsWithChildren {
  contextMessage?: string;
  actions?: React.ReactNode;
}

/**
 * Footer component.
 */
const Footer: React.FC<FooterProps> = ({ actions, contextMessage, children }) => (
  <footer className={styles.container}>
    {contextMessage && <p>{contextMessage}</p>}

    {children && <div className={styles.regularFooter}>{children}</div>}

    {actions && <div className={styles.actionContainer}>{actions}</div>}
  </footer>
);

export default Footer;
