import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import useTitle from 'react-use/lib/useTitle';
import Logo from 'src/components/logo';
import styles from './default.module.scss';

type ErrorCode = 401 | 403 | 404;

interface ErrorPageProps {
  code: ErrorCode;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ code }) => {
  const { t } = useTranslation('common', { keyPrefix: `error.${code}` });
  const navigate = useNavigate();

  useTitle(`TallKing Connect | ${code}`);

  return (
    <div className={clsx(styles.container, 'errorPage')}>
      <main>
        <Logo />

        <h1>{t('title')}</h1>
        <p>{t('message')}</p>
        <p>
          <Trans
            t={t}
            i18nKey="navigate"
            defaults="Navigate <back>back</back> or navigate to the <main>main page</main>..."
            components={{
              back: <span onClick={() => navigate(-1)} />,
              main: <Link to={'/'} />,
            }}
          />
        </p>
      </main>
    </div>
  );
};

export default ErrorPage;

export const Unauthorized = () => <ErrorPage code={401} />;
export const Forbidden = () => <ErrorPage code={403} />;
export const NotFound = () => <ErrorPage code={404} />;
