import { CareAgreementModel } from '@tallkingconnect/gateway';
import V2023 from './v2023';

/**
 * This file exports all care agreement templates that have been used during the live phase of our platform.
 * We need to keep all versions (correcting typos is fine) because this is what our users agreed to at some point.
 * We can't retrospectively change those agreed-upon agreements for obvious legal reasons.
 *
 * The default export automatically determines the correct version to display.
 */

export { V2023, V2023 as Current };

interface CareAgreementProps {
  model?: CareAgreementModel;
}

const DateBasedVersion: React.FC<CareAgreementProps> = ({ model }) => {
  // TODO: once we have multiple versions, determine the correct version based on `createdAt`,
  // or better (but not currently stored) `acceptedAt` or an explicit version value
  return <V2023 model={model} />;
};

export default DateBasedVersion;
