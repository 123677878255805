import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { useGateway } from 'src/providers/gateway';
import { formatDate } from 'src/utils';
import Dialog from '../dialog/dialog';

const PostponeExpirationDateDialog = ({ documentId, documentType, expirationDate, onConfirm, onCancel }) => {
  const { t } = useTranslation(['components', 'common']);
  const { api } = useGateway();
  const postponementDate = moment(expirationDate).add(1, 'months').toDate();

  const confirm = async () => {
    try {
      await api.onboardingScreenings.setDocumentExpirationPostponementDate({
        documentId,
        inactivePostponementDate: postponementDate,
      });
    } catch (e) {
      console.error(e);
    }

    onConfirm(postponementDate);
  };

  const cancel = () => {
    onCancel();
  };

  return (
    <Dialog
      onConfirm={confirm}
      onCancel={cancel}
      invertAction
      labelQuestion={
        <>
          <Trans
            t={t}
            i18nKey="documentExpirationPostponement"
            defaults="Je staat op het punt om het document <b>{{documentType}}</b> uitstel te geven van 1 maand.<br />Hiermee wordt de nieuwe vervaldatum <b>{{postponementDate}}</b>."
            values={{
              documentType,
              postponementDate: formatDate(postponementDate, { format: t('common:dateFormat') }),
            }}
            components={{ b: <b />, br: <br /> }}
          />
        </>
      }
      labelCancel={t('cancel', 'Annuleren')}
      labelConfirm={t('delaying', 'Uitstel geven')}
    ></Dialog>
  );
};

export default PostponeExpirationDateDialog;
