import {
  DateRangeInput,
  GetPostingBookingsForPlannerOutput,
  PostingInput,
  PostingModel,
  PostingReactionInput,
  PostingReactionModel,
  PostingStatus,
  RespondToPostingReactionInput,
} from '@tallkingconnect/gateway';
import {
  QueryClient,
  UseMutationOptions,
  UseMutationResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { produce } from 'immer';
import _ from 'lodash';
import moment from 'moment';
import ms from 'ms';
import { useMemo } from 'react';
import Flags from 'src/config/flags';
import { emptyObject } from 'src/constants';
import { useGateway } from 'src/providers/gateway';
import { formatDateHtml } from 'src/utils';
import { invalidateCachedEntriesBy } from './helpers';

const requiredPostingFields = [
  'startDate',
  'endDate',
  'hoursPerWeek',
  'suggestedRate',
  'districtTeamId',
  'careSectorId',
  'requiredDegree',
  // 'careType',
  'description',
];

export function usePostingValidation(model: Record<string, unknown>) {
  const errors: [string, string][] = [];
  const warnings: [string, string][] = [];

  requiredPostingFields.forEach((key) => {
    if (_.isNil(model[key])) {
      errors.push([key, 'required']);
    }
  });

  return {
    errors,
    warnings,
    valid: errors.length === 0,
  };
}

export function usePostings(statuses: PostingStatus[]) {
  const { api } = useGateway();

  return useQuery({
    queryKey: ['postings', { statuses }] as [string, { statuses: PostingStatus[] }],
    queryFn: ({ queryKey: [, { statuses }] }) => api.postings.getPostings(statuses),
    placeholderData: { postings: [] },
  });
}

export function usePosting(id: string) {
  const { api } = useGateway();

  return useQuery({
    queryKey: ['posting', { id }] as [string, { id: string }],
    queryFn: ({ queryKey: [, { id }] }) => api.postings.getPosting(id),
  });
}

export function usePostingReaction(id: string) {
  const { api } = useGateway();

  return useQuery({
    queryKey: ['postingReaction', { id }] as [string, { id: string }],
    queryFn: ({ queryKey: [, { id }] }) => api.postings.getPostingReaction(id),
  });
}

function selectMyPostingsForDateRange(data: GetPostingBookingsForPlannerOutput) {
  return {
    bookings: data.bookings?.map((booking) => ({
      ...booking,
      start: booking.bookingStart,
      end: booking.bookingEnd,
      status: booking.bookingStatus,
      caregiver: _.find(data.caregivers, { id: booking.caregiverId }),
    })),
    caregivers: data.caregivers,
    postings: data.postings,
    teams: data.districtTeams,
  };
}

export function useMyPostingsForDateRange(dateRange: DateRangeInput) {
  const { api } = useGateway();

  return useQuery({
    queryKey: ['postings', { dateRange }] as [string, { dateRange: DateRangeInput }],
    queryFn: ({ queryKey: [, { dateRange }] }) => api.bookings.getPostingBookingsForPlanner(dateRange),
    staleTime: ms('1m'),
    refetchInterval: Flags.enableAutoRefreshBookings ? ms('1m') : undefined,
    select: selectMyPostingsForDateRange,
    placeholderData: emptyObject,
  });
}

export function useMyPostingsForMonth(date: Date) {
  const dateRange = useMemo<DateRangeInput>(() => {
    const startDate = moment(date).startOf('month').startOf('week');
    const endDate = moment(date).endOf('month').endOf('week').add(1, 'day');

    return {
      startDate: formatDateHtml(startDate),
      endDate: formatDateHtml(endDate),
    };
  }, [date]);

  return useMyPostingsForDateRange(dateRange);
}

export function usePostingReactionAgreementPreview(id: string) {
  const { api } = useGateway();

  return useQuery({
    queryKey: ['postingReactionAgreementPreview', { id }] as [string, { id: string }],
    queryFn: ({ queryKey: [, { id }] }) => api.postings.getPostingReactionAgreementPreview(id),
  });
}

export function useSavePostingMutation(
  opts: UseMutationOptions<PostingModel, unknown, PostingInput, unknown> = emptyObject
): UseMutationResult<PostingModel, unknown, PostingInput, unknown> {
  const { api } = useGateway();
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: api.postings.savePosting,
    onSuccess: (data: PostingModel, variables: PostingInput, context: unknown) => {
      updateCachedPostings(queryClient, data);

      if (opts.onSuccess) {
        opts.onSuccess(data, variables, context);
      }
    },
  });
}

function updateCachedPostings(queryClient: QueryClient, posting: PostingModel) {
  // Can't effectively update cache using partial query key matching (`queryClient.setQueriesData({ queryKey: ..., exact: false}, updaterFn)`)
  // until this comment is fixed: https://github.com/TanStack/query/discussions/2078#discussioncomment-5416270
  // This will work fine however:
  if (!posting.id || !posting.status) {
    // posting is new or has unknown status, let's invalidate all lists
    queryClient.invalidateQueries({ queryKey: ['postings'], exact: false });
  } else {
    queryClient.setQueryData(['posting', { id: posting.id }], (prev?: PostingModel) =>
      prev ? { ...prev, ...posting } : posting
    );

    // Posting is modified, we could update the existing lists (see example below), but for now let's just invalidate them too
    queryClient.invalidateQueries({ queryKey: ['postings'], exact: false });

    // The following commented out code is intentionally left here, to showcase how we could do in-place list editing.
    // It works, but with some flaws due to the data returned from the backend not always having all fields filled in,
    // resulting in postings in the posting list with e.g. an empty team

    // queryClient
    //   .getQueryCache()
    //   .findAll(['postings'])
    //   .forEach(({ queryKey }) => {
    //     const statuses = (queryKey as [string, { statuses: PostingStatus[] }])[1]?.statuses;
    //     if (statuses) {
    //       queryClient.setQueryData(queryKey, (prev?: GetPostings) =>
    //         produce(prev, (draft) => {
    //           const postings = draft?.postings;
    //           if (!postings) {
    //             return;
    //           }

    //           const cachedIndex = postings.findIndex((p) => p.id === posting.id);
    //           // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    //           if (statuses.includes(posting.status!)) {
    //             if (cachedIndex >= 0) {
    //               Object.assign(postings[cachedIndex], posting);
    //             } else {
    //               postings.push(posting);
    //             }
    //           } else if (cachedIndex >= 0) {
    //             postings.splice(cachedIndex, 1);
    //           }
    //         })
    //       );
    //     }

    //     const dateRange = (queryKey as [string, { dateRange: DateRangeInput[] }])[1]?.dateRange;
    //     if (dateRange && posting.startDate && posting.endDate) {
    //       queryClient.setQueryData(queryKey, (prev?: GetPostingBookingsForPlannerOutput) =>
    //         produce(prev, (draft) => {
    //           const postings = draft?.postings;
    //           if (!postings) {
    //             return;
    //           }

    //           const cachedIndex = postings.findIndex((p) => p.id === posting.id);
    //           // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    //           const dateRangeOverlaps = posting.startDate! <= dateRange[1] && posting.endDate! <= dateRange[0];
    //           if (dateRangeOverlaps) {
    //             if (cachedIndex >= 0) {
    //               Object.assign(postings[cachedIndex], posting);
    //             } else {
    //               postings.push(posting);
    //             }
    //           } else if (cachedIndex >= 0) {
    //             postings.splice(cachedIndex, 1);
    //           }
    //         })
    //       );
    //     }
    //   });
  }
}

export function usePostingReactionMutation(
  opts: UseMutationOptions<PostingReactionModel, unknown, PostingReactionInput, unknown> = emptyObject
): UseMutationResult<PostingReactionModel, unknown, PostingReactionInput, unknown> {
  const { api } = useGateway();
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: api.postings.savePostingReaction,
    onSuccess: (data: PostingReactionModel, variables: PostingReactionInput, context: unknown) => {
      updateCachedPostingReactions(queryClient, data);
      
      if (opts.onSuccess) {
        opts.onSuccess(data, variables, context);
      }
    },
  });
}

export function usePostingReactionNotesMutation(
  opts: UseMutationOptions<void, unknown, { id: string; notes: string }, unknown> = emptyObject
): UseMutationResult<void, unknown, { id: string; notes: string }, unknown> {
  const { api } = useGateway();
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: ({ id, notes }) => api.postings.savePostingReactionNote(id, { value: notes }),
    onSuccess: (data: void, variables: { id: string; notes: string }, context: unknown) => {
      const { id, notes } = variables;
      queryClient.setQueriesData({ queryKey: ['posting'], exact: false }, (prev?: PostingModel) =>
        prev
          ? produce(prev, (draft) => {
              draft?.reactions?.filter((r) => r.id === id).forEach((r) => (r.privateNotes = notes));
            })
          : prev
      );

      if (opts.onSuccess) {
        opts.onSuccess(data, variables, context);
      }
    },
  });
}

export function usePostingReactionIsInterestingMutation(
  opts: UseMutationOptions<void, unknown, { id: string; interesting: boolean }, unknown> = emptyObject
): UseMutationResult<void, unknown, { id: string; interesting: boolean }, unknown> {
  const { api } = useGateway();
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: ({ id, interesting }) => api.postings.markPostingReactionAsInteresting(id, { value: interesting }),
    onSuccess: (data: void, variables: { id: string; interesting: boolean }, context: unknown) => {
      const { id, interesting } = variables;
      queryClient.setQueriesData({ queryKey: ['posting'], exact: false }, (prev?: PostingModel) =>
        prev
          ? produce(prev, (draft) => {
              draft?.reactions?.filter((r) => r.id === id).forEach((r) => (r.interesting = interesting));
            })
          : prev
      );

      if (opts.onSuccess) {
        opts.onSuccess(data, variables, context);
      }
    },
  });
}

export function usePostingReactionResponseMutation(
  opts: UseMutationOptions<PostingReactionModel, unknown, RespondToPostingReactionInput, unknown> = emptyObject
): UseMutationResult<PostingReactionModel, unknown, RespondToPostingReactionInput, unknown> {
  const { api } = useGateway();
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: api.postings.respondToPostingReaction,
    onSuccess: (data: PostingReactionModel, variables: RespondToPostingReactionInput, context: unknown) => {
      queryClient.invalidateQueries({
        queryKey: ['posting'],
        exact: false,
        predicate: (query) =>
          (query.state.data as PostingModel)?.reactions?.some((r) => r.id === variables.reactionId) ?? false,
      });

      if (opts.onSuccess) {
        opts.onSuccess(data, variables, context);
      }
    },
  });
}

function updateCachedPostingReactions(queryClient: QueryClient, reaction: PostingReactionModel) {
  if (!reaction.id) {
    // Invalidate postings
    queryClient.invalidateQueries({ queryKey: ['postings'], exact: false });
  } else {
    // Update care reaction
    // TODO: enable (and use elsewhere as well) once we store reactions in a separate cache (we currently don't)
    // queryClient.setQueryData(['postingReaction', { id: reaction.id }], (prev?: PostingReactionModel) =>
    //   prev ? { ...prev, ...reaction } : reaction
    // );

    if (reaction.postingId) {
      // TODO update only parent posting. 
      queryClient.invalidateQueries({ queryKey: ['postings'], exact: false });

      // Invalidate sibling reactions
      invalidateCachedEntriesBy(
        queryClient,
        ['postingReaction'],
        (item: unknown) =>
          _.isMatch({ postingId: reaction.postingId }, item as object) &&
          !_.isMatch({ id: reaction.id }, item as object)
      );
    }
  }
}
