import { useState } from 'react';
import { Edit, Settings, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { YesNoDialog } from 'src/components/dialog/dialog';
import DropDown from 'src/components/dropdown/dropdown';
import { useIndividualCareReactionMutation } from 'src/hooks/individualCare';
import { IndividualCareReactionStatus } from './constants';

const ActionsMenu = ({ reaction, onChangeModel }) => {
  const { t } = useTranslation('page_individualCare');
  const location = useLocation();

  const [dialogData, setDialogData] = useState(null);

  const mutation = useIndividualCareReactionMutation({
    onSuccess: (data) => {
      onChangeModel(data);
    },
    onError: (err) => {
      console.error('Error saving reaction:', err);
    },
  });

  const saveStatus = (nextStatus) => {
    mutation.mutate({
      reaction: {
        ...reaction,
        status: nextStatus,
      },
    });
  };

  const showDialog = (labelQuestion, onConfirm) =>
    setDialogData({
      labelQuestion,
      onConfirm,
      onCancel: () => setDialogData(),
    });

  const retract = () =>
    showDialog(
      t('reactions.actionsMenu.withdrawResponseDialogQuestion', 'Weet je zeker dat je deze reactie wilt intrekken?'),
      () => saveStatus(IndividualCareReactionStatus.CancelledByCaregiver)
    );

  return (
    <>
      {dialogData && <YesNoDialog {...dialogData} />}
      <DropDown>
        <Settings />
        <ul>
          <li data-has-icon>
            <Edit />
            <Link to={`${location.pathname}/${reaction.id}/edit`}>{t('reactions.actionsMenu.edit', 'Bewerken')}</Link>
          </li>
          {![IndividualCareReactionStatus.CancelledByCaregiver].includes(reaction.status) && (
            <li data-has-icon>
              <Trash2 />
              <span data-clickable onClick={retract}>
                {t('reactions.actionsMenu.withdraw', 'Intrekken')}
              </span>
            </li>
          )}
        </ul>
      </DropDown>
    </>
  );
};

export default ActionsMenu;
