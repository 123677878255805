import { useTranslation } from 'react-i18next';
import TabContainer from 'src/components/tabs/tabs';
import Care from './care/index';
import Indication from './indication/index';

const IndividualCare = () => {
  const { t } = useTranslation('page_individualCare');

  return (
    <TabContainer
      tabs={[
        {
          path: 'indicationrequest/*',
          label: t('index.indication'),
          component: Indication,
        },
        {
          path: 'carerequest/*',
          label: t('index.careRequest'),
          component: Care,
        },
      ]}
      defaultPath="indicationrequest"
    />
  );
};

export default IndividualCare;
