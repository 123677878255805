import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGateway } from 'src/providers/gateway';
import Dialog from '../dialog/dialog';
import Select from '../select/select';

const ChangeSectorDialog = ({ caregiverId, currentSectorId, sectors, onConfirm, onCancel }) => {
  const { t } = useTranslation('components');
  const { api } = useGateway();
  const [chosenSectorId, setChosenSectorId] = useState(currentSectorId);

  const confirm = async () => {
    try {
      await api.onboardingScreenings.setCaregiverSector({
        caregiverId: caregiverId,
        desiredValue: chosenSectorId,
      });
      onConfirm();
    } catch (err) {
      console.error('Error updating sector:', err);
    }
  };

  const cancel = () => {
    onCancel();
  };

  return (
    <Dialog
      onConfirm={confirm}
      onCancel={cancel}
      invertAction
      labelQuestion={
        <Trans t={t} i18nKey="changeSector">
          Je gaat de Sector van deze persoon aanpassen.
          <br />
          Dit zal geen invloed hebben op de opleiding of vereiste documenten.
        </Trans>
      }
      labelCancel={t('cancel', 'Annuleren')}
      labelConfirm={t('change', 'Veranderen')}
    >
      <Select
        onChange={setChosenSectorId}
        options={Object.entries(sectors).map(([value, label]) => ({ value, label }))}
        value={chosenSectorId}
        placeholder={t('chooseSector', 'Kies een sector')}
      />
    </Dialog>
  );
};

export default ChangeSectorDialog;
