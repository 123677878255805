import moment from 'moment';
import { FileText } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import A from 'src/components/a';
import Button from 'src/components/button/button';
import Dialog from 'src/components/dialog';
import { FormRow, FormSpacer } from 'src/components/form/form';
import Information from 'src/components/information/information';
import InputCheckbox from 'src/components/input_checkbox/input_checkbox';
import { formatDate } from 'src/utils';
import styles from './index.module.scss';

const DocumentsDialog = ({ onClose, options, value, onChange = false, showActions = false, readOnly = false }) => {
  const { t } = useTranslation(['page_planning', 'common']);
  const onChangeItem = (documentId) => (checked) =>
    onChange(checked ? value.concat(documentId) : value.filter((id) => id !== documentId));
  return (
    <Dialog
      showActions={false}
      labelQuestion={t('dialogs.documentsDialog.documents', 'Documenten')}
      onCancel={onClose}
      className={styles.docDialog}
    >
      {options.length === 0 && (
        <div className={styles.info}>
          <p>{t('dialogs.documentsDialog.documentsUploadInstructions')}</p>
        </div>
      )}
      {options.map((doc) => {
        const { isActive = true } = doc;
        return (
          <FormRow key={doc.id} className={styles.row}>
            <div className={styles.document}>
              {showActions && (
                <div className={styles.documentAction}>
                  <InputCheckbox readOnly={readOnly} onChange={onChangeItem(doc.id)} value={value.includes(doc.id)} />
                </div>
              )}
              <FileText />
              <div className={styles.text}>
                <A href={doc.url}>{doc.type}</A>
                <div>{formatDate(moment(doc.createdAt), { format: t('common:dateFormat') })}</div>
                {!isActive && (
                  <div className={styles.info}>
                    <Trans t={t} i18nKey="dialogs.documentsDialog.documentInactive">
                      <span>Dit document is momenteel inactief</span>
                      <Information>
                        Inactieve documenten kunnen niet aan boekingen worden gekoppeld. Let op: een eenmaal gekoppeld
                        document wordt niet automatisch van alle boekingen ontkoppeld door het document op inactief te
                        zetten.
                      </Information>
                    </Trans>
                  </div>
                )}
              </div>
            </div>
            <FormSpacer />
          </FormRow>
        );
      })}

      <div className={styles.actions}>
        <Button onClick={onClose}>{t('dialogs.documentsDialog.close', 'Sluiten')}</Button>
      </div>
    </Dialog>
  );
};

export default DocumentsDialog;
