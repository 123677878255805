import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { SearchButton } from '../button/button';
import { SearchField } from '../input_text/input_field';
import styles from './input_search.module.scss';

const InputSearch = ({
  className,
  value,
  onChange,
  onSearch,
  minLength = process.env.REACT_APP_CAREGIVER_SEARCH_MINIMUM_CHARS,
  ...props
}) => {
  const { t } = useTranslation('components');
  const allowSearch = minLength === 0 || (value && value.length >= minLength);

  const searchOnEnter = (event) => {
    if (event.key === 'Enter' && allowSearch) {
      onSearch();
    }
  };

  return (
    <div className={clsx(styles.container, 'searchBar', className)}>
      <SearchField
        placeholder={props.placeholder}
        value={value}
        onChange={onChange}
        onKeyUp={searchOnEnter}
        {...props}
      />
      <SearchButton
        disabled={!allowSearch}
        onClick={onSearch}
        title={
          !allowSearch
            ? t('minimumCharactersRequiredToShowTargetedSearchResults', {
                minLength,
                defaultValue: 'Minimaal {{minLength}} tekens zijn nodig om een gericht zoekresultaat te kunnen tonen',
              })
            : ''
        }
      />
    </div>
  );
};

export default InputSearch;
