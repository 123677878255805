import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/nl';

export const tzUtc = 'Etc/UTC';
export const tzAmsterdam = 'Europe/Amsterdam';

export const setupMoment = (locale) => {
  // TODO: switch out moment for something better, e.g. dayjs (which is REALLY small)
  moment.locale(locale);
  moment.updateLocale(locale, {
    week: {
      dow: 1,
      doy: 4,
    },
  });

  // Set default timezone to the Netherlands:
  moment.tz.setDefault(tzAmsterdam);
};
