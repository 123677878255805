import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isSafari } from 'src/utils';
import { timeDisplayFormat, shortTimeHtmlFormat, timeHtmlFormat } from 'src/utils/format';
import styles from './index.module.scss';

const parseDateOrNull = (value, format) => {
  const m = moment(value, format);
  return m.isValid() ? m.format(format) : null;
};

/**
 * Adds a simple styled text inputfield.
 *
 * @param {date} max - YYYY-MM-DD
 * @param {date} min - YYYY-MM-DD
 */
const InputDateTimeBase = ({
  type,
  dateDisplayFormat,
  dateOutputFormat,
  dateHtmlFormat,
  className,
  defaultValue,
  value,
  onChange = _.noop,
  onBlur = _.noop,
  min,
  max,
  errorInvalid,
  errorMax,
  errorMin,
  errorDatetimeFormat,
  ...props
}) => {
  const { i18n } = useTranslation();

  // Workaround for Safari which lacks proper date inputs
  const [defaultValueForSafari] = useState(
    parseDateOrNull(defaultValue, dateHtmlFormat) || parseDateOrNull(value, dateHtmlFormat)
  );

  const [hasInteracted, setHasInteracted] = useState(false);

  const error = useMemo(() => {
    const minMoment = min ? moment(min, dateHtmlFormat) : null;
    const maxMoment = max ? moment(max, dateHtmlFormat) : null;
    const valueMoment = moment(value, dateHtmlFormat);

    if (!valueMoment.isValid()) {
      if (hasInteracted) {
        return errorInvalid && setHasInteracted(false);
      }
    } else if (min && valueMoment.isBefore(minMoment)) {
      return errorMin.replace('%s', minMoment.format(errorDatetimeFormat));
    } else if (max && valueMoment.isAfter(maxMoment)) {
      return errorMax.replace('%s', maxMoment.format(errorDatetimeFormat));
    } else {
      return null;
    }
  }, [dateHtmlFormat, errorDatetimeFormat, errorInvalid, errorMax, errorMin, hasInteracted, max, min, value]);

  const onChangeHandler = (event) => {
    let value = event.target.value;

    setHasInteracted(true);

    // Safari uses text input, so we need to convert to date from display format to HTML (serialization) format ourselves
    // For other browsers, parse and format nonetheless to make sure the value adheres to the expected format
    const valueMoment = moment(value, isSafari ? dateDisplayFormat : dateHtmlFormat);
    value = valueMoment.format(dateOutputFormat);

    switch (event.type) {
      case 'change':
        onChange(value);
        break;
      case 'blur':
        onBlur(value);
        break;
      default:
        break;
    }
  };

  // Workaround for Safari which lacks proper date inputs: use defaultValue, never value
  if (isSafari) {
    value = undefined;
    defaultValue = defaultValueForSafari;
  }

  // Transform value into a usable format
  if (defaultValue) {
    defaultValue = moment(defaultValue, dateHtmlFormat).format(isSafari ? dateDisplayFormat : dateHtmlFormat);
  }
  if (value) {
    value = moment(value, dateHtmlFormat).format(isSafari ? dateDisplayFormat : dateHtmlFormat);
  }

  return (
    <div className={styles.inputDateTimeField}>
      <input
        lang={i18n.language}
        type={isSafari ? 'text' : type}
        className={clsx(styles.container, className)}
        defaultValue={defaultValue}
        value={value}
        onChange={onChangeHandler}
        onBlur={onChangeHandler}
        // TODO: restrict to 5 minute intervals when browsers implement it properly in their datetime pickers
        // (Chrome 83 for instance currently shows every minute, but doesn't set a value when it's not a multiple of `step`)
        // step={type === 'datetime-local' ? 60 * 5 : undefined}
        {...props}
      />
      {error && <label>{error}</label>}
    </div>
  );
};

const InputTime = (props) => {
  const { t } = useTranslation('components');
  return InputDateTimeBase({
    type: 'time',
    dateDisplayFormat: timeDisplayFormat,
    dateOutputFormat: timeHtmlFormat,
    dateHtmlFormat: shortTimeHtmlFormat,
    errorInvalid: t('invalidTime', 'Ongeldige tijd'),
    errorMax: t('timeMustNotBeLaterThan', 'Tijd mag niet later zijn dan %s'),
    errorMin: t('timeMustNotBeEarlierThan', 'Tijd mag niet eerder zijn dan %s'),
    errorDatetimeFormat: timeDisplayFormat,
    ...props,
  });
};

export { InputTime };
