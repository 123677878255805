import clsx from 'clsx';
import styles from './form.module.scss';

// TODO: transform Form/FormRow into generic Flex components? They aren't specific to forms really...
export const Form = ({ className, comfortable = false, compact = false, stretch = false, ...props }) => (
  <div
    className={clsx(
      styles.form,
      comfortable && styles.comfortable,
      compact && styles.compact,
      stretch && styles.stretch,
      className
    )}
    {...props}
  />
);

export const FormRow = ({ className, disabled = false, ...props }) => (
  <div className={clsx(styles.formRow, disabled && styles.disabled, className)} {...props} />
);

export const FormActions = ({ className, ...props }) => (
  <div className={clsx(styles.formActions, className)} {...props} />
);

export const FormSpacer = ({ className, vertical = false }) => (
  <div className={clsx(styles.formSpacer, vertical ? styles.vertical : styles.horizontal, className)} />
);
