import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, Route, Routes, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';
import LoadingOverlay, { Loader } from './components/loader/loader';
import NavigateExternally from './components/navigation_menu/navigateExternally';
import Flags from './config/flags';
import { defaultLocale } from './config/i18n';
import { setupMoment } from './config/moment';
import { setupPrimeReact } from './config/primereact';
import { useUserInformation } from './hooks/users';
import DefaultLayout from './layouts/default';
import { Unauthorized } from './layouts/error';
import LoginLayout from './layouts/login';
import Billing from './pages/admin/billing/billing';
import CorrectionBookingForm from './pages/admin/correction_booking/correction_booking';
import CompanyAccountForm from './pages/admin/create_company_account/form';
import PlannerAccountForm from './pages/admin/create_planner_account/form';
import ScreeningTabs from './pages/admin/screening/screeningTabs';
import CareAgreement from './pages/bookings/agreement';
import ApproveHours from './pages/company_admin/approve_hours';
import Home from './pages/home/home';
import IndividualCare from './pages/individual_care';
import Login from './pages/login/login';
import Onboarding from './pages/onboarding/onboarding';
import Planning from './pages/planning';
import Postings from './pages/postings';
import Register from './pages/register/register';
import ResetPassword from './pages/reset_password/reset-password';
import Teams from './pages/teams';
import { AnonymousRoute, useAuth0 } from './providers/auth0';
import routes, { findNearestRouteByPath } from './routes';

const useDefaultRoute = () => {
  const { isAuthenticated, isLoading: auth0IsLoading, user: auth0User } = useAuth0();
  const { hasRole, data: platformUser, isLoading: platformIsLoading } = useUserInformation();
  const { pathname } = useLocation();

  if (auth0IsLoading || platformIsLoading) {
    return;
  }

  if (auth0User?.email_verified === false && !pathname.startsWith('/reset-password')) {
    return '/reset-password';
  }

  if (isAuthenticated && ['/', '/login'].includes(pathname)) {
    if (hasRole('Admin')) {
      return '/screening';
    } else if (hasRole('Planner')) {
      return '/planning';
    } else if (hasRole('Approver')) {
      return '/hours/approve';
    } else if (hasRole('Recruiter')) {
      return '/postings';
    } else if (Flags.enableIndividualCareRequests && hasRole('CareConsultant')) {
      return '/individualcare';
    } else if (hasRole('Caregiver')) {
      return platformUser?.needsToCompleteOnboarding ? '/onboarding' : '/postings';
    }
  }
};

const ProtectedComponent = withAuthenticationRequired(({ element, roles = [] }) => {
  const { hasRole, isLoading } = useUserInformation();
  return isLoading ? <LoadingOverlay /> : !roles.some(hasRole) ? <Unauthorized /> : element || <Outlet />;
});

const NavigateToCaregiverEnvironment = () => {
  const { t } = useTranslation('common');

  return (
    <NavigateExternally
      to={process.env.REACT_APP_CAREGIVER_APP_DOWNLOAD_INSTRUCTIONS}
      replace
      placeholder={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            gap: '2rem',
          }}
        >
          {t('redirecting')}
          <Loader />
        </div>
      }
      delay={2000}
    />
  );
};

const useDocumentTitle = () => {
  const { t } = useTranslation('common');
  const location = useLocation();
  const { pathname } = useMatch(location.pathname);
  const titleRoute = useMemo(() => findNearestRouteByPath(pathname), [pathname]);
  useTitle(`TallKing Connect | ${t(titleRoute ? `routes.${titleRoute.i18nKey}` : 'defaultPageTitle')}`);
};

const AuthenticatedRoutes = () => {
  const { t } = useTranslation('common');
  const { hasRole } = useUserInformation();
  const navigate = useNavigate();

  // Filter menu items based on roles of the logged in user
  const menuItems = useMemo(
    () =>
      routes
        .filter((route) => route.roles?.some(hasRole))
        .map((route) => ({ ...route, title: t(`routes.${route.i18nKey}`) })),
    [hasRole, t]
  );

  const defaultRoute = useDefaultRoute();
  useEffect(() => {
    if (defaultRoute) {
      navigate(defaultRoute);
    }
  }, [defaultRoute, navigate]);

  return (
    <Routes>
      <Route element={<DefaultLayout menuItems={menuItems} />}>
        <Route index element={<Home />} />

        {/* Admin only routes */}
        <Route element={<ProtectedComponent roles={['Admin']} />}>
          <Route path="admin/*">
            <Route path="create-company-account" element={<CompanyAccountForm />} />
            <Route path="create-planner-account" element={<PlannerAccountForm />} />
            <Route path="billing" element={<Billing />} />
          </Route>

          <Route path="screening/*" element={<ScreeningTabs />} />
        </Route>

        <Route path="bookings/*">
          <Route path="corrections/*" element={<ProtectedComponent roles={['Admin']} />}>
            <Route path="add" element={<CorrectionBookingForm />} />
          </Route>
          <Route path=":id">
            <Route path="agreement" element={<CareAgreement />} />
          </Route>
        </Route>

        <Route
          path="teams/*"
          element={<ProtectedComponent roles={['Planner', 'CompanyAdmin', 'Admin']} element={<Teams />} />}
        />

        <Route path="planning/*" element={<ProtectedComponent roles={['Planner', 'Admin']} element={<Planning />} />} />
        <Route
          path="postings/*"
          element={<ProtectedComponent roles={['Recruiter', 'Caregiver', 'Admin']} element={<Postings />} />}
        />

        {Flags.enableIndividualCareRequests && (
          <Route
            path="individualcare/*"
            element={
              <ProtectedComponent roles={['CareConsultant', 'Caregiver', 'Admin']} element={<IndividualCare />} />
            }
          />
        )}

        <Route path="hours/*" element={<ProtectedComponent roles={['Approver']} element={<ApproveHours />} />} />

        <Route path="reset-password" element={<ResetPassword />} />

        <Route path="login" element={<Navigate to="/" />} />
      </Route>

      <Route element={<DefaultLayout minimal />}>
        <Route path="onboarding" element={<Onboarding />} />
        <Route path="caregiver-environment" element={<NavigateToCaregiverEnvironment />} />
      </Route>
    </Routes>
  );
};

const AnonymousRoutes = () => {
  const { pathname } = useLocation();
  return (
    <Routes>
      <Route element={<AnonymousRoute component={() => <LoginLayout />} />}>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />

        <Route index element={<Navigate to="/login" />} />
        <Route path="*" element={<Navigate to={`/login?returnTo=${pathname}`} />} />
      </Route>
    </Routes>
  );
};

const App = () => {
  const { t, i18n } = useTranslation('common');
  const { pathname } = useLocation();
  const { isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    console.info('[App] Language changed:', { language: i18n.language, resolvedLanguage: i18n.resolvedLanguage });
    setupMoment(i18n.resolvedLanguage || defaultLocale);
    setupPrimeReact(i18n.resolvedLanguage || defaultLocale);
  }, [i18n.language, i18n.resolvedLanguage]);

  useDocumentTitle();

  return (
    <div className="App">
      {isLoading ? (
        <LoadingOverlay message={pathname === '/login' ? t('login.popup') : undefined} />
      ) : isAuthenticated ? (
        <AuthenticatedRoutes />
      ) : (
        <AnonymousRoutes />
      )}
    </div>
  );
};

export default App;
