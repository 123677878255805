import styles from './progress_element.module.scss';

const ProgressElement = (props) => {
  return (
    <div className={styles.container}>
      <label>
        {props.status}% {props.placeholder ? props.placeholder : ''}
      </label>
      <div className={styles.bar}>
        <div style={{ width: `${props.status ? props.status : 0}%` }}></div>
      </div>
    </div>
  );
};

export default ProgressElement;
