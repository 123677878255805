import { IndividualCareRequestType } from '@tallkingconnect/gateway';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/button/button';
import DatePicker from 'src/components/datepicker/datepicker';
import { YesNoDialog } from 'src/components/dialog/dialog';
import { Form, FormActions, FormRow } from 'src/components/form/form';
import LabeledFormInput from 'src/components/form/labeled_form_input';
import Information from 'src/components/information';
import InputNumber, { InputCurrency } from 'src/components/input_number';
import InputText from 'src/components/input_text';
import InputTextArea from 'src/components/input_textarea';
import LoadingOverlay from 'src/components/loader/loader';
import Select from 'src/components/select/select';
import { IndividualCareRoutes } from 'src/constants/individualCare';
import { useIndividualCareRequestMutation } from 'src/hooks/individualCare';
import useCommonData from 'src/hooks/useCommonData';
import styles from '../individualCare.module.scss';

const requiredCareRequestFields = [
  'targetDate',
  'hoursPerWeek',
  'maximumRate',
  'requiredDegree',
  'postcode',
  'incidentIdentifier',
  'careRequestInfo',
  'careRequestMoments',
];

const useCareRequestModelValidation = (model) => {
  const errors = [];
  const warnings = [];

  requiredCareRequestFields.forEach((key) => {
    if (_.isNil(model[key])) {
      errors.push([key, 'required']);
    }
  });

  return {
    errors,
    warnings,
    valid: errors.length === 0,
  };
};

// TODO put this in a separate component
const LabelWithInformation = ({ label, information }) => (
  <span>
    {label}
    <Information>{information}</Information>
  </span>
);

const CareRequestForm = ({ careRequest: initialCareRequest = {}, edit: _edit = false }) => {
  const { t } = useTranslation('page_individualCare');
  const navigate = useNavigate();

  const [careRequest, setCareRequest] = useState(initialCareRequest);
  const { valid } = useCareRequestModelValidation(careRequest);

  const [showConfirmCancel, setShowConfirmCancel] = useState(false);

  const {
    data: { degrees, teams },
  } = useCommonData();

  const [validDegrees, setValidDegrees] = useState([]);

  useEffect(() => {
    const invalidDegrees = ['MBO2', 'MBO2+'];
    const validDegrees = degrees.filter((degree) => !invalidDegrees.includes(degree.value));
    setValidDegrees(validDegrees);
  }, [degrees]);

  const updateModel = (key) => (value) =>
    setCareRequest((prevState) => {
      const updatedModel = {
        ...prevState,
        [key]: value,
      };
      return updatedModel;
    });

  const bindModel = (label, key, defaultValue, component, props = {}) => (
    <LabeledFormInput
      component={component}
      label={label}
      value={_.defaultTo(_.get(careRequest, key), defaultValue)}
      onChange={updateModel(key)}
      {...props}
    />
  );

  const mutation = useIndividualCareRequestMutation({
    onSuccess: (data) => {
      updateModel({ id: data.id });
      navigate(`${IndividualCareRoutes.care}/${data.id}`);
    },
  });

  const save = () => {
    // TODO find a better way to get the team for ONVZ
    const teamData = _.find(teams, { label: 'ONVZ' }).data;
    mutation.mutate({
      careRequest: {
        ...careRequest,
        districtTeamId: teamData.id,
        careSectorId: teamData.careSectorId,
        careType: teamData.typeOfCare,
        requestType: IndividualCareRequestType.Care,
      },
    });
  };

  return (
    <>
      <Form className={styles.careRequestForm}>
        <FormRow>
          {bindModel(t('form.incidentIdentifier', 'Referentienummer'), 'incidentIdentifier', '', InputText)}
          {bindModel(t('form.postcode', 'Postcode'), 'postcode', '', InputText)}
        </FormRow>
        <FormRow>
          {bindModel(t('form.hoursPerWeek', 'Uren per week'), 'hoursPerWeek', '', InputNumber)}
          {bindModel(
            <LabelWithInformation
              label={t('form.maximumHourlyRate', 'Maximaal uurtarief')}
              information={t('form.thisIsAnAllInclusiveRate', 'Dit is een all-in-tarief')}
            />,
            'maximumRate',
            '',
            InputCurrency
          )}
        </FormRow>
        <FormRow>
          {bindModel(t('form.startDate', 'Startdatum'), 'targetDate', '', DatePicker)}
          {bindModel(t('form.minimumEducation', 'Opleiding minimaal'), 'requiredDegree', null, Select, {
            options: validDegrees || [],
          })}
        </FormRow>
        <FormRow>
          {bindModel(t('form.careRequestInfo', 'Zorgvraag (verplicht)'), 'careRequestInfo', '', InputTextArea)}
          {bindModel(t('form.careMoments', 'Zorgmomenten (verplicht)'), 'careRequestMoments', '', InputTextArea)}
        </FormRow>
        <FormActions>
          <Button secondary onClick={() => setShowConfirmCancel(true)}>
            {t('form.cancel', 'Annuleren')}
          </Button>
          <Button disabled={!valid} onClick={save}>
            {t('form.save', 'Opslaan')}
          </Button>
        </FormActions>

        {showConfirmCancel && (
          <YesNoDialog
            onConfirm={() =>
              navigate(careRequest.id ? `${IndividualCareRoutes.care}/${careRequest.id}` : IndividualCareRoutes.care)
            }
            onCancel={() => setShowConfirmCancel(false)}
            labelQuestion={t(
              'form.showConfirmCancelDialogQuestion',
              'Weet je het zeker? Eventuele wijzigingen worden niet opgeslagen.'
            )}
          />
        )}
      </Form>

      {mutation.isLoading && <LoadingOverlay />}
    </>
  );
};

export default CareRequestForm;
