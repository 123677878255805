import clsx from 'clsx';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { PlusButton } from 'src/components/button/button';
import FilterToolbar from 'src/components/filter/filterToolbar';
import LoadingOverlay from 'src/components/loader/loader';
import { emptyArray, emptyObject } from 'src/constants';
import { useMyTeams } from 'src/hooks/teams';
import useCommonData from 'src/hooks/useCommonData';
import { useUserInformation } from 'src/hooks/users';
import { sortOptions } from 'src/utils/select';
import { emptyTeam } from './constants';
import TeamForm from './form';
import styles from './teams.module.scss';
import ViewTeam from './view';

const AddTeamButton = () => {
  const navigate = useNavigate();

  return <PlusButton small onClick={() => navigate('add')} />;
};

const ListTeams = ({ teams = [], wrap = true, ...props }) => {
  return (
    <div className={clsx(styles.list, wrap && styles.wrap)}>
      {teams.map((team) => (
        <ViewTeam key={team.id} team={team} {...props} />
      ))}
    </div>
  );
};

// TODO: add/edit in the same page is not the desired solution, however due to how the backend currently operates this is the fastest way to get it working.
// TODO: extract custom edit page for this in the future.
const Page = () => {
  const { t } = useTranslation(['page_teams', 'common']);
  const { hasRole } = useUserInformation();
  const {
    data: { companies: companyOptions },
  } = useCommonData();

  const isAdmin = hasRole('Admin');
  const isCompanyAdmin = hasRole('CompanyAdmin');

  // TODO: remove once we have separate views for adding/editing teams instead of inlining it in list view
  const navigate = useNavigate();
  const location = useLocation();
  const add = location.pathname.includes('/add');
  const edit = location.pathname.includes('/edit');
  const { teamId = null } = useParams();

  const [team, setTeam] = useState(null);
  const [visibleTeamIds, setVisibleTeamIds] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [visibleCompanyIds, setVisibleCompanyIds] = useState([]);

  const {
    data: { teams, careSectors, selectableAccounts, selectableCompanies } = emptyObject,
    isLoading: teamsLoading,
    isFetched: teamsFetched,
    refetch: refetchTeams,
  } = useMyTeams();

  useEffect(() => {
    if (add) {
      setTeam(emptyTeam);
    } else if (edit) {
      if (teams && teamId) {
        setTeam(teams.find((t) => t.data.id === teamId));
      }
    } else {
      setTeam();
    }
  }, [add, edit, teamId, teams]);

  const onSaveForm = () => {
    setTeam();
    refetchTeams();
    navigate('/teams');
  };

  const onCancelForm = () => {
    setTeam();
    navigate('/teams');
  };

  // Create options for team filter based on teams that the planner has access to
  const teamOptions = useMemo(
    () =>
      teamsFetched && teams
        ? sortOptions(
            teams
              .filter((team) => _.isEmpty(visibleCompanyIds) || visibleCompanyIds.includes(team.data.companyId))
              .map((team) => ({ value: team.value, label: team.label, data: team }))
          )
        : emptyArray,
    [teams, teamsFetched, visibleCompanyIds]
  );

  const filters = [
    isAdmin && {
      key: 'companyFilter',
      multiple: true,
      placeholder: t('common:filter.companies'),
      options: companyOptions,
      value: visibleCompanyIds,
      onChange: setVisibleCompanyIds,
    },
    {
      key: 'teamFilter',
      multiple: true,
      placeholder: t('common:filter.teams'),
      options: teamOptions,
      value: visibleTeamIds,
      onChange: setVisibleTeamIds,
    },
    {
      key: 'teamSearch',
      type: 'search',
      placeholder: t('list.searchTeams'),
      value: searchValue,
      onChange: setSearchValue,
    },
  ].filter(Boolean);

  const filteredTeams = useMemo(() => {
    let filtered = teams ? teams.map((team) => team.data) : [];

    if (!_.isEmpty(visibleCompanyIds)) {
      filtered = filtered.filter((team) => visibleCompanyIds.includes(team.companyId));
    }

    if (!_.isEmpty(visibleTeamIds)) {
      filtered = filtered.filter((team) => visibleTeamIds.includes(team.id));
    }

    if (!_.isEmpty(searchValue)) {
      filtered = filtered.filter((team) => team.name.toLowerCase().includes(searchValue.toLowerCase()));
    }

    return filtered;
  }, [searchValue, teams, visibleCompanyIds, visibleTeamIds]);

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        {teamsLoading && <LoadingOverlay />}

        {/* TODO: the team editor really doesn't belong here */}
        {add || edit ? (
          team &&
          teamsFetched && (
            <>
              <div className={styles.toolbar}>
                <h1>
                  {t('list.team', { defaultValue: 'Team', count: 1 })}{' '}
                  {edit ? t('list.edit', 'bewerken') : t('list.create', 'aanmaken')}
                </h1>
              </div>

              <div className={styles.formContainer}>
                <TeamForm
                  team={team.data}
                  careSectors={careSectors}
                  selectableCompanies={selectableCompanies}
                  selectableAccounts={selectableAccounts}
                  onSave={onSaveForm}
                  onCancel={onCancelForm}
                />
              </div>
            </>
          )
        ) : (
          <>
            <div className={styles.toolbar}>
              <h1>{t('list.team', { defaultValue: 'Team', count: 2 })}</h1>
              {(isAdmin || isCompanyAdmin) && <AddTeamButton disabled={Boolean(team)} />}
            </div>

            {teams && (
              <div className={styles.filteredTeams}>
                <FilterToolbar filters={filters} className={styles.filterToolbar} />

                {!_.isEmpty(filteredTeams) ? (
                  <ListTeams
                    teams={filteredTeams}
                    careSectors={careSectors}
                    selectableAccounts={selectableAccounts}
                    selectableCompanies={selectableCompanies}
                  />
                ) : (
                  <p className={styles.feedbackMessage}>{t('list.notFound', 'Keine Mannschaften gefunden')}</p>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Page;
