import clsx from 'clsx';
import { Info } from 'react-feather';
import styles from './information.module.scss';

// TODO: investigate if react-popup is a viable alternative.
// It seems to support popups which don't get truncated by their parent component.
// See here for an example: https://react-popup.elazizi.com/react-tooltip

const Information = ({ error = false, right = false, className, ...props }) => (
  <div className={clsx(styles.container, error && styles.error, className)}>
    <span className={styles.trigger}>
      <Info />
    </span>
    <div className={clsx(styles.message, right ? styles.right : styles.left)} {...props} />
  </div>
);

export default Information;
