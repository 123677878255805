/**
 * Builds a URL from a base URL and a set of search parameters.
 *
 * @param {URL|String} path - Base URL
 * @param {URLSearchParams|Object|Array} params - Search parameters to set
 * @param {Boolean?} asURL - When true, the raw URL will be returned. Otherwise, the result is a string.
 * @returns {URL|String}
 */
export const buildUrl = (path, params = {}, asURL = false) => {
  const url = new URL(path, window.location.origin);

  const searchParams = new URLSearchParams(params);
  searchParams.forEach((v, k) => url.searchParams.append(k, v));

  return asURL ? url : url.toString();
};

/**
 * Builds a link to the public Dutch KvK (chamber of commerce) order page, using a company number.
 * This can be used as a (minimalistic) way to verify company number correctness.
 *
 * @param {Number} companyNumber
 * @returns {String}
 */
export const buildKvkOrderUrl = (companyNumber) => `https://www.kvk.nl/orderstraat/bedrijf-kiezen/?q=${companyNumber}`;

/**
 * Builds a link to the public Dutch KvK (chamber of commerce) search page, using a company number and optionally more fields.
 * This can be used as a (minimalistic) way to verify company number correctness.
 *
 * Note that this gives the same results as {@link buildKvkOrderUrl}, when only the company number is used.
 * Adding more info may or may not improve search results, depending on the correctness of the entered data.
 */
export const buildKvkSearchUrl = (companyNumber, { street, houseNumber, postcode, city }) =>
  buildUrl('https://www.kvk.nl/zoeken/handelsregister/', {
    // Required
    kvknummer: companyNumber,

    // Optional. May improve results, or hide ones if they don't match, so we'll keep them disabled for now
    straat: street,
    huisnummer: houseNumber,
    postcode: postcode?.replace(/\s/g, ''),
    plaats: city,
  });

/**
 * Builds a link to the public Dutch BIG registry, using a BIG number.
 * This can be used to verify BIG number correctness.
 *
 * @param {Number} bigNumber
 * @returns {String}
 */
export const buildBigRegisterUrl = (bigNumber) => `https://zoeken.bigregister.nl/bignummer/${bigNumber}`;

/**
 * Builds a link to the public Dutch governmental Zorgaanbieder Portal (care provider portal) order page, using a company number.
 * This can be used to verify care provider correctness.
 *
 * @param {Number} companyNumber
 * @returns {String}
 */
export const buildZorgaanbiederPortaalUrl = (companyNumber) =>
  `https://zoeken.zorgaanbiedersportaal.nl/zorgaanbieder/-/${companyNumber}`;
