import Dialog from 'src/components/dialog';
import BookingDetails from './bookingDetails';

const ViewBookingDialog = (props) => (
  <Dialog showActions={false} labelQuestion={''} onCancel={props.onClose}>
    <BookingDetails {...props} />
  </Dialog>
);

export default ViewBookingDialog;
