import _ from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Navigate as CalendarNavigate } from 'src/components/calendar';
import { YesNoDialog } from 'src/components/dialog/dialog';
import FilterToolbar from 'src/components/filter/filterToolbar';
import LoadingOverlay from 'src/components/loader/loader';
import { emptyArray, emptyObject } from 'src/constants';
import { useMyBookingsForCalendar } from 'src/hooks/bookings';
import { createSelectOptions } from 'src/hooks/helpers';
import useCommonData from 'src/hooks/useCommonData';
import { useUserInformation } from 'src/hooks/users';
import { useGateway } from 'src/providers/gateway';
import { sortOptions } from 'src/utils/select';
import { createBookingEvent, createDraftBookingEvent, draftBookingEventToNewBooking } from './booking';
import BookingCalendar from './bookingCalendar';
import { BookingStatus, BookingStatusOptions } from './constants';
import DraftBookingDialog from './dialogs/draftBookingDialog';
import SelectCaregiversDialog from './dialogs/selectCaregiversDialog';
import ViewBookingDialog from './dialogs/viewBookingDialog';
import DraftBookingList from './draftBookingList';
import styles from './index.module.scss';

// const QuickAddToolbar = () => {
//   return <div className={styles.toolbar}></div>;
// };

const DefaultPlanner = () => {
  const { t } = useTranslation(['page_planning', 'common']);
  const { api } = useGateway();
  const { hasRole } = useUserInformation();
  const isAdmin = hasRole('Admin');
  const {
    data: { companies: companyOptions },
    isFetched: commonDataLoaded,
  } = useCommonData();

  const navigate = useNavigate();
  const { year, month } = useParams();
  const location = useLocation();

  const today = moment().startOf('day').toDate();
  const date = new Date(year, month - 1, 1);
  const onNavigate = (date, _view, action) =>
    navigate(`../${date.getFullYear()}/${date.getMonth() + 1}`, {
      state: { refetch: action === CalendarNavigate.REFRESH },
    });

  const [dialogData, setDialogData] = useState(null);
  const [processing, setProcessing] = useState(false);

  const [draftEvents, setDraftEvents] = useState([]);
  const [draftEvent, setDraftEvent] = useState();

  const [selectedEvent, setSelectedEvent] = useState();
  const onClose = () => setSelectedEvent();

  /**
   * Returns React properties for events.
   *
   * @type {EventPropGetter}
   */
  // const defaultEventPropGetter = (event, start, end, isSelected) => ({});

  const {
    isLoading: bookingsLoading,
    isFetched: bookingsFetched,
    data: {
      bookings: fetchedBookings = emptyArray,
      careSectors = emptyArray,
      degrees = emptyArray,
      teams = emptyArray,
    } = emptyObject,
    refetch,
  } = useMyBookingsForCalendar(date);

  useEffect(() => {
    const { refetch: shouldRefetch, ...state } = location.state ?? {};

    if (shouldRefetch) {
      refetch();

      navigate(`${location.pathname}${location.search}${location.hash}`, {
        state,
        replace: true,
      });
    }
  }, [location, navigate, refetch]);

  // Keep track of booking ids for which the dismiss status was updated. This way we don't have to re-fetch all bookings
  // anytime the alert status for one of the bookings is changed. We'll clean up items from the list once we receive new
  // bookings from the backend.
  const [dismissedAlertStatuses, setDismissedAlertStatuses] = useState(emptyArray);
  useEffect(() => {
    setDismissedAlertStatuses((prev) => prev.filter((id) => !fetchedBookings.some((booking) => booking.id === id)));
  }, [fetchedBookings]);

  // Merge bookings with their potentially updated alert status
  const bookings = useMemo(
    () =>
      fetchedBookings?.map((booking) =>
        dismissedAlertStatuses.includes(booking.id) ? { ...booking, newAlertStatus: false } : booking
      ),
    [fetchedBookings, dismissedAlertStatuses]
  );

  // Create booking events for consumption by the calendar
  const bookingEvents = useMemo(() => bookings.map(createBookingEvent), [bookings]);

  const careSectorOptions = useMemo(() => createSelectOptions(careSectors), [careSectors]);

  const educationOptions = useMemo(() => degrees.map((degree) => ({ value: degree, label: degree })), [degrees]);

  const [visibleCompanyIds, setVisibleCompanyIds] = useState([]);

  // Create options for team filter based on teams that the planner has access to
  const teamOptions = useMemo(
    () =>
      bookingsFetched
        ? sortOptions(
            createSelectOptions(
              teams.filter((team) => _.isEmpty(visibleCompanyIds) || visibleCompanyIds.includes(team.companyId))
            )
          )
        : emptyArray,
    [bookingsFetched, teams, visibleCompanyIds]
  );

  const [visibleTeamIds, setVisibleTeamIds] = useState([]);
  const [showBookingsWithoutTeam] = useState(false);

  const [caregiverOptions, setCaregiverOptions] = useState([]);
  const [visibleCaregiverIds, setVisibleCaregiverIds] = useState([]);
  const [showBookingsWithoutCaregiver] = useState(true);

  const [visibleStatusOptions, setVisibleStatusOptions] = useState(
    JSON.parse(localStorage.getItem('visibleStatusOptions')) || []
  );

  useEffect(() => {
    if (visibleStatusOptions) {
      localStorage.setItem('visibleStatusOptions', JSON.stringify(visibleStatusOptions));
    }
  }, [visibleStatusOptions]);

  // Create options for caregiver filter based on caregivers in the currently loaded bookings
  useEffect(() => {
    const options = _.uniqBy(
      bookings.map((booking) => booking.caregiver).filter(Boolean),
      (caregiver) => caregiver.id
    ).map((caregiver) => ({
      value: caregiver.id,
      label: `${caregiver.firstName} ${caregiver.lastName}`,
      profilePictureUrl: caregiver.profilePictureUrl,
      data: caregiver,
    }));

    // Add option to show bookings without a caregiver, if those exist
    if (bookings.some((booking) => !booking.caregiver)) {
      options.push({
        value: '',
        label: t('filter.noValue'),
        profilePictureUrl: null,
        data: {},
      });
    }

    setCaregiverOptions((prevCaregiverOptions) => {
      // Prevent selected items from disappearing from the list by adding them as additional options if needed
      const additionalOptions = prevCaregiverOptions.filter(
        (opt) => visibleCaregiverIds.includes(opt.value) && !options.find((nextOpt) => nextOpt.value === opt.value)
      );

      return sortOptions(options.concat(additionalOptions));
    });
  }, [bookings, t, visibleCaregiverIds]);

  const filters = [
    isAdmin && {
      key: 'companyFilter',
      multiple: true,
      placeholder: t('common:filter.companies'),
      options: commonDataLoaded ? companyOptions : emptyArray,
      value: visibleCompanyIds,
      onChange: setVisibleCompanyIds,
    },
    {
      key: 'teamFilter',
      multiple: true,
      placeholder: t('common:filter.teams'),
      options: teamOptions,
      value: visibleTeamIds,
      onChange: setVisibleTeamIds,
    },
    {
      key: 'caregiverFilter',
      multiple: true,
      placeholder: t('common:filter.caregivers'),
      options: caregiverOptions,
      value: visibleCaregiverIds,
      onChange: setVisibleCaregiverIds,
    },
    {
      key: 'statusFilter',
      multiple: true,
      placeholder: t('common:filter.status'),
      options: BookingStatusOptions(t).filter((option) => option.value !== BookingStatus.CancelledByPlanner),
      value: visibleStatusOptions,
      onChange: setVisibleStatusOptions,
    },
  ].filter(Boolean);

  const filteredTeamOptions = useMemo(
    () =>
      teamOptions.filter(
        (option) =>
          (_.isEmpty(visibleCompanyIds) || visibleCompanyIds.includes(option.data.companyId)) &&
          (_.isEmpty(visibleTeamIds) || visibleTeamIds.includes(option.value))
      ),
    [teamOptions, visibleCompanyIds, visibleTeamIds]
  );

  const filteredEvents = useMemo(
    () =>
      bookingEvents.filter((event) =>
        (event.booking.districtTeam
          ? (_.isEmpty(visibleCompanyIds) || visibleCompanyIds.includes(event.booking.districtTeam.companyId)) &&
            (_.isEmpty(visibleTeamIds) || visibleTeamIds.includes(event.booking.districtTeam.id))
          : showBookingsWithoutTeam) &&
        (event.booking.caregiver
          ? _.isEmpty(visibleCaregiverIds) || visibleCaregiverIds.includes(event.booking.caregiver.id)
          : showBookingsWithoutCaregiver) &&
        event.booking.status
          ? _.isEmpty(visibleStatusOptions) || visibleStatusOptions.includes(event.booking.status)
          : null
      ),
    [
      bookingEvents,
      visibleStatusOptions,
      showBookingsWithoutCaregiver,
      showBookingsWithoutTeam,
      visibleCaregiverIds,
      visibleCompanyIds,
      visibleTeamIds,
    ]
  );

  const revokeBooking = async (id) => {
    try {
      await api.bookings.revokeBooking({ id });

      setSelectedEvent();
      refetch();
    } catch (err) {
      console.error('Error revoking booking:', err);
    }
  };

  const saveDirectBooking = async (directRequest) => {
    setProcessing(true);
    try {
      await api.directBookings.saveDirectBooking(directRequest);

      setSelectedEvent();
      refetch();
    } catch (err) {
      console.error('Error saving booking:', err);
    } finally {
      setProcessing(false);
    }
  };

  // Only allow creating events in the future.
  // NB: we could in theory allow adding events in the past for correctional bookings.
  const canAddEvent = (date) => date >= today;

  const onSelectSlot = ({ slots: _slots, start, end }) => {
    if (canAddEvent(start)) {
      // Replace draft event with a new one
      const draftEvent = createDraftBookingEvent({ start, end });

      setDraftEvent(draftEvent);
    }
  };

  const onSelectEvent = (event) => {
    if (draftEvents.includes(event)) {
      setDraftEvent(event);
    } else {
      setSelectedEvent(event);
      if (event.booking.newAlertStatus) {
        updateBookingAlertStatus(event);
      }
    }
  };

  const addOrUpdateDraftEvent = (event) => {
    setDraftEvents(_.reject(draftEvents, ['meta.draftId', event.meta.draftId]).concat(event));
  };

  const confirmDraftEvent = (event) => {
    addOrUpdateDraftEvent(event);
    setDraftEvent();
    onClose();
  };

  const removeDraftEvent = (draftId) => {
    setDraftEvents(_.reject(draftEvents, ['meta.draftId', draftId]));
  };

  // Save a batch of bookings
  const saveBookings = async () => {
    setProcessing(true);
    try {
      await api.bookings.saveBookings({ newBookings: draftEvents.map(draftBookingEventToNewBooking) });

      setDraftEvents([]);
      refetch();
      onCancel();
    } catch (err) {
      console.error('Error saving bookings:', err);
      alert(t('defaultPlanner.errorMessage', 'Er trad een fout op!'));
    } finally {
      setProcessing(false);
    }
  };

  const saveDurationRequest = async (durationRequest) => {
    setProcessing(true);
    try {
      await api.durationRequests.createDurationRequestAsAdmin(durationRequest);

      refetch();
      onClose();
    } catch (err) {
      console.error('Error saving request:', err);
      alert(t('defaultPlanner.errorMessage', 'Er trad een fout op!'));
    } finally {
      setProcessing(false);
    }
  };

  const updateBookingAlertStatus = async (event) => {
    const bookingId = event.booking.id;
    setProcessing(true);
    try {
      await api.bookings.updateBookingAlertStatus({ id: bookingId });

      setDismissedAlertStatuses([...dismissedAlertStatuses, bookingId]);
    } catch (err) {
      console.error('Error updating booking alert status:', err);
    } finally {
      setProcessing(false);
    }
  };

  const [showCaregiverSelection, setShowCaregiverSelection] = useState(false);

  const onCancel = () => setShowCaregiverSelection(false);

  const showDialog = (labelQuestion, onConfirm) =>
    setDialogData({
      labelQuestion,
      onConfirm,
      onCancel: () => setDialogData(),
    });

  const confirmSaveBookings = () =>
    showDialog(
      t(
        'defaultPlanner.extraCheckDialogQuestion',
        'Een extra check kan nooit kwaad. Wil je deze boekingen bevestigen?'
      ),
      saveBookings
    );
  const confirmRemoveDraftBooking = (draftId) =>
    showDialog(
      t('defaultPlanner.deleteBookingDialogQuestion', 'Weet je zeker dat je deze boeking wilt verwijderen?'),
      () => removeDraftEvent(draftId)
    );

  return (
    <>
      {dialogData && <YesNoDialog {...dialogData} />}

      <div className={styles.container}>
        {showCaregiverSelection ? (
          <SelectCaregiversDialog
            drafts={draftEvents}
            careSectorOptions={careSectorOptions}
            educationOptions={educationOptions}
            teamOptions={teamOptions}
            onChange={setDraftEvents}
            onConfirm={confirmSaveBookings}
            onCancel={onCancel}
            onEdit={onSelectEvent}
          />
        ) : (
          <BookingCalendar
            date={date}
            onNavigate={onNavigate}
            onSelectSlot={onSelectSlot}
            onSelectEvent={onSelectEvent}
            events={filteredEvents}
            draftEvents={draftEvents}
            draftEventsListComponent={(props) => (
              <DraftBookingList
                onDeleteItem={confirmRemoveDraftBooking}
                onProceed={() => setShowCaregiverSelection(true)}
                {...props}
              />
            )}
            draftEvent={draftEvent}
            toolbars={[<FilterToolbar key="filters" filters={filters} />]}
          />
        )}

        {draftEvent && (
          <DraftBookingDialog
            // options
            teamOptions={filteredTeamOptions}
            careSectorOptions={careSectorOptions}
            educationOptions={educationOptions}
            // filled in data
            draftEvent={draftEvent}
            // event handlers
            onConfirm={confirmDraftEvent}
            onCancel={() => setDraftEvent()}
          />
        )}

        {selectedEvent && (
          <ViewBookingDialog
            bookingEvent={selectedEvent}
            revokeBooking={revokeBooking}
            educationOptions={educationOptions}
            careSectorOptions={careSectorOptions}
            teamOptions={filteredTeamOptions}
            saveDirectRequest={saveDirectBooking}
            confirmDraftEvent={confirmDraftEvent}
            saveDurationRequest={saveDurationRequest}
            onClose={onClose}
          />
        )}

        {(bookingsLoading || processing) && <LoadingOverlay />}
      </div>
    </>
  );
};

const DefaultPlannerRoutes = () => {
  const now = new Date();

  return (
    <Routes>
      <Route path=":year/:month/" element={<DefaultPlanner />} />
      <Route path="*" element={<Navigate to={`${now.getFullYear()}/${now.getMonth() + 1}`} />} />
    </Routes>
  );
};

export default DefaultPlannerRoutes;
