import { IndividualCareRequestStatus, IndividualCareRequestType } from '@tallkingconnect/gateway';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PlusButton } from 'src/components/button/button';
import LoadingOverlay from 'src/components/loader/loader';
import { useDefaultIndividualCareStatuses, useIndividualCareRequests } from 'src/hooks/individualCare';
import { useUserInformation } from 'src/hooks/users';
import { formatDate } from 'src/utils';
import styles from '../individualCare.module.scss';

const IndividualCareRequestStatusStyles = {
  [IndividualCareRequestStatus.New]: null, //styles.new,
  [IndividualCareRequestStatus.Active]: null, //styles.active,
  [IndividualCareRequestStatus.Pending]: null, //styles.pending,
  [IndividualCareRequestStatus.Inactive]: styles.inactive,
  [IndividualCareRequestStatus.CancelledByCareConsultant]: styles.cancelled,
  [IndividualCareRequestStatus.Accepted]: null, //styles.accepted,
  [IndividualCareRequestStatus.CancelledByCaregiver]: styles.cancelled,
};

const ReactionsBadge = ({ count, ...props }) => {
  const { t } = useTranslation('page_individualCare');

  return count > -100 ? (
    <div
      className={styles.reactionsBadge}
      title={t('list.reactions', { count, defaultValue: '{{count}} reactions' })}
      {...props}
    >
      {count}
    </div>
  ) : (
    <div />
  );
};

const ListHeader = () => {
  const { t } = useTranslation('page_individualCare');
  const { hasRole } = useUserInformation();
  const isCaregiver = hasRole('Caregiver');
  return (
    <li className={styles.header}>
      <div>{t('list.sinceState')}</div>
      <div>{t('list.incidentIdentifier')}</div>
      <div>{t('list.postalCode')}</div>
      <div>{t('list.deadline')}</div>
      {isCaregiver ? <div>{t('list.teamName')}</div> : <div>{t('list.responsibleCareConsultant')}</div>}
      <div>{t('list.reactions')}</div>
    </li>
  );
};
const ListItem = ({
  indicationRequest: {
    id,
    incidentIdentifier,
    targetDate,
    postcode,
    createdAt,
    teamName,
    status,
    createdBy,
    reactionCount = 0,
  },
}) => {
  const { t } = useTranslation(['page_individualCare', 'common']);
  const navigate = useNavigate();
  const { hasRole } = useUserInformation();
  const isCaregiver = hasRole('Caregiver');

  const FormattedDate = ({ date }) => <div>{formatDate(date, { format: t('common:dateFormat') })}</div>;

  // Opening the reaction list is only available to care consultant and admins
  const openReactions =
    hasRole('CareConsultant') || hasRole('Admin')
      ? (e) => {
          navigate(`${id}/reactions`);
          e.stopPropagation();
        }
      : undefined;

  return (
    <li className={clsx(styles.row, IndividualCareRequestStatusStyles[status])} onClick={() => navigate(id)}>
      <div>
        <FormattedDate date={createdAt} />
        <div>{t(`constants.individualCareRequestStatusLabels.${status}`)}</div>
      </div>
      <div>{incidentIdentifier} </div>
      <div>{postcode}</div>
      <div>
        <FormattedDate date={targetDate} />
      </div>
      {isCaregiver ? <div>{teamName}</div> : <div>{createdBy}</div>}
      <div>{<ReactionsBadge count={reactionCount} onClick={openReactions} />}</div>
    </li>
  );
};

const AddIndicationRequestButton = () => {
  const navigate = useNavigate();

  return <PlusButton small onClick={() => navigate('add')} />;
};

const ListIndicationRequests = () => {
  const { t } = useTranslation('page_individualCare');
  const { hasRole, data: user } = useUserInformation();
  const canCreateIndicationRequest = hasRole('CareConsultant') || hasRole('Admin');
  const canViewIndicationRequests = canCreateIndicationRequest || !user.profileInactive;

  const statuses = useDefaultIndividualCareStatuses();
  const indicationRequests = useIndividualCareRequests({ type: IndividualCareRequestType.Indication, statuses });

  return indicationRequests.isFetched ? (
    <div className={styles.container}>
      <div className={styles.toolbar}>{canCreateIndicationRequest && <AddIndicationRequestButton />}</div>
      <ul className={styles.list}>
        {canViewIndicationRequests ? (
          indicationRequests.data.length ? (
            <>
              <ListHeader />
              {indicationRequests.data.map((indicationRequest) => (
                <ListItem key={indicationRequest.id} indicationRequest={indicationRequest} />
              ))}
            </>
          ) : (
            <div className={styles.empty}>{t('list.empty', 'Geen aanvragen om weer te geven')}</div>
          )
        ) : (
          <div className={styles.empty}>
            {t(
              'list.inactive',
              'Je profiel is inactief, dus je kunt niet reageren op boekingen. Check je profiel om de reden te zien.'
            )}
          </div>
        )}
      </ul>
    </div>
  ) : (
    <LoadingOverlay />
  );
};

const Page = () => {
  return (
    <div className={styles.page}>
      <ListIndicationRequests />
    </div>
  );
};

export default Page;
