import clsx from 'clsx';
import { HelpCircle } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import styleConfig from 'src/styles/config.module.scss';
import styles from './user_help_bar.module.scss';

// TODO: treat language separate from region, i.e., phone numbers are generally region dependent, not language dependent
const phoneNumbers = {
  nl: {
    link: 'tel:+31303071252',
    label: '030 30 71 252',
  },
  en: {
    link: 'tel:+31303071252',
    label: '030 30 71 252',
  },
};

const email = {
  link: 'mailto:hallo@tallkingconnect.com',
  label: 'hallo@tallkingconnect.com',
};

const UserHelpBar = (props) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation('components');

  const phoneNumber = phoneNumbers[resolvedLanguage];

  return (
    <div className={clsx(styles.container, props.className)}>
      <HelpCircle size={50} color={styleConfig.gray} />
      <p>
        <Trans
          t={t}
          i18nKey="userHelpBar"
          defaults="<strong>Got questions?</strong><br/>We have answers.<br/>Call us at <phone>{{phone}}</phone>,<br/>or send an email to <email>{{email}}</email>."
          components={{
            strong: <strong />,
            br: <br />,
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            phone: <a href={phoneNumber.link} className="phone" />,
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            email: <a href={email.link} />,
          }}
          values={{
            phone: phoneNumber.label,
            email: email.label,
          }}
        />
      </p>
    </div>
  );
};

export default UserHelpBar;
