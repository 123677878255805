import A from 'src/components/a';
import { formatDate } from 'src/utils';
import styles from './careAgreement.module.scss';
import { CareAgreementModel } from '@tallkingconnect/gateway';

interface CareAgreementProps {
  model?: CareAgreementModel;
}

const CareAgreement: React.FC<CareAgreementProps> = ({ model }) => (
  <div className={styles.container}>
    <h2>Overeenkomst van opdracht met zelfstandige zonder personeel voor de verlening van zorg.</h2>

    <p>De ondergetekenden,</p>

    <div className={styles.parties}>
      <table>
        <tbody>
          <tr>
            <td colSpan={2}>
              <h3>Opdrachtgever</h3>
            </td>
          </tr>
          <tr>
            <td>De organisatie:</td>
            <td>{model?.districtTeamCompanyName}</td>
          </tr>
          <tr>
            <td>Gevestigd te:</td>
            <td>{model?.districtTeamCity}</td>
          </tr>
          <tr>
            <td>Straat en huisnummer:</td>
            <td>{model?.districtTeamAddress}</td>
          </tr>
          <tr>
            <td>Telefoon:</td>
            <td>{model?.districtTeamPhone}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <h3>Opdrachtnemer</h3>
            </td>
          </tr>
          <tr>
            <td>Voorletters en achternaam:</td>
            <td>{model?.caregiverName}</td>
          </tr>
          <tr>
            <td>Geboortedatum:</td>
            <td>{model?.caregiverDateOfBirth && formatDate(model?.caregiverDateOfBirth)}</td>
          </tr>
          <tr>
            <td>Straat en huisnummer:</td>
            <td>{model?.caregiverAddress}</td>
          </tr>
          <tr>
            <td>Postcode en plaats:</td>
            <td>
              {model?.caregiverPostcode} {model?.caregiverCity}
            </td>
          </tr>
          <tr>
            <td>Telefoon:</td>
            <td>{model?.caregiverPhone}</td>
          </tr>
          <tr>
            <td>KvK nummer:</td>
            <td>{model?.caregiverCompanyNumber}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <p>Opdrachtgever en Opdrachtnemer worden in het navolgende gezamenlijk aangeduid als: Partijen.</p>
    <p>Nemen het volgende in overweging: </p>
    <ol>
      <li>
        De Opdrachtgever is een organisatie die (onder andere) zorg verleent aan cliënten op het gebied van verpleging
        en verzorging;
      </li>
      <li>
        De Opdrachtnemer is een zelfstandige, solistisch werkende zorgverlener die bevoegd en bekwaam is op het gebied
        van deze zorg;
      </li>
      <li>
        De Opdrachtgever heeft voor de uitvoering van de in deze overeenkomst beschreven werkzaamheden tijdelijk
        behoefte aan externe ondersteuning;
      </li>
      <li>De Opdrachtnemer heeft aangegeven bereid en in staat te zijn deze externe ondersteuning te bieden;</li>
      <li>De Opdrachtnemer heeft aangegeven bereid en in staat te zijn deze externe ondersteuning te bieden;</li>
      <li>
        Opdrachtgever na bemiddeling van TallKing Connect aan opdrachtnemer de opdracht wenst te verstrekken om voor hem
        werkzaamheden te verrichten zoals overeengekomen in deze overeenkomst;
      </li>
      <li>
        Opdrachtnemer vrij is in het al dan niet sluiten van overeenkomsten van opdracht met andere opdrachtgevers;
      </li>
      <li>
        De Opdrachtgever wenst aan de Opdrachtnemer de opdracht te verstrekken om buiten dienstverband voor hem
        werkzaamheden te verrichten onder de voorwaarden zoals opgenomen in deze Overeenkomst;
      </li>
      <li>
        De Opdrachtnemer verleent in praktische zin de feitelijke zorg in natura zelfstandig aan de Cliënt van
        Opdrachtgever en is &ndash; met inachtneming van het bepaalde in deze overeenkomst &ndash; vrij te bepalen op
        welke wijze de opdracht wordt uitgevoerd;
      </li>
      <li>
        Onverminderd de eindverantwoordelijkheid van Opdrachtgever in haar hoedanigheid van dossierhouder ten opzichte
        van de cliënt en contractspartij ten aanzien van derden, heeft de Opdrachtnemer een eigen verantwoordelijkheid
        op basis van de toepasselijke kwaliteitswetgeving, waaronder de Wet kwaliteit klachten en geschillen zorg (Wkkgz
        <a id="fn1-ref" href="#fn1">
          <sup>1</sup>
        </a>
        ), en is zelf altijd verantwoordelijk voor en aanspreekbaar op zijn of haar professionele handelen in de
        praktijk;
      </li>
      <li>
        Deze Overeenkomst wordt door Partijen uitsluitend gekwalificeerd als een overeenkomst van opdracht in de zin van
        artikel 7:400 e.v. BW;
      </li>
      <li>
        Partijen wensen met deze Overeenkomst uitdrukkelijk geen arbeidsovereenkomst in de zin van artikel 7:610 e.v. BW
        te sluiten noch een fictieve dienstbetrekking tot stand te laten komen. Opdrachtnemer realiseert zich dat de
        beschermende bepalingen van het arbeidsrecht op de Overeenkomst geen toepassing vinden;
      </li>
      <li>
        Opdrachtnemer is op de hoogte van de (fiscale) vereisten van (fiscaal) ondernemerschap en verklaart door
        ondertekening van deze overeenkomst hieraan te voldoen en te zullen blijven voldoen;
      </li>
      <li>Het staat Opdrachtnemer uitdrukkelijk vrij om ook voor derden werkzaam te zijn;</li>
      <li>
        Opdrachtnemer is op de hoogte van de Wet toetreding zorgaanbieders (Wtza
        <a id="fn2-ref" href="#fn2">
          <sup>2</sup>
        </a>
        ) en verklaart aan de van toepassing zijnde vereisten te voldoen.
      </li>
    </ol>
    <p>verklaren als volgt te zijn overeengekomen:</p>
    <div>
      <ol className={styles.flat}>
        <li>
          <h3>Artikel 1 &mdash; Opdrachtverlening</h3>
          <ol>
            <li>
              Opdrachtgever wenst gebruik te maken van de kennis en ervaring van Opdrachtnemer, als hierboven genoemd
              onder “in aanmerking nemende dat”. Daartoe verleent Opdrachtgever aan Opdrachtnemer, na bemiddeling door
              bemiddelaar, opdracht om in het kader van de uitoefening van zijn beroep en/of bedrijf de volgende
              werkzaamheden door Opdrachtnemer te laten verrichten (hierna: “de opdracht”).
            </li>
          </ol>
        </li>
        <li>
          <h3>Artikel 2 &mdash; De Opdracht</h3>
          <ol>
            <li>
              Opdrachtnemer accepteert de opdracht en aanvaardt daarmee de volle verantwoordelijkheid voor het op juiste
              wijze uitvoeren van de overeengekomen werkzaamheden.
            </li>
            <li>
              De Opdrachtnemer zal voor de Opdrachtgever Zorg in natura uitvoeren, te weten persoonlijke verzorging
              en/of verpleging zoals beschreven in Beleidsregel BR/REG 22101b van de Nederlandse Zorgautoriteit
              <a id="fn3-ref" href="#fn3">
                <sup>3</sup>
              </a>
              . Vanwege het dynamische karakter van het zorgplan zal Opdrachtnemer het zorgplan van een cliënt
              regelmatig bijhouden conform de daarop van toepassing zijnde regelgeving. De individuele onderdelen van de
              opdracht per cliënt volgen steeds uit het zorgdossier van de betreffende cliënt, ten aanzien van wie de
              Opdrachtnemer opdracht krijgt om zorg te verlenen.
            </li>
            <li>
              De Opdrachtnemer is bij het uitvoeren van de overeengekomen werkzaamheden geheel zelfstandig.
              Opdrachtnemer verricht de overeengekomen werkzaamheden naar eigen inzicht en zonder toezicht of leiding
              van opdrachtgever. Opdrachtgever kan wel aanwijzingen en instructies geven omtrent het resultaat van de
              opdracht. Opdrachtnemer verleent zelfstandig de feitelijke zorg in natura aan de Klant en een eigen
              verantwoordelijkheid heeft op basis van de Wkkgz en vanuit die rol zelf altijd verantwoordelijk is voor-
              en aanspreekbaar zal zijn op zijn of haar professionele handelen in de praktijk. Aangezien partijen elkaar
              uitsluitend willen contracteren op basis van deze overeenkomst, verbinden partijen zich ertoe om hun
              feitelijke gedragingen bij de uitvoering van de zorgwerkzaamheden in overeenstemming te laten zijn met de
              inhoud en de strekking van deze overeenkomst;
            </li>
            <li>
              Opdrachtnemer deelt zijn werkzaamheden zelfstandig in. Wel vindt, voor zover dat voor de uitvoering van de
              opdracht nodig is, afstemming met opdrachtgever plaats in geval van samenwerking met anderen, zodat deze
              optimaal zal verlopen. Indien noodzakelijk voor de werkzaamheden richt opdrachtnemer zich naar de
              arbeidstijden bij opdrachtgever.
            </li>
            <li>
              De Opdrachtgever kan aanwijzingen en/of richtlijnen geven aan Opdrachtnemer als bedoeld in artikel 7:402
              BW;
            </li>
            <li>De Opdrachtgever wijst een contactpersoon aan voor de Opdrachtnemer</li>
            <li>
              Opdrachtgever kan Opdrachtnemer tussentijds en aan het einde van de opdracht verzoeken om verslag uit te
              brengen over de voortgang van de Opdracht;
            </li>
            <li>
              Opdrachtnemer garandeert dat hij/zij gekwalificeerd, bevoegd en bekwaam is en blijft om de Zorg in natura
              te verlenen welke door Opdrachtgever in de Opdracht wordt gevraagd.
            </li>
          </ol>
        </li>

        <li>
          <h3>Artikel 3 &mdash; Duur van de Overeenkomst</h3>
          <ol>
            <li>
              De overeenkomst wordt aangegaan voor de duur van de opdracht, met ingang van de datum/tijd van de boeking;
            </li>
            <li>
              Elk der partijen heeft het recht om de overeenkomst zonder ingebrekestelling en met onmiddellijke ingang
              op te zeggen:
              <ul>
                <li>Indien de wederpartij surseance van betaling aanvraagt of;</li>
                <li>in staat van faillissement wordt verklaard of;</li>
                <li>anderszins in een blijvende toestand van betalingsonmacht verkeert, dan wel wordt ontbonden;</li>
                <li>
                  Indien sprake is van onvoorziene omstandigheden, daaronder begrepen wijziging van (de handhaving van)
                  wet- en regelgeving, die niet voor rekening van de opzeggende partij komen, en die zo ingrijpend zijn
                  dat van de opzeggende partij instandhouding van de overeenkomst niet gevergd kan worden;
                </li>
                <li>de wederpartij niet voldoet aan diens verplichtingen uit deze overeenkomst;</li>
                <li>
                  de wederpartij haar activiteiten staakt, of overdraagt, wordt ontbonden of feitelijk wordt
                  geliquideerd;
                </li>
                <li>de wederpartij de vrije beschikking over (een deel van) haar vermogen verliest;</li>
                <li>
                  beslag wordt gelegd op een of meer goederen van de wederpartij, tenzij dit beslag ten onrechte is.
                </li>
              </ul>
            </li>
          </ol>
        </li>

        <li>
          <h3>Artikel 4 &mdash; Werktijden</h3>
          <ol>
            <li>De werktijden zijn direct gekoppeld aan de overeengekomen datum, tijdstip en duur van de opdracht.</li>
          </ol>
        </li>

        <li>
          <h3>Artikel 5 &mdash; Aanvullende afspraken</h3>
          <ol>
            <li>
              Diensten/opdrachten kunnen tot 48 uur voor de aanvang van de dienst kosteloos worden geannuleerd door
              Opdrachtgever. Wordt een dienst binnen 48 uur voor de aanvang van de dienst geannuleerd, dan zal door
              Opdrachtnemer het van origine geboekt aantal uren in rekening worden gebracht worden bij Opdrachtgever.
            </li>
          </ol>
        </li>

        <li>
          <h3>Artikel 6 &mdash; De Vergoeding</h3>
          <ol>
            <li>
              Opdrachtgever is aan opdrachtnemer een vergoeding verschuldigd voor de uitvoering van de opdracht.
              Opdrachtgever blijft onder alle omstandigheden verantwoordelijk voor nakoming van deze
              betalingsverplichting aan Opdrachtnemer.
            </li>
            <li>
              Opdrachtnemer geeft achteraf na afloop van een boeking aan TallKing Connect door hoeveel uur er is
              gewerkt. Dit leidt tot een factuur van TallKing Connect aan opdrachtgever die de betaling voldoet binnen
              30 dagen;
            </li>
            <li>
              Betaling vindt plaats van opdrachtgever aan TallKing Connect, waarna TallKing Connect de betaling aan
              opdrachtnemer verricht maximaal 7 dagen na ontvangst van de betaling door opdrachtgever aan TallKing
              Connect. Partijen gaan ervan uit dat de dienstverlening is vrijgesteld van btw op basis van artikel 11 van
              de wet op de Omzetbelasting 1968;
            </li>
            <li>
              Opdrachtnemer ontvangt geen vergoeding ter zake van uren waarin Opdrachtnemer geen werkzaamheden ten
              behoeve van Opdrachtgever heeft verricht;
            </li>
            <li>
              Opdrachtnemer is zich ervan bewust dat bij zijn/haar arbeidsongeschiktheid geen aanspraak bestaat op enige
              betaling door Opdrachtgever. Indien de werkzaamheden niet door opdrachtnemer zelf, maar door een derde
              (vervanger), zijn uitgevoerd en deze voldoet aan de voorwaarden zoals opgenomen in artikel 7, factureert
              de vervangende derde de zorg in natura zoals bepaald in lid 1 en lid 2 van dit artikel;
            </li>
            <li>
              Door Opdrachtnemer gemaakte overige kosten, specifiek verband houdende met deze opdracht &ndash; en pas
              gemaakt na uitdrukkelijke toestemming van de Opdrachtgever &ndash; worden op dezelfde wijze als de
              vergoeding via TallKing Connect vergoed.
            </li>
          </ol>
        </li>

        <li>
          <h3>Artikel 7 &mdash; Rechten & verplichtingen opdrachtnemer en opdrachtgever</h3>
          <ol>
            <li>
              Opdrachtnemer zal bij de uitvoering van de opdracht de zorg van een goed opdrachtnemer in acht nemen.
              Opdrachtnemer beschikt voor de duur van de opdracht over de bevoegdheden en bekwaamheden die nodig zijn
              voor een juiste en tijdige uitvoering van de Opdracht, en kan dit aantonen door op verzoek de volgende
              documenten te overleggen:
              <ul>
                <li>Een geldig legitimatiebewijs (paspoort of identiteitsbewijs, geen rijbewijs);</li>
                <li>diploma’s;</li>
                <li>certificaten van bijscholing;</li>
                <li>een geldige VOG;</li>
                <li>een KvK-nummer;</li>
                <li>aansluiting bij erkende geschillencommissie + Wtza aansluiting;</li>
                <li>bewijs van beroeps- en bedrijfsaansprakelijkheidsverzekering;</li>
                <li>BIG registratie (wanneer van toepassing).</li>
              </ul>
            </li>
            <li>
              Opdrachtnemer garandeert voorts dat hij voldoet en blijft voldoen aan de beroepseisen ten aanzien van
              (bij)scholing, opleiding, deskundigheid, ervaring, et cetera;
            </li>
            <li>
              Opdrachtnemer zorgt ervoor dat interne richtlijnen, protocollen en gedragsregels, die Opdrachtgever in het
              kader van de op hem rustende eindverantwoordelijkheid voor de zorg heeft opgesteld, worden nageleefd voor
              zover deze zijn gebaseerd op de zorgwet- en regelgeving, op nadere aanwijzingen van toezichthoudende
              organen of op contractuele afspraken met zorgverzekeraars, zorgkantoren of gemeenten. Opdrachtgever
              voldoet hierdoor aan de op hem rustende verplichting in het kader van de kwaliteitswetgeving, waaronder
              artikel 4 van de Wkkgz. Opdrachtnemer dient in dit kader tevens vóór de ondertekening van deze
              overeenkomst en de aanvang van de werkzaamheden een kopie van zijn/haar geldige of actuele Verklaring
              omtrent Gedrag beschikbaar te hebben en op verzoek aan Opdrachtgever te verstrekken en (indien van
              toepassing) een bewijs van inschrijving in het BIG-register;
            </li>
            <li>
              Opdrachtnemer dient op verzoek zijn/haar inschrijving bij de Kamer van Koophandel, zijn/haar btw-nummer en
              eventueel een kopie van zijn/haar werk- en/of verblijfsvergunning aan Opdrachtgever te verstrekken.
              Opdrachtgever kan middels een verzoek aan TallKing Connect inzage krijgen in de gegevens;
            </li>
            <li>
              Indien Opdrachtnemer verhinderd is de Zorg in natura aan de Klanten te verlenen, zal Opdrachtnemer
              Opdrachtgever onverwijld van de verhindering en de verwachte duur daarvan (telefonisch) op de hoogte
              stellen wanneer de dienst binnen 48 uur plaats vindt. Opdrachtnemer dient bij verhindering, na overleg met
              Opdrachtgever, zelf voor vervanging zorg te dragen. De vervanger dient met het oog op het verlenen van
              verantwoorde zorg aan dezelfde beroepseisen te voldoen die in de Overeenkomst aan Opdrachtnemer worden
              gesteld en daarop gebaseerd een gescreend profiel bij TallKing Connect te hebben. Opdrachtgever heeft het
              recht van Opdrachtnemer te verlangen dat Opdrachtnemer een andere vervanger aanstelt, indien Opdrachtgever
              gemotiveerd kan aangeven dat de door Opdrachtnemer aangedragen vervanger ongeschikt is voor het verlenen
              van de Zorg in natura (bijv. op basis van eerdere negatieve ervaringen met Opdrachtnemer, een gegronde
              klacht tegen Opdrachtnemer, etc.) dan wel niet aan de in de Overeenkomst aan de Opdrachtnemer gestelde
              beroepseisen voldoet.
            </li>
            <li>
              Indien Opdrachtnemer niet voor geschikte vervanging kan zorgdragen zal er in gezamenlijk overleg tussen
              Opdrachtgever en Opdrachtnemer naar een andere passende oplossing worden gezocht;
            </li>
            <li>
              In geval van vervanging als bedoeld onder lid 5 blijft de Opdrachtnemer naast de eigen professionele
              verantwoordelijkheid van de vervanger zowel jegens Opdrachtgever als Klant mede op basis van de Wkkgz
              verantwoordelijk voor een kwalitatief goede uitvoering van de Zorg in natura;
            </li>
            <li>
              Opdrachtgever zal Opdrachtnemer in staat stellen de overeengekomen Zorg in natura te verlenen, door al
              hetgeen in dat kader redelijkerwijze van Opdrachtgever kan worden verlangd, te doen;
            </li>
            <li>
              Bij Opdrachtgever is een klachtenprocedure van toepassing overeenkomstig de van toepassing zijnde
              kwaliteitswetgeving, waaronder de Wkkgz. In geval de cliënt klachten heeft over de uitvoering van de zorg
              en deze niet in onderling overleg tussen Opdrachtnemer en cliënt kunnen worden verholpen, zal de cliënt
              gebruik kunnen maken van deze klachtenprocedure. Hoewel Opdrachtnemer een eigen klachtenregeling heeft,
              verklaart hij/zij de klachtenregeling van Opdrachtgever van toepassing op de Opdracht. Opdrachtnemer
              verklaart dat hij/zij voldoet aan de eisen die de van toepassing zijnde kwaliteitswetgeving, waaronder de
              Wkkgz, stelt aan zorgaanbieder terzake de geschillenbehandeling.
            </li>
          </ol>
        </li>

        <li>
          <h3>Artikel 8 &mdash; Aansprakelijkheid</h3>
          <ol>
            <li>
              Opdrachtgever is niet aansprakelijk voor schade die aan Opdrachtnemer en/of door hem ingeschakelde
              derde(n) wordt toegebracht bij de uitvoering van de uit de Opdracht voortvloeiende werkzaamheden;
            </li>
            <li>
              Opdrachtnemer vrijwaart Opdrachtgever voor aanspraken van derde(n) , waaronder door Opdrachtnemer
              ingeschakelde derde(n), voortvloeiend uit schade die door Opdrachtnemer en/of door hem ingeschakelde
              derde(n)is veroorzaakt bij de werkzaamheden in het kader van de Opdracht;
            </li>
            <li>
              Tenzij sprake is van opzet of bewuste roekeloosheid van Opdrachtgever of diens personeel, is Opdrachtgever
              niet aansprakelijk voor en vrijwaart Opdrachtnemer Opdrachtgever tegen, elke vordering of eis die tegen
              Opdrachtgever mocht worden ingesteld ter zake van schade als gevolg van ziekte, letsel en/of overlijden
              van de door Opdrachtnemer bij de uitvoering van de Opdracht ingeschakelde derde(n), dan wel van
              Opdrachtnemer zelf en/of verlies en/of schade aan de eigendommen van de door Opdrachtnemer ingeschakelde
              derde(n) dan wel van Opdrachtnemer zelf en/of vermogensschade geleden door Opdrachtnemer dan wel de door
              Opdrachtnemer ingeschakelde derde(n);
            </li>
            <li>
              Opdrachtnemer draagt zorg voor een passende aansprakelijkheidsverzekering
              (bedrijfsaansprakelijkheidsverzekering) en beroepsaansprakelijkheidsverzekering voor de door haar
              verleende en nog te verlenen Zorg in natura met een minimale dekking van €1.000.000,- per gebeurtenis en
              zorgt ervoor, dat ter zake van deze aansprakelijkheidsverzekering(en) afdoende dekking gewaarborgd zal
              blijven door de premies tijdig te betalen.
            </li>
          </ol>
        </li>

        <li>
          <h3>Artikel 9 &mdash; Vrijwaring en Verrekening</h3>
          <ol>
            <li>
              Indien het niet nakomen en/of overtreden van enige bepaling van de Overeenkomst door één van de Partijen
              (“de overtredende partij”) leidt tot schade is de andere partij daarvoor niet aansprakelijk. Voor zover de
              andere partij daarvoor wordt aangesproken vrijwaarde de overtredende partij de andere partij geheel. Onder
              schade wordt teven begrepen boetes en/of (belasting)rente;
            </li>
            <li>
              In voorkomend geval zal de geleden schade mogen worden verrekend met al hetgeen door de benadeelde partij
              op dat moment aan de overtredende partij verschuldigd is of zal worden uit welke hoofde dan ook,
              onverminderd het recht van de benadeelde partij om geleden schade op enige andere wijze te verhalen op de
              overtredende partij.
            </li>
          </ol>
        </li>

        <li>
          <h3>Artikel 10 &mdash; Geheimhouding</h3>
          <ol>
            <li>
              Opdrachtnemer is zich bewust van de zeer gevoelige aard van de cliëntgegevens die hem bij de uitvoering
              van de Opdracht ter kennis zullen komen en zal de wettelijke bepalingen met betrekking tot de bescherming
              van de persoonlijke levenssfeer en privacy van cliënten in acht nemen;
            </li>
            <li>
              Opdrachtnemer is gehouden alle informatie en bedrijfsgegevens omtrent de organisatie van Opdrachtgever (of
              aan hem gelieerde organisaties) die hem/haar in het kader van de Opdracht zijn verstrekt of ter kennis
              zijn gekomen geheim te houden. Het is Opdrachtnemer verboden om zonder toestemming van Opdrachtgever
              bedoelde informatie aan te wenden voor eigen gebruik of gebruik door derden, tenzij en voor zover dit
              noodzakelijk is in het kader van de normale uitvoering van de opdracht of ter vervulling van een
              wettelijke plicht;
            </li>
            <li>
              Opdrachtnemer verbindt zich jegens Opdrachtgever om maatregelen te treffen om geheimhouding te verzekeren
              met betrekking tot de in 9.1 en 9.2 genoemde gegevens waarvan Opdrachtnemer, of de door hem ingeschakelde
              derde(n), kennisneemt;
            </li>
            <li>Deze bepaling geldt zowel tijdens als na afloop van de Overeenkomst;</li>
            <li>
              Bij één of meer overtredingen van het bepaalde in dit artikel verbeurt de overtredende partij jegens de
              andere partij een onmiddellijk opeisbare boete van €10.000,- per overtreding, alsmede een boete gelijk aan
              €1.500,- voor elke dag dat de overtreding of de niet nakoming voortduurt, onverminderd het recht van de
              andere partij om in plaats daarvan de door haar daadwerkelijk als gevolg van de overtreding geleden schade
              te vorderen, alles te vermeerderen met kosten en de wettelijke rente ex artikel 6:119a BW.
            </li>
          </ol>
        </li>

        <li>
          <h3>Artikel 11 &mdash; Overige Voorwaarden</h3>
          <ol>
            <li>
              Wijzigingen van en/of aanvullingen op de Overeenkomst kunnen uitsluitend schriftelijk door partijen worden
              overeengekomen;
            </li>
            <li>
              Indien enige bepaling van de Overeenkomst nietig is dan wel vernietigd wordt, zullen de overige bepalingen
              van kracht blijven en zullen Partijen in overleg treden teneinde nieuwe bepalingen ter vervanging van de
              nietige c.q. vernietigde bepalingen overeen te komen, waarbij zoveel mogelijk het doel en de strekking van
              de nietige c.q. vernietigde bepalingen in acht wordt genomen;
            </li>
            <li>
              Opdrachtnemer verklaart:
              <ul>
                <li>
                  Geen gebreken te hebben, die van belang zouden kunnen zijn voor de beoordeling van de belastbaarheid
                  van de zzp’er met het oog op de vervulling van de overeengekomen opdracht, althans is daarmee niet
                  bekend of kan redelijkerwijs niet geacht worden daarmee bekend te zijn;
                </li>
                <li>
                  Niet strafrechtelijk veroordeeld te zijn of als verdachte te worden aangemerkt voor feiten die, indien
                  deze bekend zouden zijn bij de Opdrachtgever, deze ervan weerhouden zouden hebben de overeenkomst aan
                  te gaan;
                </li>
                <li>
                  Het verstrekte profiel naar waarheid te hebben opgesteld en vragen tijdens de gesprekken ihkv de
                  ‘vergewisplicht Wkkgz’ met TallKing Connect naar waarheid te hebben beantwoord en dat verstrekte
                  documenten danwel kopieën daarvan berusten op de werkelijkheid.
                </li>
              </ul>
            </li>
          </ol>
        </li>

        <li>
          <h3>Artikel 12 &mdash; Toepasselijk Recht en Forumkeuze</h3>
          <ol>
            <li>Op deze overeenkomst is Nederlands recht van toepassing.</li>
            <li>
              Alle geschillen die tussen partijen uit deze Overeenkomst voortvloeien, zullen worden voorgelegd aan de
              rechter te Utrecht.
            </li>
          </ol>
        </li>
      </ol>
    </div>
    <div className={styles.agree}>
      <p>Aldus overeengekomen door acceptatie middels het TallKing Connect platform,</p>
      <p>
        Plaats en datum: {model?.createdAt ? formatDate(model.createdAt) : 'XX-XX-XXXX'}, {model?.districtTeamCity}
      </p>
      <table>
        <tbody>
          <tr>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>Opdrachtgever</td>
            <td>Opdrachtnemer</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className={styles.reflist}>
      <ul className={styles.flat}>
        <li>
          <a id="fn1" href="#fn1-ref">
            <sup>1</sup>
          </a>
          &nbsp;
          <A href="https://www.rijksoverheid.nl/onderwerpen/kwaliteit-van-de-zorg/wet-kwaliteit-klachten-en-geschillen-zorg">
            https://www.rijksoverheid.nl/onderwerpen/kwaliteit-van-de-zorg/wet-kwaliteit-klachten-en-geschillen-zorg
          </A>
        </li>
        <li>
          <a id="fn2" href="#fn2-ref">
            <sup>2</sup>
          </a>
          &nbsp;
          <A href="https://www.rijksoverheid.nl/onderwerpen/kwaliteit-van-de-zorg/wet-toetreding-zorgaanbieders-wtza">
            https://www.rijksoverheid.nl/onderwerpen/kwaliteit-van-de-zorg/wet-toetreding-zorgaanbieders-wtza
          </A>
        </li>
        <li>
          <a id="fn3" href="#fn3-ref">
            <sup>3</sup>
          </a>
          &nbsp;
          <A href="https://puc.overheid.nl/nza/doc/PUC_696016_22/1/">
            https://puc.overheid.nl/nza/doc/PUC_696016_22/1/
          </A>
        </li>
      </ul>
    </div>
  </div>
);

export default CareAgreement;
