import clsx from 'clsx';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Eye, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import A from 'src/components/a';
import Button from 'src/components/button/button';
import { IconDialog } from 'src/components/dialog/dialog';
import { Form, FormActions, FormRow } from 'src/components/form/form';
import LabeledFormInput, { LabeledFormInputView } from 'src/components/form/labeled_form_input';
import InputFile from 'src/components/input_file/input_file';
import InputTextArea from 'src/components/input_textarea';
import { usePostingReactionMutation } from 'src/hooks/postings';
import styles from './reactions.module.scss';

const SaveDialog = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation('page_postings');
  return (
    <IconDialog
      onConfirm={onConfirm}
      onCancel={onCancel}
      labelQuestion={t(
        'reactions.form.saveDialogQuestion',
        'Door te reageren ga je ermee akkoord dat de ontvangende partij toegang krijgt tot je telefoonnummer en contact met je mag opnemen.'
      )}
    />
  );
};

const CancelDialog = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation('page_postings');
  return (
    <IconDialog
      onConfirm={onConfirm}
      onCancel={onCancel}
      labelQuestion={t(
        'reactions.form.cancelDialogQuestion',
        'Weet je het zeker? Eventuele wijzigingen worden niet opgeslagen.'
      )}
    />
  );
};

const ReplaceDocumentDialog = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation('page_postings');
  return (
    <IconDialog
      onConfirm={onConfirm}
      onCancel={onCancel}
      labelQuestion={t(
        'reactions.form.replaceDocumentDialogQuestion',
        'Weet je zeker dat je dit document wilt vervangen?'
      )}
    />
  );
};

const ReactionForm = ({ reaction: initialReaction = {}, edit: _edit = false, resumeRequired, onClose }) => {
  const { t } = useTranslation('page_postings');

  const [reaction, setReaction] = useState(initialReaction);
  const [valid, setValid] = useState(false);
  const [dialogData, setDialogData] = useState(null);

  useEffect(() => {
    const valid =
      // If resume is required, either a new file must have been added or the current model already has a reference to a resume
      (!resumeRequired || reaction.resumeBlobData || reaction.resumeId) &&
      // Description (that is: note) is required
      reaction.description &&
      reaction.description.length > 0;
    setValid(valid);
  }, [resumeRequired, reaction]);

  const updateModel = (key) => (value) =>
    setReaction((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  const bindModel = (label, key, defaultValue, component, props = {}) => (
    <LabeledFormInput
      component={component}
      label={label}
      value={_.defaultTo(_.get(reaction, key), defaultValue)}
      onChange={updateModel(key)}
      {...props}
    />
  );

  const showDialog = (component, onConfirm) =>
    setDialogData({
      component,
      onConfirm,
      onCancel: () => setDialogData(),
    });

  const showSaveDialog = () => {
    showDialog(SaveDialog, save);
  };

  const showCancelDialog = () => {
    showDialog(CancelDialog, () => onClose(false));
  };

  const showReplaceDocumentDialog = () => {
    showDialog(ReplaceDocumentDialog, () =>
      setReaction((prevState) => ({
        ...prevState,
        resumeId: null,
        resumeUrl: null,
      }))
    );
  };

  const mutation = usePostingReactionMutation({
    onSuccess: () => {
      onClose(true);
    },
  });

  const save = () => {
    const { resumeBlobData = {}, ...cleanReaction } = reaction;
    mutation.mutate({
      reaction: cleanReaction,

      // Resume blob is uploaded as `documentBase64String` and `contentType`
      ...resumeBlobData,
    });
  };

  const resumeLabel = resumeRequired
    ? t('reactions.form.cvRequiredLabel', 'Curriculum Vitae vereist')
    : t('reactions.form.cvOptionalLabel', 'Curriculum Vitae optioneel');

  return (
    <>
      {dialogData && <dialogData.component {...dialogData} />}
      <Form>
        <FormRow>
          <h2>{t('reactions.form.myReaction', 'Mijn reactie')}</h2>
        </FormRow>
        <FormRow>{bindModel(t('reactions.form.aboutMe', 'Over mij'), 'description', '', InputTextArea)}</FormRow>

        {reaction.resumeUrl ? (
          <FormRow>
            <LabeledFormInputView
              label={resumeLabel}
              value={
                <div>
                  <A href={reaction.resumeUrl} className={styles.iconLink}>
                    <Eye />
                    {t('reactions.form.view', 'Inzien')}
                  </A>
                  <div onClick={showReplaceDocumentDialog} className={clsx(styles.iconLink, styles.replace)}>
                    <Trash2 />
                    {t('reactions.form.replace', 'Vervangen')}
                  </div>
                </div>
              }
            />
          </FormRow>
        ) : (
          <FormRow>{bindModel(resumeLabel, 'resumeBlobData', '', InputFile)}</FormRow>
        )}

        <FormActions>
          <Button disabled={mutation.isLoading} secondary onClick={showCancelDialog}>
            {t('reactions.form.cancel', 'Annuleren')}
          </Button>
          <Button disabled={mutation.isLoading || !valid} onClick={showSaveDialog}>
            {t('reactions.form.save', 'Opslaan')}
          </Button>
        </FormActions>
      </Form>
    </>
  );
};

export default ReactionForm;
