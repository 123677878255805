import invariant from 'invariant';
import _ from 'lodash';
import { useState } from 'react';
import { Eye, Lock } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import A from 'src/components/a';
import { CheckButton, CrossButton } from 'src/components/button/button';
import { IconDialog } from 'src/components/dialog/dialog';
import { Form, FormActions, FormRow } from 'src/components/form/form';
import { LabeledFormInputView } from 'src/components/form/labeled_form_input';
import InputTextArea from 'src/components/input_textarea';
import { useUserInformation } from 'src/hooks/users';
import { useGateway } from 'src/providers/gateway';
import { formatCurrency, formatDateTime } from 'src/utils';
import { PostingDocumentType, PostingStatusReactable, getPostingDocumentTypeLabel } from '../constants';
import ActionsMenu from './actionsMenu';
import { PostingReactionStatus } from './constants';
import styles from './reactions.module.scss';

const AcceptOfferDialog = ({ posting, onConfirm, onCancel }) => {
  const { t } = useTranslation(['page_postings', 'common']);

  // TODO: retrieve these from the model
  const documents = [
    {
      url: t('common:termsOfUse.link'),
      label: t('common:termsOfUse.title'),
    },
    ...posting.documents
      .filter((doc) => [PostingDocumentType.Terms].includes(doc.type))
      .map((doc) => ({
        url: doc.url,
        label: getPostingDocumentTypeLabel(doc.type, t),
      })),
  ];

  return (
    <IconDialog
      className={styles.acceptOfferDialog}
      onConfirm={onConfirm}
      onCancel={onCancel}
      labelQuestion={t(
        'reactions.view.acceptTermsAndConditionsDialogQuestion',
        'Door te reageren stem je in met de volgende voorwaarden:'
      )}
    >
      <div className={styles.documents}>
        {documents.map((doc, index) => (
          <div key={index} className={styles.iconLink}>
            <Eye />
            <A href={doc.url}>{doc.label}</A>
          </div>
        ))}
      </div>
    </IconDialog>
  );
};

const RejectOfferDialog = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation('page_postings');
  const [note, setNote] = useState('');
  return (
    <IconDialog
      onConfirm={() => onConfirm(note)}
      onCancel={onCancel}
      labelQuestion={t('reactions.view.doNotAgreeBecauseDialogQuestion', 'Ik ga niet akkoord, omdat:')}
    >
      <InputTextArea value={note} onChange={setNote} />
    </IconDialog>
  );
};

const ReactionResponseForm = ({ reaction, posting }) => {
  const { t } = useTranslation('page_postings');
  const { api } = useGateway();
  const navigate = useNavigate();

  const [dialogData, setDialogData] = useState(null);
  const [processing, setProcessing] = useState(false);

  const showDialog = (component, onConfirm, props = {}) =>
    setDialogData({
      component,
      onConfirm,
      onCancel: () => setDialogData(),
      ...props,
    });

  const showAcceptDialog = () => {
    showDialog(AcceptOfferDialog, () => respondToOffer(true), { posting });
  };

  const showRejectDialog = () => {
    showDialog(RejectOfferDialog, (note) => respondToOffer(false, note));
  };

  const respondToOffer = async (accepted, note = '') => {
    setProcessing(true);
    try {
      await api.postings.respondToPostingOffer({
        reactionId: reaction.id,
        accepted,
        note,
      });
      navigate(0);
    } catch (error) {
      console.error('Error processing request:', error);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      {dialogData && <dialogData.component {...dialogData} />}
      <Form className={styles.responseForm}>
        <FormRow>
          <Trans t={t} i18nKey="reactions.view.offerReceived">
            <p>
              Er is een aanbod gedaan n.a.v. je reactie!
              <br />
              {/* TODO: model doesn't contain 'offeredRate' or similar property, only the posting does! */}
              Je hebt een voorstel gekregen tegen een uurtarief van{' '}
              <strong>{{ agreedRate: formatCurrency(posting.agreedRate || 0) }}</strong>
              <br />
              Ga je hiermee akkoord?
            </p>
          </Trans>
        </FormRow>
        <FormRow>
          <LabeledFormInputView
            label={t('reactions.view.provisionalCareAgreement', 'Voorlopige zorgovereenkomst')}
            value={
              <span className={styles.iconLink}>
                <Eye />
                <Link
                  // To open in a new page (much slower), uncomment the following 2 lines
                  // target="_blank"
                  // rel="noopener noreferrer"
                  to={`${reaction.id}/agreement`}
                >
                  Inzien
                </Link>
              </span>
            }
          />
        </FormRow>
        <FormActions>
          <CrossButton disabled={processing} onClick={showRejectDialog} />
          <CheckButton disabled={processing} onClick={showAcceptDialog} />
        </FormActions>
      </Form>
    </>
  );
};

const ReactionView = ({ reaction: initialReaction = {}, posting, onChange = _.noop }) => {
  const { t } = useTranslation(['page_postings', 'common']);
  const { hasRole } = useUserInformation();

  const [reaction, setReaction] = useState(initialReaction);

  const isCaregiver = hasRole('Caregiver');
  const canEdit = isCaregiver && reaction && PostingStatusReactable.includes(posting.status);
  const showLock = isCaregiver && !canEdit;

  const updateModel = (nextModel) => {
    setReaction(nextModel);
    onChange(nextModel);
  };

  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <h1>Reactie</h1>
        {canEdit && <ActionsMenu reaction={reaction} onChangeModel={updateModel} />}
        {showLock && (
          <span title={t('reactions.view.editingNotPossible', 'Bewerken niet (meer) mogelijk')}>
            <Lock />
          </span>
        )}
      </div>
      <Form>
        <FormRow>
          <LabeledFormInputView
            label={t('reactions.view.state', 'Status')}
            value={t(`constants.postingReactionStatusLabels.${reaction.status}`)}
          />
        </FormRow>

        {[PostingReactionStatus.Pending].includes(reaction.status) && (
          <FormRow>
            <ReactionResponseForm reaction={reaction} posting={posting} />
          </FormRow>
        )}

        {[PostingReactionStatus.Accepted].includes(reaction.status) && (
          <FormRow>
            <LabeledFormInputView
              label={t('reactions.view.careAgreement', 'Zorgovereenkomst')}
              value={
                <span className={styles.iconLink}>
                  <Eye />
                  <Link
                    // To open in a new page (much slower), uncomment the following 2 lines
                    // target="_blank"
                    // rel="noopener noreferrer"
                    to={`${reaction.id}/agreement`}
                  >
                    {t('reactions.view.view', 'Inzien')}
                  </Link>
                </span>
              }
            />
          </FormRow>
        )}

        <FormRow>
          <LabeledFormInputView
            label={t('reactions.view.cv', 'Curriculum Vitae')}
            value={
              reaction.resumeUrl ? (
                <span className={styles.iconLink}>
                  <Eye />
                  <A href={reaction.resumeUrl}>{t('reactions.view.view', 'Inzien')}</A>
                </span>
              ) : (
                '-'
              )
            }
          />
        </FormRow>
        <FormRow>
          <LabeledFormInputView label={t('reactions.view.note', 'Toelichting')} value={reaction.description} />
        </FormRow>
        <FormRow>
          <LabeledFormInputView
            label={t('reactions.view.createdAt', 'Aangemaakt op')}
            value={formatDateTime(reaction.createdAt, { format: t('common:dateTimeFormat') })}
          />
          <LabeledFormInputView
            label={t('reactions.view.updatedOn', 'Laatst aangepast op')}
            value={formatDateTime(reaction.updatedAt, { format: t('common:dateTimeFormat') })}
          />
        </FormRow>
      </Form>
    </div>
  );
};

const EmptyView = ({ placeholder }) => (
  <div className={styles.container}>
    <div className={styles.placeholder}>{placeholder}</div>
  </div>
);

const Page = ({ reaction, reactions, placeholder = '', ...props }) => {
  const { t } = useTranslation('page_postings');
  invariant(!reaction || !reactions, t('reactions.view.cantProvideBothReationAndReactionProps'));

  if (reaction) {
    reactions = [reaction];
  }

  return (
    <div className={styles.page}>
      {reactions.length ? (
        reactions.map((reaction) => <ReactionView key={reaction.id} reaction={reaction} {...props} />)
      ) : (
        <EmptyView placeholder={placeholder} />
      )}
    </div>
  );
};

export default Page;
