import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LoadingOverlay from 'src/components/loader/loader';
import { usePosting } from 'src/hooks/postings';
import PostingForm from './form';
import styles from './postings.module.scss';

const EditPosting = () => {
  const { t } = useTranslation('page_postings');
  const { postingId } = useParams();
  const posting = usePosting(postingId);

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <h1>{t('changeApplicationForLongTermCommitment', 'Aanvraag langdurige inzet aanpassen')}</h1>
        {posting.isFetched ? <PostingForm edit posting={posting.data} /> : <LoadingOverlay />}
      </div>
    </div>
  );
};

export default EditPosting;
