import moment from 'moment';
import { type DateHeaderProps } from 'react-big-calendar';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore DateHeader is not exported directly from 'react-big-calendar', and there is no properly corresponding type either at the moment
import DefaultDateHeader from 'react-big-calendar/lib/DateHeader';
import { WeekNumber } from './WeekNumber';
import styles from './calendar.module.scss';

export const DateHeader: React.FC<DateHeaderProps> = (props) => {
  const dateMoment = moment(props.date);

  return (
    <>
      <div className={styles.dateHeader}>
        {
          // Display week number on leftmost day in calendar view
          dateMoment.weekday() === 0 && <WeekNumber week={dateMoment.week()} weekYear={dateMoment.weekYear()} />
        }
      </div>

      <DefaultDateHeader {...props} />
    </>
  );
};
