import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import { IconDialog } from 'src/components/dialog/dialog';
import { Form, FormActions, FormRow } from 'src/components/form/form';
import LabeledFormInput from 'src/components/form/labeled_form_input';
import InputTextArea from 'src/components/input_textarea';
import { emptyObject } from 'src/constants';
import { useIndividualCareReactionMutation } from 'src/hooks/individualCare';
import { formatCurrency } from 'src/utils';

const SaveDialog = ({ onConfirm, onCancel, hourlyRate }) => {
  const { t } = useTranslation('page_individualCare');
  // formatCurrency gives problems in Trans text. So calculate before using
  const hourlyRateText = formatCurrency(hourlyRate);
  return (
    <IconDialog
      onConfirm={onConfirm}
      onCancel={onCancel}
      labelQuestion={
        <Trans t={t} i18nKey="reactions.form.saveDialogQuestion">
          Door te reageren ga je akkoord met het tarief van <strong>{{ hourlyRate: hourlyRateText }}</strong>
          <br />
          <br />
          Daarnaast ga je ermee akkoord dat de ontvangende partij toegang krijgt tot je telefoonnummer en contact met je
          mag opnemen
        </Trans>
      }
    />
  );
};

const CancelDialog = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation('page_individualCare');
  return (
    <IconDialog
      onConfirm={onConfirm}
      onCancel={onCancel}
      labelQuestion={t(
        'reactions.form.cancelDialogQuestion',
        'Weet je het zeker? Eventuele wijzigingen worden niet opgeslagen.'
      )}
    />
  );
};

const ReactionForm = ({ reaction: initialReaction = emptyObject, edit: _edit = false, onClose, hourlyRate }) => {
  const { t } = useTranslation('page_individualCare');

  const [reaction, setReaction] = useState(initialReaction);
  const [valid, setValid] = useState(false);
  const [dialogData, setDialogData] = useState(null);

  useEffect(() => {
    if (initialReaction) {
      setReaction(initialReaction);
    }
  }, [initialReaction]);

  useEffect(() => {
    const valid =
      // Description (that is: note) is required
      reaction.description && reaction.description.length > 0;
    setValid(valid);
  }, [reaction]);

  const updateModel = (key) => (value) =>
    setReaction((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  const bindModel = (label, key, defaultValue, component, props = {}) => (
    <LabeledFormInput
      component={component}
      label={label}
      value={_.defaultTo(_.get(reaction, key), defaultValue)}
      onChange={updateModel(key)}
      {...props}
    />
  );

  const showDialog = (component, onConfirm, hourlyRate) =>
    setDialogData({
      component,
      onConfirm,
      onCancel: () => setDialogData(),
      hourlyRate,
    });

  const showSaveDialog = () => {
    showDialog(SaveDialog, save, hourlyRate);
  };

  const showCancelDialog = () => {
    showDialog(CancelDialog, () => onClose(false));
  };

  const mutation = useIndividualCareReactionMutation({
    onSuccess: () => {
      onClose(true);
    },
  });

  const save = () => {
    mutation.mutate({
      reaction: {
        ...reaction,
        suggestedRate: hourlyRate,
      },
    });
  };

  return (
    <>
      {dialogData && <dialogData.component {...dialogData} />}
      <Form>
        <FormRow>
          <h2>{t('reactions.form.myReaction', 'Mijn reactie')}</h2>
        </FormRow>
        <FormRow>{bindModel(t('reactions.form.aboutMe', 'Over mij'), 'description', '', InputTextArea)}</FormRow>

        <FormActions>
          <Button disabled={mutation.isLoading} secondary onClick={showCancelDialog}>
            {t('reactions.form.cancel', 'Annuleren')}
          </Button>
          <Button disabled={mutation.isLoading || !valid} onClick={showSaveDialog}>
            {t('reactions.form.save', 'Opslaan')}
          </Button>
        </FormActions>
      </Form>
    </>
  );
};

export default ReactionForm;
