import clsx from 'clsx';
import validateIban from 'iban-number-validation';
import _ from 'lodash';
import { useState } from 'react';
import { TextField } from '../input_text/input_field';
import styles from './input_iban.module.scss';

const InputIban = ({ className, required = false, onValidate = _.noop, ...props }) => {
  const [valid, setValid] = useState(null);

  const validate = (value) => {
    const isValid = (!required && value === '') || validateIban(value);

    setValid(isValid);
    onValidate(isValid);
  };

  return (
    <div className={clsx(styles.container, className)}>
      <TextField valid={valid} onValidate={validate} {...props} />
    </div>
  );
};

export default InputIban;
