import { useTranslation } from 'react-i18next';
import styles from './calendar.module.scss';

export const WeekNumber = ({ week, weekYear }) => {
  const { t } = useTranslation('common');

  return (
    <span className={styles.weekNumber} title={`${t('week')} ${week}-${weekYear}`}>
      {week}
    </span>
  );
};
