import clsx from 'clsx';
import _ from 'lodash';
import { Component, createRef } from 'react';
import { XCircle } from 'react-feather';
import { withTranslation } from 'react-i18next';
import Chevron from '../chevron/chevron';
import Information from '../information';
import styles from './select.module.scss';

class Select extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      viewportOffset: null,
    };

    this.htmlElement = createRef();
  }

  onClickOutside = (event) => {
    // Check if the click occurred outside of our popup
    if (this.state.expanded && !this.htmlElement.current.contains(event.target)) {
      this.setState({ expanded: false });
    }
  };

  toggleDropdownVisible = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  selectOption = (value) => {
    const { multiple = false, value: prevValue = [] } = this.props;
    const nextValue =
      // If we support multi-select ...
      multiple
        ? // Then toggle the current option
          prevValue.includes(value)
          ? prevValue.filter((v) => v !== value)
          : prevValue.concat(value)
        : // Else set the selected items to this one value
          [value];

    this.setState({
      // Remain visible if multiselect, hide if not
      expanded: multiple,
      value: nextValue,
    });

    this.props.onChange(multiple ? nextValue : nextValue[0]);
  };

  clear = () => {
    const { multiple = false } = this.props;

    this.setState({
      value: [],
    });

    this.props.onChange(multiple ? [] : null);
  };

  componentDidMount = () => {
    const ul = this.htmlElement.current.querySelector('ul');
    if (ul) {
      this.setState(
        {
          viewportOffset: ul.getBoundingClientRect(),
        },
        () => {
          const listStyle = this.htmlElement.current.querySelector('ul').style;
          const { x, y, width, height } = this.state.viewportOffset;

          if (window.innerWidth < x + width) {
            listStyle.right = 0;
          } else {
            listStyle.left = 0;
          }
          if (window.innerHeight < y + height) {
            listStyle.bottom = '100%';
            listStyle.top = 'auto';
          } else {
            listStyle.bottom = 'auto';
            listStyle.top = '100%';
          }
        }
      );
    }

    window.addEventListener('click', this.onClickOutside, true);
  };

  componentWillUnmount = () => {
    window.removeEventListener('click', this.onClickOutside, true);
  };

  render() {
    const { t } = this.props;
    const { expanded } = this.state;
    const {
      disabled = false,
      readOnly = false,
      options,
      placeholder,
      style,
      className,
      value,
      multiple,
      showClear = false,
      clearTitle = t('clearTitle', 'Legen'),
    } = this.props;

    const hasValue = !_.isNil(value) && value !== [];
    const displayValue = hasValue
      ? options
          .filter((option) => (multiple ? value.includes(option.value) : value === option.value))
          .map((option) => option.flatLabel || option.label)
          .join(', ')
      : null;

    return (
      <div
        className={clsx(styles.container, className, disabled && styles.disabled, readOnly && styles.readOnly)}
        style={style}
      >
        <div
          ref={this.htmlElement}
          onClick={(e) => {
            e.stopPropagation();

            if (!disabled && !readOnly) {
              this.toggleDropdownVisible();
            }
          }}
          title={displayValue || placeholder}
        >
          <div className={styles.labelWrapper}>
            <label className={clsx(!displayValue && styles.placeholder, showClear && styles.hasClear)}>
              {displayValue || placeholder}
            </label>
            {showClear && !_.isEmpty(value) && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  this.clear();
                }}
                className={styles.clear}
                title={clearTitle}
              >
                <XCircle />
              </div>
            )}
          </div>
          {!disabled && !readOnly && (
            <ul className={clsx(styles.optionsList, expanded && styles.expanded)}>
              {options.map((option) => (
                <li
                  key={option.value}
                  disabled={option.disabled}
                  className={clsx(
                    hasValue &&
                      (this.props.multiple ? value.includes(option.value) : value === option.value) &&
                      styles.selected
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.selectOption(option.value);
                  }}
                >
                  <div className={styles.checkIcon} />
                  {option.profilePictureUrl && (
                    <img src={option.profilePictureUrl} title={option.label} alt={option.label} />
                  )}
                  {option.label}
                  {option.information && <Information>{option.information}</Information>}
                </li>
              ))}
            </ul>
          )}
          {!readOnly && <Chevron dir={expanded ? 'up' : 'down'} disabled={disabled} />}
        </div>
      </div>
    );
  }
}

export default withTranslation('components')(Select);
