import moment from 'moment';
import { type DateCellWrapperProps } from 'react-big-calendar';
import { PlusButton } from '../button/button';
import styles from './calendar.module.scss';

export const DateCellWrapper: React.FC<DateCellWrapperProps> = (props) => {
  const dateMoment = moment(props.value);
  const showAddButton = dateMoment.isSameOrAfter(moment(), 'day');

  return (
    <div className={styles.dateCellHeader}>
      {showAddButton && (
        <div className={styles.addButtonContainer}>
          <PlusButton className={styles.addButton} />
        </div>
      )}

      {props.children}
    </div>
  );
};
